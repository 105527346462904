import { Tabs } from "antd";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";

import PageConfigurationContext from "../../../context/pageContext";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage, Tab } from "../../../types/page";
import { ABOUT_TAB_KEY, useMobileSingleViewContext } from "../context/useMobileSingleViewContext";
import { MobileSingleViewTabProvider } from "../context/useMobileSingleViewTabContext";
import CardList from "./CardList";
import SingleViewCardMobileAboutTab from "./SingleViewCardMobileAboutTab";

const { TabPane } = Tabs;

const SingleViewMobileTabs: FC = (): JSX.Element => {
  const { isNewEntity, isEdited, activeTab, handleSetActiveTab } = useMobileSingleViewContext();

  const { t } = useTranslation();
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const pageTabs = pageConfig.tabsPanel;

  // in case of new entity we should not show all tabs
  if (isNewEntity || isEdited) {
    return <SingleViewCardMobileAboutTab />;
  }

  // disable tabs for other entities
  if (pageConfig.id !== "employee" && pageConfig.id !== "actions") {
    return <SingleViewCardMobileAboutTab />;
  }

  const getTabs = () => {
    const filteredTabs = pageTabs
      .filter(t => t.type !== "tree")
      // we need to hide files tab for now
      .filter(t => t.label !== "Files");
    const nodes = [
      <TabPane tab={t("About")} key={ABOUT_TAB_KEY}>
        <SingleViewCardMobileAboutTab />
      </TabPane>,
      ...filteredTabs.map(tab => (
        <TabPane tab={t(tab.label)} key={tab.key}>
          {renderContent(tab)}
        </TabPane>
      )),
    ];

    return nodes;
  };

  const renderContent = (tab: Tab<Record<string, unknown>, Record<string, unknown>>) => {
    return (
      <MobileSingleViewTabProvider tabConfig={tab}>
        <CardList />
      </MobileSingleViewTabProvider>
    );
  };

  return (
    <>
      <Tabs
        className="single-view-tabs"
        onTabClick={handleSetActiveTab}
        activeKey={activeTab}
        renderTabBar={(props, TabNavList) => <TabNavList {...props} mobile={false} />}
      >
        {getTabs()}
      </Tabs>
    </>
  );
};

export default SingleViewMobileTabs;
