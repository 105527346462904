import "./CardTitle.less";

import { Typography } from "antd";

import { CategoryId } from "../../../../../types/page";
import { MOBILE_PATH } from "../../../../../utils/functions";
import { useMobileSingleViewContext } from "../../../context/useMobileSingleViewContext";

type TProps = {
  title: string | number;
  category?: CategoryId;
  id?: string | number;
};

const CardTitle: React.FC<TProps> = ({ title, category, id }) => {
  const { openNextPage } = useMobileSingleViewContext();
  const { Text } = Typography;

  if (!title) return null;

  const handleRedirect = () => {
    if (!category || !id) return;
    openNextPage(`/${category}/${id}${MOBILE_PATH}`);
  };

  return (
    <Text className="card-title" ellipsis onClick={handleRedirect}>
      {title}
    </Text>
  );
};

export default CardTitle;
