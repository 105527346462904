/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, AnyAction, AsyncThunkAction } from "@reduxjs/toolkit";

import { Rule } from "antd/lib/form";
import { RcFile } from "antd/lib/upload/interface";
import { Dayjs } from "dayjs";
import React, { ReactNode, SVGProps } from "react";
import { TFunction } from "react-i18next";

import { View } from "../components/CategoryHeader/types";
import { MobileEvent } from "../components/EmbeddedModal/useFormManagmentEvents";
import { CustomComponentProps } from "../components/TableCellRenderer/TableCellRenderer";
import { TreeData } from "../pages/pageConfig/category/utilities";
import { AppSelector } from "../selectors";
import { AppDispatch, RootState } from "../store/store";
import { DefaultFilter, Filter, FilterEntityType } from "../utils/hooks/useFilter";
import { AppThunk, ArrayElement } from "../utils/types";
import { CustomPropertyType, CustomPropInfo } from "./customProperty";
import { BaseEntityType } from "./entityBase";
import { EntityPropertyInfo } from "./import";
import { UserRole, UserState } from "./user";
import { ActionTypes, ExplicitAdditionalProps, TableAddButtonType } from "./utility";

type CategoryId =
  | "equipment"
  | "employee"
  | "training"
  | "location"
  | "certification"
  | "inspectionEquipment"
  | "inspectionLocation"
  | "orgUnit"
  | "survey"
  | "equipmentType"
  | "locationType"
  | "inspectionEquipmentType"
  | "inspectionLocationType"
  | `${CategoryType}CustomProperty`
  | "actions"
  | "issueType"
  | "dashboard"
  | "recurrentActions"
  | "webhooks";

type CategoryType = "employee" | "location" | "equipment" | "training" | "inspectionEquipment" | "inspectionLocation";

type CategoryIdWithFilesTab = "equipment" | "employee" | "training" | "certification" | "orgUnit" | "actions";

export const InputTypes = {
  INPUT: "input",
  SELECT: "select",
  TEXT: "text",
  TEXT_AREA: "textArea",
  DATE: "date",
  TAGS: "tags",
  DROPDOWN: "dropdown",
  TREESELECT: "treeselect",
  AUTOCOMPLETE: "autocomplete",
  DATE_RANGE: "dateRange",
  BOOLEAN: "boolean",
  MULTI_SELECT: "multiSelect",
  NUMBER: "number",
  PHONE_INPUT: "phoneInput",
  EMAIL: "email",
  ...CustomPropertyType,
} as const;

export type InputTypes = typeof InputTypes[keyof typeof InputTypes];

export const CellTypes = {
  TEXT: InputTypes.TEXT,
  DROPDOWN: InputTypes.DROPDOWN,
  ACTIONS: "actions",
  BUTTON_ACTIONS: "buttonActions",
  SEARCH: "search",
  INPUT: InputTypes.INPUT,
  ICON: "icon",
  CUSTOM: "custom",
};

export type CellTypes = typeof CellTypes[keyof typeof CellTypes];

type ValueType =
  | "number"
  | "string"
  | "date" // if text, it won't be editable
  | "boolean";

type TOption = {
  id: string | number | null;
  label: string;
  icon?: ReactNode;
};

// type TBoolOption = {
//   id: boolean;
//   label: EmployeeBool;
// }

type CardActionFunctionType = {
  navigation: string;
  // FIXME: Fix the type to use either Normal action or async action
  action: any;
};

interface CardAction<TEntity> {
  label: string; // label of the action
  icon?: ReactNode; // icon before the label
  action: (entity: TEntity) => Partial<CardActionFunctionType>; // functionality of the action
}

interface ColumnAction<TEntity> {
  label: string; // label of the action
  conditionalLabel?: (entity: TEntity) => ActionTypes | string;
  component?: (entity: TEntity) => JSX.Element | null;
  icon?: ReactNode; // icon before the label
  className?: string;
  actionType?: string;
  action: (entity: TEntity) => any; // functionality of the action
  href?: (entity: TEntity) => string;
  changesConfirmation?: ChangesConfirmation;
  openUploadModal?: boolean;
  uploadFileAction?: AppThunk<
    any,
    { file: RcFile; value: string; entityId: number; entity?: Record<string, unknown>; date?: string }
  >;
  // cardButtons: {
  //   primaryButton: CardDropDownAction<TEntity>;
  //   dropdownButtons: CardDropDownAction<TEntity>[];
  // };
}

interface DotsMenuAction<TEntity> {
  label: string; // label of the action
  icon?: ReactNode; // icon before the label
  className?: string;
  action: (entity: TEntity[]) => any; // functionality of the action
  changesConfirmation?: ChangesConfirmation;
  type: "single" | "bulk" | "inline";
  privilege?: (user: UserState) => boolean;
  // cardButtons: {
  //   primaryButton: CardDropDownAction<TEntity>;
  //   dropdownButtons: CardDropDownAction<TEntity>[];
  // };
}

interface ModalPrompt {
  body: string;
  okText: string;
  cancelText?: string;
}

interface ChangesConfirmation {
  body: string;
  okText: string;
  cancelText: string;
  addValueToBody?: boolean;
  addValueToOkText?: boolean;
  showConditionally?: (value: string | number | boolean | null) => boolean;
}

interface NavigationWarningConfirmation extends ChangesConfirmation {
  navigateOnOk: string;
  navigateOnCancel: string;
}

export type PartialBaseField = Pick<
  BaseField<Record<string, unknown>>,
  "id" | "type" | "title" | "optionsSelector" | "value" | "disabledDate" | "required" | "placeholder" | "cellPrefixIcon"
> & { defaultValue?: string | number | null };

export type IconProps = SVGProps<SVGSVGElement> & {
  value?: any;
  title?: string | undefined;
};

export enum SectionType {
  ContextSection = "ContextSection",
  DatesSection = "DatesSection",
  AdministrationSection = "AdministrationSection",
  DetailsSection = "DetailsSection",
}

export interface BaseField<TEntity> {
  id: keyof TEntity;
  title?: string; // The static name of the field
  placeholder?: string; // Placeholder for empty state
  placeholderMobileFirst?: string; // Placeholder mobile first version
  valueText?: (entity: TEntity) => string | null | boolean | number; // The dynamic value text from the entity
  value: (entity: TEntity) => string | null | boolean | number; // The dynamic value id from the entity
  localizedValue?: (entity: TEntity) => string | null | boolean | number;
  isHidden?: (entity: TEntity | null) => boolean | null;
  isHiddenOnMobile?: (entity: TEntity | null) => boolean | null; //add isHiddenOnMobile to hide the field on mobile version (another one will be deleted on next steps of migration)
  validationRules?: Rule[];
  Icon?: React.FunctionComponent<IconProps>; // Icon / Color if any as
  type: InputTypes; // The type of input it should render;
  multilineInput?: boolean; //show popup or not
  options?: TOption[]; // If select, add the options
  optionsSelector: (state: RootState) => TOption[] | null;
  dataFetchAction?: AppThunk<any, any>;
  // If the type is 'treeselect' we need the treeData property
  treeData?: {
    treeDataSelector: (state: RootState) => Record<string, unknown>[];
    treeDataSet: (dataSet: TEntity[], currentEntityId?: string) => TreeData[];
    isNullableField?: boolean;
  };
  selector?: (state: RootState) => Record<string, unknown>[];
  // treeDataSelector?: //(state: RootState) => TEntity[];
  dependentFields?: BaseField<TEntity>[];
  required?: boolean;
  pattern?: string;
  // Note: If we want the card field to trigger a tab switch action (e.g. Clicking on Location field), then we don't render EditableInput component and instead render a clickable text that fires this action
  tabSwitchAction?: {
    tabSwitch: boolean;
    action: () => string | undefined;
  };
  editForbidden?: (e: TEntity) => boolean;
  changesConfirmation?: ChangesConfirmation;
  disabledDate?: (entity?: TEntity) => (date: Dayjs) => boolean; // If we want to disable some dates being selected from a 'date' type
  basedOnDependensiesOptions?: (currentData: any, selectedDependentData: any[] | null) => TOption[] | null;
  searchById?: boolean;
  cleanOnChange?: string[]; //list of keys to clean along with any change of field, make sure it nullable
  enableMobileScanners?: boolean;
  cellPrefixIcon?: ReactNode;
}

interface SummaryCard<TEntity> {
  CardIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >; // Icon / Color if any as
  primaryFields: BaseField<TEntity>[]; // Main one with icon
  secondaryFields: BaseField<TEntity>[]; // Fields below the primary one
  customFieldsAdditionalProps?: { editForbidden?: (e: TEntity) => boolean };
  RRuleComponent?: {
    fieldName: string; //cron field from entity
  };
  mobileSingleView?: MobileSingleView<TEntity>;
  // TODO: Maybe it should be per field, instead of global?
  globalEditForbidden?: (e: TEntity) => boolean;
  dependentFields?: (
    e: TEntity,
    isNewEntity?: boolean,
    manuallyChangedFields?: string[]
  ) => { id: keyof TEntity; parentId: keyof TEntity; defaultValue: string | number | boolean | null }[]; // Here we must specify all fields that can change their value based on their parent field's value. (e.g. Employee 'username' must become null if 'isUser' is false)
  cardInformationalMessasge?: (e: TEntity) => string; // Message to show on the upper right side of the card (e.g. 'Training status is done so no changes can be made to the card')
  // tertiaryFields: BaseField<TEntity>[]; // Fields next across secondary
  // bottomFields: BaseField<TEntity>[]; // Fields at the bottom of the card
  // actions: CardAction<TEntity>[]; // Actions related to the type of entity
  remotelyUpdatedFieldIDs?: string[]; // Fields that can be updated from the tabs panel. Usually that can be done from a "Tree" tab.
  navigationWarning?: NavigationWarningConfirmation;
  actionComponent?: (entity: TEntity) => JSX.Element | null;
  customEditButtons?: (
    entity: TEntity,
    onSubmitChanges: ({ additionTrigger }: { additionTrigger?: boolean }) => Promise<void>,
    isNewEntity?: boolean
  ) => JSX.Element[] | null; //define more than default edit buttons
  canEdit?: (user: UserState, entity: TEntity | null | undefined, canEditSelectedData?: any[]) => boolean; // Verify if the user can edit the card, if not set this will default to Admin/SU only
  canEditSelector?: (state: RootState) => any;
  confirmationMessage?: (entity: TEntity) => string | null;
}

interface Section<TEntity> {
  type: SectionType;
  fields: (BaseField<TEntity> | undefined)[];
}

interface Summary<TEntity> {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title?: BaseField<TEntity>;
  id?: BaseField<TEntity>;
  status?: BaseField<TEntity>;
  mainContextRelation?: BaseField<TEntity>;
  additionalContextRelation?: BaseField<TEntity>;
  date?: BaseField<TEntity>;
  [key: string]: any;
}

interface MobileSingleView<TEntity> {
  summary: Summary<TEntity>;
  sections: Section<TEntity>[];
}

export type MobileAddEntitySectionTProps = {
  entity: Record<string, string | number | RcFile | boolean | null>;
  onChange: (id: string) => (value: string | number | boolean | null) => void;
  onFiledValueChange: (field: Record<string, string | number | RcFile | boolean | null>) => void;
};

export type MobileCardTemplateTProps<TEntity> = {
  entity: TEntity;
};

export type DistributedMobileCardTemplateTProps<T> = T extends any ? React.FC<MobileCardTemplateTProps<T>> : never;

type DistributedColumnWithDifferentCells<T> = T extends any ? ColumnWithDifferentCells<T> : never;

type TablePresentationType = "table" | "tree" | "paginatedTable";

interface Tab<TEntity, TPrimaryEntity> {
  key: string;
  label: string;
  tabSelector: AppSelector<TEntity[], RootState>; // Select the whole data set for the tab
  tabDataThunk?: (e: string) => AsyncThunkAction<any, any, any>; // TODO: fix any type
  type: TablePresentationType;
  addButton?: {
    enablingFor?: (userState: UserState) => boolean;
    visibleSelector?: AppSelector<any, RootState>;
    visibleCondition?: (data: any) => boolean;
    conditionalVisible?: (state: TEntity[]) => boolean;
    action?: ActionCreatorWithoutPayload<string>;
    label: string;
    type: TableAddButtonType;
    uploadFileAction?: AppThunk<any, { file: any; expiration: string }>;
    addCommentAction?: AppThunk<any, { comment: string }>;
    addRecordModalAction?: (entity: TEntity) => any;
    addModalInputFields?: {
      primaryFields: PartialBaseField[];
      secondaryFields?: PartialBaseField[];
    };
    privilege?: (user: UserState) => boolean;
    mobileAddButtonTemplate?: React.FC<MobileAddEntitySectionTProps>;
    actionField?: DistributedColumnWithDifferentCells<TEntity>;
    addEntityFields?: DistributedColumnWithDifferentCells<TEntity>[];
  };
  dropdownButtonActions?: DotsMenuAction<TEntity>[];
  rowSelection?: boolean; // only for a tableTab; Can the table rows be selected
  currentSelection?: () => number | null;
  columns?: ColumnWithSearchCell<ArrayElement<TEntity>>[] | ColumnWithDropdownCell<ArrayElement<TEntity>>[]; //ColumnType<ArrayElement<TEntity>>[];
  changeNewEntityParent?: ActionCreatorWithPayload<number>; // This is valid only for "Tree" tabs. Controls the 'Move' button functionality of a newly created entity
  changeExistingEntityParent?: AppThunk<TPrimaryEntity, { objectId: number; newValue: number }>; // This is valid only for "Tree" tabs. Controls the 'Move' button functionality of an existing entity
  selectedKeyId?: string; // This is valid only for "Tree" tabs. Describe which is the id by which the row is selected. Controls the row selection inside the "TreeTable" component
  listSearch?: ListSearch<TEntity>;
  generateDynamicColumns: (props: ExplicitAdditionalProps[], data: any) => ColumnType<any>[]; // TODO: fix any type
  customPropertiesSelector: AppSelector<ExplicitAdditionalProps[] | null, RootState>;
  onRowClick?: (entity: TEntity) => void;
  defaultFilters?: Filter[];
  mobileCardTemplate?: DistributedMobileCardTemplateTProps<TEntity>;
}

interface ColumnType<TEntity> {
  id: keyof TEntity; // The index key by which to select
  label: string; // Label of the column;
  renderValue: (e: TEntity) => string | null; // How we are going to present the value
  valueIcon?: (e: TEntity) => ReactNode; // If the value should hold an icon as well;
  valueType?: ValueType; // The type of value this column data;
  asyncFetchFilterOptionsEntityType?: FilterEntityType; // The type of value this column data;
  asyncFetchFilterOptionsUseKeyValue?: boolean; // Use endpoint to return id and requested value pair on async fetch;
  asyncFetchFilterOptionsIsId?: boolean; // Use endpoint to find and return requested id;
  propName?: string;
  optionsSelector: (state: RootState) => TOption[] | null; // Return () => null if filter is not option supported // The options we can filter by
  fetchAsyncOptions?: AppThunk<{ queryString: string }, void>;
  sortable: boolean; // If it is sortable
  filterType?: InputTypes; //The type of filter to render
  filterLabel?: string; //The label of filter to render
  width?: number;
  cellType?: (e: TEntity) => CellTypes; // What we are going to show in the column's cells
  CustomComponent?: React.FunctionComponent<CustomComponentProps>; // Custom component to render in the cell
  isCellContentVisible?: (e: TEntity) => boolean;
  onCellOnClick?: (e: TEntity) => void;
  onChange?: (e: TEntity, newValue: number | string | boolean | null, fieldId: string) => any;
  onEditableModeChanged?: ActionCreatorWithPayload<{ value: boolean; id: number }>;
  // cellDropdownOptions?: TOption[]; // The options available if the 'cellType' is 'dropdown'
  // cellDropdownOnChange?: (e: TEntity) => any; // The onChangeHandler if the 'cellType' is 'dropdown'
  // cellSearchSelector?: AppSelector<TEntity[], RootState>; // The search data selector if the 'cellType' is 'search'
  stagingActions?: ColumnAction<TEntity>[]; // The actions available if the 'cellType' is 'actions'
  actions?: ColumnAction<TEntity>[]; // The actions available if the 'cellType' is 'actions'
  buttonWithDropdownActions?: {
    primaryButton: ColumnAction<TEntity>;
    dropdownButtons: ColumnAction<TEntity>[];
  };
  // buttons?: {
  //   stagingButtons:
  // }
  hidden?: boolean;
  isHidden?: (e: TEntity) => boolean;
  primaryColumn?: (
    e: TEntity
  ) => {
    navigationTarget: string;
  };
  editable?: (e: TEntity) => boolean;
  cutomPropInfo?: CustomPropInfo;
  exportPropertyId?: keyof TEntity; //The index key by which the value needs to be taken for export
  cellPrefixIcon?: ReactNode;
  placeholder?: string;
}

interface ColumnWithSearchCell<TEntity> extends ColumnType<TEntity> {
  cellSearchSelector?: AppSelector<TEntity[], RootState>; // The search data selector if the 'cellType' is 'search'
  cellSearchResultColumns?: ColumnType<ArrayElement<TEntity>>[]; // The columns we'll show on the search result window
  cellSearchKeys?: string[];
  cellSearchRecordClick?: ActionCreatorWithPayload<{ row: TEntity; targetEntity: Record<string, unknown> }>;
}

interface ColumnWithDropdownCell<TEntity> extends ColumnType<TEntity> {
  cellDropdownOptions?: (e: TEntity) => TOption[]; // The options available if the 'cellType' is 'dropdown'
  cellDropdownOnChange?: (e: TEntity, newValue: number | string | boolean | null) => any; // The onChangeHandler if the 'cellType' is 'dropdown'
}

export type ColumnWithDifferentCells<TEntity> = ColumnWithDropdownCell<TEntity> & ColumnWithSearchCell<TEntity>;

export type ListViewTable<TEntity> = {
  columns: ColumnType<ArrayElement<TEntity>>[];
  fetchListViewData?: AppThunk<
    { primaryData: TEntity; defaultCustomProperties: ExplicitAdditionalProps[] },
    boolean | undefined
  >;
  fetchPaginatedData?: AppThunk<
    {
      primaryData: TEntity;
      defaultCustomProperties: ExplicitAdditionalProps[];
      possibleResults: number;
      defaultPageSize?: number;
    },
    { filters?: string; page?: number; pageSize?: number; forceUpdate?: boolean } | undefined
  >;
  possibleResultsSelector?: AppSelector<number, RootState>;
  resetCurrentPage?: ActionCreatorWithoutPayload<string>;
  searchPaginatedData?: AppThunk<TEntity, { filters?: string; page?: number } | undefined>;
  resetSearchResult?: ActionCreatorWithoutPayload<string>;
  searchResultsSelector?: AppSelector<TEntity | [], RootState>;
  tableSelector: AppSelector<TEntity | [], RootState>;
  rowSelection: boolean;
  defaultColumnKeys?: string[];
  disableNavigation?: boolean;
  type: TablePresentationType;
};

// interface ListSearchColumn<TEntity> {
//   id: keyof TEntity; // The index key by which to select
//   label: string; // Label of the column;
//   renderValue: (e: TEntity) => string | null; // How we are going to present the value
//   valueIcon?: (e: TEntity) => ReactNode; // If the value should hold an icon as well;
// }

interface ListSearch<TEntity> {
  columns: Partial<ColumnType<ArrayElement<TEntity>>>[]; // The columns we'll show on the search result window
  keys: string[]; // The keys we want to search by
}

interface ListView<TEntity> {
  table: ListViewTable<TEntity>;
  // Note: This lets us generate dynamic columns based on the customPropertyValues (custom fields for each tenant). After that they are concatenated with the static columns inside ListViewVTable
  generateDynamicColumns: (props: ExplicitAdditionalProps[], data: any) => ColumnType<ArrayElement<TEntity>>[];

  // listViewDataSelector?: AppSelector<TEntity, RootState>;
  // listSearch?: ListSearch<TEntity>;
  customNavigation?: {
    pageKey: string;
    entityId: (record: Record<string, unknown>) => number;
  };
}

interface GroupView<TEntity, RootState> {
  CardIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  cardButtons?: {
    primaryButton: CardDropDownAction<TEntity>;
    dropdownButtons: CardDropDownAction<TEntity>[];
  };
  totalCountField: CardBaseField<TEntity>;
  primaryFields: CardBaseField<TEntity>[];
  secondaryFields: CardBaseField<TEntity>[];
  cardsSelector: AppSelector<TEntity[], RootState>;
  fetchCardThunk: AppThunk<TEntity[], boolean | undefined>;
}

// interface GroupViewCard<TEntity> {

// }
type CardBaseField<TEntity> = {
  id: keyof TEntity;
  title?: string; // The static name of the field
  placeholder?: string; // Placeholder for empty state
  value: (entity: TEntity) => string | null | boolean | number; // The dynamic value from the entity
  localizedValue?: (entity: TEntity) => string | null | boolean | number;
  action?: (
    entity: TEntity
  ) => {
    action: () => any;
    link?: {
      path: string;
      state?: Record<string, unknown>;
    };
  };
  icon?: (e: TEntity) => ReactNode;
  disableOnClickEvent?: boolean;
};

/**
 * Used to define actions in the drop-down menu on configurable pages
 */
interface CardDropDownAction<TEntity> {
  title: string;
  action?: (e: TEntity) => void;
  link?: (
    e: TEntity
  ) => {
    path: string;
    prefillData?: Record<string, unknown>; // TODO: Type could be more specific (e.g. keyof primary entity)
  };
  showColumnManagement?: () => boolean;
}

export interface DropDownAction<TEntity> {
  /**
   * Use this ID to hard identity the action from the list
   */
  id: string;
  title: string;
  /**
   * Identifies that the action should be performed on data, and will be updated dynamically from the ListViewTable
   */
  isDataOperation: boolean;
  action?: (
    a: DropDownAction<TEntity>,
    jwt: string,
    e?: TEntity[],
    t?: TFunction<"translation">,
    dispatch?: AppDispatch
  ) => void | string | null | undefined;
  /**
   * Cached version of the original action (if any) to be used with context
   * preservation when called from outer components
   * @desc This is a helper property and should not be used directly
   */
  originalAction?:
    | ((
        a: DropDownAction<TEntity>,
        jwt: string,
        e?: TEntity[],
        t?: TFunction<"translation">,
        dispatch?: AppDispatch
      ) => void | string | null | undefined)
    | null;
  disabled?: boolean;
  link?: (
    entities: TEntity[]
  ) => {
    path: string;
    prefillData?: Record<string, unknown>; // Note: Data that the card must be prefilled with - it gets added to location.state.prefillData
    warningMessage?: ModalPrompt;
  };
  showColumnManagement?: () => boolean;
  canAccess?: () => boolean;
  content?: ReactNode;
  // validateAction?: ModalPrompt;
  stateBaseAccess?: (user: UserState, singleViewEnitity?: TEntity | null) => boolean;
}

type BasePage<TEntity extends BaseEntityType> = {
  id: CategoryId | CategoryIdWithFilesTab;
  title: string;
  mobileFirstTitle?: string;
  intializeDataActions?: AppThunk<any, any>[];
  PageIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  addButton?: {
    label: string; // i18key
    action: () => void;
    enablingFor?: (userState: UserState) => boolean;
    isMenu?: boolean;
    addButtonActions?: DropDownAction<TEntity["listViewData"]>[];
    addButtonModal?: {
      addModalLabel?: string; // i18key
      visible?: boolean;
      type?: TableAddButtonType;
      addRecordModalAction?: (entity: any) => any;
      addModalInputFields?: {
        primaryFields: PartialBaseField[];
        secondaryFields?: PartialBaseField[];
      };
      addNewByDefault?: boolean;
    };
  };
  isLoading: AppSelector<boolean, boolean[]>;
  actions?: DropDownAction<TEntity["entityData"]["primaryData"]>[];
  singleViewActions?: DropDownAction<TEntity["entityData"]["primaryData"]>[];
  lastUpdatedSelector: AppSelector<string, RootState>;
  allowedUserRoles?: UserRole[];
  defaultView?: View;
  primarySingleEntitySelector: AppSelector<TEntity["entityData"]["primaryData"] | null | undefined, RootState>;
  mobileEvents?: Array<{ type: MobileEvent; listener: (event: Event) => AnyAction; dispatch?: boolean }>;
};
// TODO: Redo type once we have more than one page categories
interface CustomPage<TEntity extends BaseEntityType> extends BasePage<TEntity> {
  CustomView?: React.FunctionComponent<any>;
}
interface CategoryPage<TEntity extends BaseEntityType> extends BasePage<TEntity> {
  entityEndpoint: string;
  fetchSingle: AppThunk<
    {
      singleData: TEntity["entityData"]["primaryData"];
      subData: TEntity["subData"];
      defaultCustomProperties: ExplicitAdditionalProps[];
      additionalData?: any; //if needs to pass any aditional data, use this property
    },
    string
  >;
  primaryErrorSelector: AppSelector<string | null, RootState>;
  clearError: ActionCreatorWithoutPayload<string>;
  fetchCustomProps?: AppThunk<ExplicitAdditionalProps[], void>;
  createNewEntityTemplate?: ActionCreatorWithoutPayload<string>; // Resets the state of the entity in order to create a new one
  createNewEntity?: AppThunk<
    TEntity["entityData"]["primaryData"],
    { entity: TEntity["entityData"]["primaryData"]; additionTrigger?: boolean }
  >; // Creates a new entity (equipment/employee/etc.)
  updateEntity?: AppThunk<TEntity["entityData"]["primaryData"], TEntity["entityData"]["primaryData"]>; // Updates an existing entity (equipment/employee/etc.)
  breadcrumbSingleValue: (e: TEntity["entityData"]["primaryData"]) => TOption;
  customPropertiesSelector: AppSelector<ExplicitAdditionalProps[], RootState>;
  summaryCard: SummaryCard<TEntity["entityData"]["primaryData"]>;
  tabsPanel: Tab<TEntity["tabs"], TEntity["entityData"]["primaryData"]>[];
  listView?: ListView<TEntity["listViewData"][]>;
  listSearch?: ListSearch<TEntity["entityData"]["primaryData"][]>;
  hasGroupView: boolean;
  hasListView?: boolean;
  groupView?: GroupView<TEntity["groupViewData"], RootState>;
  listViewActions?: DropDownAction<TEntity["listViewData"]>[];
  singleViewActions?: DropDownAction<TEntity["entityData"]["primaryData"]>[];
  entityProperties?: EntityPropertyInfo[];
  //difining default filter
  defaultFilters?: DefaultFilter[];
}

export type {
  CategoryId,
  CategoryIdWithFilesTab,
  CustomPage,
  CategoryPage,
  TOption,
  BasePage,
  InputTypes as FieldType,
  ColumnType,
  // ListSearchColumn,
  TablePresentationType,
  Tab,
  CardAction,
  ColumnAction,
  ChangesConfirmation,
  DotsMenuAction,
  CardBaseField,
  CategoryType,
};
