import { Col } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import DetailsComponent from "./DetailsComponent";
import IconComponent from "./IconComponent";

type SingleViewHeaderProps = {
  editIcon?: JSX.Element;
  actionComponent?: JSX.Element | null;
  isNewEntity?: boolean;
};

const SingleViewHeader: React.FC<SingleViewHeaderProps> = ({ editIcon, actionComponent }) => {
  const direction = useSelector((state: RootState) => state.user.settings.direction);

  return (
    <div dir={direction} className="entity-card">
      <div className="header">
        <div className="employee-info">
          <div className="image-container">
            <Col>
              <IconComponent />
            </Col>
          </div>
          <DetailsComponent isNewEntity />
          <div className="icons-container">
            {editIcon}
            {actionComponent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleViewHeader;
