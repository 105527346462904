import "chart.js/auto";
import "./HBChart.less";

import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import { BubbleDataPoint, ChartTypeRegistry, ScatterDataPoint } from "chart.js";
import React, { createRef, useState } from "react";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { Layout } from "react-grid-layout";
import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";

import { RootState } from "../../../store/store";
import { Chart, ChartWithData } from "../../../types/dashboard";
import ChartHeader from "./ChartHeader";
import ChartOptions from "./ChartOptions/ChartOptions";
import HierarchicalDataControl from "./ChartOptions/HirarchialDataControl/HierarchicalDataControl";
import { HierarchicalDataControlContextProvider } from "./ChartOptions/HirarchialDataControl/HierarchicalDataControlContextProvider";
import ChartContainer from "./components/ChartContainer";

type HBChartProps = {
  layout?: Layout;
  chart?: Chart;
  locked?: boolean;
  chartIndex?: number;
};

export type GraphRef = React.RefObject<
  ChartJSOrUndefined<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>
>;

type HBChartContext = {
  modal?: Omit<ModalStaticFunctions, "warn">;
  chart?: ChartWithData;
  graph?: GraphRef;
};

export const ChartContext = React.createContext<HBChartContext>({});

export default function HBChart({ locked, chart }: HBChartProps) {
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  // const [graph, setGraph] = useState<GraphRef>(createRef<ChartJSOrUndefined>());
  const [graph] = useState<GraphRef>(createRef<ChartJSOrUndefined>());

  return (
    <ChartContext.Provider value={{ chart: chart, graph }}>
      <HierarchicalDataControlContextProvider>
        <div className="hbChart">
          <div className="hb-chart-header-container">
            <div className="hbChartHeader">
              {!locked && (
                <div
                  className="chartHandel"
                  style={{
                    backgroundColor: tinycolor(primaryColor).darken(2).setAlpha(0.1).toString(),
                    borderColor: tinycolor(primaryColor).darken(10).setAlpha(0.5).toString(),
                  }}
                />
              )}
              <ChartHeader />
              <ChartOptions />
            </div>
            {chart?.useHierarchicalNavigation && <HierarchicalDataControl />}
          </div>
          <ChartContainer ref={graph} />
        </div>
      </HierarchicalDataControlContextProvider>
    </ChartContext.Provider>
  );
}
