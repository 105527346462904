import { createSelector } from "@reduxjs/toolkit";
import { Row, Typography } from "antd";
import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TreeData } from "../../pages/pageConfig/category/utilities";
import { pageSelectors } from "../../selectors";
import { RootState } from "../../store/store";
import { FieldType, InputTypes, TOption } from "../../types/page";
import { AppThunk } from "../../utils/types";
import { InputModal } from "../InputModal/InputModal";
import InputRenderer from "../InputRender";
import "./editableInput.less";

type TProps = {
  onChange: (v: string | null | boolean | number) => void; // The change handler for the new value coming from the input
  fieldValue: string | null | number; // The current value
  fieldText?: string | null | number; // The current value Text
  placeholder?: string; // Placeholder for the input;
  multilineInput?: boolean;
  type: FieldType;
  id: string; // Name of the field (type/name/email/etc.)
  title?: string | undefined; // Translatable name of the field (type/name/email/etc.)
  options?: TOption[];
  globalEdit: boolean;
  icon?: string | null | React.ReactNode;
  optionSelector: (state: RootState) => TOption[] | null;
  treeData?: {
    treeDataSelector: (state: RootState) => Record<string, unknown>[];
    treeDataSet: (dataSet: Record<string, unknown>[]) => TreeData[];
  };
  tabSwitch?: () => string | undefined;
  handleSetActiveTab?: (tabKey: string) => void;
  isNewEntity?: boolean;
  editForbidden?: boolean | null;
  onEditableModeChanged?: (value: boolean) => void;
  isEditMode?: boolean;
  disabledDate?: (date: Dayjs) => boolean;
  currentData?: Record<string, unknown> | null;
  selector?: (state: RootState) => Record<string, unknown>[];
  basedOnDependensiesOptions?: (currentData: unknown, selectedDependentData: unknown[] | null) => TOption[] | null;
  searchById?: boolean;
  canEdit?: boolean | undefined;
  localizedValue?: string | number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataFetchAction?: AppThunk<any, any>;
  onCardEdited?: (value: boolean) => void;
};

const EditableInput: React.FC<TProps> = ({
  fieldValue,
  fieldText,
  placeholder,
  onChange,
  multilineInput,
  type,
  id,
  icon,
  title,
  options,
  globalEdit,
  optionSelector,
  tabSwitch,
  handleSetActiveTab,
  children,
  isNewEntity,
  editForbidden,
  onEditableModeChanged,
  isEditMode,
  treeData,
  disabledDate,
  currentData,
  selector,
  basedOnDependensiesOptions,
  searchById,
  canEdit,
  localizedValue,
  dataFetchAction,
  onCardEdited,
}): JSX.Element => {
  const [isEditing, setEditing] = useState(false);
  const [isEditable, setIsEditable] = useState(canEdit);
  const [valueCheck, setValueCheck] = useState<string | number | null>(fieldValue);
  const [label, setLabel] = useState<string | number | null>(fieldValue);
  // Note: will be filled with options depending on the config implementation (which are used in 'autocomplete'/'dropdown'). Will return 'null' otherwise.
  const optionsSelector = createSelector(pageSelectors.stateSelector, optionSelector);
  const dependentDataSelector = createSelector(pageSelectors.stateSelector, selector ? selector : () => null);
  const selectedDependentData = useSelector(dependentDataSelector);
  const autoCompleteOptions = useSelector(optionsSelector);
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const treeDataSet = useSelector(treeData?.treeDataSelector || (() => null));
  const { t } = useTranslation();
  const [inputModalVissible, setInputModalVissible] = useState<boolean>(false);

  useEffect(() => {
    setIsEditable(canEdit);
  }, [canEdit]);

  useEffect(() => {
    if (isNewEntity) {
      setEditing(false);
    }
  }, [isNewEntity]);

  useEffect(() => {
    if (type === InputTypes.DROPDOWN || type === InputTypes.AUTOCOMPLETE || type === InputTypes.DICTIONARY) {
      const currentOption = (options ||
        autoCompleteOptions ||
        (basedOnDependensiesOptions && basedOnDependensiesOptions(currentData, selectedDependentData)))!.find(
        o => o.id === fieldValue
      );
      setValueCheck(currentOption?.id || fieldValue);
      return setLabel(localizedValue || currentOption?.label || null);
    }

    if (type === InputTypes.TREESELECT && treeDataSet) {
      const currentOption = treeDataSet!.find(o => o.id === fieldValue);
      setValueCheck(fieldValue);
      return setLabel((currentOption?.name as string) || null);
    }

    if (type === InputTypes.BOOLEAN && typeof fieldValue === "boolean") {
      setValueCheck(+fieldValue);
      const label = (options || autoCompleteOptions)!.find(o => o.id === +fieldValue)?.label;
      const localizedLabel = label ? t(label.toString()) : fieldValue;
      return setLabel(localizedLabel);
    }

    setLabel(localizedValue || fieldValue);
    // if (type === "date") {
    //   debugger;
    // }
    return setValueCheck(fieldValue);
  }, [fieldValue, autoCompleteOptions, options, treeData]);

  useEffect(() => {
    if (!globalEdit) {
      setEditing(false);
    }
  }, [globalEdit]);

  useEffect(() => {
    if (isEditMode !== undefined) {
      setEditing(isEditMode);
    }
  }, [isEditMode]);

  const handleClick = () => {
    if (type === InputTypes.INPUT || type === InputTypes.TEXT_AREA) {
      setInputModalVissible(true);
    }

    // We fire the alternative action if such is provided, instead of always entering edit mode
    if (type === InputTypes.TEXT) {
      if (tabSwitch && handleSetActiveTab) {
        return handleSetActiveTab(tabSwitch() as string);
      }
      return;
    }
    setEditing(true);

    if (onEditableModeChanged) onEditableModeChanged(true);
  };

  const handleSetInputModalVissible = () => {
    setInputModalVissible(!inputModalVissible);
  };

  const renderValue = () => {
    return fieldText || (label !== "0" && label) || placeholder || null;
  };

  return (
    <div data-test={`EditableInput=${id}`}>
      {isEditing ? (
        <Row style={{ width: "100%" }}>
          {inputModalVissible && (
            <InputModal
              value={valueCheck}
              handleOpen={handleSetInputModalVissible}
              open={inputModalVissible && Boolean(multilineInput) && type === InputTypes.INPUT}
              onChange={onChange}
            />
          )}
          <InputRenderer
            onCardEdited={onCardEdited}
            onFocus={handleSetInputModalVissible}
            onChange={onChange}
            value={valueCheck}
            managedValue={valueCheck}
            type={type}
            options={
              options ||
              autoCompleteOptions ||
              (basedOnDependensiesOptions && basedOnDependensiesOptions(currentData, selectedDependentData))
            }
            conditionalFocus={isEditing && !inputModalVissible}
            id={id}
            icon={icon}
            title={title}
            treeData={treeData}
            label={label}
            disabledDate={disabledDate}
            searchById={searchById}
            dataFetchAction={dataFetchAction}
          >
            {children}
          </InputRenderer>
        </Row>
      ) : (
        <Row
          onClick={!editForbidden && isEditable ? handleClick : undefined}
          className={(!editForbidden && isEditable ? "pointer " : "") + "editableInput-wrapper"}
          // style={{ width: "100%" }}
        >
          <Row className="editableInput-wrapper__value">
            {icon && (
              <Typography.Text dir={direction} className="editableInput-wrapper__addl-info" ellipsis>
                {icon}
              </Typography.Text>
            )}
            {title && (
              <Typography.Text dir={direction} className="editableInput-wrapper__addl-info" ellipsis title={title}>
                {`${title}:`}
              </Typography.Text>
            )}
            <Typography.Text
              className={type === InputTypes.PHONE_INPUT ? "ltr-only-phone-input" : ""}
              ellipsis
              title={label !== null ? label?.toString() : undefined}
            >
              {renderValue()}
            </Typography.Text>
          </Row>
        </Row>
      )}
    </div>
  );
};

export default EditableInput;
