import { Typography } from "antd";
import { Dayjs } from "dayjs";
import { useEffect } from "react";
import { booleanOptions } from "../pages/pageConfig/category/common";
import { TreeData } from "../pages/pageConfig/category/utilities";
import { RootState, useAppDispatch } from "../store/store";
import { InputTypes, TOption } from "../types/page";
import { AppThunk } from "../utils/types";
import HBAutocomplete from "./HBComponents/Autocomplete/HBAutocomplete";
import HBDatePicker from "./HBComponents/DatePicker/HBDatePicker";
import HBDropdown from "./HBComponents/Dropdown/HBDropdown";
import HBInput from "./HBComponents/Input/HBInput";
import { HBTreeSelect } from "./HBComponents/TreeSelect/TreeSelect";
import TagsComponent from "./TagsComponent/TagsComponent";

type TProps = {
  onChange: (v: string | null | boolean | number) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>; // Change handler for the new value;
  value: string | null | number; // Current value;
  type: InputTypes; // Type of input
  id: string; // Name of the field (type/name/email/etc.)
  options?: TOption[] | null; // Options if any (always true for dropdown)
  conditionalFocus?: boolean; // Input should be force focused
  icon?: string | null | React.ReactNode;
  title?: string | undefined; // Translatable name of the field (type/name/email/etc.)
  managedValue?: string | null | number;
  treeData?: {
    treeDataSelector: (state: RootState) => Record<string, unknown>[];
    treeDataSet: (dataSet: Record<string, unknown>[]) => TreeData[];
  };
  label: string | number | null;
  disabledDate?: (date: Dayjs) => boolean;
  searchById?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataFetchAction?: AppThunk<any, any>;
  enableMobileScanners?: boolean;
  onCardEdited?: (value: boolean) => void;
  prefixIcon?: React.ReactNode;
  placeholder?: string;
};

const InputRenderer: React.FC<TProps> = ({
  onChange,
  onFocus,
  value,
  managedValue,
  options,
  type,
  conditionalFocus,
  children,
  icon,
  title,
  treeData,
  label,
  disabledDate,
  searchById,
  dataFetchAction,
  enableMobileScanners,
  prefixIcon,
  onCardEdited,
  placeholder,
}): JSX.Element | null => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (dataFetchAction) dispatch(dataFetchAction(false));
  }, []);

  const renderInput = () => {
    switch (type) {
      case InputTypes.TAGS:
        return (
          <TagsComponent title={title} options={options} onChange={onChange} initialTags={value as string | null} />
        );
      case InputTypes.TEXT_AREA:
        return (
          <HBInput onChange={onChange} type={type} value={value} conditionalFocus={conditionalFocus} title={title} />
        );
      case InputTypes.INPUT:
      case InputTypes.STRING: // For CustomProps
      case InputTypes.NUMERIC: // For CustomProps
        return (
          <HBInput
            additionalAction={onCardEdited}
            onFocus={onFocus}
            onChange={onChange}
            value={value}
            conditionalFocus={conditionalFocus}
            title={title}
            enableMobileScanners={enableMobileScanners}
          />
        );
      // return <Form.Item label="Form Size" name="size"></Form.Item>;

      case InputTypes.NUMBER:
        return (
          <HBInput
            additionalAction={onCardEdited}
            onChange={onChange}
            value={value}
            conditionalFocus={conditionalFocus}
            title={title}
            type={InputTypes.NUMBER}
          />
        );

      case InputTypes.DROPDOWN:
      case InputTypes.BOOLEAN:
      case InputTypes.BOOL: // For CustomProps
        return (
          <HBDropdown
            onChange={onChange}
            value={value}
            options={options! || booleanOptions}
            managedValue={managedValue}
            conditionalFocus={conditionalFocus}
            type={type}
            icon={icon}
            title={title}
            prefixIcon={prefixIcon}
          >
            {children}
          </HBDropdown>
        );

      case InputTypes.TEXT:
        return <Typography.Text className="just-text">{value}</Typography.Text>;

      case InputTypes.DATE:
        return (
          <HBDatePicker
            value={value}
            onChange={onChange}
            icon={icon}
            disabledDate={disabledDate}
            placeholder={placeholder}
          >
            {children}
          </HBDatePicker>
        );
      case InputTypes.AUTOCOMPLETE:
        return (
          <HBAutocomplete
            enableMobileScanners={enableMobileScanners}
            onCardEdited={onCardEdited}
            searchById={searchById}
            value={value}
            onChange={onChange}
            options={options!}
            prefixIcon={prefixIcon}
            placeholder={placeholder}
          >
            {children}
          </HBAutocomplete>
        );
      case InputTypes.TREESELECT:
        return (
          <HBTreeSelect
            onCardEdited={onCardEdited}
            value={value}
            onChange={onChange}
            treeData={treeData}
            title={title}
            label={label}
          >
            {children}
          </HBTreeSelect>
        );
      case InputTypes.DICTIONARY:
        return (
          <HBAutocomplete
            onCardEdited={onCardEdited}
            searchById={searchById}
            value={value}
            onChange={onChange}
            options={options!}
          >
            {children}
          </HBAutocomplete>
        );
      case InputTypes.PHONE_INPUT:
        return (
          <HBInput
            onChange={onChange}
            value={value}
            conditionalFocus={conditionalFocus}
            title={title}
            type={InputTypes.PHONE_INPUT}
          />
        );
      case InputTypes.EMAIL:
        return (
          <HBInput
            additionalAction={onCardEdited}
            onChange={onChange}
            value={value}
            conditionalFocus={conditionalFocus}
            title={title}
            type={type}
          />
        );
      default:
        return null;
    }
  };

  return renderInput();
};

export default InputRenderer;
