import { Col, Form, Row } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PageConfigurationContext from "../../../context/pageContext";
import { getEmptyValue } from "../../../pages/pageConfig/category/utilities";
import { RootState } from "../../../store/store";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage } from "../../../types/page";
import { AdditionalProps } from "../../../types/utility";
import EditableInputMobileFirst from "../../EditableInput/EditableInputMobileFirst";
import { useMobileSingleViewContext } from "../context/useMobileSingleViewContext";

const CUSTOM_PROPERTY_VALUES_KEY = "customPropertyValues";

const CustomFieldsSection: React.FC = () => {
  const { t } = useTranslation();
  const { summaryCard } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const companyDictionaries = useSelector((state: RootState) => state.customProperty.companyDictionaries);

  const {
    canEditCard,
    handleChange,
    isEdited,
    handleSetActiveTab,
    isNewEntity,
    data,
    isGlobalEditForbidden,
  } = useMobileSingleViewContext();

  if (!data || !data[CUSTOM_PROPERTY_VALUES_KEY] || !(data[CUSTOM_PROPERTY_VALUES_KEY] as AdditionalProps[]).length) {
    return null;
  }

  return (
    <div className="section section-context">
      <span className="context-title">{t("PluginCustomPropName")}</span>
      <Row gutter={[16, 0]}>
        {Array.isArray(data[CUSTOM_PROPERTY_VALUES_KEY]) &&
          (data[CUSTOM_PROPERTY_VALUES_KEY] as AdditionalProps[]).map(input => {
            const currentCompanyDictionary =
              input.dictionaryId && companyDictionaries.find(x => x.id === input.dictionaryId);
            return (
              <Col key={input.name} className="field-wrapper" span={12}>
                <Row>
                  <Form.Item label={input.name && t(input.name)} name={input.name}>
                    <EditableInputMobileFirst
                      enableMobileScanners={input.actAsIdentifier}
                      isEditMode={isEdited}
                      fieldValue={(input.dictionaryId ? Number(input.value) : input.value) || ""}
                      globalEdit={isEdited}
                      onChange={handleChange(input.name, undefined)}
                      type={input.type}
                      id={input.name}
                      // TODO: add options from the dictionary of the custom field
                      options={
                        currentCompanyDictionary
                          ? currentCompanyDictionary.dictionaryValues
                            ? currentCompanyDictionary.dictionaryValues
                                .filter(x => x.isActive)
                                .map(x => ({ id: x.id, label: x.value }))
                            : [{ id: getEmptyValue().key, label: getEmptyValue().localizedValue }]
                          : undefined
                      }
                      optionSelector={() => null}
                      handleSetActiveTab={handleSetActiveTab}
                      isNewEntity={isNewEntity}
                      editForbidden={
                        (!isNewEntity &&
                          summaryCard.customFieldsAdditionalProps?.editForbidden &&
                          summaryCard.customFieldsAdditionalProps.editForbidden(data)) ||
                        isGlobalEditForbidden
                      }
                      canEdit={canEditCard}
                    >
                      {input.name && !isEdited && t(input.name)}
                    </EditableInputMobileFirst>
                  </Form.Item>
                </Row>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default CustomFieldsSection;
