import { InboxOutlined } from "@ant-design/icons";
import { Upload, Select, Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import { RcFile } from "antd/lib/upload/interface";
import dayjs, { Dayjs } from "dayjs";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InspectionStatus } from "../../../types/inspection";
import { UploadModalType } from "../../../types/utility";
import HBDatePicker, { DATE_FORMAT_WITHOUT_TIME } from "../DatePicker/HBDatePicker";
import HBInput from "../Input/HBInput";
import "./hbUploadModal.less";

const { Dragger } = Upload;
const { Option } = Select;
type TProps = {
  isVisible: boolean;
  beforeUpload: (
    comment: string,
    status?: InspectionStatus,
    date?: string
  ) => (file: RcFile, FileList: RcFile[]) => Promise<void | File | Blob | boolean>;
  closeModal: () => void;
  uploadModalType: UploadModalType;
  container?: string;
  disabledDate?: ((date: dayjs.Dayjs) => boolean) | undefined;
  setErrors?: (value: Record<string, string[]>) => void;
};

export const hasSpecialChars = (filename: string) => {
  return /[+%]/g.test(filename);
};

const HBUploadModal = ({
  isVisible,
  beforeUpload,
  closeModal,
  uploadModalType,
  container,
  disabledDate,
  setErrors,
}: TProps): JSX.Element => {
  const { t } = useTranslation();
  const [date, setDate] = useState(dayjs().format(DATE_FORMAT_WITHOUT_TIME));
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState<InspectionStatus | undefined>();
  const [file, setFile] = useState<RcFile | undefined>(undefined);

  const onDatePickerChanged = (value: string | null | number) => {
    const dayJsValue = value ? dayjs(value) : null;
    if (value && typeof value === "string" && dayJsValue && dayJsValue.isValid())
      setDate(dayJsValue.format(DATE_FORMAT_WITHOUT_TIME));
  };

  const onCommentChanged = (value: string | null | number) => {
    setComment(value?.toString() || "");
  };

  const onFileSelected = (file: RcFile): boolean | Promise<void | File | Blob> => {
    if (hasSpecialChars(file.name)) {
      setErrors && setErrors({ title: [t("Error")], message: [t("ErrorSpecialCharactersInFileName")] });
      return false;
    }
    setFile(file);

    return false;
  };

  const onSave = () => {
    beforeUpload(comment, status, date)(file!, []);
    setFile(undefined);
    setComment("");
    setDate(dayjs().format(DATE_FORMAT_WITHOUT_TIME));
  };

  const onClose = () => {
    setFile(undefined);
    setComment("");
    setDate(dayjs().format(DATE_FORMAT_WITHOUT_TIME));

    closeModal();
  };

  const onChangeStatus = (value: InspectionStatus) => setStatus(value);

  return (
    <Modal
      title={t("UploadFile")}
      open={isVisible}
      footer={null}
      onCancel={onClose}
      style={{ right: 0 }}
      getContainer={container}
    >
      {uploadModalType === UploadModalType.FileUpload && (
        <div>
          <span>{t("FieldExpiration")}</span>
          <span>
            <HBDatePicker noWrapperStyle value={date} onChange={onDatePickerChanged} />
          </span>
        </div>
      )}

      {uploadModalType === UploadModalType.TodoInspectionCheckpointFixed ||
      uploadModalType === UploadModalType.TodoInspectionCheckpointNotFixed ||
      uploadModalType === UploadModalType.MannualyUpdateFixedInspection ? (
        <div>
          {uploadModalType !== UploadModalType.TodoInspectionCheckpointNotFixed && (
            <>
              <span>{t("ActualCheck")}</span>
              <span>
                <HBDatePicker
                  disabledDate={
                    disabledDate ??
                    (uploadModalType === UploadModalType.MannualyUpdateFixedInspection ||
                      uploadModalType === UploadModalType.TodoInspectionCheckpointFixed)
                      ? (current: Dayjs) => current && current > dayjs(new Date())
                      : undefined
                  }
                  noWrapperStyle
                  value={date}
                  onChange={onDatePickerChanged}
                />
              </span>
            </>
          )}
          <div className="upload-modal-comment">
            <span>{t("Comment")}</span>
            <span>
              <HBInput onChange={onCommentChanged} value={comment} />
            </span>
          </div>
          {uploadModalType === UploadModalType.TodoInspectionCheckpointFixed ||
          uploadModalType === UploadModalType.TodoInspectionCheckpointNotFixed ? (
            <div className="select-status">
              <span>{t("Status")}:</span>
              <Select className="select-status" onChange={onChangeStatus}>
                <Option value={InspectionStatus.Failed}>{t(InspectionStatus.Failed)}</Option>
                <Option value={InspectionStatus.Passed}>{t(InspectionStatus.Passed)}</Option>
                <Option value={InspectionStatus.PassedWithConditions}>
                  {t(InspectionStatus.PassedWithConditions)}
                </Option>
              </Select>
            </div>
          ) : null}
        </div>
      ) : null}
      {uploadModalType === UploadModalType.InspectionStatus && (
        <div className="upload-modal-comment">
          <span>{t("Comment")}</span>
          <span>
            <HBInput onChange={onCommentChanged} value={comment} />
          </span>
        </div>
      )}
      <Dragger
        accept=".xlsx, .csv, .png, .jpg, .jpeg, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf,application/vnd.ms-excel"
        beforeUpload={
          uploadModalType === UploadModalType.InspectionStatus ||
          uploadModalType === UploadModalType.TodoInspectionCheckpointFixed ||
          uploadModalType === UploadModalType.TodoInspectionCheckpointNotFixed ||
          uploadModalType === UploadModalType.MannualyUpdateFixedInspection
            ? onFileSelected
            : beforeUpload(
                uploadModalType === UploadModalType.FileUpload
                  ? dayjs(date, DATE_FORMAT_WITHOUT_TIME).isValid()
                    ? dayjs(date, DATE_FORMAT_WITHOUT_TIME).toISOString()
                    : ""
                  : comment
              )
        }
        maxCount={1}
        itemRender={() => <div>{file?.name}</div>}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t("ClickOrDropFile")}</p>
      </Dragger>
      {uploadModalType === UploadModalType.InspectionStatus ||
      uploadModalType === UploadModalType.TodoInspectionCheckpointFixed ||
      uploadModalType === UploadModalType.TodoInspectionCheckpointNotFixed ||
      uploadModalType === UploadModalType.MannualyUpdateFixedInspection ? (
        <div className="upload-modal-action-button">
          <Button onClick={onSave}>{t("Save")}</Button>
        </div>
      ) : null}
    </Modal>
  );
};

export default memo(HBUploadModal);
