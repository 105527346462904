import dayjs from "dayjs";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import { RootState } from "../../../store/store";
import { granularityOptions } from "./ChartOptions/Menus/SliceByDimensions/SliceByTimeDimension";
import { ChartContext } from "./HBChart";

export default function ChartHeader() {
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const { t } = useTranslation();
  const { chart } = useContext(ChartContext);

  const constructSubTitle: () => string = () => {
    const resultsDecomposed = chart?.resultSet?.decompose();
    const results = resultsDecomposed && resultsDecomposed?.length > 1 ? resultsDecomposed[0] : chart?.resultSet;
    if (results) {
      const measureString = Object.entries(results.annotation().measures).map(a => t(a[0], a[1].title));
      const dateRangeString = results.query().timeDimensions?.map(tDimension => {
        if (tDimension.dateRange) {
          const dimensionTitle =
            Object.keys(results.annotation().timeDimensions).length > 0
              ? tDimension.dimension
                ? t(tDimension.dimension, results.annotation().timeDimensions[tDimension.dimension].title)
                : results.annotation().timeDimensions[tDimension.dimension].title
              : t(tDimension.dimension, "");
          const startDate = dayjs.utc(tDimension.dateRange[0]).local().format("L");
          const endDate = dayjs.utc(tDimension.dateRange[1]).local().format("L");
          if (tDimension.granularity && resultsDecomposed && resultsDecomposed?.length === 1) {
            const granularity =
              granularityOptions.find(g => g.granularity === tDimension.granularity)?.title || tDimension.granularity;
            if (results.query().timeDimensions?.length == 1) {
              return `${startDate} - ${endDate} ${t("DashboarGranularityPer", "per")} ${t(
                granularity,
                tDimension.granularity
              )}`;
            }
            return `${dimensionTitle}, ${startDate} - ${endDate} ${t("DashboarGranularityPer", "per")} ${t(
              granularity,
              tDimension.granularity
            )}`;
          } else if (resultsDecomposed && resultsDecomposed?.length === 1) {
            if (results.query().timeDimensions?.length == 1) {
              return `${startDate} - ${endDate}`;
            }
            return `${dimensionTitle}, ${startDate} - ${endDate}`;
          } else {
            if (tDimension.granularity) {
              const granularity =
                granularityOptions.find(g => g.granularity === tDimension.granularity)?.title || tDimension.granularity;
              return `${t("DashboarGranularityPer", "per")} ${t(granularity, tDimension.granularity)}`;
            } else {
              const dateRangeString = resultsDecomposed
                ?.map(results =>
                  results
                    .query()
                    .timeDimensions?.map(timeDimension => {
                      return `${dayjs(timeDimension.dateRange?.[0]).format("L")} - ${dayjs(
                        timeDimension.dateRange?.[1]
                      ).format("L")}`;
                    })
                    .join(",")
                )
                .join(" and ");
              return `${t("DashboarCompareDateRangeFor", "for")} ${dimensionTitle} - ${t(
                "DashboarCompareDateRangeComparing",
                "comparing"
              )} ${dateRangeString}`;
            }
          }
        }
      });
      const dimensions = results.query().dimensions;
      if (dimensions && dimensions.length > 0 && !dimensions.includes("compareDateRange")) {
        const seriesSlicerAnnotation = Object.entries(results.annotation().dimensions)[0];
        const seriesSlicer = t(seriesSlicerAnnotation[0], seriesSlicerAnnotation[1].title);
        const dimensionString = Object.entries(results.annotation().dimensions)
          .slice(1)
          .map(a => t(a[0], a[1].title));
        if (dimensionString.length > 0) {
          return (
            `${measureString} ${t("DashboarOfLabel", "of")} ${seriesSlicer} ${dateRangeString} | ${t(
              "DashboarGranularitySlicedBy",
              "sliced by"
            )} ${dimensionString}` || ""
          );
        }
        return `${measureString} ${t("DashboarOfLabel", "of")} ${seriesSlicer} ${dateRangeString}` || "";
      }
      return `${measureString} ${dateRangeString}` || "";
    }
    return "";
  };

  return (
    <div className="chartHeaderContainer">
      <span className="chartName">{chart?.name}</span>
      <span
        className="chartSubtitle"
        style={{
          color: tinycolor(primaryColor).brighten(5).desaturate().toHexString(),
        }}
      >
        {constructSubTitle()}
      </span>
    </div>
  );
}
