import { createAsyncThunk, createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";

import dayjs from "dayjs";
import unionBy from "lodash/unionBy";

import { DATE_FORMAT_WITHOUT_TIME } from "../../components/HBComponents/DatePicker/HBDatePicker";
import { CustomError, getCustomPropertiesWithValues } from "../../pages/pageConfig/category/utilities";
import { Inspection } from "../../types/equipment";
import { File, FileUploadResponse } from "../../types/files";
import {
  Training,
  TrainingPaginatedData,
  TrainingState,
  TrainingStatus,
  TrainingTrainee,
  TrainingTraineeRequest,
} from "../../types/training";
import {
  ExplicitAdditionalProps,
  HistoryLog,
  PrivilegeData,
  PrivilegedEntityType,
  TraineeStatus,
  UpdateCustomProps,
} from "../../types/utility";
import { generateFiltersAndEmptyProps, generateGridifySorterQuery } from "../../utils/gridifyQueryHelper";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import { fetchHistoryLog } from "./commonThunks";
import {
  addEntityPrivilege,
  createNewPrivilegeTemplate,
  deleteEntityPrivilege,
  deleteMultipleEntityPrivileges,
  deleteNewPrivilegeEntryTemplate,
  fillNewPrivilegeEntryTemplate,
  getEntityPrivileges,
  updateAccountableEntity,
  updateEntityPrivilegeOULvl,
  updateEntityPrivilegeRole,
  updateNewPrivilegeEntry,
} from "./privileges";

// TODO: REMOVE DRY CODE (ACTIONS/THUNKS/TYPES). ESPECIALLY FOR TABS FUNCTIONALITY

export const initialState: TrainingState = {
  data: [],
  isLoading: false,
  singleData: null,
  subData: {
    inspections: [],
    historyLog: [],
    participants: [],
    accountable: [],
    files: [],
  },
  searchResults: [],
  paginationInfo: {
    count: 0,
    currentPage: 0,
  },
  error: null,
  defaultCustomProperties: [],
  lastUpdated: dayjs().toISOString(),
};

export const newTraining: Training = {
  id: 0,
  certificateId: 0,
  certificateName: "",
  completedDate: dayjs().toISOString(),
  plannedDate: null,
  description: "",
  supplier: "",
  status: TrainingStatus.Planned,
  externalId: null,
  locationId: null,
  locationName: "",
  attendedParticipentsCount: 0,
  participentsCount: 0,
  employeeIds: null,
};

export const addTraining = createAsyncThunk<Training, { entity: Training }, { state: RootState }>(
  "@@TRAINING/ADD",
  async ({ entity }, { getState, rejectWithValue }) => {
    const { user, training } = getState();

    const modifiedPropertyValues: UpdateCustomProps = {};
    entity.customPropertyValues?.forEach(prop => {
      modifiedPropertyValues[prop.propertyId] = prop.value;
    });

    try {
      const modifiedEntity = { ...entity, customPropertyValues: modifiedPropertyValues };
      const response = await hbApi.post<Training>("/Training", modifiedEntity, hbApiOptions(user.jwt));

      return {
        ...response.data,
        customPropertyValues: getCustomPropertiesWithValues(
          training.defaultCustomProperties,
          response.data.customPropertyValues
        ),
      };
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { training } = getState();
      return !training.isLoading;
    },
  }
);

export const patchLocationId = createAsyncThunk<Training, { objectId: number; newValue: number }, { state: RootState }>(
  "@@TRAINING/PATCH_LOCATION_ID",
  async ({ objectId, newValue }, { getState, rejectWithValue }) => {
    const { user } = getState();

    try {
      const response = await hbApi.patch<Training>(
        `/Training/${objectId}`,
        [{ path: "/locationId", op: "replace", value: newValue }],
        hbApiOptions(user.jwt)
      );
      return response.data;
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { training, user } = getState();
      return !training.isLoading && !!user.jwt;
    },
  }
);

export const updateTraining = createAsyncThunk<Training, Training, { state: RootState }>(
  "@@TRAINING/UPDATE",
  async (entity, { getState, rejectWithValue }) => {
    const { user, training } = getState();

    // Note: We need to modify the request body for the custom props, before sending it to the server
    const modifiedPropertyValues: UpdateCustomProps = {};
    entity.customPropertyValues?.forEach(prop => {
      modifiedPropertyValues[prop.propertyId] = prop.value;
    });

    try {
      const modifiedEntity = { ...entity, customPropertyValues: modifiedPropertyValues };
      const response = await hbApi.put<Training>(`/Training/${entity.id}`, modifiedEntity, hbApiOptions(user.jwt));

      return {
        ...response.data,
        customPropertyValues: getCustomPropertiesWithValues(
          training.defaultCustomProperties,
          response.data.customPropertyValues
        ),
      };
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { training } = getState();
      return !training.isLoading;
    },
  }
);

export const removeFiles = createAsyncThunk<number[], number[], { state: RootState }>(
  "@@TRAINING/REMOVE_FILE",
  async (ids, { getState }) => {
    const { user, training } = getState();
    await hbApi.delete<File[]>(
      "/File",
      hbApiOptions(user.jwt, {
        entityId: training.singleData?.id,
        entityType: PrivilegedEntityType.Training,
        fileIds: ids,
      })
    );

    return ids;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadFile = createAsyncThunk<File[] | null, { file: any; expiration: string }, { state: RootState }>(
  "@@TRAINING/UPLOAD_FILE",
  async ({ file, expiration }, { getState }) => {
    const { user, training } = getState();
    const formData = new FormData();
    formData.append("files[]", file);

    try {
      const fileEntity = await hbApi.post<FileUploadResponse>(
        `/File/upload?entityType=Training&entityId=${training?.singleData?.id || 0}&expirationDate=${expiration}`,
        formData,
        hbApiOptions(user.jwt)
      );

      return fileEntity.data?.data;
    } catch (e) {
      throw new CustomError(e.message);
    }
  }
);

export const fetchSingleTraining = createAsyncThunk<
  {
    singleData: Training;
    subData: { historyLog: HistoryLog[]; inspections: Inspection[]; files: File[] };
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>("@@SINGLE_TRAINING/FETCH", async (id, { getState, rejectWithValue }) => {
  const { user } = getState();
  try {
    const cardDataResponse = await hbApi.get<Training>(`/Training/${id}`, hbApiOptions(user.jwt));
    // const historyLogResponse = await hbApi.get<HistoryLog[]>("/changeme");
    // const inspectionsResponse = await hbApi.get<Inspection[]>("/inspections");
    const filesResponse = await hbApi.get<File[]>(`/File?entityType=Training&entityId=${id}`, hbApiOptions(user.jwt));
    const trainingCustomProperties = await hbApi.get<ExplicitAdditionalProps[]>(
      "/TrainingCustomProperty",
      hbApiOptions(user.jwt)
    );

    return {
      singleData: {
        ...cardDataResponse.data,
        customPropertyValues: getCustomPropertiesWithValues(
          trainingCustomProperties.data,
          cardDataResponse.data.customPropertyValues
        ),
      },
      subData: {
        inspections: [],
        historyLog: [],
        files: filesResponse.data,
      },
      defaultCustomProperties: trainingCustomProperties.data,
    };
  } catch (e) {
    if (e.status === 404 || e.status === 403) {
      return rejectWithValue({ status: e.status, data: e.message });
    } else {
      return rejectWithValue(e.message);
    }
  }
});

export const fetchTrainingCustomProps = createAsyncThunk<ExplicitAdditionalProps[], void, { state: RootState }>(
  "@@TRAINING_CUSTOM_PROPS/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<ExplicitAdditionalProps[]>("/TrainingCustomProperty", hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { training } = getState();
      return !training.isLoading;
    },
  }
);

export const changeTraineeStatus = createAsyncThunk<
  { entity: TrainingTrainee; newValue: TraineeStatus },
  { entity: TrainingTrainee; newValue: TraineeStatus },
  { state: RootState }
>(
  "@@TRAINING/CHANGE_TRAINEE_STATUS",
  async ({ entity, newValue }, { getState, rejectWithValue }) => {
    const { user } = getState();
    const body = [
      {
        employeeId: entity.id,
        status: newValue,
      },
    ];
    try {
      await hbApi.put<TrainingTrainee>(`/TrainingEmployee/${entity.trainingId}`, body, hbApiOptions(user.jwt));
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }

    return { entity, newValue };
  },
  {
    condition: (_, { getState }) => {
      const { training } = getState();
      return !training.isLoading;
    },
  }
);

export const changeBulkTraineeStatus = createAsyncThunk<
  { employeeId: string | number; status: TraineeStatus; trainingId: number | null }[],
  { entities: TrainingTrainee[]; newStatus: TraineeStatus },
  { state: RootState }
>(
  "@@TRAINING/CHANGE_BULK_TRAINEE_STATUS",
  async ({ entities, newStatus }, { getState, rejectWithValue }) => {
    const currentTraining = entities[0]?.trainingId;
    const { user } = getState();
    const body = entities.map(e => ({
      employeeId: e.id,
      status: newStatus,
      trainingId: currentTraining,
    }));
    // const body = [
    //   {
    //     employeeId: entity.id,
    //     status: newValue,
    //   },
    // ];
    try {
      await hbApi.put<TrainingTrainee>(`/TrainingEmployee/${currentTraining}`, body, hbApiOptions(user.jwt));
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }

    return body;
  },
  {
    condition: (_, { getState }) => {
      const { training } = getState();
      return !training.isLoading;
    },
  }
);

export const addTrainingTrainee = createAsyncThunk<TrainingTrainee, TrainingTrainee, { state: RootState }>(
  "@@TRAINING/ADD_TRAINEE_RELATION",
  async (entity, { getState, rejectWithValue }) => {
    const { user } = getState();
    if (!entity.activeId) {
      throw new CustomError("Entity not found.");
    }
    const body = [
      {
        employeeId: entity.activeId,
        status: entity.status,
      },
    ];
    try {
      await hbApi.post<TrainingTrainee[]>(`/TrainingEmployee/${entity.trainingId}`, body, hbApiOptions(user.jwt));
      return entity;
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { training } = getState();
      return !training.isLoading;
    },
  }
);

export const deleteTraineeRelation = createAsyncThunk<TrainingTrainee, TrainingTrainee, { state: RootState }>(
  "@@TRAINING/DELETE_TRAINEE_RELATION",
  async (entity, { getState, rejectWithValue }) => {
    const { user } = getState();
    const additionalData = {
      employeeIds: [entity.id],
    };
    try {
      await hbApi.delete(`/TrainingEmployee/${entity.trainingId}`, hbApiOptions(user.jwt, additionalData));
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }

    return entity;
  },
  {
    condition: (_, { getState }) => {
      const { training } = getState();
      return !training.isLoading;
    },
  }
);

export const deleteBulkTraineeRelations = createAsyncThunk<TrainingTrainee[], TrainingTrainee[], { state: RootState }>(
  "@@TRAINING/DELETE_BULK_TRAINEE_RELATIONS",
  async (entity, { getState, rejectWithValue }) => {
    const { user } = getState();

    const currentTraining = entity[0]?.trainingId;

    const additionalData = {
      employeeIds: entity.filter(r => !r.staging).map(r => r.id),
    };

    try {
      if (additionalData.employeeIds.length) {
        await hbApi.delete(`/TrainingEmployee/${currentTraining}`, hbApiOptions(user.jwt, additionalData));
      }
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
    return entity;
  },
  {
    condition: (_, { getState }) => {
      const { training } = getState();
      return !training.isLoading;
    },
  }
);

export const getTrainingTrainees = createAsyncThunk<TrainingTraineeRequest[], number, { state: RootState }>(
  "@@TRAINING/FETCH_TRAINING_TRAINEES",
  async (trainingId, { getState }) => {
    const { user } = getState();

    const response = await hbApi.get<TrainingTraineeRequest[]>(
      `/TrainingEmployee/${trainingId}`,
      hbApiOptions(user.jwt)
    );
    return response.data;
  }
);

export const fetchPaginatedTraining = createAsyncThunk<
  { primaryData: Training[]; defaultCustomProperties: ExplicitAdditionalProps[]; possibleResults: number },
  { page?: number; pageSize?: number; forceUpdate?: boolean } | undefined,
  { state: RootState }
>(
  "@@TRAINING/FETCH_PAGINATED",
  async (params, { getState }) => {
    const { user, training, filter } = getState();

    const trainingFilters = filter.filters.training?.training?.activeFilters;

    const { filters, emptyPropIds } = generateFiltersAndEmptyProps(trainingFilters);

    const orders = filter.filterValues.training?.order
      ? generateGridifySorterQuery(filter.filterValues.training?.order)
      : undefined;
    const response = await hbApi.post<TrainingPaginatedData>(
      "/Training/pagedList",
      {
        gridifyQuery: {
          Page: params?.page || training.paginationInfo.currentPage + 1,
          PageSize: params?.pageSize,
          Filter: filters,
          OrderBy: orders,
        },
        emptyPropIds: emptyPropIds,
      },
      hbApiOptions(user.jwt)
    );

    const trainingCustomProperties = await hbApi.get<ExplicitAdditionalProps[]>(
      "/TrainingCustomProperty",
      hbApiOptions(user.jwt)
    );
    return {
      primaryData: response.data.data,
      defaultCustomProperties: trainingCustomProperties.data,
      possibleResults: response.data.count,
    };
  },
  {
    condition: (arg, { getState }) => {
      const { training } = getState();
      return (
        arg?.forceUpdate ||
        (dayjs(training.lastUpdated).isBefore(dayjs()) &&
          !training.isLoading &&
          training.data.length !== training.paginationInfo.count)
      );
    },
  }
);
export const searchTraining = createAsyncThunk<
  Training[],
  { filters?: string; page?: number } | undefined,
  { state: RootState }
>(
  "@@TRAINING/SEARCH_PAGINATED",
  async (params, { getState }) => {
    const { user } = getState();
    const response = await hbApi.post<TrainingPaginatedData>(
      "/Training/pagedList",
      {
        gridifyQuery: {
          Page: params?.page || 1,
          PageSize: 100,
          Filter: params?.filters,
        },
      },
      hbApiOptions(user.jwt)
    );
    return response.data.data;
  },
  {
    condition: (_, { getState }) => {
      const { training } = getState();
      return !training.isLoading;
    },
  }
);

export const cancelTraining = createAsyncThunk<boolean, void, { state: RootState }>(
  "@@TRAINING/CANCEL_TRAINING",
  async (_, { getState }) => {
    const { user, training } = getState();
    try {
      await hbApi.delete(`/Training/${training.singleData?.id}/cancel`, hbApiOptions(user.jwt));
    } catch (e) {
      return false;
    }

    return true;
  },
  {
    condition: (_, { getState }) => {
      const { training } = getState();
      return !training.isLoading;
    },
  }
);

const slice = createSlice({
  name: "training",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    resetCurrentPage: state => {
      state.paginationInfo.currentPage = 0;
    },
    createTrainingTemplate: state => {
      const customProps = state.defaultCustomProperties;
      state.singleData = {
        ...newTraining,
        customPropertyValues: getCustomPropertiesWithValues(customProps),
      };
      state.subData.historyLog = [];
      state.subData.inspections = [];
      state.subData.participants = [];
      state.subData.accountable = [];
    },
    createNewTraineeTemplate: state => {
      state.subData.participants.unshift({
        externalId: null,
        id: nanoid(),
        name: "",
        status: TraineeStatus.Pending,
        staging: true,
        trainingId: state.singleData?.id || 0,
        isExternal: null,
        // phone: "",
      });
    },
    fillNewTraineeTemplate: {
      prepare: (payload: { row: TrainingTrainee; targetEntity: Record<string, unknown> }) => ({ payload }),
      reducer: (state, action: PayloadAction<{ row: TrainingTrainee; targetEntity: Record<string, unknown> }>) => {
        const stagedRecordIndex = state.subData.participants.findIndex(r => r.id === action.payload.row.id);
        const castTargetEntity = (action.payload.targetEntity as unknown) as TrainingTrainee;
        state.subData.participants[stagedRecordIndex] = {
          ...state.subData.participants[stagedRecordIndex],
          name: castTargetEntity.name,
          externalId: castTargetEntity.externalId,
          activeId: castTargetEntity.id,
          isEmployee: castTargetEntity.isEmployee,
          isOrgUnit: castTargetEntity.isOrgUnit,
          // status: castTargetEntity.status,
          // phone: castTargetEntity.phone,
          isExternal: typeof castTargetEntity.isExternal === "undefined" ? null : castTargetEntity.isExternal,
        };
      },
    },
    updateNewTraineeEntryStatus: {
      prepare: (payload: { entity: TrainingTrainee; newValue: TraineeStatus }) => ({ payload }),
      reducer: (state, action: PayloadAction<{ entity: TrainingTrainee; newValue: TraineeStatus }>) => {
        const updatedEntityIndex = state.subData.participants.findIndex(r => r.id === action.payload.entity.id);
        state.subData.participants[updatedEntityIndex].status = action.payload.newValue;
      },
    },
    deleteNewTraineeEntryTemplate: {
      prepare: (payload: TrainingTrainee) => ({ payload }),
      reducer: (state, action: PayloadAction<TrainingTrainee>) => {
        state.subData.participants = state.subData.participants.filter(r => r.id !== action.payload.id);
      },
    },
    createNewParticipantTemplate: createNewPrivilegeTemplate,
    fillNewParticipantTemplate: fillNewPrivilegeEntryTemplate,
    updateNewAccountableEntry: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      prepare: (payload: { entity: PrivilegeData; newValue: any; property: keyof PrivilegeData }) => ({ payload }),
      reducer: updateNewPrivilegeEntry,
    },
    deleteNewParticipantEntryTemplate: {
      prepare: (payload: PrivilegeData) => ({ payload }),
      reducer: deleteNewPrivilegeEntryTemplate,
    },
    updateLocationId: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        if (state.singleData) {
          state.singleData = { ...state.singleData, locationId: action.payload };
        }
      },
    },
    clearTrainingError: state => {
      state.error = null;
    },
    resetSearchResults: state => {
      state.searchResults = [];
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchSingleTraining.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addTraining.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateTraining.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Patch Location ID
      .addCase(patchLocationId.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Fetch training custom props
      .addCase(fetchTrainingCustomProps.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(cancelTraining.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadFile.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeFiles.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEntityPrivileges.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPaginatedTraining.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchTraining.pending, state => {
        state.error = null;
      })
      // Note - Rejected:
      .addCase(cancelTraining.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleTraining.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addTraining.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateTraining.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // Fetch training custom props
      .addCase(fetchTrainingCustomProps.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // Patch Location ID
      .addCase(patchLocationId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addTrainingTrainee.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(removeFiles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(deleteTraineeRelation.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(changeTraineeStatus.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(deleteBulkTraineeRelations.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(getTrainingTrainees.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(changeBulkTraineeStatus.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchHistoryLog.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(getEntityPrivileges.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchPaginatedTraining.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(searchTraining.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      // Note - Fulfilled:
      .addCase(cancelTraining.fulfilled, state => {
        if (state.singleData) {
          state.singleData.status = TrainingStatus.Canceled;
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchPaginatedTraining.fulfilled, (state, action) => {
        if (action.meta.arg?.page === 1) {
          state.data = action.payload.primaryData;
          state.paginationInfo.currentPage = 1;
        } else {
          state.data = unionBy(state.data, action.payload.primaryData, "id");
          state.paginationInfo.currentPage = state.paginationInfo.currentPage + 1;
        }
        state.paginationInfo.count = action.payload.possibleResults;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.lastUpdated = dayjs().toISOString();
        state.isLoading = false;
      })
      .addCase(searchTraining.fulfilled, (state, action) => {
        state.searchResults = [...action.payload];
        state.error = null;
      })
      .addCase(fetchSingleTraining.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.subData.historyLog = action.payload.subData.historyLog;
        state.subData.inspections = action.payload.subData.inspections;
        state.subData.files = action.payload.subData.files;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addTraining.fulfilled, (state, action) => {
        state.singleData = action.payload;
        state.data.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateTraining.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(training => training.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      // Fetch training custom props
      .addCase(fetchTrainingCustomProps.fulfilled, (state, action) => {
        state.defaultCustomProperties = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const filesArray = [...state.subData.files];

        if (action.payload && action.payload.length) filesArray.unshift(action.payload[0]);
        state.subData.files = filesArray;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(removeFiles.fulfilled, (state, action) => {
        state.subData.files = state.subData.files.filter(x => !action.payload.includes(x.id));
        state.isLoading = false;
        state.error = null;
      })
      .addCase(patchLocationId.fulfilled, (state, action) => {
        if (state.singleData) {
          state.singleData.locationId = action.payload.locationId;
          const updatedEntityIndex = state.data.findIndex(employee => employee.id === action.payload.id);
          state.data[updatedEntityIndex].locationId = action.payload.locationId;
          state.isLoading = false;
          state.error = null;
        }
      })
      // Get Trainee Relations
      .addCase(getTrainingTrainees.fulfilled, (state, action) => {
        action.payload
          ? (state.subData.participants = action.payload.map(entity => {
              return {
                id: entity.employee.id,
                externalId: entity.employee.externalId,
                isExternal: entity.employee.isExternal,
                name: entity.employee.name,
                // phone: entity.employee.phone,
                status: entity.status,
                trainingId: entity.trainingId,
              } as TrainingTrainee;
            }))
          : (state.subData.participants = []);

        state.isLoading = false;
        state.error = null;
      })
      // Add Trainee Relation
      .addCase(addTrainingTrainee.fulfilled, (state, action) => {
        state.subData.participants = state.subData.participants.filter(r => r.id !== action.payload.id);
        state.subData.participants.unshift({
          ...action.payload,
          id: action.payload.activeId!,
          staging: false,
        });
        if (state.singleData) {
          state.singleData.participentsCount += 1;
        }
        const updatedEntityIndex = state.data.findIndex(r => r.id === action.payload.trainingId);
        state.data[updatedEntityIndex].participentsCount += 1;
        state.isLoading = false;
        state.error = null;
      })
      // Delete Trainee Relation
      .addCase(deleteTraineeRelation.fulfilled, (state, action) => {
        state.subData.participants = state.subData.participants.filter(r => r.id !== action.payload.id);
        // const updatedTrainingIndex = state.data.findIndex(r => r.id === action.payload.trainingId);
        if (state.singleData) {
          state.singleData.participentsCount -= 1;
          // state.data[updatedTrainingIndex].participentsCount -= 1;
        }
        state.isLoading = false;
        state.error = null;
      })
      // Change Trainee Status
      .addCase(changeTraineeStatus.fulfilled, (state, action) => {
        const updatedEntityIndex = state.subData.participants.findIndex(r => r.id === action.payload.entity.id);
        const updatedTrainingIndex = state.data.findIndex(r => r.id === action.payload.entity.trainingId);
        if (
          action.payload.newValue === TraineeStatus.Attended &&
          state.subData.participants[updatedEntityIndex].status !== TraineeStatus.Attended
        ) {
          if (state.singleData) {
            state.singleData.attendedParticipentsCount += 1;
            state.data[updatedTrainingIndex].attendedParticipentsCount += 1;
          }
        }
        if (
          action.payload.newValue !== TraineeStatus.Attended &&
          state.subData.participants[updatedEntityIndex].status === TraineeStatus.Attended
        ) {
          if (state.singleData) {
            state.singleData.attendedParticipentsCount -= 1;
            state.data[updatedTrainingIndex].attendedParticipentsCount -= 1;
          }
        }
        state.subData.participants[updatedEntityIndex].status = action.payload.newValue;

        state.isLoading = false;
        state.error = null;
      })
      .addCase(changeBulkTraineeStatus.fulfilled, (state, action) => {
        let attendedCount = 0;
        state.subData.participants.forEach(r => {
          const traineeToChange = action.payload.find(trainee => trainee.employeeId === r.id);
          if (traineeToChange) {
            if (r.status !== TraineeStatus.Attended && traineeToChange.status === TraineeStatus.Attended) {
              attendedCount += 1;
            }
            if (r.status === TraineeStatus.Attended && traineeToChange.status !== TraineeStatus.Attended) {
              attendedCount -= 1;
            }
            r.status = traineeToChange.status;
          }
        });
        if (state.singleData) state.singleData.attendedParticipentsCount += attendedCount;
        const updatedEntityIndex = state.data.findIndex(r => r.id === action.payload[0]?.trainingId);
        if (updatedEntityIndex) {
          state.data[updatedEntityIndex].attendedParticipentsCount += attendedCount;
        }
        state.isLoading = false;
        state.error = null;
      })
      // Delete Bulk Trainee Relations
      .addCase(deleteBulkTraineeRelations.fulfilled, (state, action) => {
        const relationsToRemove = action.payload.map(r => r.id);
        state.subData.participants = state.subData.participants.filter(r => !relationsToRemove.includes(r.id));
        if (state.singleData) {
          state.singleData.participentsCount -= relationsToRemove.length;
        }
        state.isLoading = false;
        state.error = null;
      })
      //START
      // Get Participant Relations
      .addCase(getEntityPrivileges.fulfilled, (state, action) => {
        state.subData.accountable = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      // Add Participant Relation
      .addCase(addEntityPrivilege.fulfilled, (state, action) => {
        state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
        state.subData.accountable.unshift({
          ...action.payload,
          id: action.payload.id!,
          staging: false,
        });
        state.error = null;
      })
      .addCase(deleteEntityPrivilege.fulfilled, (state, action) => {
        state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
        state.error = null;
      })
      // Change Participant Role
      .addCase(updateEntityPrivilegeRole.fulfilled, (state, action) => {
        const updatedEntityIndex = state.subData.accountable.findIndex(r => r.id === action.payload.id);
        state.subData.accountable[updatedEntityIndex].role = action.payload.role;
        state.error = null;
      })
      // Delete Bulk Participant Relations
      .addCase(deleteMultipleEntityPrivileges.fulfilled, (state, action) => {
        const privilegesToRemove = action.payload.map(r => r.id);
        state.subData.accountable = state.subData.accountable.filter(r => !privilegesToRemove.includes(r.id));
        state.error = null;
      })
      .addCase(fetchHistoryLog.fulfilled, (state, action) => {
        state.subData.historyLog = action.payload
          ? action.payload.map(r => ({
              ...r,
              id: nanoid(),
              timeStamp: dayjs(r.timeStamp).format(DATE_FORMAT_WITHOUT_TIME),
            }))
          : [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateEntityPrivilegeOULvl.fulfilled, (state, action) => {
        updateAccountableEntity(state, action);
      });
  },
});

export const {
  createTrainingTemplate,
  updateLocationId,
  clearTrainingError,
  createNewTraineeTemplate,
  fillNewTraineeTemplate,
  updateNewTraineeEntryStatus,
  deleteNewTraineeEntryTemplate,
  createNewParticipantTemplate,
  fillNewParticipantTemplate,
  updateNewAccountableEntry,
  deleteNewParticipantEntryTemplate,
  resetCurrentPage,
  resetSearchResults,
} = slice.actions;
export default slice.reducer;
