import React, { useContext, useMemo } from "react";

import PageConfigurationContext from "../../../context/pageContext";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage, SectionType } from "../../../types/page";
import CustomFieldsSection from "../components/CustomFieldsSection";
import Section from "../components/SectionComponent";
import { useMobileSingleViewContext } from "../context/useMobileSingleViewContext";

const SingleViewCardMobileAboutTab: React.FC = () => {
  const { currentData, isEdited } = useMobileSingleViewContext();

  const { summaryCard } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;

  const summaryFields = useMemo(() => {
    return isEdited ? [] : Object.values(summaryCard.mobileSingleView?.summary || {}).map(field => ({ id: field?.id }));
  }, [isEdited, summaryCard]);

  const sectionsFields = useMemo(() => {
    return summaryCard.mobileSingleView?.sections.flatMap(section => section.fields) || [];
  }, [summaryCard]);

  const allFieldsInUse = useMemo(() => {
    return [...sectionsFields, ...summaryFields];
  }, [sectionsFields, summaryFields]);

  const allFieldInUseIds = useMemo(() => {
    return new Set(allFieldsInUse.map(x => x && x.id));
  }, [allFieldsInUse]);

  const allFields = useMemo(() => {
    return [...summaryCard.primaryFields, ...summaryCard.secondaryFields];
  }, [summaryCard.primaryFields, summaryCard.secondaryFields]);

  const fields = useMemo(() => {
    return allFields.filter(
      x =>
        (!x.isHidden || (typeof x.isHidden === "function" && currentData && !x.isHidden(currentData))) &&
        !allFieldInUseIds.has(x.id)
    );
  }, [summaryCard, currentData, allFieldInUseIds]);

  return (
    <>
      <Section title={SectionType.DetailsSection} fields={fields} />
      {summaryCard.mobileSingleView?.sections.map((section, index) => (
        <Section key={index} fields={section.fields} title={SectionType[section.type]} />
      ))}

      <CustomFieldsSection />
    </>
  );
};

export default SingleViewCardMobileAboutTab;
