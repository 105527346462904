import { Space } from "antd";

import { CellTypes, ColumnType, FieldType, MobileAddEntitySectionTProps } from "../../../../types/page";
import { PrivilegeData } from "../../../../types/utility";
import EditableInputMobileFirst from "../../../EditableInput/EditableInputMobileFirst";
import { EditableCellSearch } from "../../../FuseSearch/EditableCellSearch";
import { useMobileSingleViewTabContext } from "../../context/useMobileSingleViewTabContext";

const AddEmployeeSection: React.FC<MobileAddEntitySectionTProps> = ({ entity, onChange }) => {
  const { tabConfig } = useMobileSingleViewTabContext();
  if (!entity) return null;

  const fields = tabConfig.addButton?.addEntityFields || [];

  return (
    <Space direction="vertical" size={9} className="buttons-space">
      {fields.map(field => {
        const type = field.cellType?.(entity);
        if (type === CellTypes.SEARCH) {
          return (
            <EditableCellSearch
              dataSelector={field.cellSearchSelector!}
              resultColumns={field.cellSearchResultColumns! as Partial<ColumnType<Record<string, unknown>>>[]}
              searchKeys={field.cellSearchKeys!}
              resultClickAction={field.cellSearchRecordClick!}
              defaultValue={entity[field.id as keyof PrivilegeData] as string}
              currentRow={entity}
              prefixIcon={field.cellPrefixIcon}
              placeholder={field.placeholder}
            />
          );
        }
        return (
          <EditableInputMobileFirst
            key={String(field.id)}
            isEditMode={true}
            id={String(field.id)}
            type={type as FieldType}
            optionSelector={field.optionsSelector}
            globalEdit={true}
            onChange={onChange(String(field.id))}
            fieldValue={field.renderValue(entity)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            options={(field as any).cellDropdownOptions?.() || []}
            prefixIcon={field.cellPrefixIcon}
          />
        );
      })}
    </Space>
  );
};

export default AddEmployeeSection;
