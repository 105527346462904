import "./ContainerWithDivider.less";

import { Space } from "antd";
import React from "react";

import CircleDivider from "../CircleDivider/CircleDivider";

const ContainerWithDivider: React.FC = ({ children }) => {
  return (
    <Space className="mobile-card-row" direction="horizontal" size={10} split={<CircleDivider />}>
      {children}
    </Space>
  );
};

export default ContainerWithDivider;
