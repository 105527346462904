import { Badge, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";

import { DATE_FORMAT_WITHOUT_TIME } from "../../../../components/HBComponents/DatePicker/HBDatePicker";
import { ReactComponent as FileIcon } from "../../../../media/file.svg";
import { setFileInfoToOpen } from "../../../../store/slices/common";
import {
  cancelCheckpointEdit,
  changeLocalStatus,
  confirmStatus,
  deleteNewCheckpointEntryTemplate,
  openNewAppointment,
} from "../../../../store/slices/inspection";
import store from "../../../../store/store";
import { ROW_SVG_SIZE } from "../../../../types/constants";
import { Inspection, InspectionCheckpoint, InspectionStatus } from "../../../../types/inspection";
import { CellTypes, ColumnType, ColumnWithDifferentCells, InputTypes, TOption } from "../../../../types/page";
import { InspectionStatusOptions } from "../common";
import { localizeText } from "../utilities";

export const inspectionTableColumns: ColumnType<Inspection>[] = [
  {
    id: "inspectionTypeId",
    exportPropertyId: "inspectionTypeName",
    label: "FieldType",
    renderValue: value => value.inspectionTypeName,
    sortable: true,
    width: 100,
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "InspectionType",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: InputTypes.MULTI_SELECT,
    primaryColumn: e => {
      const pageId = window.location.pathname.replace("/", "");

      return { navigationTarget: `/${pageId}/${e.id}` };
    },
  },
  {
    id: "locationExternalId",
    label: "FieldSerialNumber",
    renderValue: value => value.linkedEntityExternalId || "",
    sortable: true,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    propName: "externalId",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
  },
  {
    id: "locationId",
    exportPropertyId: "linkedEntityName",
    label: "AppointmentLocationLink",
    renderValue: value => value.linkedEntityName || "",
    sortable: true,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    propName: "name",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
    asyncFetchFilterOptionsUseKeyValue: true,
  },
  {
    id: "createdAt",
    label: "FieldCreateDate",
    renderValue: value =>
      dayjs(value.createdAt).isValid() ? dayjs(value.createdAt).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: true,
    width: 100,
    filterType: InputTypes.DATE_RANGE,
    optionsSelector: () => null,
  },
  {
    id: "expirationDate",
    label: "FieldExpiration",
    width: 100,
    renderValue: value =>
      dayjs(value.expirationDate).isValid() ? dayjs(value.expirationDate).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: true,
    filterType: InputTypes.DATE_RANGE,
    optionsSelector: () => null,
    valueIcon: value => {
      const expDate = dayjs(value.expirationDate);

      if (!expDate) return null;

      const isValidDate = expDate.isValid();
      const isExpired = expDate.isBefore(dayjs(), "day");

      return isValidDate ? <Badge color={!isExpired ? "green" : "red"} /> : null;
    },
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: entity =>
      entity.status === InspectionStatus.Missing ? localizeText(InspectionStatus.Expired) : localizeText(entity.status),
    sortable: true,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: () =>
      inspectionsStatusOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
  },
];

export const equipmentInspectionTableColumns: ColumnType<Inspection>[] = [
  {
    id: "inspectionTypeId",
    exportPropertyId: "inspectionTypeName",
    label: "FieldType",
    renderValue: value => value.inspectionTypeName,
    sortable: true,
    width: 100,
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "InspectionType",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: InputTypes.MULTI_SELECT,
    primaryColumn: e => {
      const pageId = window.location.pathname.replace("/", "");

      return { navigationTarget: `/${pageId}/${e.id}` };
    },
  },
  {
    id: "equipmentSerialNumber",
    label: "FieldSerialNumber",
    renderValue: value => value.linkedEntityExternalId || "",
    sortable: true,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    propName: "serialNumber",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Equipment",
  },
  {
    id: "equipmentID",
    exportPropertyId: "equipmentName",
    label: "EquipmentName",
    renderValue: value => value.linkedEntityName || "",
    sortable: true,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Equipment",
    asyncFetchFilterOptionsUseKeyValue: true,
  },
  {
    id: "equipmentTypeId",
    exportPropertyId: "equipmentTypeName",
    label: "PluginEquipment_EquipmentType",
    renderValue: value => value.linkedEntityTypeName || "",
    sortable: true,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "EquipmentType",
    asyncFetchFilterOptionsUseKeyValue: true,
  },
  {
    id: "locationId",
    exportPropertyId: "equipmentLocationName",
    label: "AppointmentLocationLink",
    renderValue: value => value.linkedEntityLocation || "",
    sortable: true,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
    asyncFetchFilterOptionsUseKeyValue: true,
  },
  {
    id: "createdAt",
    label: "FieldCreateDate",
    renderValue: value =>
      dayjs(value.createdAt).isValid() ? dayjs(value.createdAt).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: true,
    width: 100,
    filterType: InputTypes.DATE_RANGE,
    optionsSelector: () => null,
  },
  {
    id: "expirationDate",
    label: "FieldExpiration",
    renderValue: value =>
      dayjs(value.expirationDate).isValid() ? dayjs(value.expirationDate).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: true,
    width: 100,
    filterType: InputTypes.DATE_RANGE,
    optionsSelector: () => null,
    valueIcon: value => {
      const expDate = dayjs(value.expirationDate);

      if (!expDate) return null;

      const isValidDate = expDate.isValid();
      const isExpired = expDate.isBefore(dayjs(), "day");

      return isValidDate ? <Badge color={!isExpired ? "green" : "red"} /> : null;
    },
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: entity =>
      entity.status === InspectionStatus.Missing ? localizeText(InspectionStatus.Expired) : localizeText(entity.status),
    sortable: true,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: () =>
      inspectionsStatusOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
  },
];

export const checkpointsColumns: ColumnWithDifferentCells<InspectionCheckpoint>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: value => (value.id && typeof value.id !== "string" ? value.id.toString() : ""),
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "startPeriod" as keyof InspectionCheckpoint,
    label: "StartPeriod",
    renderValue: value =>
      dayjs(value.startPeriod).isValid() ? dayjs(value.startPeriod).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: true,
    filterType: InputTypes.DATE_RANGE,
    optionsSelector: () => null,
  },
  {
    id: "periodEnd" as keyof InspectionCheckpoint,
    label: "PeriodEnd",
    renderValue: value =>
      dayjs(value.endPeriod).isValid() ? dayjs(value.endPeriod).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: true,
    filterType: InputTypes.DATE_RANGE,
    optionsSelector: () => null,
  },
  {
    id: "actualCheck" as keyof InspectionCheckpoint,
    label: "ActualCheck",
    renderValue: value =>
      dayjs(value.completedAt).isValid() ? dayjs(value.completedAt).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: true,
    filterType: InputTypes.DATE_RANGE,
    optionsSelector: () => null,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: entity =>
      entity.status === InspectionStatus.Missing ? localizeText(InspectionStatus.Expired) : localizeText(entity.status),
    sortable: false,
    filterType: InputTypes.MULTI_SELECT,
    cellType: entity =>
      dayjs(entity.endPeriod).isBefore(dayjs(), "date") || !entity.id ? CellTypes.TEXT : CellTypes.DROPDOWN,
    cellDropdownOptions: entity =>
      dayjs(entity.endPeriod).isBefore(dayjs(), "date")
        ? []
        : InspectionStatusOptions.filter(x =>
            [
              InspectionStatus.Passed.toString(),
              InspectionStatus.Failed.toString(),
              InspectionStatus.PassedWithConditions.toString(),
            ].includes(x.label)
          ).map(ao => ({
            id: ao.id,
            label: localizeText(ao.label),
          })),
    cellDropdownOnChange: (entity, newStatus) => changeLocalStatus({ entity, status: newStatus as InspectionStatus }),
    optionsSelector: state =>
      Array.from(new Set(state.inspection.singleData?.checkpoints.map(x => x.status))).map(l => ({
        id: l,
        label: localizeText(l),
      })),
  },
  {
    id: "comment",
    label: "FieldComment",
    renderValue: value => value.comment,
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: () => null,
  },
  {
    id: "fileUrl",
    label: "FieldFile",
    renderValue: () => null,
    valueIcon: value =>
      value.fileUrl && value.id !== 0 ? (
        <Typography.Text className="w-100-col" ellipsis title={value.fileUrl}>
          <FileIcon height={ROW_SVG_SIZE} width={ROW_SVG_SIZE} />
        </Typography.Text>
      ) : (
        value.fileUrl ?? ""
      ),
    sortable: false,
    optionsSelector: () => null,
    onCellOnClick: entity => {
      if (!entity.fileUrl) return;
      const url = entity.fileUrl as string;
      const name = url.split("/").pop() || "";
      store.dispatch(
        setFileInfoToOpen({
          url,
          name,
        })
      );
    },
  },
  {
    id: "action" as keyof InspectionCheckpoint,
    label: "FieldAction",
    renderValue: () => null,
    sortable: false,
    optionsSelector: () => null,
    cellType: () => CellTypes.BUTTON_ACTIONS,
    width: 200,
    stagingActions: [
      {
        label: "Save",
        action: e => (e.inspectionTypeReviewId ? openNewAppointment({ checkpoint: e }) : null),
        openUploadModal: true,
        uploadFileAction: confirmStatus,
      },
      {
        label: "Cancel",
        action: entity => {
          store.dispatch(deleteNewCheckpointEntryTemplate(entity));
          store.dispatch(cancelCheckpointEdit());
        },
      },
    ],
  },
];

export const renderInspectionStatusIcon = (status: InspectionStatus): React.ReactElement | null => {
  switch (status) {
    case InspectionStatus.Pending:
      return <Badge color="#B7B7B7" />;

    case InspectionStatus.Expired:
    case InspectionStatus.Failed:
      return <Badge color="#D9042B" />;
    case InspectionStatus.Missing:
      return <Badge color="#D9042B" />;
    case InspectionStatus.Passed:
    case InspectionStatus.PassedWithConditions:
      return <Badge color="#02D924" />;

    default:
      return null;
  }
};

const inspectionsStatusOptions: TOption[] = [
  {
    id: InspectionStatus.Pending,
    label: InspectionStatus.Pending,
  },
  {
    id: InspectionStatus.Missing,
    label: InspectionStatus.Expired,
  },
  {
    id: InspectionStatus.IsNoLongerNeeded,
    label: InspectionStatus.IsNoLongerNeeded,
  },
  {
    id: InspectionStatus.Failed,
    label: InspectionStatus.Failed,
  },
  {
    id: InspectionStatus.PassedWithConditions,
    label: InspectionStatus.PassedWithConditions,
  },
];
