import "./CardLabelWithIcon.less";

import { Typography } from "antd";

import { ReactComponent as EquipmentIcon } from "../../../../../media/shapes-icon.svg";
import { ReactComponent as UserIcon } from "../../../../../media/user-icon.svg";

export enum ICON_NAMES {
  USER = "user-icon",
  EQUIPMENT = "equipment-icon",
}

type TProps = {
  label: string;
  iconName: ICON_NAMES;
};

const CardLabelWithIcon: React.FC<TProps> = ({ label, iconName }) => {
  const { Text } = Typography;

  if (!label) return null;

  const icon = () => {
    switch (iconName) {
      case ICON_NAMES.USER:
        return <UserIcon />;
      case ICON_NAMES.EQUIPMENT:
        return <EquipmentIcon />;
      default:
        return null;
    }
  };

  return (
    <Text className={"card-label-with-icon"}>
      {icon()}
      {label}
    </Text>
  );
};

export default CardLabelWithIcon;
