import { unwrapResult } from "@reduxjs/toolkit";
import { getFile } from "../store/slices/common";
import store from "../store/store";
import { BaseFile } from "../types/files";

export const onDownloadFile = (file: BaseFile): void => {
  const fileType = file.name.split(".").pop() || "";

  store
    .dispatch(getFile(file.url))
    .then(response => unwrapResult(response))
    .then(data => {
      const file = new Blob([data], { type: "arraybuffer" });
      const fileURL = window.URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = fileURL;
      a.download = decodeURI(`${fileURL.split("//")[1].split("/")[1]}.${fileType}`);
      a.click();
    });
};
