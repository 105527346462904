import { MoreOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Checkbox, Form, Input, Menu, Modal, Popover, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { changePassword } from "../../../store/slices/employee";
import { forgotPassword } from "../../../store/slices/user";
import { useAppDispatch } from "../../../store/store";
import { ChangePasswordModel } from "../../../types/employee";
import useOutsideClick from "../../../utils/hooks/useOutsideClick";
import "./handleEmployeeComponent.less";

type TProps = {
  employeeId: number;
  employeeName: string;
};

export const HandleEmployee = ({ employeeId, employeeName }: TProps): JSX.Element => {
  const { t } = useTranslation();
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState<boolean>(false);
  const [changePasswordModel, setChangePasswordModel] = useState<ChangePasswordModel>();
  const [validationError, setValidationError] = useState<string>("");
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setPopoverVisible(false));
  const dispatch = useAppDispatch();

  const handleChangePassword = async () => {
    try {
      const response = await dispatch(changePassword({ ...changePasswordModel, userId: employeeId }));
      if (response.error) {
        throw new Error(response.error.message);
      }
      setChangePasswordModalVisible(false);
      Modal.success({
        content: t("ChangePasswordEmployeeSingleView"),
      });
    } catch (error) {
      setValidationError(t(error.message));
    }
  };
  useEffect(() => {
    const { newPassword, repeatNewPassword } = changePasswordModel || {};
    if (newPassword && repeatNewPassword && newPassword !== repeatNewPassword) {
      setValidationError(t("ErrorPasswordNotMatch"));
    } else {
      setValidationError("");
    }
  }, [changePasswordModel]);

  return (
    <>
      <Modal
        className="change-employee-password-modal"
        closable
        maskClosable
        centered
        open={changePasswordModalVisible}
        onCancel={() => setChangePasswordModalVisible(false)}
        footer={null}
      >
        <div className="modal-content">
          <img src="media/vitre-logo.png" className="modal-logo hb-logo-new" />
        </div>
        <span className="login-title">{t("ChangePassword")}</span>
        <span className="login-title login-title-small">
          {t("UserTitle") + ": "}
          {employeeName}
        </span>
        <Form className="login-wrapper" name="basic" onFinish={handleChangePassword}>
          <Form.Item className="password-input" data-testid="passwordInput" name="newPassword">
            <Input.Password
              placeholder={t("FieldPassword")}
              value={changePasswordModel?.newPassword}
              onChange={e => setChangePasswordModel({ ...changePasswordModel, newPassword: e.target.value })}
              data-testid="reset-password-input"
            />
          </Form.Item>

          <Form.Item className="password-input" data-testid="passwordInput" name="repeatNewPassword">
            <Input.Password
              placeholder={t("FieldRepeatPassword")}
              value={changePasswordModel?.repeatNewPassword}
              onChange={e => setChangePasswordModel({ ...changePasswordModel, repeatNewPassword: e.target.value })}
              data-testid="reset-password-repeat-input"
            />
          </Form.Item>
          <Form.Item
            className="checkbox-input"
            data-testid="mustChangePassword"
            name="mustChangePassword"
            valuePropName="checked"
          >
            <Checkbox
              checked={changePasswordModel?.mustChangePassword}
              onChange={e => setChangePasswordModel({ ...changePasswordModel, mustChangePassword: e.target.checked })}
            >
              {t("FieldMustChangePassword")}
            </Checkbox>
          </Form.Item>
          <div className="login-error-message" data-testid="reset-password-error-message">
            {validationError}
          </div>
          <Space>
            <Button
              type="default"
              className="button-submit"
              data-testid="reset-password-submit-button"
              onClick={() => setChangePasswordModalVisible(false)}
            >
              {t("Cancel")}
            </Button>
            <Button
              type="primary"
              className="button-submit"
              htmlType="submit"
              data-testid="reset-password-submit-button"
              disabled={!changePasswordModel?.newPassword || !changePasswordModel?.repeatNewPassword}
            >
              {t("Change")}
            </Button>
          </Space>
        </Form>
      </Modal>
      <Popover
        id="popover"
        open={popoverVisible}
        content={
          <Menu selectable={false}>
            <Menu.Item>
              <div
                onClick={e => {
                  e.stopPropagation();
                  setPopoverVisible(false);
                  setChangePasswordModalVisible(true);
                }}
              >
                {t("ChangePassword")}
              </div>
            </Menu.Item>
            <Menu.Item>
              <div
                onClick={() => {
                  dispatch &&
                    dispatch(forgotPassword())
                      .then(response => unwrapResult(response))
                      .then(
                        response =>
                          response &&
                          Modal.success({
                            content: t("ResetPasswordEmployeeSingleView"),
                          })
                      )
                      .catch(e => {
                        Modal.error({
                          content: t(e.message),
                        });
                      });
                }}
              >
                {t("ResetPasswordTitle")}
              </div>
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Button
          onClick={e => {
            e.stopPropagation();
            setPopoverVisible(!popoverVisible);
          }}
          className="dropdown-button"
          type="text"
          shape="round"
        >
          <MoreOutlined className="dropdown-button__icon" />
        </Button>
      </Popover>
    </>
  );
};
