import {
  ColumnManagementAction,
  CreateTrainingWithEmployees,
  ExportAction,
  GetFiltersUrl,
  ImportAction,
  ResetColumnsWidthAction,
} from "../../../../actions/menuActions";
import { HandleEmployee } from "../../../../components/HBComponents/HandleEmployeeComponent/handleEmployeeComponent";
import AddEmployeeSection from "../../../../components/SingleViewCardMobileFirst/components/addEntityTemplates/AddEmployeeSection";
import AddFileSection from "../../../../components/SingleViewCardMobileFirst/components/addEntityTemplates/AddFileSection";
import EmployeeAccountableMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/accountable/EmployeeAccountableMobileCardTemplate";
import CertificateMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/CertificateMobileCardTemplate";
import ContextActionsMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/ContextActionsMobileCardTemplate";
import FilesMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/FilesMobileCardTemplate";
import HistoryLogMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/HistoryLogMobileCardTemplate";
import { ReactComponent as PageIcon } from "../../../../media/employee-page-icon.svg";
import { employeeSelectors, locationEntitySelectors, orgUnitSelectors, pageSelectors } from "../../../../selectors";
import { setFileInfoToOpen } from "../../../../store/slices/common";
import { fetchHistoryLog } from "../../../../store/slices/commonThunks";
import {
  addEmployee,
  addEmployeeManualCertificate,
  clearEmployeeError,
  createEmployeeTemplate,
  createNewAccountableEntryTemplate,
  fetchEmployeeCustomProps,
  fetchPaginatedEmployee,
  fetchSingleEmployee,
  getEmployeeCertificates,
  patchLocationId,
  patchOrgUnitId,
  removeFiles,
  resetCurrentPage,
  resetSearchResults,
  searchEmployee,
  updateEmployee,
  updateLocationId,
  updateOrgUnitId,
  uploadFile,
} from "../../../../store/slices/employee";
import { fetchOrgUnits } from "../../../../store/slices/orgUnit";
import { deleteMultipleEntityPrivileges, getEntityPrivileges } from "../../../../store/slices/privileges";
import store from "../../../../store/store";
import { ManualCertificate } from "../../../../types/certification";
import { Employee, EmployeePage } from "../../../../types/employee";
import { BaseFile } from "../../../../types/files";
import { Location } from "../../../../types/location";
import { OrgUnit } from "../../../../types/orgUnit";
import { CategoryPage, SectionType } from "../../../../types/page";
import { PrivilegeData, PrivilegedEntityType, TableAddButtonType } from "../../../../types/utility";
import { certificateIdModalField, expirationDateModalField, trainingDateTabModalField } from "../certification/fields";
import {
  contextActionsTableColumns,
  enableForAdminOrUserOwner,
  filesTableColumns,
  historyLogTableColumns,
  locationsTableColumns,
} from "../common";
import {
  localizeText,
  pickSpecificColumns,
  prepareDynamicColumns,
  prepareDynamicColumnsPaginated,
  removeColumnFilters,
} from "../utilities";
import {
  certificationsTableColumns,
  employeeAccountableActionField,
  employeeAccountableExternalIdField,
  employeeAccountableNameField,
  employeeAccountableRoleField,
  employeesTabColumns,
  employeesTableColumns,
  orgUnitsTableColumns,
} from "./employeeConfigPageData";
import {
  authTypeField,
  displayNameField,
  expirationDateField,
  externalIdField,
  isUserField,
  locationIdField,
  orgUnitIdField,
  primaryFields,
  secondaryFields,
  statusField,
  tenantRoleField,
  twoFactorTypeField,
} from "./fields";

// TODO: FIX STATIC CONFIG
const employeeConfigPage = (): CategoryPage<EmployeePage> => ({
  id: "employee",
  title: "PluginEmployeeName",
  entityEndpoint: "/Employee",
  PageIcon: PageIcon,
  hasGroupView: false,
  hasListView: true,
  primaryErrorSelector: employeeSelectors.error,
  intializeDataActions: [fetchOrgUnits],
  fetchCustomProps: fetchEmployeeCustomProps,
  lastUpdatedSelector: employeeSelectors.employeeLastUpdatedText,
  // fetchPrimaryData: fetchEmployeeCustomProps,
  // fetchSecondaryData: [fetchLocations],
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.displayName };
  },
  addButton: {
    label: "AddEmployee",
    enablingFor: enableForAdminOrUserOwner,
    // eslint-disable-next-line no-console
    action: () => console.log("Create new employee"),
  },
  summaryCard: {
    actionComponent: entity =>
      entity.isUser ? <HandleEmployee employeeId={entity.id} employeeName={entity.displayName} /> : null,
    CardIcon: PageIcon,
    canEdit(user) {
      return enableForAdminOrUserOwner(user);
    },
    dependentFields: e => [
      {
        id: "username",
        parentId: "isUser",
        defaultValue: e.email,
      },
    ],

    mobileSingleView: {
      summary: {
        icon: PageIcon,
        title: displayNameField,
        id: externalIdField,
        status: statusField,
        mainContextRelation: orgUnitIdField,
        date: expirationDateField,
      },
      sections: [
        {
          type: SectionType.AdministrationSection,
          fields: [isUserField, tenantRoleField, authTypeField, twoFactorTypeField],
        },
        {
          type: SectionType.ContextSection,
          fields: [locationIdField],
        },
        {
          type: SectionType.DatesSection,
          fields: [],
        },
      ],
    },

    primaryFields: primaryFields,
    secondaryFields: secondaryFields,
  },
  // primaryEntitySelector: employeeSelectors.all,
  tabsPanel: [
    {
      key: "actions",
      label: "FieldActions",
      tabSelector: employeeSelectors.actions,
      columns: removeColumnFilters(contextActionsTableColumns()),
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: ContextActionsMobileCardTemplate,
    },
    {
      key: "location",
      label: "AppointmentLocationLink",
      tabSelector: locationEntitySelectors.basic,
      columns: removeColumnFilters(locationsTableColumns) as [],
      rowSelection: true,
      type: "tree",
      changeNewEntityParent: updateLocationId,
      changeExistingEntityParent: patchLocationId,
      selectedKeyId: "locationId",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<Location>(props, data)),
      customPropertiesSelector: locationEntitySelectors.locationCustomProperties,
    },
    {
      key: "team",
      label: "FieldTeam",
      tabSelector: orgUnitSelectors.basic,
      columns: removeColumnFilters(orgUnitsTableColumns) as [],
      rowSelection: true,
      type: "tree",
      changeNewEntityParent: updateOrgUnitId,
      changeExistingEntityParent: patchOrgUnitId,
      selectedKeyId: "orgUnitId",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<OrgUnit>(props, data)),
      customPropertiesSelector: orgUnitSelectors.orgUnitCustomProperties,
    },
    {
      key: "certifications",
      label: "PluginCertificationName",
      tabDataThunk: id => getEmployeeCertificates(+id),
      tabSelector: employeeSelectors.employeeCertifications,
      columns: removeColumnFilters(certificationsTableColumns) as [],
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      addButton: {
        // action: () => console.log("zxc"),
        label: "AddOrRenew",
        type: TableAddButtonType.AddModal,
        enablingFor: enableForAdminOrUserOwner,
        addModalInputFields: {
          primaryFields: [certificateIdModalField],
          secondaryFields: [trainingDateTabModalField, expirationDateModalField],
        },
        addRecordModalAction: e => addEmployeeManualCertificate((e as unknown) as ManualCertificate),
      },
      mobileCardTemplate: CertificateMobileCardTemplate,
    },
    {
      key: "accountable",
      label: "Accountable",
      tabSelector: employeeSelectors.employeeAccountableRelations,
      tabDataThunk: id => getEntityPrivileges({ id: +id, entityType: PrivilegedEntityType.Employee }),
      columns: removeColumnFilters(employeesTabColumns) as [],
      rowSelection: true,
      type: "table",
      addButton: {
        action: createNewAccountableEntryTemplate,
        label: "AddEmployee",
        type: TableAddButtonType.AddInline,
        enablingFor: enableForAdminOrUserOwner,
        mobileAddButtonTemplate: AddEmployeeSection,
        actionField: employeeAccountableActionField,
        addEntityFields: [
          employeeAccountableNameField,
          employeeAccountableExternalIdField,
          employeeAccountableRoleField,
        ],
      },
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      dropdownButtonActions: [
        {
          label: "DeleteBulk",
          action: entities =>
            deleteMultipleEntityPrivileges({
              entity: entities as PrivilegeData[],
              entityType: PrivilegedEntityType.Employee,
              id: null,
            }),
          type: "bulk",
          changesConfirmation: {
            body: "ConfirmEntitiesRemoval",
            okText: "Remove",
            cancelText: "Keep",
            addValueToBody: false,
            addValueToOkText: false,
          },
        },
      ],
      mobileCardTemplate: EmployeeAccountableMobileCardTemplate,
    },
    {
      key: "files",
      label: "Files",
      tabSelector: employeeSelectors.files,
      columns: removeColumnFilters(filesTableColumns("employee")) as [],
      addButton: {
        enablingFor: enableForAdminOrUserOwner,
        action: createNewAccountableEntryTemplate,
        label: "AddFile",
        // controlUploadModal: true,
        type: TableAddButtonType.UploadFile,
        uploadFileAction: uploadFile,
        mobileAddButtonTemplate: AddFileSection,
      },
      rowSelection: true,
      type: "table",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<File>(props, data)),
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      onRowClick: fileInfo => store.dispatch(setFileInfoToOpen(fileInfo as BaseFile)),
      dropdownButtonActions: [
        {
          label: localizeText("DeleteBulk"),
          action: entities => removeFiles(entities.map(x => x.id) as number[]),
          type: "bulk",
          changesConfirmation: {
            body: "Removing the files could not be reversible, Are you should you wish to remove the files?",
            okText: "Yes I wish to remove the files",
            cancelText: "Keep the files",
          },
        },
      ],
      mobileCardTemplate: FilesMobileCardTemplate,
    },
    {
      key: "historyLog",
      label: "HistoryLog",
      tabDataThunk: id => fetchHistoryLog({ entityId: +id, entityType: "Employee" }),
      tabSelector: employeeSelectors.employeeHistoryLog,
      columns: removeColumnFilters(historyLogTableColumns) as [],
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: HistoryLogMobileCardTemplate,
    },
  ],
  listView: {
    table: {
      tableSelector: employeeSelectors.all,
      fetchPaginatedData: fetchPaginatedEmployee,
      searchResultsSelector: employeeSelectors.employeeSearchResults,
      possibleResultsSelector: employeeSelectors.employeePossibleResults,
      columns: employeesTableColumns,
      rowSelection: true,
      defaultColumnKeys: ["orgUnitId", "externalId", "displayName", "position", "isExternal", "expirationDate"],
      type: "paginatedTable",
      searchPaginatedData: searchEmployee,
      resetSearchResult: resetSearchResults,
      resetCurrentPage: resetCurrentPage,
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumnsPaginated<Employee>(props, data),
  },
  listSearch: {
    columns: pickSpecificColumns<Employee>(employeesTableColumns, "displayName", "externalId"),
    keys: ["displayName", "externalId"],
  },
  entityProperties: [
    { property: "externalId", title: "ID", required: true },
    { property: "displayName", title: "FullName", required: true },
    { property: "isUser", title: "User" },
    { property: "tenantRole", title: "TenantUserRole" },
    { property: "email", title: "FieldEmail" },
    { property: "phone", title: "FieldPhone" },
    { property: "teamOUIdentifier", title: "TeamOuId" },
    { property: "position", title: "FieldPosition" },
    { property: "locationIdentifier", title: "FieldLocation" },
    { property: "ownerLocationIdentifiers", title: "OwnerLocations" },
    { property: "recipientLocationIdentifiers", title: "RecipientLocations" },
    { property: "isExternal", title: "IsExternal" },
    { property: "expirationDate", title: "FieldExpirationDate" },
    { property: "username", title: "AuthTypeUsername" },
    { property: "authType", title: "FieldAuthType" },
    { property: "twoFactorType", title: "FieldTwoFactorType" },
  ],
  isLoading: pageSelectors.isEmployeePageLoading,
  primarySingleEntitySelector: employeeSelectors.single,
  customPropertiesSelector: employeeSelectors.employeeCustomProperties,
  fetchSingle: fetchSingleEmployee,
  clearError: clearEmployeeError,
  createNewEntityTemplate: createEmployeeTemplate,
  createNewEntity: addEmployee,
  updateEntity: updateEmployee,
  listViewActions: [
    ImportAction,
    ColumnManagementAction,
    ResetColumnsWidthAction,
    ExportAction,
    CreateTrainingWithEmployees,
    GetFiltersUrl,
  ],
  singleViewActions: [],
});

export default employeeConfigPage;
