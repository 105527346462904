import "./MobileCardTemplate.less";

import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import PDFActions from "../../../../pages/TableActions/PDFActions";
import { FILE_FIELD_NAME } from "../../../../types/files";
import { MobileCardTemplateTProps } from "../../../../types/page";
import { HistoryLog } from "../../../../types/utility";
import HBTextArea from "../../../HBComponents/Input/HBTextArea";
import CardLabel from "../core/CardLabel/CardLabel";
import CardLabelWithIcon, { ICON_NAMES } from "../core/CardLabelWithIcon/CardLabelWithIcon";
import CardWithSelect from "../core/CardWithSelect/CardWithSelect";
import ContainerWithDivider from "../core/ContainerWithDivider/ContainerWithDivider";
import RowContainer from "../core/RowContainer/RowContainer";
import StatusMark from "../core/StatusMark/StatusMark";

const HistoryLogMobileCardTemplate: React.FC<MobileCardTemplateTProps<HistoryLog>> = ({ entity }) => {
  const { t } = useTranslation();

  const getNewValueLabel = () => {
    if (entity.fieldName === FILE_FIELD_NAME) {
      return entity.newValue;
    }
    return `${t("NewValue")} - ${entity.newValue}`;
  };

  const openTextInModal = (label: string, value: string) => {
    Modal.info({
      title: t(label),
      icon: null,
      content: <HBTextArea value={value} disabled />,
      maskClosable: true,
    });
  };

  return (
    <CardWithSelect entity={entity}>
      <ContainerWithDivider>
        {!!entity.timeStamp && <CardLabel label={entity.timeStamp} />}
        {!!entity.userName && <CardLabelWithIcon label={entity.userName} iconName={ICON_NAMES.USER} />}
      </ContainerWithDivider>
      <ContainerWithDivider>
        {!!entity.fieldName && <CardLabel label={entity.fieldName} />}
        {!!entity.operation && <StatusMark statusLabel={entity.operation} />}
      </ContainerWithDivider>
      <ContainerWithDivider>
        {!!entity.oldValue && (
          <div onClick={() => openTextInModal(t("OldValue"), entity.oldValue)}>
            <CardLabel label={`${t("OldValue")} - ${entity.oldValue}`} />
          </div>
        )}
        {!!entity.newValue && (
          <div onClick={() => openTextInModal(t("NewValue"), getNewValueLabel())}>
            <CardLabel label={getNewValueLabel()} />
          </div>
        )}
      </ContainerWithDivider>
      <RowContainer>{entity.pdfLink && <PDFActions pdfLink={entity.pdfLink} />}</RowContainer>
    </CardWithSelect>
  );
};

export default HistoryLogMobileCardTemplate;
