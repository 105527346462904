import { createAsyncThunk, createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";

import { Modal } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import dayjs from "dayjs";
import unionBy from "lodash/unionBy";

import { DATE_FORMAT_WITHOUT_TIME } from "../../components/HBComponents/DatePicker/HBDatePicker";
import { CustomError, getCustomPropertiesWithValues } from "../../pages/pageConfig/category/utilities";
import { Equipment, EquipmentPaginatedData, EquipmentState, GroupViewEquipments } from "../../types/equipment";
import { File, FileUploadResponse } from "../../types/files";
import { InspectionCheckpoint, InspectionStatus, InspectionTabModel } from "../../types/inspection";
import { ContextActions, ContextActionsPaginatedData } from "../../types/tasks";
import {
  CreateAppointmentResponseModel,
  ExplicitAdditionalProps,
  HistoryLog,
  PrivilegeData,
  PrivilegedEntityType,
  Status,
  UpdateCustomProps,
} from "../../types/utility";
import { extractAndProcessAppointmentUrl } from "../../utils/functions";
import { generateFiltersAndEmptyProps, generateGridifySorterQuery } from "../../utils/gridifyQueryHelper";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import { fetchHistoryLog } from "./commonThunks";
import {
  addEntityPrivilege,
  createNewPrivilegeTemplate,
  deleteEntityPrivilege,
  deleteMultipleEntityPrivileges,
  deleteNewPrivilegeEntryTemplate,
  fillNewPrivilegeEntryTemplate,
  getEntityPrivileges,
  updateAccountableEntity,
  updateEntityPrivilegeOULvl,
  updateEntityPrivilegeRole,
  updateNewPrivilegeEntry,
} from "./privileges";

// TODO: REMOVE DRY CODE (ACTIONS/THUNKS/TYPES). ESPECIALLY FOR TABS FUNCTIONALITY

export const initialState: EquipmentState = {
  data: [],
  isLoading: false,
  singleData: null,
  subData: {
    inspections: [],
    historyLog: [],
    accountable: [],
    files: [],
    actions: [],
  },
  basicData: [],
  error: null,
  defaultCustomProperties: [],
  lastUpdated: dayjs().toISOString(),
  groupViewData: [],
  searchResults: [],
  paginationInfo: {
    count: 0,
    currentPage: 0,
  },
};

export const newEquipment: Equipment = {
  id: 0,
  typeId: null,
  typeName: "",
  locationId: null,
  locationName: "",
  serialNumber: "",
  name: "",
  expiration: dayjs().toISOString(),
  status: Status.Active,
};

export const addEquipment = createAsyncThunk<Equipment, { entity: Equipment }, { state: RootState }>(
  "@@EQUIPMENT/ADD",
  async ({ entity }, { getState, rejectWithValue }) => {
    const { user, equipment } = getState();
    const modifiedPropertyValues: UpdateCustomProps = {};
    entity.customPropertyValues?.forEach(prop => {
      modifiedPropertyValues[prop.propertyId] = prop.value;
    });

    const modifiedEntity = { ...entity, customPropertyValues: modifiedPropertyValues };
    try {
      const response = await hbApi.post<Equipment>("/Equipment", modifiedEntity, hbApiOptions(user.jwt));

      return {
        ...response.data,
        customPropertyValues: getCustomPropertiesWithValues(
          equipment.defaultCustomProperties,
          response.data.customPropertyValues
        ),
      };
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { equipment, user } = getState();
      return !equipment.isLoading && !!user.jwt;
    },
  }
);

export const setNewComment = createAsyncThunk<
  { id: number; comment: string },
  { id: number; comment: string },
  { state: RootState }
>("@@EQUIPMENT/SET_NEW_COMMENT", async ({ id, comment }, { getState }) => {
  const { user } = getState();

  await hbApi.patch<InspectionTabModel>(
    `/InspectionEquipment/${id}`,
    [{ path: "/comment", op: "replace", value: comment }],
    hbApiOptions(user.jwt)
  );

  return { id, comment };
});

export const updateInspectionCommentAsync = createAsyncThunk<
  { id: number; newValue: string },
  { id: number; newValue: string },
  { state: RootState }
>("@@EQUIPMENT/UPDATE_INSPECTION_FIELD", async ({ id, newValue }, { getState }) => {
  const { user } = getState();

  await hbApi.patch<InspectionTabModel>(
    `/InspectionEquipment/${id}`,
    [{ path: "/comment", op: "replace", value: newValue }],
    hbApiOptions(user.jwt)
  );

  return { id, newValue };
});

export const openNewAppointment = createAsyncThunk<void, { checkpoint: InspectionTabModel }, { state: RootState }>(
  "@@INSPECTIONEQUIPMENT/OPEN_NEW_APPOINTMENT",
  async (model, { getState }) => {
    const { user } = getState();

    const isPasProEnabled = user.companySettings.enablePasPro;
    const getAppointmentInfo = async (check: boolean) =>
      await hbApi.post<CreateAppointmentResponseModel>(
        "/InspectionEquipment/create-appointment",
        {
          checkpointId: model.checkpoint.id,
          checkAppointmentAlreadyExists: check,
        },
        hbApiOptions(user.jwt, null, { LANGUAGE_KEY: user.settings.lang })
      );
    const {
      data: { warningMessage, urlToAppointment },
    } = await getAppointmentInfo(true);

    if (warningMessage) {
      Modal.confirm({
        content: warningMessage,
        onOk: async () => {
          try {
            const appointmentInfo = await getAppointmentInfo(false);
            extractAndProcessAppointmentUrl(appointmentInfo.data?.urlToAppointment, dispatch, isPasProEnabled);
          } catch (error) {
            console.error("Error processing appointment info on confirmation:", error);
          }
        },
        onCancel: () => null,
        closable: true,
        maskClosable: true,
      });
    } else {
      extractAndProcessAppointmentUrl(urlToAppointment, dispatch, isPasProEnabled);
    }
  }
);

export const changeInspectionStatus = createAsyncThunk<
  { id: number; newValue: string },
  { id: number; newValue: string },
  { state: RootState }
>("@@EQUIPMENT/CHANGE_INSPECTION_STATUS", async ({ id, newValue }, { getState, rejectWithValue }) => {
  const { user } = getState();

  try {
    await hbApi.patch<InspectionTabModel>(
      `/InspectionEquipment/${id}`,
      [{ path: "/status", op: "replace", value: newValue }],
      hbApiOptions(user.jwt)
    );
  } catch (e) {
    if (e.status === 400) {
      return rejectWithValue(e.data);
    }
    throw new CustomError(e.message || e.Message);
  }

  return { id, newValue };
});

export const updateEquipment = createAsyncThunk<Equipment, Equipment, { state: RootState }>(
  "@@EQUIPMENT/UPDATE",
  async (entity, { getState, rejectWithValue }) => {
    const { user, equipment } = getState();
    const modifiedPropertyValues: UpdateCustomProps = {};

    entity.customPropertyValues?.forEach(prop => {
      modifiedPropertyValues[prop.propertyId] = prop.value;
    });

    const modifiedEntity = { ...entity, customPropertyValues: modifiedPropertyValues };
    try {
      const response = await hbApi.put<Equipment>(`/Equipment/${entity.id}`, modifiedEntity, hbApiOptions(user.jwt));

      return {
        ...response.data,
        customPropertyValues: getCustomPropertiesWithValues(
          equipment.defaultCustomProperties,
          response.data.customPropertyValues
        ),
      };
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { equipment, user } = getState();
      return !equipment.isLoading && !!user.jwt;
    },
  }
);

export const fetchEquipmentCustomProps = createAsyncThunk<ExplicitAdditionalProps[], void, { state: RootState }>(
  "@@EQUIPMENT_CUSTOM_PROPS/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<ExplicitAdditionalProps[]>("/EquipmentCustomProperty", hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { equipment } = getState();
      return !equipment.isLoading;
    },
  }
);

export const fetchEquipmentsCards = createAsyncThunk<GroupViewEquipments[], boolean | undefined, { state: RootState }>(
  "@@EQUIPMENT_CARDS/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const equipmentCards = await hbApi.get<GroupViewEquipments[]>(
      "/Equipment/EquipmentTypeCardView",
      hbApiOptions(user.jwt)
    );

    return equipmentCards.data;
  }
);

export const fetchFullEquipments = createAsyncThunk<{ basicData: Equipment[] }, void, { state: RootState }>(
  "@@EQUIPMENT/FETCH_BASICS",
  async (_, { getState }) => {
    const { user } = getState();
    const res = await hbApi.get<Equipment[]>("/Equipment/full", hbApiOptions(user.jwt));
    return {
      basicData: res.status === 200 ? res.data : [],
    };
  }
);

export const removeFiles = createAsyncThunk<number[], number[], { state: RootState }>(
  "@@EQUIPMENT/REMOVE_FILE",
  async (ids, { getState }) => {
    const { user, equipment } = getState();
    await hbApi.delete<File[]>(
      "/File",
      hbApiOptions(user.jwt, {
        entityId: equipment.singleData?.id,
        entityType: PrivilegedEntityType.Equipment,
        fileIds: ids,
      })
    );

    return ids;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadFile = createAsyncThunk<File[] | null, { file: any; expiration: string }, { state: RootState }>(
  "@@EQUIPMENT/UPLOAD_FILE",
  async ({ file, expiration }, { getState }) => {
    const { user, equipment } = getState();
    const formData = new FormData();
    formData.append("files[]", file);

    try {
      const fileEntity = await hbApi.post<FileUploadResponse>(
        `/File/upload?entityType=Equipment&entityId=${equipment?.singleData?.id || 0}&expirationDate=${expiration}`,
        formData,
        hbApiOptions(user.jwt)
      );

      return fileEntity.data?.data;
    } catch (e) {
      throw new CustomError(e.message);
    }
  }
);

export const fetchSingleEquipment = createAsyncThunk<
  {
    singleData: Equipment;
    subData: { historyLog: HistoryLog[]; inspections: InspectionTabModel[]; files: File[]; actions: ContextActions[] };
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>(
  "@@SINGLE_EQUIPMENT/FETCH",
  async (id, { getState, rejectWithValue }) => {
    const { user } = getState();
    try {
      const cardDataResponse = await hbApi.get<Equipment>(`/Equipment/${id}`, hbApiOptions(user.jwt));
      const inspectionsResponse = await hbApi.get<InspectionTabModel[]>(
        `/InspectionEquipment/get-inspections-by-equipment/${id}`,
        hbApiOptions(user.jwt)
      );
      const filesResponse = await hbApi.get<File[]>(
        `/File?entityType=Equipment&entityId=${id}`,
        hbApiOptions(user.jwt)
      );
      const contextActionsResponse = await hbApi.get<ContextActionsPaginatedData>(
        `/Task/context-actions?entityId=${id}&entityType=Equipment`,
        hbApiOptions(user.jwt)
      );
      const equipmentCustomProperties = await hbApi.get<ExplicitAdditionalProps[]>(
        "/EquipmentCustomProperty",
        hbApiOptions(user.jwt)
      );

      return {
        singleData: {
          ...cardDataResponse.data,
          customPropertyValues: getCustomPropertiesWithValues(
            equipmentCustomProperties.data,
            cardDataResponse.data.customPropertyValues
          ),
        },
        subData: {
          inspections: inspectionsResponse.data
            ? inspectionsResponse.data.map(x => ({ ...x, newComment: x.comment }))
            : [],
          historyLog: [],
          files: filesResponse.data,
          actions: contextActionsResponse.data.data,
        },
        defaultCustomProperties: equipmentCustomProperties.data,
      };
    } catch (e) {
      if (e.status === 400 || e.status === 404) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { equipment, user } = getState();
      return !equipment.isLoading && !!user.jwt;
    },
  }
);

export const patchLocationId = createAsyncThunk<
  Equipment,
  { objectId: number; newValue: number },
  { state: RootState }
>(
  "@@EQUIPMENT/PATCH_LOCATION_ID",
  async ({ objectId, newValue }, { getState }) => {
    const { user } = getState();

    try {
      const response = await hbApi.patch<Equipment>(
        `/Equipment/${objectId}`,
        [{ path: "/locationId", op: "replace", value: newValue }],
        hbApiOptions(user.jwt)
      );
      return response.data;
    } catch (e) {
      if (e.status === 400) {
        throw new CustomError(e.message || e.Message);
      }
      throw new CustomError();
    }
  },
  {
    condition: (_, { getState }) => {
      const { location, user } = getState();
      return !location.isLoading && !!user.jwt;
    },
  }
);

export const confirmStatus = createAsyncThunk<
  { checkpoint: InspectionCheckpoint | null },
  { file: RcFile; value: string; entityId: number },
  { state: RootState }
>("@@INSPECTIONEQUIPMENT/CONFIRM_STATUS", async (model, { getState }) => {
  const { user, equipment } = getState();
  const currentCheckpoint = equipment.subData?.inspections?.find(x => x.id === model.entityId) || null;

  // Data change check
  if (!currentCheckpoint) return { checkpoint: null };

  const formData = new FormData();

  formData.append("file", model.file);

  try {
    const fileUrl = await hbApi.post<string>("/File/upload-file", formData, hbApiOptions(user.jwt));
    const response = await hbApi.patch<InspectionCheckpoint>(
      `/InspectionEquipment/${model.entityId}`,
      [
        { path: "/comment", op: "replace", value: model.value },
        { path: "/fileUrl", op: "replace", value: fileUrl.data },
        { path: "/status", op: "replace", value: currentCheckpoint.status },
      ],
      hbApiOptions(user.jwt)
    );
    return {
      checkpoint: response.data,
    };
  } catch (e) {
    throw new CustomError(e.message);
  }
});

export const fetchPaginatedEquipments = createAsyncThunk<
  { primaryData: Equipment[]; defaultCustomProperties: ExplicitAdditionalProps[]; possibleResults: number },
  { page?: number; pageSize?: number; forceUpdate?: boolean } | undefined,
  { state: RootState }
>(
  "@@EQUIPMENT/FETCH_PAGINATED",
  async (params, { getState }) => {
    const { user, equipment, filter } = getState();

    const equipmentFilters = filter.filters.equipment?.equipment?.activeFilters;

    const { filters, emptyPropIds } = generateFiltersAndEmptyProps(equipmentFilters);

    const orders = filter.filterValues.equipment?.order
      ? generateGridifySorterQuery(filter.filterValues.equipment?.order)
      : undefined;

    const response = await hbApi.post<EquipmentPaginatedData>(
      "/Equipment/pagedList",
      {
        gridifyQuery: {
          Page: params?.page || equipment.paginationInfo.currentPage + 1,
          PageSize: params?.pageSize,
          Filter: filters,
          OrderBy: orders,
        },
        emptyPropIds: emptyPropIds,
      },
      hbApiOptions(user.jwt)
    );

    const equipmentCustomProperties = await hbApi.get<ExplicitAdditionalProps[]>(
      "/EquipmentCustomProperty",
      hbApiOptions(user.jwt)
    );
    return {
      primaryData: response.data.data,
      defaultCustomProperties: equipmentCustomProperties.data,
      possibleResults: response.data.count,
    };
  },
  {
    condition: (arg, { getState }) => {
      const { equipment } = getState();
      return (
        arg?.forceUpdate ||
        (dayjs(equipment.lastUpdated).isBefore(dayjs()) &&
          !equipment.isLoading &&
          equipment.data.length !== equipment.paginationInfo.count)
      );
    },
  }
);
export const searchEquipment = createAsyncThunk<
  Equipment[],
  { filters?: string; page?: number } | undefined,
  { state: RootState }
>(
  "@@EQUIPMENT/SEARCH_PAGINATED",
  async (params, { getState }) => {
    const { user } = getState();
    const response = await hbApi.post<EquipmentPaginatedData>(
      "/Equipment/pagedList",
      {
        gridifyQuery: {
          Page: params?.page || 1,
          PageSize: 100,
          Filter: params?.filters,
        },
      },
      hbApiOptions(user.jwt)
    );
    return response.data.data;
  },
  {
    condition: (_, { getState }) => {
      const { equipment } = getState();
      return !equipment.isLoading;
    },
  }
);

const slice = createSlice({
  name: "equipment",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    createEqTemplate: state => {
      const customProps = state.defaultCustomProperties;
      state.singleData = {
        ...newEquipment,
        customPropertyValues: getCustomPropertiesWithValues(customProps),
      };
      state.subData.historyLog = [];
      state.subData.inspections = [];
    },
    changeLocalStatus: {
      prepare: (payload: { entity: InspectionTabModel; status: InspectionStatus }) => ({ payload }),
      reducer: (state, action: PayloadAction<{ entity: InspectionTabModel; status: InspectionStatus }>) => {
        if (state.subData && state.subData.inspections) {
          const index = state.subData.inspections.findIndex(x => x.id === action.payload.entity.id);

          if (index >= 0) {
            state.subData.inspections[index].status = action.payload.status;
            state.subData.inspections[index].staging = true;
          }
        }
      },
    },
    createNewAccountableEntryTemplate: createNewPrivilegeTemplate,
    fillNewAccountableEntryTemplate: {
      prepare: (payload: { row: PrivilegeData; targetEntity: Record<string, unknown> }) => ({ payload }),
      reducer: fillNewPrivilegeEntryTemplate,
    },
    updateNewAccountableEntry: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      prepare: (payload: { entity: PrivilegeData; newValue: any; property: keyof PrivilegeData }) => ({ payload }),
      reducer: updateNewPrivilegeEntry,
    },
    deleteNewAccountableEntryTemplate: {
      prepare: (payload: PrivilegeData) => ({ payload }),
      reducer: deleteNewPrivilegeEntryTemplate,
    },
    cancelNewComment: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        const index = state.subData.inspections.findIndex(x => x.id === action.payload);

        if (index >= 0) {
          state.subData.inspections[index].newComment = state.subData.inspections[index].comment;
          state.subData.inspections[index].staging = false;
        }
      },
    },
    updateInspectionComment: {
      prepare: (payload: { value: string | number | boolean | null; id: number }) => ({ payload }),
      reducer: (state, action: PayloadAction<{ value: string | number | boolean | null; id: number }>) => {
        const index = state.subData.inspections.findIndex(x => x.id === action.payload.id);

        if (index >= 0) state.subData.inspections[index].newComment = action.payload.value as string;
      },
    },
    setInspectionEditMode: {
      prepare: (payload: { value: boolean; id: number }) => ({ payload }),
      reducer: (state, action: PayloadAction<{ value: boolean; id: number }>) => {
        const index = state.subData.inspections.findIndex(x => x.id === action.payload.id);

        if (index >= 0) state.subData.inspections[index].staging = action.payload.value;
      },
    },
    updateLocationId: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        if (state.singleData) {
          state.singleData = { ...state.singleData, locationId: action.payload };
        }
      },
    },
    clearEquipmentError: state => {
      state.error = null;
    },
    resetCurrentPage: state => {
      state.paginationInfo.currentPage = 0;
    },
    resetSearchResults: state => {
      state.searchResults = [];
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:

      // Fetch all
      .addCase(fetchEquipmentsCards.pending, state => {
        state.isLoading = true;
        state.error = null;
      })

      // Fetch single
      .addCase(fetchSingleEquipment.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPaginatedEquipments.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchEquipment.pending, state => {
        state.error = null;
      })

      // Add
      .addCase(addEquipment.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(openNewAppointment.pending, state => {
        state.isLoading = true;
        state.error = null;
      })

      // Update
      .addCase(updateEquipment.pending, state => {
        state.isLoading = true;
        state.error = null;
      })

      // Patch Parent ID
      .addCase(patchLocationId.pending, state => {
        state.isLoading = true;
        state.error = null;
      })

      .addCase(getEntityPrivileges.pending, state => {
        state.isLoading = true;
        state.error = null;
      })

      // // Add LocationEmployee
      // .addCase(addEquipmentEmployees.pending, state => {
      //   state.isLoading = true;
      //   state.error = null;
      // })

      // // Add LocationOrgUnit
      // .addCase(addEquipmentOrgUnit.pending, state => {
      //   state.isLoading = true;
      //   state.error = null;
      // })

      // // Delete LocationEmployee
      // .addCase(deleteEquipmentRelation.pending, state => {
      //   state.isLoading = true;
      //   state.error = null;
      // })

      // .addCase(changeEmployeeRole.pending, state => {
      //   state.isLoading = true;
      //   state.error = null;
      // })
      // .addCase(deleteBulkRelations.pending, state => {
      //   state.isLoading = true;
      //   state.error = null;
      // })
      .addCase(uploadFile.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeFiles.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchFullEquipments.pending, state => {
        state.basicData = [];
        state.error = null;
      })
      // Note - Rejected:

      // Fetch all
      .addCase(fetchEquipmentsCards.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      // Fetch single
      .addCase(fetchSingleEquipment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
        state.singleData = undefined;
      })

      // Add
      .addCase(addEquipment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(openNewAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      // Update
      .addCase(updateEquipment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      // Patch Parent ID
      .addCase(patchLocationId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(changeInspectionStatus.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.isLoading = false;
      })
      .addCase(updateInspectionCommentAsync.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.isLoading = false;
      })
      .addCase(fetchHistoryLog.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(removeFiles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchFullEquipments.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(getEntityPrivileges.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchPaginatedEquipments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(searchEquipment.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      // Note - Fulfilled:

      // Fetch all
      .addCase(fetchPaginatedEquipments.fulfilled, (state, action) => {
        if (action.meta.arg?.page === 1) {
          state.data = action.payload.primaryData;
          state.paginationInfo.currentPage = 1;
        } else {
          state.data = unionBy(state.data, action.payload.primaryData, "id");
          state.paginationInfo.currentPage = state.paginationInfo.currentPage + 1;
        }
        state.paginationInfo.count = action.payload.possibleResults;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.lastUpdated = dayjs().toISOString();
        state.isLoading = false;
      })
      .addCase(searchEquipment.fulfilled, (state, action) => {
        state.searchResults = [...action.payload];
        state.error = null;
      })
      .addCase(fetchEquipmentCustomProps.fulfilled, (state, action) => {
        state.defaultCustomProperties = action.payload;
        // state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(changeInspectionStatus.fulfilled, (state, action) => {
        const updatedEntityIndex = state.subData.inspections.findIndex(r => r.id === action.payload.id);

        state.subData.inspections[updatedEntityIndex].status =
          InspectionStatus[action.payload.newValue as keyof typeof InspectionStatus];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateInspectionCommentAsync.fulfilled, (state, action) => {
        const updatedEntityIndex = state.subData.inspections.findIndex(r => r.id === action.payload.id);

        state.subData.inspections[updatedEntityIndex].comment = action.payload.newValue;
        state.subData.inspections[updatedEntityIndex].staging = false;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchEquipmentsCards.fulfilled, (state, action) => {
        state.groupViewData = action.payload;
        state.lastUpdated = dayjs().toISOString();
        state.isLoading = false;
        state.error = null;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const filesArray = [...state.subData.files];

        if (action.payload && action.payload.length) filesArray.unshift(action.payload[0]);
        state.subData.files = filesArray;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(removeFiles.fulfilled, (state, action) => {
        state.subData.files = state.subData.files.filter(x => !action.payload.includes(x.id));
        state.isLoading = false;
        state.error = null;
      })

      // Fetch single
      .addCase(fetchSingleEquipment.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.subData.historyLog = action.payload.subData.historyLog;
        state.subData.files = action.payload.subData.files;
        state.subData.actions = action.payload.subData.actions;
        state.subData.inspections = action.payload.subData.inspections;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.subData.accountable = [];
        state.isLoading = false;
        state.error = null;
      })

      // Add
      .addCase(addEquipment.fulfilled, (state, action) => {
        state.singleData = action.payload;
        state.data = state.data ? [...state.data, action.payload] : [action.payload];
        state.isLoading = false;
        state.error = null;
      })

      // Update
      .addCase(updateEquipment.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(equipment => equipment.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.subData.inspections = state.subData.inspections.map(x => ({
          ...x,
          status:
            action.payload.status === Status.InActive
              ? InspectionStatus.IsNoLongerNeeded
              : x.status === InspectionStatus.IsNoLongerNeeded
              ? InspectionStatus.Pending
              : x.status,
        }));
        state.isLoading = false;
        state.error = null;
      })

      .addCase(patchLocationId.fulfilled, (state, action) => {
        if (state.singleData) {
          state.singleData.locationId = action.payload.locationId;
          const updatedEntityIndex = state.data.findIndex(equipment => equipment.id === action.payload.id);
          state.data[updatedEntityIndex].locationId = action.payload.locationId;
          state.isLoading = false;
          state.error = null;
        }
      })
      // Get EquipmentEmployees and EquipmentOrgUnits relations
      .addCase(getEntityPrivileges.fulfilled, (state, action) => {
        state.subData.accountable = action.payload;
        state.isLoading = false;
        state.error = null;
      })

      // Add EquipmentOrgUnit
      .addCase(addEntityPrivilege.fulfilled, (state, action) => {
        state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
        state.subData.accountable.unshift({
          ...action.payload,
          id: action.payload.id!,
          staging: false,
        });
        // state.isLoading = false;
        state.error = null;
      })

      // Delete EquipmentEmployee relation
      .addCase(deleteEntityPrivilege.fulfilled, (state, action) => {
        state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
        // state.isLoading = false;
        state.error = null;
      })

      // Change Relation Role
      .addCase(updateEntityPrivilegeRole.fulfilled, (state, action) => {
        const updatedEntityIndex = state.subData.accountable.findIndex(r => r.id === action.payload.id);
        state.subData.accountable[updatedEntityIndex].role = action.payload.role;
        // state.isLoading = false;
        state.error = null;
      })

      // Delete Bulk Relations
      .addCase(deleteMultipleEntityPrivileges.fulfilled, (state, action) => {
        const privilegesToRemove = action.payload.map(r => r.id);
        state.subData.accountable = state.subData.accountable.filter(r => !privilegesToRemove.includes(r.id));
        // state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchHistoryLog.fulfilled, (state, action) => {
        state.subData.historyLog = action.payload
          ? action.payload.map(r => ({
              ...r,
              id: nanoid(),
              timeStamp: dayjs(r.timeStamp).format(DATE_FORMAT_WITHOUT_TIME),
            }))
          : [];
        // state.isLoading = false;
        state.error = null;
      })
      .addCase(openNewAppointment.fulfilled, state => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchFullEquipments.fulfilled, (state, action) => {
        state.basicData = action.payload.basicData;
        state.error = null;
      })
      .addCase(updateEntityPrivilegeOULvl.fulfilled, (state, action) => {
        updateAccountableEntity(state, action);
      });
  },
});

export const {
  createEqTemplate,
  updateLocationId,
  fillNewAccountableEntryTemplate,
  updateNewAccountableEntry,
  deleteNewAccountableEntryTemplate,
  createNewAccountableEntryTemplate,
  cancelNewComment,
  updateInspectionComment,
  setInspectionEditMode,
  changeLocalStatus,
  clearEquipmentError,
  resetCurrentPage,
  resetSearchResults,
} = slice.actions;
export default slice.reducer;
