import { SortOrder } from "antd/lib/table/interface";
import dayjs from "dayjs";
import escapeRegExp from "lodash/escapeRegExp";
import { DictionaryValues } from "../types/customProperty";
import { NoValueKey } from "../types/utility";
import { FieldType, Filter, FilterType } from "./hooks/useFilter";

export const getGridifyOperator = (operator: FilterType) => {
  switch (operator) {
    case "equals": {
      return "=";
    }
    case "includes": {
      return "=*";
    }
    case "lessThan": {
      return "<=";
    }
    case "moreThan": {
      return ">=";
    }
    case "tags": {
      return "=*";
    }
    default: {
      return "=";
    }
  }
};

const convertToGriddifySortOrder = (order: SortOrder) => {
  switch (order) {
    case "ascend":
      return "asc";
    case "descend":
      return "desc";
  }
};

const createFilterRecord = (filters: Filter[]) => {
  return filters.reduce<Record<string, { type: FieldType | undefined; filters: Filter[] }>>((acc, filter) => {
    return {
      ...acc,
      [filter.key]: acc[filter.key]
        ? { ...acc[filter.key], filters: [...acc[filter.key].filters, filter] }
        : { type: filter.fieldType, filters: [filter] },
    };
  }, {});
};

const convertFilter = (filter: Filter) => {
  const isNoValue = filter.value === NoValueKey;
  if (filter.additionalInfo && isNoValue) {
    return "";
  }

  if (filter.additionalInfo) {
    //custom props
    const propId = escapeRegExp(String(filter.additionalInfo.propId));
    const value = filter.additionalInfo.dictionaryId
      ? filter.additionalInfo.dictionaryValues.find((x: DictionaryValues) => x.value === filter.value)?.id
      : String(filter.value);

    return `(propertyId=${propId},propertyValue=${escapeRegExp(value)})`;
  }

  const operator = isNoValue || filter.value === "" ? "equals" : filter.filterType;
  let value = "";
  if (isNoValue) {
    value = "";
  } else if ((filter.fieldType === "date" || filter.fieldType === "dateRange") && typeof filter.value === "string") {
    value = dayjs.utc(filter.value).startOf("minute").format("YYYY-MM-DDTHH:mm");
  } else {
    value = String(filter.value);
  }
  const blankKey = filter.isBlank ? `|${filter.key}=` : "";
  const escapedFValue = escapeRegExp(value).replace(/,/g, "\\,");

  return `${filter.key}${getGridifyOperator(operator)}${escapedFValue}${blankKey}`;
};

const getFiltersString = (
  [key, { type }]: [string, { type: FieldType | undefined; filters: Filter[] }],
  convertedFilters: string[]
) => {
  if (key === "tags") {
    return convertedFilters.map(x => x.replace(/,/g, "\\,")).join(",");
  }

  switch (type) {
    case "dateRange":
      return `(${convertedFilters.join(",")})`;
    default:
      return convertedFilters.length ? `(${convertedFilters.join("|")})` : "";
  }
};

export const generateGridifyQuery = (filters: Filter[]) => {
  if (filters.length > 0) {
    return Object.entries(createFilterRecord(filters))
      .map(entry => {
        const convertedFilters = entry[1].filters.flatMap(convertFilter).filter(item => item && item !== NoValueKey);
        const escapedFilters = convertedFilters.map(filter => filter.replace(/\n/g, "\\n"));
        return getFiltersString(entry, escapedFilters);
      })
      .filter(Boolean)
      .join(",");
  }
  return null;
};

export const generateGridifyEmptyPropsQuery = (filters: Filter[]) => {
  const emptyPropsIds = filters
    .filter(filter => filter.value === NoValueKey && filter.additionalInfo)
    .map(filter => escapeRegExp(String(filter.additionalInfo.propId)));

  return emptyPropsIds.length > 0 ? emptyPropsIds.join(",") : null;
};

export const generateGridifySorterQuery = (sorter: Record<string, SortOrder>) => {
  return (
    Object.entries(sorter)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, sortOrder]) => sortOrder)
      .map(([key, sortOrder]) => `${key} ${convertToGriddifySortOrder(sortOrder)}`)
      .join(" , ")
  );
};

export function generateFiltersAndEmptyProps(activeFilters?: Filter[]) {
  const filters = activeFilters?.length ? generateGridifyQuery(activeFilters) : undefined;
  const emptyPropIds = activeFilters?.length ? generateGridifyEmptyPropsQuery(activeFilters) : undefined;

  return { filters, emptyPropIds };
}
