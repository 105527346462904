import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Modal } from "antd";
import dayjs from "dayjs";
import {
  filterAppointmentsBySearchTerm,
  filterInspectionsBySearchTerm,
  filterTasksBySearchTerm,
  filterTodoListByStatus,
} from "../../pages/TodoList/utils";
import {
  InitialTodoObj,
  InspectionLinkedEntityType,
  TodoList,
  TodoListSectioned,
  TodolistState,
  TodoListStatusFilter,
  TodoListViewFilter,
  TodoSection,
  TodoTotal,
} from "../../types/todolist";
import { CreateAppointmentResponseModel, TodoListCardStatus } from "../../types/utility";
import { extractAndProcessAppointmentUrl } from "../../utils/functions";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

export const initialState: TodolistState = {
  todoList: InitialTodoObj,
  filteredTodoList: InitialTodoObj,
  todoListLoaded: false,
  isLoading: false,
  sectionIsLoading: { today: false, month: false, quarter: false, week: false, year: false },
  error: null,
  total: { today: 0, month: 0, quarter: 0, week: 0, year: 0 },
  totalFiltered: { today: 0, month: 0, quarter: 0, week: 0, year: 0 },
  searchTerm: "",
  todoListStatusFilter: {
    PendingOnly: true,
    LateOnly: false,
    ShowAll: false,
  },
  todoListViewFilter: TodoListViewFilter.OnlyMy,
};

const updateClosedTaskOnSection = (sectionKey: string, section: TodoListSectioned, taskId: number) => {
  const typedSection = sectionKey as keyof TodoListSectioned;
  section[typedSection] = {
    ...section[typedSection],
    tasks: section[typedSection].tasks.map(t => {
      if (t.id === taskId) {
        return {
          ...t,
          completedDate: dayjs().format(),
          cardStatus: TodoListCardStatus.Close,
          canBeCompleted: false,
        };
      }
      return t;
    }),
  };
};

const updateReopendTask = (sectionKey: string, section: TodoListSectioned, taskId: number) => {
  const typedSection = sectionKey as keyof TodoListSectioned;
  section[typedSection] = {
    ...section[typedSection],
    tasks: section[typedSection].tasks.map(t => {
      if (t.id === taskId) {
        return {
          ...t,
          cardStatus: TodoListCardStatus.Open,
          completedDate: undefined,
        };
      }
      return t;
    }),
  };
};

const updateProcessingTaskOnSection = (sectionKey: string, section: TodoListSectioned, taskId: number) => {
  const typedSection = sectionKey as keyof TodoListSectioned;
  section[typedSection] = {
    ...section[typedSection],
    tasks: section[typedSection].tasks.map(t => {
      if (t.id === taskId) {
        return {
          ...t,
          cardStatus: TodoListCardStatus.Proccessing,
        };
      }
      return t;
    }),
  };
};

export const fetchInitialTodoList = createAsyncThunk<
  { today: TodoList; totals: TodoTotal } | null,
  { mannualTriggered?: boolean; actionsMode: boolean },
  { state: RootState }
>(
  "@@TODOLIST/GET_TODO_LIST",
  async ({ actionsMode }, { getState }) => {
    const { user, todoList } = getState();
    if (user.jwt) {
      const response = await hbApi.get<{
        today: TodoList;
        totals: TodoTotal;
      }>(
        `/account/todo-list-init?timeZoneOffset=${new Date().getTimezoneOffset() / 60}&includeViewedByUser=${
          todoList.todoListViewFilter === TodoListViewFilter.ViewedByMe
        }${actionsMode ? "&actionsMode=true" : ""}`,
        hbApiOptions(user.jwt)
      );
      return response.data;
    } else {
      return null;
    }
  },
  {
    condition: ({ mannualTriggered }, { getState }) => {
      const { todoList } = getState();
      if (mannualTriggered || !todoList.todoListLoaded) {
        return true;
      }
      if (todoList.todoListLoaded && !mannualTriggered) {
        return false;
      }
    },
  }
);

export const fetchTodoListSection = createAsyncThunk<
  { data: TodoList; section: TodoSection } | null,
  { section: TodoSection; actionsMode: boolean },
  { state: RootState }
>("@@TODOLIST/GET_TODO_LIST_SECTION", async ({ section, actionsMode }, { getState }) => {
  const { user, todoList } = getState();
  if (user.jwt) {
    const response = await hbApi.get<TodoList>(
      `/account/todo-list-section?timeZoneOffset=${
        new Date().getTimezoneOffset() / 60
      }&section=${section}&includeViewedByUser=${todoList.todoListViewFilter === TodoListViewFilter.ViewedByMe}${
        actionsMode ? "&actionsMode=true" : ""
      }`,
      hbApiOptions(user.jwt)
    );

    return {
      data: response.data,
      section: section,
    };
  } else {
    return null;
  }
});

export const fetchTodoListDate = createAsyncThunk<
  TodoList | null,
  { date: string; actionsMode: boolean },
  { state: RootState }
>("@@TODOLIST/GET_TODO_LIST_DATE", async ({ date, actionsMode }, { getState }) => {
  const { user, todoList } = getState();
  if (user.jwt) {
    const response = await hbApi.get<TodoList>(
      `/account/todo-list-date?timeZoneOffset=${new Date().getTimezoneOffset() / 60}&date=${date}&includeViewedByUser=${
        todoList.todoListViewFilter === TodoListViewFilter.ViewedByMe
      }${actionsMode ? "&actionsMode=true" : ""}`,
      hbApiOptions(user.jwt)
    );
    return response.data;
  } else {
    return null;
  }
});

export const openNewAppointment = createAsyncThunk<
  void,
  { inspectionEquipmentId: number; linkedEntityType: InspectionLinkedEntityType },
  { state: RootState }
>("@@TODOLIST/OPEN_NEW_APPOINTMENT", async (model, { getState, dispatch }) => {
  const { user } = getState();
  const baseUrl = `/Inspection${model.linkedEntityType}/`;
  // const isPasProEnabled = user.companySettings.enablePasPro;

  const getAppointmentInfo = async (check: boolean) =>
    await hbApi.post<CreateAppointmentResponseModel>(
      `${baseUrl}create-appointment`,
      {
        checkpointId: model.inspectionEquipmentId,
        checkAppointmentAlreadyExists: check,
      },
      hbApiOptions(user.jwt, null, { LANGUAGE_KEY: user.settings.lang })
    );

  const {
    data: { warningMessage, urlToAppointment },
  } = await getAppointmentInfo(true);

  if (warningMessage) {
    Modal.confirm({
      content: warningMessage,
      onOk: async () => {
        try {
          const appointmentInfo = await getAppointmentInfo(false);
          extractAndProcessAppointmentUrl(appointmentInfo.data?.urlToAppointment, dispatch, false);
        } catch (error) {
          console.error("Error processing appointment info on confirmation:", error);
        }
      },
      onCancel: () => null,
      closable: true,
      maskClosable: true,
    });
  } else {
    extractAndProcessAppointmentUrl(urlToAppointment, dispatch, false);
  }
});

const slice = createSlice({
  name: "todoList",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    filterTodoListBySearchTerm: {
      prepare: (payload?: string) => ({ payload }),
      reducer: (state, action: PayloadAction<string | undefined>) => {
        state.isLoading = true;
        if (action.payload !== undefined) {
          state.searchTerm = action.payload;
        }
        const todoList = state.filteredTodoList;
        const searchValue = state.searchTerm;

        const filteredTodoList: TodoListSectioned = JSON.parse(JSON.stringify(todoList));
        const totalFiltered: TodoTotal = JSON.parse(JSON.stringify(state.totalFiltered));

        Object.keys(todoList).forEach(section => {
          const typedSection = section as keyof TodoListSectioned;
          filteredTodoList[typedSection] = {
            inspections: filterInspectionsBySearchTerm(filteredTodoList[typedSection].inspections, searchValue),
            tasks: filterTasksBySearchTerm(filteredTodoList[typedSection].tasks, searchValue),
            appointments: filterAppointmentsBySearchTerm(filteredTodoList[typedSection].appointments, searchValue),
          };
        });

        Object.keys(totalFiltered).forEach(section => {
          const typedSection = section as keyof TodoTotal;
          totalFiltered[typedSection] =
            filteredTodoList[typedSection].appointments.length +
            filteredTodoList[typedSection].inspections.length +
            filteredTodoList[typedSection].tasks.length;
        });

        state.filteredTodoList = filteredTodoList;
        state.totalFiltered = totalFiltered;
        state.isLoading = false;
      },
    },

    filterTodolistByStatus: {
      prepare: (payload: TodoListStatusFilter | undefined) => ({ payload }),
      reducer: (state, action: PayloadAction<TodoListStatusFilter | undefined>) => {
        state.isLoading = true;
        const todoListStatusFilter = state.todoListStatusFilter;
        const statusFilter =
          action.payload || Object.entries(todoListStatusFilter).filter(enrty => enrty[1] === true)[0][0];
        const todoList = state.todoList;
        const filteredTodoList: TodoListSectioned = JSON.parse(JSON.stringify(todoList));
        const totalFiltered: TodoTotal = JSON.parse(JSON.stringify(state.totalFiltered));

        Object.keys(todoList).forEach(section => {
          const typedSection = section as keyof TodoListSectioned;
          filteredTodoList[typedSection] = {
            inspections: filteredTodoList[typedSection].inspections.filter(inspection =>
              filterTodoListByStatus(statusFilter, inspection.cardStatus)
            ),
            tasks: filteredTodoList[typedSection].tasks.filter(task =>
              filterTodoListByStatus(statusFilter, task.cardStatus)
            ),
            appointments: filteredTodoList[typedSection].appointments.filter(appointemtn =>
              filterTodoListByStatus(statusFilter, appointemtn.cardStatus)
            ),
          };
        });

        Object.keys(totalFiltered).forEach(section => {
          const typedSection = section as keyof TodoTotal;
          totalFiltered[typedSection] =
            filteredTodoList[typedSection].appointments.length +
            filteredTodoList[typedSection].inspections.length +
            filteredTodoList[typedSection].tasks.length;
        });
        const toggeledStatusFilter = { ...todoListStatusFilter };
        Object.keys(toggeledStatusFilter).forEach(
          k => (toggeledStatusFilter[(k as unknown) as TodoListStatusFilter] = k === statusFilter)
        );

        state.todoListStatusFilter = toggeledStatusFilter;
        state.filteredTodoList = filteredTodoList;
        state.totalFiltered = totalFiltered;
        state.isLoading = false;
      },
    },
    filterTodolistByView: {
      prepare: (payload: TodoListViewFilter) => ({ payload }),
      reducer: (state, action: PayloadAction<TodoListViewFilter>) => {
        state.isLoading = true;
        const viewFilter = action.payload;

        const toggeledViewFilter = viewFilter;
        state.totalFiltered.month = 0;
        state.totalFiltered.quarter = 0;
        state.totalFiltered.week = 0;
        state.totalFiltered.year = 0;
        state.todoListViewFilter = toggeledViewFilter;
        state.isLoading = false;
      },
    },
    replaceFilteredData: {
      prepare: (payload: TodoListSectioned) => ({ payload }),
      reducer: (state, action: PayloadAction<TodoListSectioned>) => {
        state.filteredTodoList = action.payload;
      },
    },
    updateClosedTask: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        if (state.todoList) {
          Object.keys(state.todoList).forEach(section =>
            updateClosedTaskOnSection(section, state.todoList, action.payload)
          );
        }
        if (state.filteredTodoList) {
          Object.keys(state.filteredTodoList).forEach(section =>
            updateClosedTaskOnSection(section, state.filteredTodoList, action.payload)
          );
        }
      },
    },
    updateProcessingTask: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        if (state.todoList) {
          Object.keys(state.todoList).forEach(section =>
            updateProcessingTaskOnSection(section, state.todoList, action.payload)
          );
        }
        if (state.filteredTodoList) {
          Object.keys(state.filteredTodoList).forEach(section =>
            updateProcessingTaskOnSection(section, state.filteredTodoList, action.payload)
          );
        }
      },
    },

    updateReopenedTask: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        if (state.todoList) {
          Object.keys(state.todoList).forEach(section => updateReopendTask(section, state.todoList, action.payload));
        }
        if (state.filteredTodoList) {
          Object.keys(state.filteredTodoList).forEach(section =>
            updateReopendTask(section, state.filteredTodoList, action.payload)
          );
        }
      },
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      .addCase(fetchInitialTodoList.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTodoListSection.pending, (state, action) => {
        state.sectionIsLoading[action.meta.arg.section] = true;
        state.error = null;
      })
      .addCase(fetchTodoListDate.pending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(openNewAppointment.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchInitialTodoList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(openNewAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(fetchTodoListSection.rejected, (state, action) => {
        state.sectionIsLoading[action.meta.arg.section] = false;
        state.error = null;
      })
      .addCase(fetchTodoListDate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(fetchInitialTodoList.fulfilled, (state, action) => {
        if (action.payload) {
          const { tasks, appointments, inspections } = action.payload.today;
          state.todoList.today = {
            tasks: tasks || [],
            appointments: appointments || [],
            inspections: inspections || [],
          };

          state.filteredTodoList.today = {
            tasks: tasks || [],
            appointments: appointments || [],
            inspections: inspections || [],
          };
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const initialTotal = Object.keys(action.payload.totals).reduce((accumulator: any, key) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accumulator[key.toLowerCase()] = (action.payload?.totals as any)[key];
            return accumulator;
          }, {});
          state.total = initialTotal;
          state.totalFiltered = initialTotal;
          state.total.today = tasks.length + appointments.length + inspections.length;
          state.totalFiltered = {
            ...initialTotal,
            today: appointments.length + inspections.length + tasks.length,
          };
        }
        state.todoListLoaded = true;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchTodoListSection.fulfilled, (state, action) => {
        if (action.payload) {
          const { tasks, appointments, inspections } = action.payload.data;
          state.todoList[action.payload.section] = {
            tasks: tasks || [],
            appointments: appointments || [],
            inspections: inspections || [],
          };
          state.filteredTodoList[action.payload.section] = {
            tasks: tasks || [],
            appointments: appointments || [],
            inspections: inspections || [],
          };

          state.total[action.payload.section] = tasks.length + appointments.length + inspections.length;
        }
        state.sectionIsLoading[action.meta.arg.section] = false;
        state.error = null;
        // state.isLoading = false;
      })
      .addCase(fetchTodoListDate.fulfilled, (state, action) => {
        if (action.payload) {
          const { tasks, appointments, inspections } = action.payload;
          state.todoList.today = {
            tasks: tasks || [],
            appointments: appointments || [],
            inspections: inspections || [],
          };

          state.filteredTodoList.today = {
            tasks: tasks || [],
            appointments: appointments || [],
            inspections: inspections || [],
          };

          state.total.today = tasks.length + appointments.length + inspections.length;
        }

        state.error = null;
        state.isLoading = false;
      })
      .addCase(openNewAppointment.fulfilled, state => {
        state.error = null;
        state.isLoading = false;
      });
  },
});

export const {
  filterTodoListBySearchTerm,
  replaceFilteredData,
  filterTodolistByStatus,
  filterTodolistByView,
  updateClosedTask,
  updateProcessingTask,
  updateReopenedTask,
} = slice.actions;
export default slice.reducer;
