import dayjs from "dayjs";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ChartContext } from "../Chart/HBChart";
import { appointmentStatus } from "./Appointment";

export type LocalizedDimension = {
  dimension: string;
  values: { [key: string]: string };
};

export const CUBE_NO_VALUE = "∅";

export const localizedDimensions: LocalizedDimension[] = [appointmentStatus];

export default function useLocalizeDimensions() {
  const { chart } = useContext(ChartContext);
  const { t } = useTranslation();

  const localizeDimension = (dimension: string, value: string) => {
    const localizedDim = localizedDimensions.find(x => x.dimension === dimension)?.values[value] || value;
    return t(localizedDim, value);
  };

  const formatDateLabel: (date: string, granularity: string) => string = (date, granularity) => {
    switch (granularity) {
      case "week": {
        return `${dayjs(date).format("YYYY")}-${dayjs(date).format("w")}`;
      }
      case "month": {
        return dayjs(date).format("MMMM");
      }
      case "quarter": {
        return `${dayjs(date).format("YY")}Q${dayjs(date).format("Q")}`;
      }
      case "year": {
        return dayjs(date).format("YYYY");
      }
      default: {
        return dayjs(date).format("L");
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatSeriesLabel: (resultSet: any, seriesKey: string, seriesLabel: string) => string = (
    resultSet,
    seriesKey,
    seriesLabel
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryType = (resultSet as any).queryType;
    const resultsQuery = queryType === "compareDateRangeQuery" ? resultSet.decompose()[1].query() : resultSet.query();
    if (resultsQuery.timeDimensions && resultsQuery.timeDimensions!.length > 0) {
      if (queryType === "compareDateRangeQuery") {
        const splittedLabel = seriesLabel.split(",");
        return splittedLabel
          .map((part, index) => {
            if (index === 0) {
              return part
                .split(" - ")
                .map(date => formatDateLabel(date, "day"))
                .join(" - ");
            }
            return part;
          })
          .join(",");
      }
    }
    const labelSplitted = seriesLabel.split(",");
    let label = labelSplitted[0];

    if (chart?.pivotConfig && chart?.pivotConfig.y!.length > 1) {
      if (labelSplitted.length > 1) {
        const localizedDim = localizedDimensions.find(dimension => chart!.pivotConfig!.y![0] === dimension.dimension);
        if (localizedDim) {
          return t(localizedDim.values[labelSplitted[0]], labelSplitted[0]);
        }
      } else {
        label = CUBE_NO_VALUE;
      }
    } else {
      label = t(seriesKey, seriesLabel);
    }
    return label;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatCategoryLabel: (resultSet: any, xLabel: string) => string = (resultSet, xLabel) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryType = (resultSet as any).queryType;
    const resultsQuery = queryType === "compareDateRangeQuery" ? resultSet.decompose()[1].query() : resultSet.query();
    if (resultsQuery.timeDimensions && resultsQuery.timeDimensions!.length > 0) {
      if (chart?.pivotConfig) {
        if (
          chart.pivotConfig.x?.includes(
            `${resultsQuery.timeDimensions![0].dimension}.${resultsQuery.timeDimensions![0].granularity}`
          )
        ) {
          return formatDateLabel(xLabel, resultsQuery.timeDimensions![0].granularity);
        } else if (chart.pivotConfig.x && chart.pivotConfig.x.length > 0) {
          const localizedDim =
            localizedDimensions.find(dimension => chart!.pivotConfig!.x![0] === dimension.dimension)?.values[xLabel] ||
            xLabel;
          return t(localizedDim, xLabel);
        }
      }
    }
    return xLabel;
  };

  return { localizeDimension, formatSeriesLabel, formatCategoryLabel, formatDateLabel };
}
