import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Spin } from "antd";
import React, { useState, useCallback, Suspense, useEffect, memo, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PageConfigurationContext from "../../context/pageContext";
import { setAppointmentToOpen, setFillAForm } from "../../store/slices/common";
import { RootState, useAppDispatch } from "../../store/store";
import "./FormManagmentModal.less";
import useIsMobile from "../../utils/hooks/useIsMobile";
import useFormManagmentEvents, { EmbeddedPASProMode, MobileEvent } from "./useFormManagmentEvents";

const EmbeddedFormManagmentComponent = React.lazy(() => import("./EmbeddedFormManagmentComponent"));

interface FormManagmentModalProps {
  assetBase?: string;
  src?: string;
  open?: boolean;
  initialJwt?: string;
  initialAppointmentId?: number;
  onModalLoaded?: () => void;
  width?: string | number;
  showHeader?: boolean;
  maskClosable?: boolean;
}

const FormManagmentModal: React.FC<FormManagmentModalProps> = ({ width, showHeader, maskClosable }) => {
  const pageConfig = useContext(PageConfigurationContext);
  const {
    fireMobileEvent,
    formManagmentModalRef,
    addMobileEventListener,
    removeMobileEventListener,
  } = useFormManagmentEvents();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const appointmentId = useSelector((state: RootState) => state.common.appointmentToOpen);
  const fillAForm = useSelector((state: RootState) => state.common.fillAForm);
  const lang = useSelector((state: RootState) => state.user.settings.lang);
  const isMobile = useIsMobile();
  const pageEventListenersMap = useRef(new Map());

  useEffect(() => {
    if (appointmentId) {
      setIsModalOpen(true);
      fireMobileEvent(MobileEvent.OpenAppointmentFromWeb, { appointmentId: `${appointmentId}`, lang: lang });
    } else if (fillAForm) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [appointmentId, fillAForm]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    dispatch(setFillAForm(undefined));
    dispatch(setAppointmentToOpen(undefined));
  }, []);

  const onModalClose = useCallback(() => {
    fireMobileEvent(MobileEvent.ExitFromWeb, {});
  }, [formManagmentModalRef.current]);

  const subscribeToPageMobileEvents = () => {
    pageConfig.mobileEvents?.forEach(event => {
      const funcWrapper = event.dispatch ? (evt: Event) => dispatch(event.listener(evt)) : event.listener;
      const listener = (event: Event) => funcWrapper(event);
      pageEventListenersMap.current.set(event.type, listener);
      addMobileEventListener(event.type, listener);
    });
  };

  const unsubscribeFromPageMobileEvents = () => {
    pageConfig.mobileEvents?.forEach(event => {
      const listener = pageEventListenersMap.current.get(event.type);
      if (listener) {
        removeMobileEventListener(event.type, listener);
        pageEventListenersMap.current.delete(event.type);
      }
    });
  };

  useEffect(() => {
    if (formManagmentModalRef.current) {
      subscribeToPageMobileEvents();
    }
    return () => {
      unsubscribeFromPageMobileEvents();
    };
  }, [pageConfig.id]);

  return (
    <Modal
      closable={false}
      className="form-managment-modal"
      width={width ?? isMobile ? "40rem" : "60rem"}
      open={isModalOpen}
      footer={null}
      title={
        showHeader === undefined || showHeader ? (
          <div className="form-managment-title">
            <div>{t("FormManagmentTitle")}</div>
            <Button shape="round" type="text" icon={<CloseOutlined />} onClick={onModalClose} />
          </div>
        ) : null
      }
      onCancel={onModalClose}
      onOk={onModalClose}
      maskClosable={maskClosable === undefined || maskClosable}
    >
      {isModalOpen && (
        <Suspense fallback={<Spin spinning />}>
          <EmbeddedFormManagmentComponent
            isOpen={isModalOpen}
            onClose={closeModal}
            onLoad={subscribeToPageMobileEvents}
            mode={EmbeddedPASProMode.FormManagment}
          />
        </Suspense>
      )}
    </Modal>
  );
};

export default memo(FormManagmentModal);
