import { unwrapResult } from "@reduxjs/toolkit";

import { TableColumnType } from "antd";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ExportData } from "../../actions/menuActions";
import { ListViewColumn } from "../../components/ListViewVTable/ListViewVTable";
import { ModalInfo } from "../../components/SingleViewCard/types";
import { ConfirmationType } from "../../components/SingleViewCardMobileFirst/types";
import { RootState, useAppDispatch } from "../../store/store";
import { DotsMenuAction, Tab } from "../../types/page";

interface ErrorModalData {
  title: string;
  message: string;
}

interface TProps {
  setModalInfo: (value: React.SetStateAction<ModalInfo | null>) => void;
  setIsTableDataLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrors: (errors: Record<string, string[]>, errorType?: ConfirmationType) => void;
  currentTab: Tab<Record<string, unknown>, Record<string, unknown>>;
  isExportEnabled: boolean;
  setError?: React.Dispatch<React.SetStateAction<ErrorModalData | undefined>>;
}

function useBulkActions({
  setModalInfo,
  setIsTableDataLoading,
  setErrors,
  isExportEnabled,
  setError,
  currentTab,
}: TProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const tabData = useSelector(currentTab.tabSelector);
  const settings = useSelector((state: RootState) => state.user.settings);

  const [selectedEntities, setSelectedEntities] = useState<typeof tabData>([]);
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const columnsRef = useRef<TableColumnType<Record<string, unknown>>[]>();

  const setColumns = (value: TableColumnType<Record<string, unknown>>[]) => {
    columnsRef.current = value;
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: typeof tabData) => {
      setSelectedEntities(selectedRows);
    },
    columnWidth: 25,
  };

  const isSelected = (entity: typeof tabData[0]) => {
    return selectedEntities.includes(entity);
  };

  const onEntitySelect = (entity: typeof tabData[0]) => {
    if (isSelected(entity)) {
      setSelectedEntities(selectedEntities.filter(row => row !== entity));
    } else {
      setSelectedEntities([...selectedEntities, entity]);
    }
  };

  const onDotsMenuConfirmationClick = (confirmed: boolean, action: DotsMenuAction<Record<string, unknown>>) => () => {
    setModalInfo(null);
    if (confirmed) {
      handleDotsMenuClick(action, true);
    }
  };

  const exportVisibleData = () => {
    if (!columnsRef.current || !isExportEnabled) return;
    setIsExporting(true);
    const exportTemplate = [];
    for (const column of columnsRef.current) {
      exportTemplate.push({
        type: "Header",
        property: column.exportPropertyId,
        value: column.title,
      });
    }

    if (settings.direction === "rtl") {
      exportTemplate.push({
        type: "Option",
        property: "RTL",
        value: "true",
      });
    }

    const tableData = tabData.map(entity => {
      const finalEntity = { ...entity };
      columnsRef.current?.forEach(c => {
        const column = c as ListViewColumn<typeof entity>;
        finalEntity[c.dataIndex as keyof typeof finalEntity] = column.renderValue(entity);
      });
      return finalEntity;
    });

    ExportData(tableData, exportTemplate, `${currentTab.key}.xlsx`)
      .then(() => {
        setIsExporting(false);
      })
      .catch(() => {
        setError?.({
          title: "Operation failed",
          message: "File download error",
        });
      });
  };

  const handleDotsMenuClick = async (action: DotsMenuAction<Record<string, unknown>>, skipConfirmation?: boolean) => {
    const { changesConfirmation } = action;
    if (changesConfirmation && !skipConfirmation) {
      return setModalInfo({
        body: changesConfirmation.addValueToBody ? t(changesConfirmation.body) : t(changesConfirmation.body),
        okText: changesConfirmation.addValueToOkText ? t(changesConfirmation.okText) : t(changesConfirmation.okText),
        cancelText: t(changesConfirmation.cancelText),
        onConfirm: onDotsMenuConfirmationClick(true, action),
        onCancel: onDotsMenuConfirmationClick(false, action),
      });
    }
    if (action.type === "inline") {
      const inlineMethod = action.action([]);
      if (isExportEnabled && inlineMethod === "export") {
        exportVisibleData();
      }
    }
    if (action.type === "bulk") {
      try {
        setIsTableDataLoading(true);
        const actionResponse = await dispatch(action.action(selectedEntities));
        await unwrapResult(actionResponse);
        setIsTableDataLoading(false);
      } catch (customError) {
        setErrors(customError as Record<string, string[]>);
        setIsTableDataLoading(false);
      }

      action.action(selectedEntities);
    }
  };

  return {
    selectedEntities,
    rowSelection,
    onEntitySelect,
    isSelected,
    handleDotsMenuClick,
    isExporting,
    setIsExporting,
    setColumns,
  };
}

export default useBulkActions;
