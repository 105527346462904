import { unwrapResult } from "@reduxjs/toolkit";
import { Spin } from "antd";
import parseInt from "lodash/parseInt";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SingleViewCard from "../../../components/SingleViewCard/SingleViewCard";
import SingleViewTabs from "../../../components/SingleViewTabs/SingleViewTabs";
import PageConfigurationContext from "../../../context/pageContext";
import { RootState, useAppDispatch } from "../../../store/store";
import { HBEventName } from "../../../types/analyticsTypes/HBEvent";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage } from "../../../types/page";
import useInitTrackEvents from "../../../utils/hooks/useInitTrackEvents";
import useLoadInitalData from "../../../utils/hooks/useLoadInitalData";
import useRouter from "../../../utils/hooks/useRouter";
import Layout from "../../Layout/Layout";
import NotAllowedPage from "../../NotAllowedPage/NotAllowedPage";
import NotFoundPage from "../../NotFoundPage/NotFoundPage";

enum Visibility {
  LOADING = "loading",
  VISIBLE = "visible",
  NOT_FOUND = "notFound",
  NOT_ALLOWED = "notAllowed",
}

const CategorySingle = ({ isNewEntity }: { isNewEntity?: boolean }): JSX.Element | null => {
  useLoadInitalData();
  const dispatch = useAppDispatch();
  const { params, location } = useRouter<{ id: string }>();
  const [activeTab, setActiveTab] = useState<string | undefined>();
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const [isEdited, setIsEdited] = useState(false);
  const [visibility, setVisibility] = useState<Visibility>(Visibility.LOADING);
  const data = useSelector(pageConfig.primarySingleEntitySelector);
  const customProperties = useSelector(pageConfig.customPropertiesSelector);
  const isLoading = useSelector(pageConfig.isLoading) ?? true;
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const basicData = useSelector(
    (state: RootState) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((state as any)[pageConfig.id] !== undefined ? (state as any)[pageConfig.id]["basicData"] : []) as [
        { id: number }
      ]
  );
  const { track } = useInitTrackEvents();

  const handleSetIsEdited = (isEdited: boolean) => {
    setIsEdited(isEdited);
  };

  const handleSetActiveTab = (tabKey: string) => {
    setActiveTab(tabKey);
  };

  // Note: Takes care of creating the template once the default custom properties have been fetched from the fetchPrimaryData method
  // TODO: maybe move the defaultCustomProperties property in the singleData instead of the global location state?
  useEffect(() => {
    if (isNewEntity && customProperties.length > 0 && pageConfig.createNewEntityTemplate) {
      dispatch(pageConfig.createNewEntityTemplate());
      return;
    }
  }, [customProperties]);

  useEffect(() => {
    if (isNewEntity && pageConfig.createNewEntityTemplate) {
      dispatch(pageConfig.createNewEntityTemplate());
      setVisibility(Visibility.VISIBLE);
      return;
    }
    if (!isNewEntity && jwt) {
      // TODO: Create better limitation when to refetch
      dispatch(pageConfig.fetchSingle(params.id))
        .then(result => unwrapResult(result))
        .then(() => {
          track({ eventName: HBEventName.SaveEntityChanges, data: { entityId: data?.id } });
          setVisibility(Visibility.VISIBLE);
        })
        .catch(e => {
          console.error("categorySingle error: ", e);
          if (basicData?.find(x => x.id === parseInt(params.id))) {
            setVisibility(Visibility.NOT_ALLOWED);
          } else {
            setVisibility(Visibility.NOT_FOUND);
          }
        });
    }
  }, [location, jwt]);

  return (
    <>
      {visibility === Visibility.LOADING && (
        <Spin spinning={true}>
          <Layout hasTableSearchBar={false} entityKey={pageConfig.id}></Layout>
        </Spin>
      )}
      {visibility === Visibility.VISIBLE && (
        <Spin spinning={isLoading}>
          <Layout hasTableSearchBar={false} entityKey={pageConfig.id}>
            <SingleViewCard
              isNewEntity={isNewEntity}
              data={data}
              handleSetActiveTab={handleSetActiveTab}
              handleSetIsEdited={handleSetIsEdited}
              isEdited={isEdited}
            />
            {pageConfig.tabsPanel && pageConfig.tabsPanel.length ? (
              <SingleViewTabs
                isNewEntity={isNewEntity}
                activeTab={activeTab}
                handleSetActiveTab={handleSetActiveTab}
                isEdited={isEdited}
              />
            ) : null}
          </Layout>
        </Spin>
      )}
      {visibility === Visibility.NOT_FOUND && <NotFoundPage />}
      {visibility === Visibility.NOT_ALLOWED && <NotAllowedPage />}
    </>
  );
};

export default CategorySingle;
