import { Badge, Modal, Tooltip } from "antd";
import dayjs from "dayjs";

import ActionSummaryTooltipComponent from "../../../../components/ActionSummaryTooltipComponent/ActionSummaryTooltipComponent";
import { HandleTaskModal } from "../../../../components/HBComponents/HandleTaskModal/HandleTaskModal";
import HBTextArea from "../../../../components/HBComponents/Input/HBTextArea";
import NewTabComponent from "../../../../components/HBComponents/NewTabIcon/NewTabComponent";
import { ReactComponent as SearchIcon } from "../../../../media/search-icon.svg";
import { ReactComponent as UsersIcon } from "../../../../media/users-icon.svg";
import { employeeSelectors, tasksSelectors } from "../../../../selectors";
import { addEntityPrivilege, updateEntityPrivilegeOULvl } from "../../../../store/slices/privileges";
import {
  deleteNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  updateNewAccountableEntry,
} from "../../../../store/slices/tasks";
import { CellTypes, ColumnType, ColumnWithDifferentCells, InputTypes } from "../../../../types/page";
import { LinkedAction, Task } from "../../../../types/tasks";
import {
  ActionTypes,
  HistoryLog,
  OpenClosedStatus,
  PrivilegeData,
  PrivilegedEntityType,
  Role,
} from "../../../../types/utility";
import renderConditional from "../../../../utils/components/renderConditional";
import PDFActions from "../../../TableActions/PDFActions";
import { boolOptions, getCellSearchResultColumns, openClosedOptions, priorityOptions } from "../common";
import {
  getAccoutableColumnCellType,
  localizeText,
  newRenderBoolStatus,
  renderBoolStatus,
  renderOpenCloseStatus,
  renderTaskPriority,
} from "../utilities";

export const tasksTableColumns: ColumnType<Task>[] = [
  {
    id: "id",
    label: "ID",
    valueIcon: value => <NewTabComponent value={value.id.toString()} href={`/actions/${value.id}`} />,
    renderValue: () => null,
    valueType: "number",
    sortable: true,
    optionsSelector: tasksSelectors.uniqueIds,
    width: 100,
  },
  {
    id: "title",
    label: "FieldTitle",
    renderValue: value => value.title,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "HbTask",
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "createDate",
    label: "FieldCreateDate",
    renderValue: value => (value.createDate ? dayjs(value.createDate).format("DD/MM/YYYY HH:mm") : null),
    filterType: InputTypes.DATE_RANGE,
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "dueDate",
    label: "FieldDueDate",
    optionsSelector: () => null,
    valueIcon: value => {
      if (value.dueDate) {
        const expDate = dayjs(value.dueDate);
        const dayDiff = expDate.diff(dayjs(), "days");
        return <Badge color={dayDiff >= 1 ? "green" : "red"} />;
      }
    },
    renderValue: value => (value.dueDate ? dayjs(value.dueDate).format("DD/MM/YYYY HH:mm") : null),
    sortable: true,
    filterType: InputTypes.DATE_RANGE,
  },
  {
    id: "closeDate",
    label: "FieldCloseDate",
    renderValue: value => (value.closeDate ? dayjs(value.closeDate).format("DD/MM/YYYY HH:mm") : null),
    filterType: InputTypes.DATE_RANGE,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "parentTaskId",
    label: "FieldParentTask",
    renderValue: () => null,
    valueIcon: value => {
      return (
        <>
          {!!value.parentTaskId ? (
            <NewTabComponent
              href={`/actions/${value.parentTaskId}`}
              component={<ActionSummaryTooltipComponent actionId={value.parentTaskId} />}
            />
          ) : null}
        </>
      );
    },
    filterType: InputTypes.MULTI_SELECT,
    asyncFetchFilterOptionsEntityType: "HbTask",
    asyncFetchFilterOptionsIsId: true,
    propName: "parentId",
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "createdByUserId",
    exportPropertyId: "createdByUserName",
    label: "FieldCreatedBy",
    valueIcon: value =>
      renderConditional(
        !!value.createdByUserId,
        <NewTabComponent value={value.createdByUserName} href={`/employee/${value.createdByUserId}`} />
      ),
    renderValue: () => null,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    propName: "displayName",
    asyncFetchFilterOptionsEntityType: "User",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "responsibleUserId",
    exportPropertyId: "responsibleUserName",
    label: "FieldResponsible",
    valueIcon: value =>
      renderConditional(
        !!value.responsibleUserId,
        <NewTabComponent value={value.responsibleUserName} href={`/employee/${value.responsibleUserId}`} />
      ),
    renderValue: () => null,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    propName: "displayName",
    asyncFetchFilterOptionsEntityType: "User",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null,
    valueIcon: (value: Task): React.ReactNode | null => renderOpenCloseStatus(value.status),
    sortable: true,
    optionsSelector: () =>
      openClosedOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
        icon: l.icon,
      })),
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "priority",
    label: "FieldPriority",
    renderValue: () => null,
    cellType: () => CellTypes.ICON,
    width: 150,
    valueIcon: (value: Task): React.ReactNode | null => renderTaskPriority(value.priority),
    optionsSelector: () =>
      priorityOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
        icon: l.icon,
      })),
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "actions",
    label: "FieldActions",
    renderValue: () => null,
    optionsSelector: () => null,
    sortable: false,
    cellType: () => CellTypes.ACTIONS,
    width: 200,
    actions: [
      {
        label: "",
        component: entity => (
          <HandleTaskModal
            status={entity.status}
            title={entity.title}
            isOpen={entity.status === OpenClosedStatus.Opened ? true : false}
            taskId={entity.id}
          />
        ),
        action: () => null,
      },
      {
        label: ActionTypes.PDF,
        component: entity =>
          entity.pdfInfo?.livePdfGuid || entity.pdfInfo?.fileUrl ? (
            <PDFActions pdfLink={entity.pdfInfo?.livePdfGuid} title={entity.title} fileLink={entity.pdfInfo?.fileUrl} />
          ) : null,
        action: () => null,
      },
    ],
  },
  {
    id: "followUpReviewId",
    exportPropertyId: "followUpReviewName",
    label: "FieldReview",
    renderValue: value => value.followUpReviewName,
    valueType: "string",
    optionsSelector: () => null,
    propName: "name",
    asyncFetchFilterOptionsEntityType: "Review",
    asyncFetchFilterOptionsUseKeyValue: true,
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "projectId",
    exportPropertyId: "projectName",
    label: "FieldProject",
    renderValue: value => value.projectName,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    propName: "name",
    asyncFetchFilterOptionsEntityType: "Project",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "tags",
    label: "FieldTags",
    renderValue: value => value.tags,
    optionsSelector: tasksSelectors.uniqueTags,
    sortable: true,
    filterType: InputTypes.TAGS,
  },
  {
    id: "issueTypeId",
    exportPropertyId: "issueTypeName",
    label: "FieldIssueType",
    valueIcon: value =>
      renderConditional(
        !!value.issueTypeId,
        <NewTabComponent value={value.issueTypeName} href={`/issueType/${value.issueTypeId}`} />
      ),
    renderValue: () => null,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    propName: "name",
    asyncFetchFilterOptionsEntityType: "IssueType",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "employeeId",
    exportPropertyId: "employeeName",
    label: "FieldEmployee",
    valueIcon: value =>
      !!value.employeeId ? <NewTabComponent value={value.employeeName} href={`/employee/${value.employeeId}`} /> : null,
    optionsSelector: () => null,
    renderValue: () => null,
    sortable: true,
    valueType: "string",
    propName: "displayName",
    asyncFetchFilterOptionsEntityType: "User",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "equipmentId",
    exportPropertyId: "equipmentName",
    label: "FieldEquipment",
    valueIcon: value =>
      !!value.equipmentId ? (
        <NewTabComponent value={value.equipmentName} href={`/equipment/${value.equipmentId}`} />
      ) : null,
    optionsSelector: () => null,
    renderValue: () => null,
    sortable: true,
    valueType: "string",
    propName: "name",
    asyncFetchFilterOptionsEntityType: "Equipment",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "equipmentTypeId",
    exportPropertyId: "equipmentTypeName",
    label: "FieldEquipmentType",
    valueIcon: value =>
      !!value.equipmentTypeId ? (
        <NewTabComponent value={value.equipmentTypeName} href={`/equipmentType/${value.equipmentTypeId}`} />
      ) : null,
    optionsSelector: () => null,
    renderValue: () => null,
    sortable: true,
    valueType: "string",
    propName: "name",
    asyncFetchFilterOptionsEntityType: "EquipmentType",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "locationId",
    exportPropertyId: "locationName",
    label: "FieldLocation",
    valueIcon: value =>
      !!value.locationId ? <NewTabComponent value={value.locationName} href={`/location/${value.locationId}`} /> : null,
    optionsSelector: () => null,
    renderValue: () => null,
    sortable: true,
    valueType: "string",
    propName: "name",
    asyncFetchFilterOptionsEntityType: "Location",
    asyncFetchFilterOptionsUseKeyValue: true,
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "description",
    label: "FieldDescription",
    renderValue: () => null,
    valueIcon: value => (
      <Tooltip title={value.description} overlayStyle={{ whiteSpace: "pre-line" }}>
        {value.description && value.description.length >= 10
          ? `${value.description?.substring(0, 10)}...`
          : value.description}
      </Tooltip>
    ),
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "HbTask",
    filterType: InputTypes.MULTI_SELECT,
  },
];

export const actionsAccountableNameField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "name",
  label: "FieldName",
  renderValue: entity => entity.name || "",
  sortable: true,
  filterType: InputTypes.MULTI_SELECT,
  cellType: entity => (entity.staging ? CellTypes.SEARCH : CellTypes.TEXT),
  cellSearchSelector: tasksSelectors.allTaskRelations,
  cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
  cellSearchKeys: ["name"],
  cellSearchRecordClick: fillNewAccountableEntryTemplate,
  optionsSelector: () => null,
  width: 100,
  primaryColumn: entity => ({
    navigationTarget: `/employee/${entity.userId}`,
  }),
  cellPrefixIcon: <UsersIcon className="user-icon" />,
  placeholder: "Name",
};

export const actionsAccountableExternalIdField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "externalId",
  label: "ExternalID",
  renderValue: entity => entity.externalId || "",
  sortable: true,
  filterType: InputTypes.MULTI_SELECT,
  cellType: entity => (entity.staging ? CellTypes.SEARCH : CellTypes.TEXT),
  cellSearchSelector: tasksSelectors.allTaskRelations,
  cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
  cellSearchKeys: ["externalId"],
  cellSearchRecordClick: fillNewAccountableEntryTemplate,
  optionsSelector: employeeSelectors.basicEmployeeExternalIDs,
  width: 100,
  cellPrefixIcon: <SearchIcon className="search-icon" />,
  placeholder: "ExternalID",
};

export const actionsAccountableStatusField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "status",
  label: "FieldStatus",
  renderValue: entity => (entity.status ? localizeText(entity.status.toString()) : ""),
  sortable: true,
  optionsSelector: () => null,
  width: 100,
};

export const actionsAccountableIsExternalField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "isExternal",
  label: "External",
  renderValue: entity => (entity.isExternal !== null ? renderBoolStatus(entity.isExternal) : ""),
  sortable: true,
  optionsSelector: () => null,
  width: 100,
};

export const actionsAccountableRoleField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "role",
  label: "Accountability",
  renderValue: e => (e.role ? localizeText(e.role.toString()) : ""),
  cellType: () => CellTypes.TEXT,
  sortable: true,
  optionsSelector: () => null,
  width: 100,
};

export const isAssignedToOUBranchField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "isAssignedToOUBranch",
  label: "AllLevels",
  renderValue: value => {
    return value.orgUnitId && value.role === Role.Recipient ? newRenderBoolStatus(value.isAssignedToOUBranch) : null;
  },
  cellType: e => getAccoutableColumnCellType(e.role, e.orgUnitId),
  sortable: true,
  cellDropdownOptions: () =>
    boolOptions.map(b => ({
      id: b.id,
      label: localizeText(b.label),
    })),
  cellDropdownOnChange: (entity, newValue) =>
    entity.staging
      ? updateNewAccountableEntry({ entity: entity, newValue: newValue, property: "isAssignedToOUBranch" })
      : updateEntityPrivilegeOULvl({
          entity: entity,
          isAssignedToOUBranch: newValue as boolean,
          entityType: PrivilegedEntityType.HbTask,
          id: null,
        }),
  optionsSelector: () => null,
  width: 100,
};

export const actionsAccountableActionField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "action" as keyof PrivilegeData,
  label: "FieldAction",
  renderValue: () => null,
  sortable: true,
  optionsSelector: () => null,
  cellType: () => CellTypes.BUTTON_ACTIONS,
  width: 100,
  stagingActions: [
    {
      label: "Add",
      className: "hb-primary-button",
      action: entity => addEntityPrivilege({ entity: entity, entityType: PrivilegedEntityType.HbTask, id: null }),
    },
    {
      label: "Cancel",
      className: "hb-tertiary-button",
      action: entity => deleteNewAccountableEntryTemplate(entity),
    },
  ],
};

export const accountableTabColumns: ColumnWithDifferentCells<PrivilegeData>[] = [
  actionsAccountableNameField,
  actionsAccountableExternalIdField,
  actionsAccountableStatusField,
  actionsAccountableIsExternalField,
  actionsAccountableRoleField,
  isAssignedToOUBranchField,
  actionsAccountableActionField,
];

export const tasksHistoryLogTableColumns: ColumnType<HistoryLog>[] = [
  {
    id: "userName",
    label: "FieldUserName",
    renderValue: value => value.userName,
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "operation",
    label: "Operation",
    renderValue: value => localizeText(value.operation),
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "oldValue",
    label: "OldValue",
    renderValue: value => localizeText(value.oldValue),
    sortable: false,
    optionsSelector: () => null,
    onCellOnClick(e) {
      Modal.info({
        title: localizeText(this.label),
        icon: null,
        content: <HBTextArea value={e.oldValue} disabled />,
        maskClosable: true,
      });
    },
  },
  {
    id: "newValue",
    label: "NewValue",
    renderValue: value => localizeText(value.newValue),
    sortable: false,
    optionsSelector: () => null,
    onCellOnClick(e) {
      Modal.info({
        title: localizeText(this.label),
        icon: null,
        content: <HBTextArea value={e.newValue} disabled />,
        maskClosable: true,
      });
    },
  },
  {
    id: "timeStamp",
    label: "Timestamp",
    renderValue: value => value.timeStamp,
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "pdfLink",
    label: "FieldActions",
    renderValue: value => value.pdfLink || null,
    sortable: false,
    optionsSelector: () => null,
    cellType: () => CellTypes.ACTIONS,
    //in this case, this is regular actions, not staging, just using this key
    actions: [
      {
        label: ActionTypes.PDF,
        component: entity => (entity.pdfLink ? <PDFActions pdfLink={entity.pdfLink} /> : null),
        action: () => null,
      },
    ],
  },
];

export const tasksLinkedActionsField: ColumnType<LinkedAction> = {
  id: "actions" as keyof LinkedAction,
  label: "FieldActions",
  renderValue: () => null,
  optionsSelector: () => null,
  sortable: false,
  cellType: () => CellTypes.ACTIONS,
  width: 200,
  actions: [
    {
      label: ActionTypes.PDF,
      component: entity =>
        entity.pdfInfo?.livePdfGuid || entity.pdfInfo?.fileUrl ? (
          <PDFActions pdfLink={entity.pdfInfo?.livePdfGuid} title={entity.title} fileLink={entity.pdfInfo?.fileUrl} />
        ) : null,
      action: () => null,
    },
  ],
};

export const tasksLinkedActionsColumns: ColumnType<LinkedAction>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: value => value.id.toString(),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    primaryColumn: entity => ({
      navigationTarget: `/actions/${entity.id}`,
    }),
  },

  {
    id: "title",
    label: "FieldTitle",
    renderValue: value => value.title,
    sortable: false,
    optionsSelector: () => null,
    primaryColumn: entity => ({
      navigationTarget: `/actions/${entity.id}`,
    }),
  },
  {
    id: "relationship",
    label: "FieldRelationshipType",
    renderValue: value => localizeText(value.relationship),
    sortable: false,
    optionsSelector: () => null,
  },

  {
    id: "createDate",
    label: "FieldCreateDate",
    renderValue: value => (value.createDate ? dayjs(value.createDate).format("DD/MM/YYYY HH:mm") : null),
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null,
    valueIcon: (value: LinkedAction): React.ReactNode | null => renderOpenCloseStatus(value.status),
    sortable: true,
    optionsSelector: () => null,
  },
  tasksLinkedActionsField,
];
