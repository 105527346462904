import {
  ExportAction,
  ColumnManagementAction,
  MannualyUpdateInspection,
  GetFiltersUrl,
  ResetColumnsWidthAction,
} from "../../../../actions/menuActions";
import { MobileEvent } from "../../../../components/EmbeddedModal/useFormManagmentEvents";
import { ReactComponent as Logo } from "../../../../media/inspection-icon.svg";
import { inspectionSelectors, pageSelectors } from "../../../../selectors";
import {
  fetchSingleInspection,
  fetchInspectionCustomProps,
  clearInspectionError,
  fetchPaginatedInspection,
  searchInspection,
  resetCurrentPage,
  createNewCheckpointEntryTemplate,
  resetSearchResults,
  confirmChangeLocalStatusFromEmbeded,
  cancelCheckpointEdit,
} from "../../../../store/slices/inspection";
import { InspectionPage, Inspection, InspectionCheckpoint } from "../../../../types/inspection";
import { CategoryPage, SectionType } from "../../../../types/page";
import { TableAddButtonType } from "../../../../types/utility";
import { contextActionsTableColumns } from "../common";
import { prepareDynamicColumns, removeColumnFilters } from "../utilities";
import {
  equipmentNameField,
  inspectionTypeNameField,
  lastCheckField,
  locationField,
  nextCheckField,
  primaryFields,
  secondaryFields,
  statusFieldInspection,
} from "./fields";
import {
  inspectionTableColumns,
  checkpointsColumns,
  equipmentInspectionTableColumns,
} from "./inspectionConfigPageData";

const LOCALIZED_KEY_PREFIX = "MobileFirstTitle";

const inspectionConfigPage = (pageId: "inspectionEquipment" | "inspectionLocation"): CategoryPage<InspectionPage> => ({
  id: pageId || "inspection",
  title: "Inspection",
  //Capitalize first letter
  mobileFirstTitle: `${LOCALIZED_KEY_PREFIX}${pageId.charAt(0).toUpperCase()}${pageId.slice(1)}`,
  entityEndpoint: "/EquipmentInspection",
  PageIcon: Logo,
  hasGroupView: false,
  hasListView: true,
  primaryErrorSelector: inspectionSelectors.error,
  clearError: clearInspectionError,
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.inspectionTypeName };
  },
  fetchSingle: fetchSingleInspection,
  intializeDataActions: [fetchInspectionCustomProps],
  fetchCustomProps: fetchInspectionCustomProps,
  listView: {
    table: {
      tableSelector: inspectionSelectors.all,
      fetchPaginatedData: fetchPaginatedInspection,
      searchResultsSelector: inspectionSelectors.inspectionSearchResults,
      possibleResultsSelector: inspectionSelectors.inspectionPossibleResults,
      columns: pageId === "inspectionEquipment" ? equipmentInspectionTableColumns : inspectionTableColumns,
      rowSelection: true,
      defaultColumnKeys: [
        "inspectionTypeId",
        "eqSerialNumber",
        "equipmentId",
        "equipmentTypeId",
        "location",
        "expirationDate",
        "inspectionDate",
        "status",
      ],
      type: "paginatedTable",
      searchPaginatedData: searchInspection,
      resetSearchResult: resetSearchResults,
      resetCurrentPage: resetCurrentPage,
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumns<Inspection>(props, data),
  },
  primarySingleEntitySelector: inspectionSelectors.single,
  customPropertiesSelector: inspectionSelectors.inspectionCustomProperties,
  isLoading: pageSelectors.isInspectionPageLoading,
  lastUpdatedSelector: inspectionSelectors.inspectionLastUpdatedText,
  summaryCard: {
    CardIcon: Logo,
    primaryFields: primaryFields,
    secondaryFields: secondaryFields,
    mobileSingleView: {
      summary: {
        icon: Logo,
        title: inspectionTypeNameField,
        status: statusFieldInspection,
        mainContextRelation: equipmentNameField,
        additionalContextRelation: locationField,
        date: nextCheckField,
      },
      sections: [
        {
          type: SectionType.ContextSection,
          fields: [],
        },
        {
          type: SectionType.DatesSection,
          fields: [lastCheckField, nextCheckField],
        },
      ],
    },
  },
  tabsPanel: [
    {
      key: "actions",
      label: "FieldActions",
      tabSelector: inspectionSelectors.actions,
      columns: removeColumnFilters(contextActionsTableColumns()),
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
    },
    {
      key: "checkpoints",
      label: "Checkpoints",
      tabSelector: inspectionSelectors.inspectionCheckpoints,
      type: "table",
      addButton: {
        action: createNewCheckpointEntryTemplate,
        label: "AddCheckpoint",
        type: TableAddButtonType.AddInline,
      },
      rowSelection: false,
      columns: removeColumnFilters(checkpointsColumns) as [],
      generateDynamicColumns: (props, data) =>
        removeColumnFilters(prepareDynamicColumns<InspectionCheckpoint>(props, data)),
      customPropertiesSelector: inspectionSelectors.inspectionCustomProperties,
    },
  ],
  listViewActions: [
    ExportAction,
    ColumnManagementAction,
    ResetColumnsWidthAction,
    MannualyUpdateInspection,
    GetFiltersUrl,
  ],
  mobileEvents: [
    {
      type: MobileEvent.FormAnsweringFinished,
      listener: confirmChangeLocalStatusFromEmbeded,
      dispatch: true,
    },
    {
      type: MobileEvent.ExitFromMobile,
      listener: cancelCheckpointEdit,
      dispatch: true,
    },
  ],
});

export default inspectionConfigPage;
