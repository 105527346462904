import { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../store/store";
import { DotsMenuAction, Tab } from "../../../types/page";
import { PrivilegeData } from "../../../types/utility";
import { notSupportedFunc } from "../../../utils/functions";
import useBulkActions from "../../../utils/hooks/useBulkActions";
import { useMobileSingleViewContext } from "./useMobileSingleViewContext";

export type SingleMobileEntity = Record<string, unknown>; //  PrivilegeData | ContextActions | EmployeeCertificate | File | HistoryLog;

function isStaging(entity: SingleMobileEntity): boolean {
  return (entity as PrivilegeData).staging === true;
}

interface MobileSingleViewTabContextData {
  tabConfig: Tab<Record<string, unknown>, Record<string, unknown>>;
  tabData: SingleMobileEntity[];
  stagingEntity?: PrivilegeData;
  selectedEntities: SingleMobileEntity[];
  onEntitySelect: (entiy: SingleMobileEntity) => void;
  isSelected: (entiy: SingleMobileEntity) => boolean;
  isTableDataLoading: boolean;
  setIsTableDataLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleDotsMenuClick: (action: DotsMenuAction<Record<string, unknown>>, skipConfirmation?: boolean) => Promise<void>;
}

const DEFAUTL_CONTEXT_VALUE: MobileSingleViewTabContextData = {
  tabConfig: {} as Tab<Record<string, unknown>, Record<string, unknown>>,
  tabData: [],
  stagingEntity: undefined,
  selectedEntities: [],
  onEntitySelect: notSupportedFunc,
  isSelected: () => false,
  isTableDataLoading: false,
  setIsTableDataLoading: notSupportedFunc,
  handleDotsMenuClick: notSupportedFunc,
};

const MobileSingleViewTabContext = createContext(DEFAUTL_CONTEXT_VALUE);

type TProps = {
  tabConfig: Tab<SingleMobileEntity, SingleMobileEntity>;
};

const MobileSingleViewTabProvider: FC<PropsWithChildren<TProps>> = ({ children, tabConfig }) => {
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);

  const { data, setModalInfo, setErrors } = useMobileSingleViewContext();
  const dispatch = useAppDispatch();

  const tabData = useSelector(tabConfig.tabSelector);

  const { selectedEntities, handleDotsMenuClick, onEntitySelect, isSelected } = useBulkActions({
    setModalInfo,
    setIsTableDataLoading,
    setErrors,
    isExportEnabled: false,
    currentTab: tabConfig,
  });

  useEffect(() => {
    if (tabConfig.tabDataThunk && data) {
      dispatch(tabConfig.tabDataThunk(data?.id));
    }
  }, [tabConfig, data]);

  const savedData = tabData.filter(data => !isStaging(data));
  const stagingEntity = tabData.filter(data => isStaging(data))[0] as PrivilegeData;

  const value = useMemo(
    () => ({
      tabConfig,
      tabData: savedData,
      stagingEntity,
      selectedEntities,
      onEntitySelect,
      isSelected,
      isTableDataLoading,
      setIsTableDataLoading,
      handleDotsMenuClick,
    }),
    [selectedEntities, tabData, isTableDataLoading]
  );

  return <MobileSingleViewTabContext.Provider value={value}>{children}</MobileSingleViewTabContext.Provider>;
};

const useMobileSingleViewTabContext = () => useContext(MobileSingleViewTabContext);

export { useMobileSingleViewTabContext, MobileSingleViewTabProvider, MobileSingleViewTabContext };
