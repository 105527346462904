import { BaseEntityType } from "./entityBase";
import { Equipment, Inspection } from "./equipment";
import { File } from "./files";
import { Location } from "./location";
import { OrgUnit } from "./orgUnit";
import { ContextActions } from "./tasks";
import { Training } from "./training";
import {
  AdditionalProps,
  CertificateTraineeStatus,
  CustomProperties,
  ExplicitAdditionalProps,
  HistoryLog,
  PrivilegeData,
  Role,
  Status,
  AuthType,
  TwoFactorType,
} from "./utility";

export enum EmployeeStatus {
  Active = "Active",
  Terminated = "Terminated",
}

export enum TenantRole {
  Admin = "Admin",
  User = "User",
  Reporter = "Reporter",
}

export enum EmployeeBool {
  Yes = "Yes",
  No = "No",
}

export enum Bool {
  True = "True",
  False = "False",
}
export interface EmployeeState {
  data: Employee[];
  basicData: Employee[];
  isLoading: boolean;
  singleData: Employee | null | undefined;
  subData: {
    inspections: Inspection[];
    historyLog: HistoryLog[];
    accountable: PrivilegeData[];
    certifications: EmployeeCertificate[];
    files: File[];
    actions: ContextActions[];
  };
  searchResults: Employee[];
  error: string | null;
  defaultCustomProperties: ExplicitAdditionalProps[];
  lastUpdated: string;
  paginationInfo: {
    currentPage: number;
    count: number;
  };
}

export type EntitiesWithCustomProperties = Location | Training | Employee | Equipment;

export type Employee = {
  id: number;
  username: string | null;
  orgUnitId: number | null;
  orgUnitName: string | null;
  externalId: string | null;
  locationId: number | null;
  locationName: string | null;
  displayName: string;
  phone: string;
  email: string;
  rank: string | null;
  position: number;
  expirationDate: string | null;
  jobTitle: string | null;
  isExternal: boolean;
  isUser: boolean;
  isActive: boolean;
  status: Status;
  authType: AuthType | null;
  twoFactorType: TwoFactorType | null;
  customPropertyValues?: AdditionalProps[];
  customProperties?: CustomProperties[];
  locations: {
    locationId: number;
    role: Role;
  }[];
  tenantRole: TenantRole;
  projectIds: number[];
};

export type EmployeePaginatedData = {
  data: Employee[];
  count: number;
};

export interface EmployeeRelationsRequest {
  participant: {
    externalId: string;
    status: Status;
    id: number;
    name: string;
    isExternal: boolean;
  } | null;
  orgUnit: {
    externalId: string;
    status: Status;
    id: number;
    name: string;
  } | null;
  role: Role;
  employeeId: number | null;
}

export type EmployeeRelation = {
  externalId: string | undefined | null;
  status: Status | null;
  id: number | string;
  name: string;
  isExternal: boolean | null;
  staging?: boolean; // Is this a 'new' record (still not saved)
  activeId?: number | string; // The ID of the active counterpart of a staged new entity
  isOrgUnit?: boolean;
  isEmployee?: boolean;
  role: Role;
  employeeId: number | null;
};

export type EmployeeCertificate = {
  id: number | string;
  employeeId: number;
  certificateId: number;
  certificateName: string;
  certificateExpirationDate: string | null;
  nextTrainingSessionDate: string | null;
  status: CertificateTraineeStatus;
  staging?: boolean;
};

export type EmployeePage = BaseEntityType & {
  entityData: {
    primaryData: Employee;
    additionalData: null;
    // sharedData: {
    //   location: Location;
    // };
  };
  subData: {
    historyLog: HistoryLog[];
    inspections: Inspection[];
    files: File[];
    certificates: EmployeeCertificate[];
    actions: ContextActions[];
  };
  listViewData: Employee;
  tabs: Location | OrgUnit | PrivilegeData | EmployeeCertificate | HistoryLog | File | ContextActions;
  secondary: Inspection | HistoryLog | Location;
};

export interface ChangePasswordModel {
  userId?: number;
  newPassword?: string;
  repeatNewPassword?: string;
  mustChangePassword?: boolean;
}
