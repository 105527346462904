import { Badge } from "antd";
import dayjs from "dayjs";
import React from "react";

import { DATE_FORMAT_WITHOUT_TIME } from "../../../../components/HBComponents/DatePicker/HBDatePicker";
import { ReactComponent as CertificateIcon } from "../../../../media/certification-page-icon.svg";
import { ReactComponent as SearchIcon } from "../../../../media/search-icon.svg";
import { ReactComponent as UsersIcon } from "../../../../media/users-icon.svg";
import { employeeSelectors } from "../../../../selectors";
import {
  deleteNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  setNotNeededCertificate,
  updateNewAccountableEntry,
} from "../../../../store/slices/employee";
import {
  addEntityPrivilege,
  updateEntityPrivilegeOULvl,
  updateEntityPrivilegeRole,
} from "../../../../store/slices/privileges";
import { Employee, EmployeeCertificate, TenantRole } from "../../../../types/employee";
import { OrgUnit } from "../../../../types/orgUnit";
import { CellTypes, ColumnType, ColumnWithDifferentCells, InputTypes, TOption } from "../../../../types/page";
import { CertificateTraineeStatus, PrivilegeData, PrivilegedEntityType, Role } from "../../../../types/utility";
import {
  AccountabilityOptions,
  authTypeOptions,
  boolOptions,
  employeeStatusOptions,
  getCellSearchResultColumns,
  twoFactorTypeOptions,
} from "../common";
import {
  fetchOrgUnitById,
  getAccoutableColumnCellType,
  localizeText,
  newRenderBoolStatus,
  renderBoolStatus,
  renderEmployeeStatus,
  renderTableStatus,
} from "../utilities";

const CertificationNeededOption = [{ id: 0, label: localizeText("CertificationRequired") }];
const CertificationNotNeededOption = [{ id: 1, label: localizeText("NoLongerRequired") }];

export const orgUnitsTableColumns: ColumnType<OrgUnit>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: value => value.id.toString(),
    sortable: false,
    optionsSelector: () => null,
    // hidden: true,
  },
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "parentId",
    label: "ParentOU",
    renderValue: value => fetchOrgUnitById(value.parentId!) || "Root",
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "status",
    label: "FieldStatus",
    optionsSelector: () => null,
    width: 100,
    renderValue: () => null, // Note: We render the value together with the icon when we want to show a tag
    valueIcon: value => {
      // TODO: Check if other colors need to be added
      return renderTableStatus(value.status);
    },
    sortable: true,
  },
];

export const employeesTableColumns: ColumnType<Employee>[] = [
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId,
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    width: 100,
  },
  {
    id: "id",
    label: "ID",
    renderValue: value => value.id.toString(),
    sortable: true,
    optionsSelector: () => null,
    width: 30,
  },
  {
    id: "email",
    label: "TestEmail",
    renderValue: value => value.email,
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    width: 100,
  },
  {
    id: "phone",
    label: "FieldPhone",
    renderValue: value => value.phone,
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    width: 100,
  },
  {
    id: "displayName",
    label: "FieldLanguageDisplayName",
    renderValue: value => value.displayName,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "position",
    label: "FieldPosition",
    renderValue: value => value.position?.toString(),
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: employeeSelectors.uniquePositions,
    width: 100,
  },
  {
    id: "orgUnitId",
    exportPropertyId: "orgUnitName",
    label: "OrganizationalUnit",
    renderValue: value => value.orgUnitName,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "OrgUnit",
    asyncFetchFilterOptionsUseKeyValue: true,
    propName: "name",
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "locationId",
    exportPropertyId: "locationName",
    label: "FieldLocation",
    renderValue: value => value.locationName,
    sortable: true,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
    asyncFetchFilterOptionsUseKeyValue: true,
    propName: "name",
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "expirationDate",
    label: "FieldExpirationDate",
    optionsSelector: () => null,
    renderValue: value => (value.expirationDate ? dayjs(value.expirationDate).format(DATE_FORMAT_WITHOUT_TIME) : null),
    valueIcon: value => {
      if (value.expirationDate) {
        const expDate = dayjs(value.expirationDate);
        const dayDiff = expDate.diff(dayjs(), "days");
        return <Badge color={dayDiff >= 30 ? "green" : "red"} />;
      }
    },
    sortable: true,
    filterType: InputTypes.DATE_RANGE,
    width: 100,
  },
  {
    id: "isExternal",
    label: "External",
    renderValue: value => renderBoolStatus(value.isExternal),
    sortable: true,
    filterType: InputTypes.BOOLEAN,
    optionsSelector: () =>
      boolOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
    width: 100,
  },
  {
    id: "isUser",
    label: "User",
    renderValue: value => renderBoolStatus(value.isUser),
    sortable: true,
    filterType: InputTypes.BOOLEAN,
    optionsSelector: () =>
      boolOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null, // label is rendered together with icon in renderTableStatus()
    valueIcon: (value: Employee): React.ReactNode | null => renderEmployeeStatus(value.status),
    sortable: true,
    optionsSelector: () =>
      employeeStatusOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
        icon: l.icon,
      })),
    filterType: InputTypes.MULTI_SELECT,
    width: 100,
  },
  {
    id: "username",
    label: "FieldUserName",
    renderValue: value => value.username,
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    width: 100,
  },
  {
    id: "tenantRole",
    label: "TenantUserRole",
    renderValue: value => localizeText(value.tenantRole),
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    optionsSelector: () => tenantRoleOptions.map(role => ({ ...role, label: localizeText(role.label) })),
    width: 100,
  },
  {
    id: "authType",
    label: "FieldAuthType",
    renderValue: e =>
      authTypeOptions.find(o => o.id === e.authType)
        ? localizeText(authTypeOptions.filter(o => o.id === e.authType)[0].label)
        : "",
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    optionsSelector: () => authTypeOptions.map(a => ({ ...a, label: localizeText(a.label) })),
    width: 100,
  },
  {
    id: "twoFactorType",
    label: "FieldTwoFactorType",
    renderValue: e =>
      twoFactorTypeOptions.find(o => o.id === e.twoFactorType)
        ? localizeText(twoFactorTypeOptions.filter(o => o.id === e.twoFactorType)[0].label)
        : "",
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    optionsSelector: () => twoFactorTypeOptions.map(a => ({ ...a, label: localizeText(a.label) })),
    width: 100,
  },
];

export const employeeAccountableNameField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "name",
  label: "FieldName",
  renderValue: value => value.name || "",
  sortable: true,
  filterType: InputTypes.MULTI_SELECT,
  cellType: e => (e.staging ? CellTypes.SEARCH : CellTypes.TEXT),
  cellSearchSelector: employeeSelectors.allEmployeeRelations,
  cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
  cellSearchKeys: ["name"],
  cellSearchRecordClick: fillNewAccountableEntryTemplate,
  optionsSelector: () => null,
  width: 100,
  primaryColumn: e => ({
    navigationTarget: e.userId ? `/employee/${e.userId}` : `/orgUnit/${e.orgUnitId}`,
  }),
  cellPrefixIcon: <UsersIcon className="user-icon" />,
  placeholder: "Name",
};

export const employeeAccountableExternalIdField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "externalId",
  label: "ExternalID",
  renderValue: value => value.externalId || "",
  sortable: true,
  filterType: InputTypes.MULTI_SELECT,
  cellType: e => (e.staging ? CellTypes.SEARCH : CellTypes.TEXT),
  cellSearchSelector: employeeSelectors.allEmployeeRelations,
  cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
  cellSearchKeys: ["externalId"],
  cellSearchRecordClick: fillNewAccountableEntryTemplate,
  optionsSelector: employeeSelectors.allEmployeeExternalIDs,
  width: 100,
  cellPrefixIcon: <SearchIcon className="search-icon" />,
  placeholder: "ExternalID",
};

export const employeeAccountableStatusField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "status",
  label: "FieldStatus",
  renderValue: value => (value.status ? localizeText(value.status) : ""),
  sortable: true,
  optionsSelector: () => null,
  width: 100,
};

export const employeeAccountableIsExternalField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "isExternal",
  label: "External",
  renderValue: value => (value.isExternal !== null ? renderBoolStatus(value.isExternal) : ""),
  sortable: true,
  optionsSelector: () => null,
  width: 100,
};

export const employeeAccountableRoleField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "role",
  label: "Accountability",
  renderValue: e => (e.role ? localizeText(e.role.toString()) : ""),
  cellType: () => CellTypes.DROPDOWN,
  cellDropdownOptions: () => AccountabilityOptions,
  cellDropdownOnChange: (e, newValue) =>
    e.staging
      ? updateNewAccountableEntry({ entity: e, newValue: newValue, property: "role" })
      : updateEntityPrivilegeRole({
          entity: e,
          role: newValue as Role,
          id: null,
          entityType: PrivilegedEntityType.Employee,
        }),
  sortable: true,
  optionsSelector: () => null,
  width: 100,
  cellPrefixIcon: <UsersIcon />,
};

export const isAssignedToOUBranchField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "isAssignedToOUBranch",
  label: "AllLevels",
  renderValue: value => {
    return value.orgUnitId && value.role === Role.Recipient ? newRenderBoolStatus(value.isAssignedToOUBranch) : null;
  },
  cellType: e => getAccoutableColumnCellType(e.role, e.orgUnitId),
  sortable: true,
  cellDropdownOptions: () =>
    boolOptions.map(b => ({
      id: b.id,
      label: localizeText(b.label),
    })),
  cellDropdownOnChange: (entity, newValue) =>
    entity.staging
      ? updateNewAccountableEntry({ entity: entity, newValue: newValue, property: "isAssignedToOUBranch" })
      : updateEntityPrivilegeOULvl({
          entity: entity,
          isAssignedToOUBranch: newValue as boolean,
          entityType: PrivilegedEntityType.Employee,
          id: null,
        }),
  optionsSelector: () => null,
  width: 100,
};

export const employeeAccountableActionField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "action" as keyof PrivilegeData,
  label: "FieldAction",
  renderValue: () => null,
  sortable: true,
  optionsSelector: () => null,
  cellType: () => CellTypes.BUTTON_ACTIONS,
  width: 100,
  stagingActions: [
    {
      label: "Add",
      className: "hb-primary-button",
      action: entity => addEntityPrivilege({ entity: entity, entityType: PrivilegedEntityType.Employee, id: null }),
    },
    {
      label: "Cancel",
      className: "hb-tertiary-button",
      action: entity => deleteNewAccountableEntryTemplate(entity),
    },
  ],
  // buttonWithDropdownActions: {
  //   primaryButton: {
  //     label: "Action Button",
  //     action: entity => console.log("clicked primary button"),
  //   },
  //   dropdownButtons: [
  //     {
  //       label: "Delete Relation",
  //       className: "hb-primary-button",
  //       action: entity => deleteEmployeeRelation(entity), // TODO: Implement a thunk for deleting a LocationOrgUnit relation which would be based on 'staging'
  //       changesConfirmation: {
  //         body: "Are you sure you wish to remove this entity?",
  //         okText: "Remove",
  //         cancelText: "Keep",
  //         addValueToBody: false,
  //         addValueToOkText: false,
  //       },
  //     },
  //   ],
  // },
};

export const employeesTabColumns: ColumnWithDifferentCells<PrivilegeData>[] = [
  employeeAccountableNameField,
  employeeAccountableExternalIdField,
  employeeAccountableStatusField,
  employeeAccountableIsExternalField,
  employeeAccountableRoleField,
  isAssignedToOUBranchField,
  employeeAccountableActionField,
];

export const employeeCertificateNameField: ColumnWithDifferentCells<EmployeeCertificate> = {
  id: "certificateName",
  label: "FieldCertificate",
  renderValue: value => value.certificateName,
  sortable: true,
  optionsSelector: () => null,
  // hidden: true,
  primaryColumn: e => ({
    navigationTarget: `/certification/${e.certificateId}`,
  }),
};

export const employeeCertificateExpirationDateField: ColumnWithDifferentCells<EmployeeCertificate> = {
  id: "certificateExpirationDate",
  label: "FieldExpirationDate",
  renderValue: value =>
    value.certificateExpirationDate ? dayjs(value.certificateExpirationDate).format(DATE_FORMAT_WITHOUT_TIME) : null,
  valueIcon: value => {
    if (value.certificateExpirationDate) {
      const expDate = dayjs(value.certificateExpirationDate);
      const dayDiff = expDate.diff(dayjs(), "days");
      return <Badge color={dayDiff >= 30 ? "green" : "red"} />;
    }
  },
  sortable: true,
  optionsSelector: () => null,
};

export const employeeCertificateNextTrainingSessionDateField: ColumnWithDifferentCells<EmployeeCertificate> = {
  id: "nextTrainingSessionDate",
  label: "FieldNextTrainingSession",
  renderValue: value =>
    value.nextTrainingSessionDate
      ? dayjs(value.nextTrainingSessionDate).format(DATE_FORMAT_WITHOUT_TIME)
      : localizeText("Undefined"),
  valueIcon: value => {
    if (value.nextTrainingSessionDate) {
      const expDate = dayjs(value.nextTrainingSessionDate);
      const dayDiff = expDate.diff(dayjs(), "days");
      return <Badge color={dayDiff >= 30 ? "green" : "red"} />;
    }
  },
  sortable: true,
  optionsSelector: () => null,
};

export const employeeCertificateStatusField: ColumnWithDifferentCells<EmployeeCertificate> = {
  id: "status",
  label: "FieldStatus",
  optionsSelector: () => null,
  cellType: () => CellTypes.DROPDOWN,
  cellDropdownOptions: e =>
    e.status === CertificateTraineeStatus.IsNoLongerNeeded ? CertificationNeededOption : CertificationNotNeededOption,
  cellDropdownOnChange: (e, newValue) => setNotNeededCertificate({ entity: e, newValue: !!newValue }),
  renderValue: e =>
    e.status === CertificateTraineeStatus.IsNoLongerNeeded ? localizeText("NoLongerRequired") : localizeText(e.status),
  sortable: true,
  cellPrefixIcon: <CertificateIcon className="certificate-icon" />,
};

export const certificationsTableColumns: ColumnWithDifferentCells<EmployeeCertificate>[] = [
  employeeCertificateNameField,
  employeeCertificateExpirationDateField,
  employeeCertificateNextTrainingSessionDateField,
  employeeCertificateStatusField,
];

export const tenantRoleOptions: TOption[] = [
  {
    id: TenantRole.User,
    label: TenantRole.User,
  },
  {
    id: TenantRole.Admin,
    label: TenantRole.Admin,
  },
  {
    id: TenantRole.Reporter,
    label: TenantRole.Reporter,
  },
];
