import { Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

type TProps = {
  labelTranslationKey: string;
  date: string | null;
};

const LabelWithDate: React.FC<TProps> = ({ labelTranslationKey, date }) => {
  const { t } = useTranslation();
  const { Text } = Typography;

  if (!date) return null;

  const formattedValue = dayjs(date).format("DD MMM YYYY");

  return <Text>{`${t(labelTranslationKey)} ${formattedValue}`}</Text>;
};

export default LabelWithDate;
