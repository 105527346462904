import { Badge } from "antd";
import dayjs from "dayjs";

import { DATE_FORMAT_WITHOUT_TIME } from "../../../components/HBComponents/DatePicker/HBDatePicker";
import { HandleTaskModal } from "../../../components/HBComponents/HandleTaskModal/HandleTaskModal";
import NewTabComponent from "../../../components/HBComponents/NewTabIcon/NewTabComponent";
import { ReactComponent as HighPriorityIcon } from "../../../media/priorityIcons/high.svg";
import { ReactComponent as HighestPriorityIcon } from "../../../media/priorityIcons/highest.svg";
import { ReactComponent as LowPriorityIcon } from "../../../media/priorityIcons/low.svg";
import { ReactComponent as LowestPriorityIcon } from "../../../media/priorityIcons/lowest.svg";
import { ReactComponent as MediumPriorityIcon } from "../../../media/priorityIcons/medium.svg";
import { Bool, EmployeeBool } from "../../../types/employee";
import { EqLocTypeInspTypeRelationStatus } from "../../../types/equipmentType";
import { File } from "../../../types/files";
import { Location } from "../../../types/location";
import { CategoryIdWithFilesTab, CellTypes, ColumnType, InputTypes, TOption } from "../../../types/page";
import { ContextActions, ResponsibleChangeReasonType } from "../../../types/tasks";
import { UserRole, UserState } from "../../../types/user";
import {
  ActionTypes,
  AppointmentStatus,
  AuthType,
  HistoryLog,
  OpenClosedStatus,
  Status,
  TaskPriority,
  TasksPageStatus,
  TwoFactorType,
} from "../../../types/utility";
import PDFActions from "../../TableActions/PDFActions";
import { localizeText, renderBoolStatus, renderOpenCloseStatus, renderTableStatus } from "./utilities";

export const boolOptions: TOption[] = [
  {
    id: 1,
    label: EmployeeBool.Yes,
  },
  {
    id: 0,
    label: EmployeeBool.No,
  },
];

export const booleanOptions: TOption[] = [
  {
    id: Bool.True,
    label: Bool.True,
  },
  {
    id: Bool.False,
    label: Bool.False,
  },
];

export const options: TOption[] = [
  {
    id: Status.Active,
    label: "Active",
    icon: <Badge color="#02D924" />,
  },
  {
    id: Status.InActive,
    label: "InActive",
    icon: <Badge color="#B7B7B7" />,
  },
];

export const employeeStatusOptions: TOption[] = [
  {
    id: Status.Active,
    label: "Active",
    icon: <Badge color="#02D924" />,
  },
  {
    id: Status.Inactive,
    label: "InActive",
  },
];

export const authTypeOptions: TOption[] = [
  {
    id: AuthType.OTP,
    label: "AuthTypeOTP",
  },
  {
    id: AuthType.SSO,
    label: "AuthTypeSSO",
  },
  {
    id: AuthType.Username,
    label: "AuthTypeUsername",
  },
];

export const twoFactorTypeOptions: TOption[] = [
  {
    id: TwoFactorType.None,
    label: "TfaType_None",
  },
  {
    id: TwoFactorType.Email,
    label: "TfaType_Email",
  },
  {
    id: TwoFactorType.Sms,
    label: "TfaType_Sms",
  },
];

export const openClosedOptions: TOption[] = [
  {
    id: OpenClosedStatus.Opened,
    label: "Opened",
    icon: <Badge color="#02D924" />,
  },
  {
    id: OpenClosedStatus.Closed,
    label: "Closed",
    icon: <Badge color="#B7B7B7" />,
  },
  {
    id: OpenClosedStatus.Canceled,
    label: OpenClosedStatus.Canceled,
    icon: <Badge color="#E54841" />,
  },
];

export const tasksPageStatusOptions: TOption[] = [
  {
    id: TasksPageStatus.Assigned,
    label: "Assigned",
    icon: <Badge color="#02D924" />,
  },
  {
    id: TasksPageStatus.Unassigned,
    label: "Unassigned",
    icon: <Badge color="#B7B7B7" />,
  },
  {
    id: TasksPageStatus.PastDueDate,
    label: "PastDueDate",
    icon: <Badge color="#D9042B" />,
  },
];

export const priorityOptions: TOption[] = [
  {
    id: TaskPriority.Highest,
    label: "TaskPriority_Critical",
    icon: <HighestPriorityIcon />,
  },
  {
    id: TaskPriority.High,
    label: "TaskPriority_High",
    icon: <HighPriorityIcon />,
  },
  {
    id: TaskPriority.Medium,
    label: "TaskPriority_Medium",
    icon: <MediumPriorityIcon />,
  },
  {
    id: TaskPriority.Low,
    label: "TaskPriority_Low",
    icon: <LowPriorityIcon />,
  },
  {
    id: TaskPriority.Lowest,
    label: "TaskPriority_None",
    icon: <LowestPriorityIcon />,
  },
];

export const surveyStatusOptions: TOption[] = [
  {
    id: AppointmentStatus.Completed,
    label: "SurveyStatus_Completed",
    icon: <Badge color="#02D924" />,
  },
  {
    id: AppointmentStatus.Draft,
    label: "SurveyStatus_Draft",
    icon: <Badge color="#B7B7B7" />,
  },
  {
    id: AppointmentStatus.Sent,
    label: "SurveyStatus_Sent",
    icon: <Badge color="#FFFF00" />,
  },
  {
    id: AppointmentStatus.Proccessing,
    label: "SurveyStatus_Proccessing",
    icon: <Badge color="#00ecec" />,
  },
  {
    id: AppointmentStatus.PartiallyCompleted,
    label: "SurveyStatus_PartiallyCompleted",
    icon: <Badge color="#00ecec" />,
  },
  {
    id: AppointmentStatus.Canceled,
    label: "Canceled",
    icon: <Badge color="#E54841" />,
  },
];

export const reasonOptions: TOption[] = [
  {
    id: ResponsibleChangeReasonType.NotAbleToFix,
    label: `ResponsibleChange_${ResponsibleChangeReasonType.NotAbleToFix}`,
  },
  {
    id: ResponsibleChangeReasonType.NotMyResponsibility,
    label: `ResponsibleChange_${ResponsibleChangeReasonType.NotMyResponsibility}`,
  },
  {
    id: ResponsibleChangeReasonType.Other,
    label: `ResponsibleChange_${ResponsibleChangeReasonType.Other}`,
  },
];

export const locationsTableColumns: ColumnType<Location>[] = [
  // {
  //   id: "id",
  //   label: "ID",
  //   renderValue: value => value.id.toString(),
  //   sortable: false,
  //   optionsSelector: () => null,
  //   width: 100,
  //   hidden: true,
  // },
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name,
    sortable: true,
    // optionsSelector: () => null,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    primaryColumn: () => ({
      navigationTarget: "/location",
    }),
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
  },
  {
    id: "externalId",
    label: "ExternalID",
    // FIXME: Find a better way
    renderValue: value => value.externalId,
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
    width: 100,
  },
  {
    id: "parentId",
    exportPropertyId: "parentName",
    label: "ParentLocation",
    renderValue: value => value.parentName,
    filterType: InputTypes.MULTI_SELECT,
    sortable: true,
    width: 100,
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
    asyncFetchFilterOptionsUseKeyValue: true,
  },
  {
    id: "status",
    label: "FieldStatus",
    // optionsSelector: () => null,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: () =>
      options.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
    width: 50,
    renderValue: () => null, // Note: We render the value together with the icon when we want to show a tag
    valueIcon: value => {
      // TODO: Check if other colors need to be added
      return renderTableStatus(value.status);
    },
    sortable: true,
  },
  {
    id: "isSite",
    label: "FieldSite",
    renderValue: value => renderBoolStatus(value.isSite),
    sortable: true,
    filterType: InputTypes.BOOLEAN,
    width: 100,
    optionsSelector: () =>
      boolOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
  },
  {
    id: "typeId",
    exportPropertyId: "typeName",
    label: "FieldType",
    renderValue: entity => (entity.typeName ? entity.typeName : null),
    filterType: InputTypes.MULTI_SELECT,
    sortable: true,
    width: 100,
    valueType: "string",
    optionsSelector: () => null,
    propName: "name",
    asyncFetchFilterOptionsEntityType: "LocationType",
    asyncFetchFilterOptionsUseKeyValue: true,
  },
];

export const AccountabilityOptions = [
  { id: "Owner", label: "Owner" },
  { id: "Recipient", label: "Recipient" },
];

export const TraineeStatusOptions = [
  { id: "Pending", label: "Pending" },
  // { id: "Completed", label: "Completed" },
  { id: "Attended", label: "Attended" },
  { id: "Missing", label: "Missing" },
  // { id: "Canceled", label: "Canceled" },
];

export const InspectionStatusOptions = [
  { id: "Passed", label: "Passed" },
  { id: "Failed", label: "Failed" },
  { id: "Pending", label: "Pending" },
  { id: "PassedWithConditions", label: "PassedWithConditions" },
  { id: "Expired", label: "Expired" },
];

export const EqTypeInspTypeRelationStatusOptions = [
  { id: EqLocTypeInspTypeRelationStatus.Active, label: EqLocTypeInspTypeRelationStatus.Active },
  { id: EqLocTypeInspTypeRelationStatus.CreatedByMistake, label: EqLocTypeInspTypeRelationStatus.CreatedByMistake },
  { id: EqLocTypeInspTypeRelationStatus.IsNoLongerNeeded, label: EqLocTypeInspTypeRelationStatus.IsNoLongerNeeded },
];

export function getCellSearchResultColumns<T>(
  columnKeys: string[]
): ColumnType<T & { name: string; externalId: string; phone: string }>[] {
  const allAvailableColumns: ColumnType<T & { name: string; externalId: string; phone: string }>[] = [
    {
      id: "name" as keyof T,
      label: "Display Name",
      renderValue: value => value.name || "",
      sortable: false,
      optionsSelector: () => null,
      width: 100,
    },
    {
      id: "externalId" as keyof T,
      label: "External ID",
      renderValue: value => value.externalId || "",
      sortable: false,
      optionsSelector: () => null,
      width: 100,
    },
    {
      id: "phone",
      label: "Phone",
      renderValue: value => value.phone || "",
      sortable: false,
      optionsSelector: () => null,
      width: 100,
    },
  ];
  return allAvailableColumns.filter(c => columnKeys.includes(c.id as string));
}

export const filesTableColumns = (pageId: CategoryIdWithFilesTab): ColumnType<File>[] => [
  {
    id: "createdDate",
    label: "CreateDate",
    renderValue: value =>
      dayjs(value.createdDate).isValid() ? dayjs(value.createdDate).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: true,
    optionsSelector: () => null,
    width: 100,
    filterType: InputTypes.DATE_RANGE,
  },
  {
    id: "expirationDate",
    label: "FieldExpirationDate",
    renderValue: value =>
      dayjs(value.expirationDate).isValid() ? dayjs(value.expirationDate).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: true,
    optionsSelector: () => null,
    width: 100,
    filterType: InputTypes.DATE_RANGE,
  },
  {
    id: "createdByUserId",
    label: "UserCreated",
    renderValue: value => (value.createdByUser ? value.createdByUser.name : null),
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: state =>
      state[pageId].subData.files
        ? state[pageId].subData.files.map(x => ({ id: x.createdByUser.id, label: x.createdByUser.name }))
        : [],
    width: 100,
  },
  {
    id: "type",
    label: "FieldType",
    renderValue: value => (value.name ? value.name.split(".").pop() || "" : ""),
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: state =>
      state[pageId].subData.files
        ? state[pageId].subData.files
            .map(x => {
              const fileType = x.url.split(".").pop() || "";

              return {
                id: fileType,
                label: fileType,
              };
            })
            .filter((value, index, self) => self.indexOf(value) === index)
        : [],
    width: 100,
  },
  {
    id: "url",
    label: "FieldFile",
    renderValue: value => value.name,
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: state =>
      state[pageId].subData.files ? state[pageId].subData.files.map(x => ({ id: x.name, label: x.name })) : [],
    width: 100,
  },
];

export const contextActionsColumnWithActions: ColumnType<ContextActions> = {
  id: "actions" as keyof ContextActions,
  label: "FieldActions",
  renderValue: () => null,
  optionsSelector: () => null,
  sortable: false,
  cellType: () => CellTypes.ACTIONS,
  width: 200,
  actions: [
    {
      label: "",
      component: entity => (
        <HandleTaskModal
          status={entity.status}
          isOpen={entity.status === OpenClosedStatus.Opened ? true : false}
          taskId={entity.id}
        />
      ),
      action: () => null,
    },
    {
      label: ActionTypes.PDF,
      component: entity =>
        entity.pdfInfo?.livePdfGuid || entity.pdfInfo?.fileUrl ? (
          <PDFActions pdfLink={entity.pdfInfo?.livePdfGuid} title={entity.title} fileLink={entity.pdfInfo?.fileUrl} />
        ) : null,
      action: () => null,
    },
  ],
};

export const contextActionsTableColumns = (): ColumnType<ContextActions>[] => [
  {
    id: "id",
    label: "ID",
    valueIcon: value => <NewTabComponent value={value.id.toString()} href={`/actions/${value.id}`} />,
    renderValue: () => null,
    valueType: "number",
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "title",
    label: "FieldTitle",
    renderValue: value => value.title,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "reviewName",
    label: "FieldReview",
    renderValue: value => value.reviewName,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "responsibleUserName",
    label: "FieldResponsible",
    renderValue: value => value.responsibleUserName,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null,
    valueIcon: (value: ContextActions): React.ReactNode | null => renderOpenCloseStatus(value.status),
    sortable: true,
    optionsSelector: () =>
      openClosedOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
        icon: l.icon,
      })),
    width: 150,
  },
  {
    id: "subTasksCompleted",
    label: "CompletedSubTasks",
    renderValue: value =>
      value.subTasksTotal === 0 ? localizeText("None") : `${value.subTasksCompleted}/${value.subTasksTotal}`,
    sortable: true,
    optionsSelector: () => null,
  },
  contextActionsColumnWithActions,
];

export const historyLogTableColumns: ColumnType<HistoryLog>[] = [
  {
    id: "userName",
    label: "FieldUserName",
    renderValue: value => value.userName,
    sortable: false,
    optionsSelector: () => null,
    // hidden: true,
    // primaryColumn: e => ({
    //   navigationTarget: `/certification/${e.certificateId}`,
    // }),
  },
  {
    id: "fieldName",
    label: "FieldName",
    renderValue: value => localizeText(value.fieldName),
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "operation",
    label: "Operation",
    renderValue: value => localizeText(value.operation),
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "oldValue",
    label: "OldValue",
    renderValue: value => localizeText(value.oldValue),
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "newValue",
    label: "NewValue",
    renderValue: value => localizeText(value.newValue),
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "timeStamp",
    label: "Timestamp",
    renderValue: value => value.timeStamp,
    sortable: false,
    optionsSelector: () => null,
  },
];

export const enableForAdminOrUserOwner: (userState: UserState) => boolean = userState => {
  if ([UserRole.Admin, UserRole.SU].includes(userState.settings.role)) {
    return true;
  } else if (userState.settings.role === UserRole.User && userState.personalSettings.userPrivileges.orgUnitOwner) {
    return true;
  }
  return false;
};
