import { unwrapResult } from "@reduxjs/toolkit";

import { Form, FormInstance } from "antd";
import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PageConfigurationContext from "../../../context/pageContext";
import { userSelectors } from "../../../selectors";
import { useAppDispatch } from "../../../store/store";
import { HBEventName } from "../../../types/analyticsTypes/HBEvent";
import { CustomPropertyType } from "../../../types/customProperty";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage, ChangesConfirmation } from "../../../types/page";
import { AdditionalProps, NoValueKey, ParamsKeys } from "../../../types/utility";
import { isRoleAdminLevel } from "../../../utils/functions";
import useInitTrackEvents from "../../../utils/hooks/useInitTrackEvents";
import useRouter from "../../../utils/hooks/useRouter";
import { useUIErrorHandling } from "../../../utils/hooks/useUIErrorHandling";
import useFormManagmentEvents, { MobileEvent } from "../../EmbeddedModal/useFormManagmentEvents";
import { ConfirmationType, ModalInfo } from "../../SingleViewCard/types";

const TODO_LIST_PATH = "/todolist?mobile=true";

export const ABOUT_TAB_KEY = "About";

interface MobileSingleViewProps {
  enitityId: string;
  isNewEntity?: boolean;
  data: Record<string, unknown> | null | undefined;
  handleDataFetch: () => Promise<void>;
}

type SingleViewContextType = {
  handleDataFetch: () => Promise<void>;
  singleViewRef: React.MutableRefObject<null>;
  data: Record<string, unknown> | null | undefined;
  currentData: Record<string, unknown> | null | undefined;
  setCurrentData: React.Dispatch<React.SetStateAction<Record<string, unknown> | null | undefined>>;
  isGlobalEditForbidden: boolean;
  canEditCard: boolean;
  summaryCardIcon: {
    name: string;
    alt: string;
    init?: boolean | undefined;
  } | null;
  setSummaryCardIcon: React.Dispatch<
    React.SetStateAction<{
      name: string;
      alt: string;
      init?: boolean | undefined;
    } | null>
  >;
  renderValue: (value: (entity: Record<string, unknown>) => string | null | ReactNode) => React.ReactNode;
  handleChange: (
    id: string,
    changesConfirmation?: ChangesConfirmation | null,
    manuallyChanged?: boolean,
    cleanOnChange?: string[]
  ) => (newValue: string | null | boolean | number) => void;
  isEdited: boolean;
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: string;
  handleSetActiveTab: (tabKey: string) => void;
  isNewEntity?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  onSubmitChanges: ({ additionTrigger }: { additionTrigger?: boolean }) => Promise<void>;
  handleClearErrors: () => void;
  errorMessages: string[];
  setErrors: (errors: Record<string, string[]>, errorType?: ConfirmationType) => void;
  onFinishFailed: ({
    errorFields,
  }: {
    errorFields: {
      name: (string | number)[];
      errors: string[];
    }[];
  }) => void;
  cardValidationErrors: string[];
  setCardValidationErrors: React.Dispatch<React.SetStateAction<string[]>>;
  todoListInParams: boolean;
  navigationModalVisibility: boolean;
  setNavigationModalVisiblity: React.Dispatch<React.SetStateAction<boolean>>;
  handleModalClose: () => void;
  modalInfo: ModalInfo | null;
  setModalInfo: React.Dispatch<React.SetStateAction<ModalInfo | null>>;
  handleDependentFieldsValues: (changedValues: Partial<Record<string, unknown> | null | undefined>) => void;
  resetEditMode: () => void;
  openNextPage: (location: string) => void;
  handleBack: () => void;
  handleBackToPageBeforeDetailPage: () => void;
  handleBlockedNavigation: () => boolean;
};

const DEFAUTL_CONTEXT_VALUE: SingleViewContextType = {} as SingleViewContextType;

const MobileSingleViewContext = createContext(DEFAUTL_CONTEXT_VALUE);

const MobileSingleViewProvider: FC<PropsWithChildren<MobileSingleViewProps>> = ({
  children,
  data,
  isNewEntity,
  enitityId,
  handleDataFetch,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { history, location } = useRouter<{ prefillData: Record<string, unknown> }>();
  const historyStack = useRef<string[]>([]);
  const backHandlerRef = useRef<() => void | undefined>();
  const todoListInParamsRef = useRef(false);

  const { fireMobileEvent, setFormManagmentRef } = useFormManagmentEvents();

  const { summaryCard, createNewEntity, updateEntity, id } = useContext(
    PageConfigurationContext
  ) as CategoryPage<BaseEntityType>;

  const user = useSelector(userSelectors.getCurrentUser);
  const canEditSelectedData = useSelector(summaryCard?.canEditSelector || (() => []));

  const [form] = Form.useForm();

  const { track } = useInitTrackEvents();

  const [currentData, setCurrentData] = useState<Record<string, unknown> | null | undefined>(null);

  const [activeTab, setActiveTab] = useState<string>(ABOUT_TAB_KEY);
  const [isEdited, setIsEdited] = useState(!!isNewEntity);
  const [isGlobalEditForbidden, setIsGlobalEditForbidden] = useState(false);
  const [summaryCardIcon, setSummaryCardIcon] = useState<{
    name: string;
    alt: string;
    init?: boolean;
  } | null>({ name: "10.png", alt: "10", init: true });

  const { errorMessages, setErrors, handleClearErrors } = useUIErrorHandling();

  const [modalInfo, setModalInfo] = useState<ModalInfo | null>(null);
  const [manuallyChangedFields, addManuallyChangedFields] = useState<string[]>([]);
  const [cardValidationErrors, setCardValidationErrors] = useState<string[]>([]);

  const singleViewRef = useRef(null);

  const openNextPage = (location: string) => {
    historyStack.current.push(location);
    history.push(location);
  };

  const replaceCurrentPage = (location: string) => {
    historyStack.current.pop();
    historyStack.current.push(location);
    history.replace(location);
  };

  const openPreviousPage = () => {
    const previousPath = historyStack.current.pop();
    if (previousPath) {
      history.goBack();
    } else {
      handleGoToPageBeforeDetailPage();
    }
  };

  useEffect(() => {
    const navigationWarning = summaryCard.navigationWarning;
    if (
      location?.state?.prefillData &&
      navigationWarning &&
      (!navigationWarning.showConditionally ||
        (navigationWarning.showConditionally && navigationWarning.showConditionally("test")))
    ) {
      return setModalInfo({
        body: navigationWarning.addValueToBody ? `${t(navigationWarning.body)}` : t(navigationWarning.body),
        okText: navigationWarning.addValueToOkText ? `${t(navigationWarning.okText)}` : t(navigationWarning.okText),
        cancelText: t(navigationWarning.cancelText),
        onConfirm: () => {
          setModalInfo(null);
          navigationWarning.navigateOnOk && openNextPage(navigationWarning.navigateOnOk);
        },
        onCancel: () => {
          setModalInfo(null);
          navigationWarning.navigateOnCancel && openNextPage(navigationWarning.navigateOnCancel);
        },
      });
    }
  }, [summaryCard.navigationWarning]);

  useEffect(() => {
    setIsEdited(!!isNewEntity);
  }, [isNewEntity]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    todoListInParamsRef.current = !!queryParams.get(ParamsKeys.TODO_LIST);
    setFormManagmentRef(singleViewRef);
  }, []);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  useEffect(() => {
    setActiveTab(ABOUT_TAB_KEY);
  }, [enitityId]);

  useEffect(() => {
    if (isEdited) {
      handleSetActiveTab(ABOUT_TAB_KEY);
    }
  }, [isEdited]);

  useEffect(() => {
    setCurrentData(data);
    const prefillData = location?.state?.prefillData;
    if (prefillData) {
      for (const property in prefillData) {
        setCurrentData(prevData => ({ ...prevData, [property]: prefillData[property] }));
      }
    }
  }, [data]);

  useEffect(() => {
    setIsGlobalEditForbidden(false);
    if (summaryCard.globalEditForbidden && data && !isNewEntity) {
      setIsGlobalEditForbidden(summaryCard.globalEditForbidden(data));
    }
  }, [summaryCard.globalEditForbidden, data]);

  const handleSetActiveTab = (tabKey: string) => {
    setActiveTab(tabKey);
  };

  const renderValue = useCallback(
    (value: (entity: Record<string, unknown>) => string | null | ReactNode) => {
      if (!currentData) {
        return null;
      }
      return value(currentData);
    },
    [currentData]
  );

  const onModalClick = (
    id: string,
    manuallyChanged: boolean,
    newValue: string | null | boolean | number,
    confirmed: boolean
  ) => () => {
    setModalInfo(null);

    if (confirmed) {
      handleChange(id, null, manuallyChanged)(newValue);
    }
  };

  const handleChange = (
    id: string,
    changesConfirmation?: ChangesConfirmation | null,
    manuallyChanged = true,
    cleanOnChange?: string[]
  ) => (newValue: string | null | boolean | number) => {
    if (currentData && currentData[id] === newValue) return;
    if (!manuallyChangedFields.includes(id) && manuallyChanged) addManuallyChangedFields(oldArray => [...oldArray, id]);

    if (
      changesConfirmation &&
      (!changesConfirmation.showConditionally ||
        (changesConfirmation.showConditionally && changesConfirmation.showConditionally(newValue)))
    ) {
      return setModalInfo({
        body: changesConfirmation.addValueToBody
          ? `${t(changesConfirmation.body)} ${newValue}`
          : t(changesConfirmation.body),
        okText: changesConfirmation.addValueToOkText
          ? `${t(changesConfirmation.okText)} ${newValue}`
          : t(changesConfirmation.okText),
        cancelText: t(changesConfirmation.cancelText),
        onConfirm: onModalClick(id, manuallyChanged, newValue, true),
        onCancel: onModalClick(id, manuallyChanged, newValue, false),
      });
    }

    return setCurrentData(current => {
      const customProperties = current ? (current["customPropertyValues"] as AdditionalProps[]) : null;

      if (!current) {
        return current;
      }

      const keys = Object.keys(current);
      const additionalPropsKeys = customProperties?.map(property => property.name) || null;

      if (!keys.includes(id) && !additionalPropsKeys?.includes(id)) {
        return current;
      }

      if (current[id] === newValue || Number(customProperties?.find(prop => prop.name === id)?.value) === newValue) {
        return current;
      }

      // Note: Case where a custom prop was changed
      if (additionalPropsKeys?.includes(id)) {
        setIsEdited(true);
        track({ eventName: HBEventName.EnterEditMode, data: { entity: currentData } });
        return {
          ...current,
          customPropertyValues: customProperties?.map(prop => {
            if (prop.name === id) {
              return {
                ...prop,
                value:
                  prop.type === "Dictionary" ? (newValue === NoValueKey ? "" : newValue ? newValue : "") : newValue,
              };
            }
            return prop;
          }),
        };
      }
      // Note: Case where a static prop was changed
      setIsEdited(true);
      track({ eventName: HBEventName.EnterEditMode, data: { entity: currentData } });

      //specifically logic for Dictionary. Todo: improve that process
      if (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (Object as any).values(CustomPropertyType).includes(current.type) &&
        (id === "type" || id === "dictionaryId")
      ) {
        return { ...current, [id]: newValue, defaultValue: null };
      }

      if (cleanOnChange?.length) {
        const currentToModify = JSON.parse(JSON.stringify(current));

        cleanOnChange.forEach(x => (currentToModify[x] = null));

        return {
          ...currentToModify,
          [id]: newValue,
        };
      }

      return { ...current, [id]: newValue };
    });
  };

  const onSubmitChanges = async ({ additionTrigger }: { additionTrigger?: boolean }) => {
    if (!currentData) {
      return;
    }

    if (isNewEntity && createNewEntity) {
      return dispatch(
        createNewEntity({
          entity: { ...currentData, iconType: summaryCardIcon?.alt || null },
          additionTrigger: additionTrigger,
        })
      )
        .then(unwrapResult)
        .then(originalPromiseResult => {
          track({ eventName: HBEventName.CreateNew, data: { entity: currentData } });
          setIsEdited(false);
          replaceCurrentPage(`../${id}/${originalPromiseResult.id}/mobile`);
        })
        .catch(customError => {
          setErrors(customError);
        });
    }

    if (updateEntity) {
      dispatch(updateEntity({ ...currentData, iconType: summaryCardIcon?.alt || null }))
        .then(unwrapResult)
        .then(() => {
          track({ eventName: HBEventName.SaveEntityChanges, data: { entity: currentData } });
          setIsEdited(false);
        })
        .catch(customError => {
          setErrors(customError);
        });
    }
  };

  const onFinishFailed = ({ errorFields }: { errorFields: { name: (string | number)[]; errors: string[] }[] }) => {
    if (errorFields.length > 0) {
      const errors = errorFields.map(field => {
        return `${field.name.join(".")}: ${field.errors.join(", ")}`;
      });
      setCardValidationErrors(errors);
    }
  };

  const canEditCard =
    (summaryCard.canEdit && summaryCard.canEdit(user, currentData, canEditSelectedData)) ||
    isRoleAdminLevel(user.settings.role);

  const [navigationModalVisibility, setNavigationModalVisiblity] = useState(false);

  const resetEditMode = () => {
    setCurrentData(data);
    setIsEdited(false);
    setNavigationModalVisiblity(false);
    form.resetFields();
    window.scrollTo(0, 0);

    if (backHandlerRef.current) {
      const handler = backHandlerRef.current;
      backHandlerRef.current = undefined;
      handler();
    }
  };

  const handleGoToPageBeforeDetailPage = () => {
    todoListInParamsRef.current
      ? openNextPage(TODO_LIST_PATH)
      : fireMobileEvent(MobileEvent.SingleViewBackToSearchEvent, {});
  };

  const handleBlockedNavigation = () => {
    if (!navigationModalVisibility) {
      setNavigationModalVisiblity(true);
      return false;
    }

    return true;
  };

  const handleBack = () => {
    if (isEdited) {
      backHandlerRef.current = openPreviousPage;
      handleBlockedNavigation();
    } else {
      openPreviousPage();
    }
  };

  const handleBackToPageBeforeDetailPage = () => {
    if (isEdited) {
      backHandlerRef.current = handleGoToPageBeforeDetailPage;
      handleBlockedNavigation();
    } else {
      handleGoToPageBeforeDetailPage();
    }
  };

  const handleModalClose = () => {
    setNavigationModalVisiblity(false);
  };

  const handleDependentFieldsValues = (changedValues: Partial<typeof data>) => {
    const dependentFields =
      summaryCard.dependentFields &&
      currentData &&
      summaryCard.dependentFields({ ...currentData, ...changedValues }, isNewEntity, manuallyChangedFields);
    if (!dependentFields) {
      return;
    }

    for (const property in changedValues) {
      const currentProp = dependentFields.find(f => f.parentId === property);
      if (currentProp) {
        handleChange(currentProp.id, undefined, false)(currentProp.defaultValue);
        form.setFieldsValue({
          [currentProp.id]: currentProp.defaultValue,
        });
      }
    }
  };

  const value = useMemo(
    () => ({
      handleDataFetch,
      singleViewRef,
      data,
      currentData,
      canEditCard,
      setCurrentData,
      isGlobalEditForbidden,
      isNewEntity,
      activeTab,
      handleSetActiveTab,
      isEdited,
      renderValue,
      setIsEdited,
      summaryCardIcon,
      setSummaryCardIcon,
      form,
      handleChange,
      onSubmitChanges,
      handleClearErrors,
      errorMessages,
      setErrors,
      onFinishFailed,
      cardValidationErrors,
      setCardValidationErrors,
      handleGoToPageBeforeDetailPage,
      todoListInParams: todoListInParamsRef.current,
      navigationModalVisibility,
      handleModalClose,
      modalInfo,
      setModalInfo,
      setNavigationModalVisiblity,
      handleDependentFieldsValues,
      openNextPage,
      handleBack,
      handleBackToPageBeforeDetailPage,
      handleBlockedNavigation,
      resetEditMode,
    }),
    [
      data,
      currentData,
      isNewEntity,
      isEdited,
      isGlobalEditForbidden,
      summaryCardIcon,
      form,
      navigationModalVisibility,
      canEditCard,
      errorMessages,
      cardValidationErrors,
      todoListInParamsRef.current,
      modalInfo,
      activeTab,
    ]
  );

  return <MobileSingleViewContext.Provider value={value}>{children}</MobileSingleViewContext.Provider>;
};

const useMobileSingleViewContext = () => useContext(MobileSingleViewContext);

export { useMobileSingleViewContext, MobileSingleViewProvider, MobileSingleViewContext };
