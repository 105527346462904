import "../ManagmentToolsPage.less";

import { DownloadOutlined, LinkOutlined } from "@ant-design/icons";

import { Alert, Button, message, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ExportData } from "../../../actions/menuActions";
import {
  runBlobMigration,
  runCompanyUserInDestinationTool,
  runDulicateEntitiesCheck,
} from "../../../store/slices/companyMigration";
import { RootState, useAppDispatch } from "../../../store/store";
import { CompanyMigration, CompanyMigrationSelection, CompanyUser } from "../../../types/companyMigration";
import { Employee } from "../../../types/employee";
import { Equipment } from "../../../types/equipment";
import { OrgUnit } from "../../../types/orgUnit";

type MigrationToolsProps = {
  migrationId?: number;
  companyMigrationSelection?: CompanyMigrationSelection;
  allowedMigrationTools: MigrationTool[];
};

export enum MigrationTool {
  CompanyUserInDestinationCompanyCheck,
  DuplicateEntities,
  BlobMigration,
}

enum DuplicationToolEntitiyType {
  Employee,
  Equipment,
  OrgUnit,
}

export default function MigrationTools({
  migrationId,
  companyMigrationSelection,
  allowedMigrationTools,
}: MigrationToolsProps) {
  const dispatch = useAppDispatch();
  const [selectedTool, setselectedTool] = useState<MigrationTool | undefined>();
  const [toolRunFinished, setToolRunFinished] = useState<boolean>(false);
  const { migrations, tools } = useSelector((state: RootState) => state.companyMigration);

  const getMigrationDetails = () => {
    if (migrationId) {
      const migration: CompanyMigration | undefined = migrations.find(m => m.id === migrationId);
      if (migration) {
        return {
          sourceCompanyId: migration.sourceCompanyId,
          destinationCompanyId: migration.destinationCompanyId,
        };
      }
    } else if (companyMigrationSelection) {
      return companyMigrationSelection;
    }
  };

  const runCompanyUserInDestinationCheck = () => {
    setselectedTool(MigrationTool.CompanyUserInDestinationCompanyCheck);
    const migrationDetails = getMigrationDetails();
    if (migrationDetails) {
      dispatch(runCompanyUserInDestinationTool(migrationDetails));
    }
  };
  const runDuplicateEntities = () => {
    setselectedTool(MigrationTool.DuplicateEntities);
    const migrationDetails = getMigrationDetails();
    if (migrationDetails) {
      dispatch(runDulicateEntitiesCheck(migrationDetails));
    }
  };
  const runBlobMigrationTool = () => {
    setselectedTool(MigrationTool.BlobMigration);
    if (migrationId) {
      dispatch(runBlobMigration(migrationId));
    }
  };

  useEffect(() => {
    if (
      selectedTool !== undefined &&
      !toolRunFinished &&
      (tools.companyUsersInDestination !== undefined ||
        tools.duplicateEntities !== undefined ||
        tools.blobMigration !== undefined)
    ) {
      switch (selectedTool) {
        case MigrationTool.CompanyUserInDestinationCompanyCheck:
          tools.companyUsersInDestination ? setToolRunFinished(true) : setToolRunFinished(false);
          break;
        case MigrationTool.DuplicateEntities:
          tools.duplicateEntities ? setToolRunFinished(true) : setToolRunFinished(false);
          break;
        case MigrationTool.BlobMigration:
          tools.blobMigration ? setToolRunFinished(true) : setToolRunFinished(false);
          break;
      }
    }
  }, [tools]);

  const exportDuplicationsToExcel = (tool: MigrationTool, entityType?: DuplicationToolEntitiyType) => () => {
    const migrationDetails = getMigrationDetails();
    let columnNames: string[] | undefined = undefined;
    let data: Equipment[] | OrgUnit[] | Employee[] | CompanyUser[] | undefined = undefined;
    let filename: string | undefined = undefined;
    switch (tool) {
      case MigrationTool.CompanyUserInDestinationCompanyCheck: {
        if (tools.companyUsersInDestination && tools.companyUsersInDestination.length > 0) {
          columnNames = Object.getOwnPropertyNames(tools.companyUsersInDestination[0]);
          data = tools.companyUsersInDestination;
          filename = `company-users-in-src-${migrationDetails?.sourceCompanyId}-missing-from-dest-${migrationDetails?.destinationCompanyId}`;
          exportToExcel(columnNames, data, filename, "Downloading Company Users In Destination Results As Excel..");
        }
        break;
      }
      case MigrationTool.DuplicateEntities: {
        if (tools.duplicateEntities && foundDuplicatedEntities()) {
          switch (entityType) {
            case DuplicationToolEntitiyType.Employee: {
              columnNames = Object.getOwnPropertyNames(tools.duplicateEntities.employees[0]);
              data = tools.duplicateEntities.employees;
              filename = `employees-duplications-between-${migrationDetails?.sourceCompanyId}-and-${migrationDetails?.destinationCompanyId}`;
              exportToExcel(columnNames, data, filename, "Downloading Employees Duplications As Excel..");
              break;
            }
            case DuplicationToolEntitiyType.Equipment: {
              columnNames = Object.getOwnPropertyNames(tools.duplicateEntities.equipments[0]);
              data = tools.duplicateEntities.equipments;
              filename = `equipment-duplications-between-${migrationDetails?.sourceCompanyId}-and-${migrationDetails?.destinationCompanyId}`;
              exportToExcel(columnNames, data, filename, "Downloading Equipments Duplications As Excel..");
              break;
            }
            case DuplicationToolEntitiyType.OrgUnit: {
              columnNames = Object.getOwnPropertyNames(tools.duplicateEntities.orgUnits[0]);
              data = tools.duplicateEntities.orgUnits;
              filename = `orgunits-duplications-between-${migrationDetails?.sourceCompanyId}-and-${migrationDetails?.destinationCompanyId}`;
              exportToExcel(columnNames, data, filename, "Downloading Org Units Duplications As Excel..");
              break;
            }
          }
        }
      }
    }
  };

  const exportToExcel = (
    columnNames: string[],
    data: Equipment[] | OrgUnit[] | Employee[] | CompanyUser[] | undefined,
    filename: string,
    messageTitle: string
  ) => {
    message.loading({
      content: messageTitle,
      duration: 0,
      key: "dowloadResultsToExcel",
    });
    const template = columnNames.map(column => {
      const template = {
        type: "Header",
        property: column,
        value: column,
      };

      return template;
    });

    template.push({
      type: "Option",
      property: "RTL",
      value: "true",
    });

    const today = new Date();

    ExportData(
      data,
      template,
      `${filename}-${today.toLocaleString().split(",")[0].replaceAll(".", "-")}.xlsx`
    ).then(() => message.destroy("dowloadResultsToExcel"));
  };

  const foundDuplicatedEntities = () => {
    return (
      tools.duplicateEntities &&
      tools.duplicateEntities.employees.length > 0 &&
      tools.duplicateEntities.equipments.length > 0 &&
      tools.duplicateEntities.orgUnits.length > 0
    );
  };

  const renderCompanyUserInDestinationCheck = () => {
    if (tools.companyUsersInDestination && tools.companyUsersInDestination?.length > 0)
      return (
        <Space className="companyusers-in-destination-results-container" direction="vertical">
          <Space>
            <Alert
              className="alert-message"
              type="error"
              message={`Found ${tools.companyUsersInDestination?.length} missing CompanyUsers in Destination`}
            />
            <Button
              icon={<DownloadOutlined />}
              onClick={exportDuplicationsToExcel(MigrationTool.CompanyUserInDestinationCompanyCheck)}
            />
          </Space>
          {tools.companyUsersInDestination?.map(companyUser => (
            <Tag
              color={companyUser.isActive ? "default" : "#dbdbdb"}
              key={companyUser.id}
            >{`${companyUser.displayName} - ${companyUser.id}`}</Tag>
          ))}
        </Space>
      );
    return <Alert type="success" message="All Uses Exists in Destination Company" />;
  };

  const renderDuplicatedEntities = () => {
    if (foundDuplicatedEntities()) {
      return (
        <div>
          <Space className="duplications-results-container" direction="vertical">
            <Space>
              <Alert
                className="alert-message"
                type="error"
                message={`Found ${tools.duplicateEntities?.employees.length} Duplicated Employees `}
              />
              <Button
                icon={<DownloadOutlined />}
                onClick={exportDuplicationsToExcel(
                  MigrationTool.DuplicateEntities,
                  DuplicationToolEntitiyType.Employee
                )}
              />
            </Space>
            {tools.duplicateEntities?.employees.map(employee => (
              <Tag
                color={employee.isActive ? "default" : "#dbdbdb"}
                key={employee.id}
              >{`${employee.displayName} - ${employee.id}`}</Tag>
            ))}
          </Space>
          <Space className="duplications-results-container" direction="vertical">
            <Space>
              <Alert
                className="alert-message"
                type="error"
                message={`Found ${tools.duplicateEntities?.equipments.length} Duplicated Equipments `}
              />
              <Button
                icon={<DownloadOutlined />}
                onClick={exportDuplicationsToExcel(
                  MigrationTool.DuplicateEntities,
                  DuplicationToolEntitiyType.Equipment
                )}
              />
            </Space>
            {tools.duplicateEntities?.equipments.map(equipment => (
              <Tag
                color={equipment.isActive ? "default" : "#dbdbdb"}
                key={equipment.id}
              >{`${equipment.name} - ${equipment.id}`}</Tag>
            ))}
          </Space>
          <Space className="duplications-results-container" direction="vertical">
            <Space>
              <Alert
                className="alert-message"
                type="error"
                message={`Found ${tools.duplicateEntities?.orgUnits.length} Duplicated OrgUnits `}
              />
              <Button
                icon={<DownloadOutlined />}
                onClick={exportDuplicationsToExcel(MigrationTool.DuplicateEntities, DuplicationToolEntitiyType.OrgUnit)}
              />
            </Space>
            {tools.duplicateEntities?.orgUnits.map(orgUnit => (
              <Tag
                color={orgUnit.isActive ? "default" : "#dbdbdb"}
                key={orgUnit.id}
              >{`${orgUnit.name} - ${orgUnit.id}`}</Tag>
            ))}
          </Space>
        </div>
      );
    }
    return <Alert type="success" message="No Duplicated entities found" />;
  };

  const openHangfireLink: (jobId: number | undefined) => void = jobId => {
    window.open(`${process.env.REACT_APP_OLD_PAS_URL}/hangfire/jobs/details/${jobId}`, "_blank");
  };

  const renderBlobMigration = () => {
    return (
      <Space direction="horizontal">
        <Alert type="info" message="Blob Migration is in Progress, Result will be emailed to you" />
        {tools?.blobMigration !== undefined && (
          <Button
            icon={<LinkOutlined />}
            onClick={() => {
              openHangfireLink(tools.blobMigration);
            }}
          />
        )}
      </Space>
    );
  };

  const renderToolType = () => {
    switch (selectedTool) {
      case MigrationTool.CompanyUserInDestinationCompanyCheck:
        return tools?.companyUsersInDestination && renderCompanyUserInDestinationCheck();
      case MigrationTool.DuplicateEntities:
        return tools?.duplicateEntities && renderDuplicatedEntities();
      case MigrationTool.BlobMigration:
        return tools?.blobMigration && renderBlobMigration();
    }
  };

  const renderToolResults = () => {
    return (
      <Space className="tools-results-container" direction="vertical">
        {renderToolType()}
      </Space>
    );
  };

  return (
    <Space direction="vertical">
      <Space className="tools-buttons-container" wrap>
        {allowedMigrationTools.includes(MigrationTool.CompanyUserInDestinationCompanyCheck) && (
          <Button onClick={() => runCompanyUserInDestinationCheck()}>CompanyUser In Destination Check</Button>
        )}
        {allowedMigrationTools.includes(MigrationTool.DuplicateEntities) && (
          <Button onClick={() => runDuplicateEntities()}>Entities Duplications</Button>
        )}
        {allowedMigrationTools.includes(MigrationTool.BlobMigration) && (
          <Button onClick={() => runBlobMigrationTool()}>Blob Migration</Button>
        )}
      </Space>
      {toolRunFinished && renderToolResults()}
    </Space>
  );
}
