import React, { useCallback, useState } from "react";

export enum ExitFormManagmentStatus {
  ExitFromWeb = "ExitEventFromWeb",
  ExitFromMobile = "ScreenExit",
  NoExitFromMobile = "ScreenNoExit",
}

export enum EmbeddedPASProMode {
  LandingPage = "LandingPage",
  FormManagment = "FormManagment",
}

export enum MobileEvent {
  LogoutFromWeb = "LogoutFromWeb",
  LogoutFromMobile = "LogoutFromMobile",
  ExitFromWeb = "ExitEventFromWeb",
  ExitFromMobile = "ScreenExit",
  TokenExpired = "TokenExpired",
  FormAnsweringFinished = "FormAnsweringFinished",
  WindowOpenedEventFromWeb = "WindowOpenedEventFromWeb",
  JWTChangedFromWeb = "JWTChangedFromWeb",
  LandingPageOpenedFromWeb = "LandingPageOpenedFromWeb",
  OpenAppointmentFromWeb = "openAppointmentFromWeb",
  SingleViewBackToSearchEvent = "singleViewBackToSearchEvent",
  CallNfcReader = "CallNfcReader",
  CallBarcodeScanner = "CallBarcodeScanner",
  ScannerValueFromMobile = "ScannerValueFromMobile",
}

export type UseFormManagmentEventsReturn = {
  formManagmentModalRef: React.RefObject<HTMLDivElement>;
  setFormManagmentRef: (ref: React.RefObject<HTMLDivElement>) => void;
  fireMobileEvent: (event: MobileEvent, detail: Record<string, string>) => void;
  addMobileEventListener: (event: MobileEvent, listener: EventListener) => void;
  removeMobileEventListener: (event: MobileEvent, listener: EventListener) => void;
};

export type FormManagmentModalContextType = {
  formManagmentModalRef: React.RefObject<HTMLDivElement>;
  setFormManagmentRef: (ref: React.RefObject<HTMLDivElement>) => void;
};

export const FormManagmentModalContext = React.createContext<FormManagmentModalContextType | undefined>(undefined);
export const formManagmentRef = React.createRef<HTMLDivElement>();

const useFormManagmentEvents = (): UseFormManagmentEventsReturn => {
  const [formManagmentModalRef, setFormManagmentModalRef] = useState<React.RefObject<HTMLDivElement>>(formManagmentRef);

  const setFormManagmentRef: (ref: React.RefObject<HTMLDivElement>) => void = ref => {
    setFormManagmentModalRef(ref);
  };

  const fireMobileEvent = useCallback(
    (event: MobileEvent, detail: Record<string, string>) => {
      formManagmentModalRef?.current?.dispatchEvent(new CustomEvent(event, { detail: detail }));
      window.postMessage(event, "*");
    },

    [formManagmentModalRef.current]
  );

  const addMobileEventListener: (event: MobileEvent, listener: EventListener) => void = useCallback(
    (event, listener) => {
      formManagmentModalRef?.current?.addEventListener(event, listener);
    },
    [formManagmentModalRef.current]
  );

  const removeMobileEventListener: (event: MobileEvent, listener: EventListener) => void = useCallback(
    (event, listener) => {
      formManagmentModalRef?.current?.removeEventListener(event, listener);
    },
    [formManagmentModalRef.current]
  );

  return {
    formManagmentModalRef,
    setFormManagmentRef,
    fireMobileEvent,
    addMobileEventListener,
    removeMobileEventListener,
  };
};

export default useFormManagmentEvents;
