import "../MobileCardTemplate.less";

import { unwrapResult } from "@reduxjs/toolkit";

import { employeeAccountableRoleField } from "../../../../../pages/pageConfig/category/employee/employeeConfigPageData";
import { useAppDispatch } from "../../../../../store/store";
import { FieldType, MobileCardTemplateTProps } from "../../../../../types/page";
import { PrivilegeData } from "../../../../../types/utility";
import EditableInputMobileFirst from "../../../../EditableInput/EditableInputMobileFirst";
import { useMobileSingleViewContext } from "../../../context/useMobileSingleViewContext";
import { useMobileSingleViewTabContext } from "../../../context/useMobileSingleViewTabContext";
import AccountableMobileCardTemplate from "./AccountableMobileCardTemplate";

const EmployeeAccountableMobileCardTemplate: React.FC<MobileCardTemplateTProps<PrivilegeData>> = ({ entity }) => {
  const { setIsTableDataLoading } = useMobileSingleViewTabContext();
  const { setErrors } = useMobileSingleViewContext();

  const dispatch = useAppDispatch();

  const roleSelectConfig = employeeAccountableRoleField;

  const handleColumnDropdownOnChange = async (newValue: string | number | boolean | null) => {
    try {
      if (roleSelectConfig.cellDropdownOnChange) {
        setIsTableDataLoading(true);
        const actionResponse = await dispatch(roleSelectConfig.cellDropdownOnChange(entity, newValue));
        await unwrapResult(actionResponse);
        setIsTableDataLoading(false);
      }
    } catch (customError) {
      setErrors(customError as Record<string, string[]>);
      setIsTableDataLoading(false);
    }
  };

  return (
    <AccountableMobileCardTemplate entity={entity}>
      <EditableInputMobileFirst
        className="action-select"
        key={String(roleSelectConfig.id)}
        isEditMode={true}
        id={String(roleSelectConfig.id)}
        type={roleSelectConfig.cellType?.(entity) as FieldType}
        optionSelector={roleSelectConfig.optionsSelector}
        globalEdit={true}
        onChange={handleColumnDropdownOnChange}
        fieldValue={entity.role}
        options={roleSelectConfig.cellDropdownOptions?.(entity) || []}
        selector={roleSelectConfig.cellSearchSelector!}
        prefixIcon={roleSelectConfig.cellPrefixIcon}
      />
    </AccountableMobileCardTemplate>
  );
};

export default EmployeeAccountableMobileCardTemplate;
