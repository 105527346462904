import { CloseCircleOutlined } from "@ant-design/icons";

import { Button } from "antd";
import { nanoid } from "nanoid";

import {
  AddNewAction,
  ColumnManagementAction,
  ExportAction,
  FormSettings,
  GetFiltersUrl,
  QuickCreateAppointment,
  ResetColumnsWidthAction,
  SetActionStatus,
  SetFiltersToDefault,
} from "../../../../actions/menuActions";
import { ExportSelectedSurveysAction } from "../../../../actions/surveyActions";
import { HandleTaskModal } from "../../../../components/HBComponents/HandleTaskModal/HandleTaskModal";
import AddCommentSection from "../../../../components/SingleViewCardMobileFirst/components/addEntityTemplates/AddCommentSection";
import AddEmployeeSection from "../../../../components/SingleViewCardMobileFirst/components/addEntityTemplates/AddEmployeeSection";
import AddFileSection from "../../../../components/SingleViewCardMobileFirst/components/addEntityTemplates/AddFileSection";
import AccountableMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/accountable/AccountableMobileCardTemplate";
import HistoryLogMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/HistoryLogMobileCardTemplate";
import LinkedActionsMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/LinkedActionsMobileCardTemplate";
import TaskFilesMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/TaskFilesMobileCardTemplate";
import { ReactComponent as PageIcon } from "../../../../media/sidebar/tasks.svg";
import { pageSelectors, tasksSelectors } from "../../../../selectors";
import { setFileInfoToOpen } from "../../../../store/slices/common";
import { fetchIssueTypes } from "../../../../store/slices/issueType";
import { deleteMultipleEntityPrivileges, getEntityPrivileges } from "../../../../store/slices/privileges";
import {
  addTask,
  addTaskComment,
  clearTasksError,
  createNewAccountableEntryTemplate,
  createTasksTemplate,
  fetchActionFiles,
  fetchLinkedRelatedActions,
  fetchPaginatedActions,
  fetchSingleTask,
  fetchTaskHistoryLog,
  fetchTasksAdditionalData,
  removeTaskFiles,
  resetCurrentPage,
  resetSearchResults,
  searchTasks,
  updateTask,
  uploadTaskFile,
} from "../../../../store/slices/tasks";
import store, { RootState } from "../../../../store/store";
import { BaseFile, TaskFile } from "../../../../types/files";
import { CategoryPage, SectionType } from "../../../../types/page";
import { Task, TasksPage } from "../../../../types/tasks";
import { OpenClosedStatus, PrivilegeData, PrivilegedEntityType, TableAddButtonType } from "../../../../types/utility";
import { filesTableColumns } from "../common";
import { localizeText, pickSpecificColumns, prepareDynamicColumns, removeColumnFilters } from "../utilities";
import {
  closeDateField,
  createdDateField,
  dueDateField,
  employeeIdField,
  equipmentIdField,
  idField,
  issueTypeIdField,
  locationIdField,
  primaryFields,
  responsibleUserIdField,
  secondaryFields,
  startDateField,
  statusField,
  titleField,
} from "./fields";
import {
  accountableTabColumns,
  actionsAccountableActionField,
  actionsAccountableExternalIdField,
  actionsAccountableNameField,
  tasksHistoryLogTableColumns,
  tasksLinkedActionsColumns,
  tasksTableColumns,
} from "./tasksConfigPageData";

const tasksConfigPage = (): CategoryPage<TasksPage> => ({
  id: "actions",
  title: "TitleActions",
  entityEndpoint: "/actions",
  PageIcon: PageIcon,
  hasGroupView: false,
  hasListView: true,
  intializeDataActions: [fetchTasksAdditionalData, fetchIssueTypes],
  isLoading: pageSelectors.isTasksPageLoading,
  lastUpdatedSelector: tasksSelectors.tasksLastUpdatedText,
  primarySingleEntitySelector: tasksSelectors.single,
  primaryErrorSelector: tasksSelectors.error,
  customPropertiesSelector: tasksSelectors.tasksCustomProperties,
  fetchSingle: fetchSingleTask,
  updateEntity: updateTask,
  clearError: clearTasksError,
  createNewEntityTemplate: createTasksTemplate,
  createNewEntity: addTask,
  addButton: {
    label: "AddAction",
    action: () => null,
    isMenu: true,
    addButtonActions: [QuickCreateAppointment, AddNewAction],
  },
  listViewActions: [
    ColumnManagementAction,
    ResetColumnsWidthAction,
    ExportAction,
    GetFiltersUrl,
    SetActionStatus,
    SetFiltersToDefault,
    FormSettings,
    ExportSelectedSurveysAction,
  ],
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.title };
  },

  summaryCard: {
    canEdit: () => true,
    remotelyUpdatedFieldIDs: ["parentId"],
    cardInformationalMessasge: e => {
      return e.parentId === null ? "This is the root organization unit, and it can not be edited" : "";
    },
    globalEditForbidden: e => !!(e.parentId === null),
    CardIcon: PageIcon,

    mobileSingleView: {
      summary: {
        icon: PageIcon,
        title: titleField,
        id: idField,
        status: statusField,
        mainContextRelation: dueDateField,
        date: responsibleUserIdField,
      },
      sections: [
        {
          type: SectionType.ContextSection,
          fields: [issueTypeIdField, employeeIdField, equipmentIdField, locationIdField],
        },
        {
          type: SectionType.DatesSection,
          fields: [createdDateField, startDateField, closeDateField],
        },
      ],
    },
    primaryFields: primaryFields,
    secondaryFields: secondaryFields,
    actionComponent: entity => (
      <HandleTaskModal
        status={entity.status}
        isOpen={entity.closeDate || entity.status === OpenClosedStatus.Canceled ? false : true}
        isFormBased={entity.isFormBased}
        taskId={entity.id}
        pdfInfo={entity.pdfInfo}
        isButton={true}
        title={entity.title}
      />
    ),
    customEditButtons: (entity, onSubmitChanges, isNewEntity) => [
      <>
        {isNewEntity && !entity.followUpReviewId ? (
          <Button
            key={nanoid()}
            className="complete-action"
            onClick={() => onSubmitChanges({ additionTrigger: true })}
            type="primary"
          >
            <CloseCircleOutlined />
            {localizeText("CompleteAction")}
          </Button>
        ) : null}
      </>,
    ],
  },
  listView: {
    table: {
      type: "paginatedTable",
      tableSelector: tasksSelectors.all,
      searchResultsSelector: tasksSelectors.searchResults,
      possibleResultsSelector: tasksSelectors.possibleResults,
      fetchPaginatedData: fetchPaginatedActions,
      resetCurrentPage: resetCurrentPage,
      searchPaginatedData: searchTasks,
      resetSearchResult: resetSearchResults,
      columns: tasksTableColumns,
      rowSelection: true,
      defaultColumnKeys: [
        "id",
        "title",
        "createDate",
        "createdByUserName",
        "responsibleUserName",
        "status",
        "actions",
        "followUpReviewName",
        "projectName",
        "description",
      ],
      disableNavigation: false,
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumns<Task>(props, data),
  },
  tabsPanel: [
    {
      key: "accountable",
      label: "Accountable",
      tabSelector: tasksSelectors.taskAccountableRelations,
      tabDataThunk: id => getEntityPrivileges({ id: +id, entityType: PrivilegedEntityType.HbTask }),
      columns: removeColumnFilters(accountableTabColumns) as [],
      rowSelection: true,
      type: "table",
      addButton: {
        action: createNewAccountableEntryTemplate,
        label: "AddEmployee",
        type: TableAddButtonType.AddInline,
        mobileAddButtonTemplate: AddEmployeeSection,
        actionField: actionsAccountableActionField,
        addEntityFields: [actionsAccountableNameField, actionsAccountableExternalIdField],
      },
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      dropdownButtonActions: [
        {
          label: "DeleteBulk",
          action: entities =>
            deleteMultipleEntityPrivileges({
              entity: entities as PrivilegeData[],
              entityType: PrivilegedEntityType.HbTask,
              id: null,
            }),
          type: "bulk",
          changesConfirmation: {
            body: "ConfirmEntitiesRemoval",
            okText: "Remove",
            cancelText: "Keep",
            addValueToBody: false,
            addValueToOkText: false,
          },
        },
      ],
      mobileCardTemplate: AccountableMobileCardTemplate,
    },
    {
      addButton: {
        label: localizeText("AddComment"),
        type: TableAddButtonType.AddComment,
        addCommentAction: addTaskComment,
        mobileAddButtonTemplate: AddCommentSection,
      },
      key: "historyLog",
      label: "HistoryLog",
      tabDataThunk: id => fetchTaskHistoryLog({ taskId: id }),
      tabSelector: tasksSelectors.taskHistoryLog,
      columns: removeColumnFilters(tasksHistoryLogTableColumns),
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: HistoryLogMobileCardTemplate,
    },
    {
      key: "linkedActions",
      label: "LinkedActions",
      tabDataThunk: id => fetchLinkedRelatedActions({ taskId: id }),
      tabSelector: tasksSelectors.taskLinkedActions,
      columns: removeColumnFilters(tasksLinkedActionsColumns),
      rowSelection: true,
      type: "table",
      addButton: {
        label: "AddSubTask",
        type: TableAddButtonType.AddSubTask,
      },
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: LinkedActionsMobileCardTemplate,
    },
    {
      key: "files",
      label: "Files",
      tabSelector: tasksSelectors.taskFilesSelector,
      tabDataThunk: id => fetchActionFiles({ taskId: id }),
      columns: removeColumnFilters(filesTableColumns("actions")) as [],
      rowSelection: true,
      type: "table",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<TaskFile>(props, data)),
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      onRowClick: fileInfo => store.dispatch(setFileInfoToOpen(fileInfo as BaseFile)),
      addButton: {
        label: "AddFile",
        type: TableAddButtonType.UploadTaskFile,
        uploadFileAction: uploadTaskFile,
        mobileAddButtonTemplate: AddFileSection,
      },
      dropdownButtonActions: [
        {
          label: localizeText("DeleteBulk"),
          action: entities => removeTaskFiles(entities.map(x => x.id) as number[]),
          type: "bulk",
          changesConfirmation: {
            body: "Removing the files could not be reversible, Are you should you wish to remove the files?",
            okText: "Yes I wish to remove the files",
            cancelText: "Keep the files",
          },
        },
      ],
      mobileCardTemplate: TaskFilesMobileCardTemplate,
    },
  ],
  listSearch: {
    columns: pickSpecificColumns<Task>(tasksTableColumns, "id", "title"),
    keys: ["id", "title"],
  },
  defaultFilters: [
    {
      filterType: "equals",
      key: "status",
      label: "FieldStatus",
      textValue: "Opened",
      value: OpenClosedStatus.Opened,
    },
    {
      filterType: "equals",
      key: "responsibleUserName",
      label: "FieldResponsible",
      userBasedTextValue: (state: RootState) => state.user.settings.displayName,
      userBasedValue: (state: RootState) => state.user.settings.displayName,
    },
  ],
});

export default tasksConfigPage;
