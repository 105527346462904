import "./CardList.less";

import { Space } from "antd";
import { useMobileSingleViewTabContext } from "../context/useMobileSingleViewTabContext";
import MobileCardAddEntitySection from "./MobileCardAddEntitySection";

const CardList: React.FC = () => {
  const { tabConfig, tabData } = useMobileSingleViewTabContext();

  return (
    <>
      <MobileCardAddEntitySection />
      <Space direction="vertical" size={10} className="mobile-card-list">
        {tabData.map((entity, index) => {
          if (tabConfig.mobileCardTemplate) {
            const Template = tabConfig.mobileCardTemplate;
            return <Template key={(entity?.id as string) || index} entity={entity} />;
          }

          return null;
        })}
      </Space>
    </>
  );
};

export default CardList;
