import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import catchUnauthorizeMiddleware from "./middlewares";
import certification from "./slices/certification";
import common from "./slices/common";
import companyMigration from "./slices/companyMigration";
import cubeDimensions from "./slices/cubeDimensions";
import customProperty from "./slices/customProperty";
import dashboard from "./slices/dashboard";
import employee from "./slices/employee";
import equipment from "./slices/equipment";
import equipmentType from "./slices/equipmentType";
import filter from "./slices/filter";
import inspection from "./slices/inspection";
import inspectionType from "./slices/inspectionType";
import issueType from "./slices/issueType";
import landingPage from "./slices/landingPage";
import location from "./slices/location";
import locationType from "./slices/locationType";
import map from "./slices/map";
import orgUnit from "./slices/orgUnit";
import recurrentActions from "./slices/recurrentActions";
import survey from "./slices/survey";
import actions from "./slices/tasks";
import todoList from "./slices/todolist";
import training from "./slices/training";
import user from "./slices/user";
import webhooks from "./slices/webhooks";

export const combinedReducer = combineReducers({
  equipment,
  employee,
  training,
  certification,
  dashboard,
  location,
  orgUnit,
  survey,
  actions,
  user,
  filter,
  inspection,
  cubeDimensions,
  companyMigration,
  common,
  map,
  equipmentType,
  inspectionType,
  locationType,
  customProperty,
  issueType,
  landingPage,
  todoList,
  recurrentActions,
  webhooks,
});
export type RootState = ReturnType<typeof combinedReducer>;

const store = configureStore({
  reducer: combinedReducer,
  // Note: the code below may be used if we want to handle actions at a global level instead of local slice level.
  // reducer: rootReducer as typeof combinedReducer,

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(catchUnauthorizeMiddleware), // Add the middleware to the chain
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}
// type RootState = ReturnType<typeof store.getState>;
// export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export type { AppDispatch };
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
