import "../MobileCardTemplate.less";

import { useTranslation } from "react-i18next";

import { PrivilegeData } from "../../../../../types/utility";
import CardLabel from "../../core/CardLabel/CardLabel";
import CardLabelWithIcon, { ICON_NAMES } from "../../core/CardLabelWithIcon/CardLabelWithIcon";
import CardTitle from "../../core/CardTitle/CardTitle";
import CardWithSelect from "../../core/CardWithSelect/CardWithSelect";
import ContainerWithDivider from "../../core/ContainerWithDivider/ContainerWithDivider";
import RowContainer from "../../core/RowContainer/RowContainer";
import StatusMark from "../../core/StatusMark/StatusMark";

type TProps = {
  entity: PrivilegeData;
};

const AccountableMobileCardTemplate: React.FC<TProps> = ({ entity, children }) => {
  const { t } = useTranslation();

  return (
    <CardWithSelect entity={entity}>
      <ContainerWithDivider>
        {entity.name && <CardTitle title={entity.name} category="employee" id={entity.userId!} />}
        {entity.role && <CardLabelWithIcon label={entity.role} iconName={ICON_NAMES.USER} />}
      </ContainerWithDivider>
      <RowContainer>
        {entity.status && <StatusMark statusLabel={entity.status || ""} />}
        <CardLabel label={`${t("External")} - ${entity.isExternal ? t("Yes") : t("No")}`} />
      </RowContainer>
      {children}
    </CardWithSelect>
  );
};

export default AccountableMobileCardTemplate;
