import { InboxOutlined } from "@ant-design/icons";

import { Space } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { RcFile } from "antd/lib/upload/interface";
import { useTranslation } from "react-i18next";

import { MobileAddEntitySectionTProps } from "../../../../types/page";
import { hasSpecialChars } from "../../../HBComponents/UploadModal/HBUploadModal";
import { useMobileSingleViewContext } from "../../context/useMobileSingleViewContext";

const AddFileSection: React.FC<MobileAddEntitySectionTProps> = ({ entity, onFiledValueChange }) => {
  const { t } = useTranslation();
  const { setErrors } = useMobileSingleViewContext();

  if (!entity) return null;

  const file = entity?.file as RcFile;

  const onFileSelected = (file: RcFile): boolean | Promise<void | File | Blob> => {
    if (hasSpecialChars(file.name)) {
      setErrors && setErrors({ title: [t("Error")], message: [t("ErrorSpecialCharactersInFileName")] });
      return false;
    }
    onFiledValueChange({ file });

    return false;
  };

  return (
    <Space direction="vertical" size={9} className="buttons-space">
      <Dragger
        accept=".xlsx, .csv, .png, .jpg, .jpeg, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf,application/vnd.ms-excel"
        maxCount={1}
        beforeUpload={onFileSelected}
        itemRender={() => <div>{file?.name}</div>}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t("ClickOrDropFile")}</p>
      </Dragger>
    </Space>
  );
};

export default AddFileSection;
