/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { CustomError } from "../../pages/pageConfig/category/utilities";
import { UserRole, UserState } from "../../types/user";
import {
  PrivilegeData,
  PrivilegedEntityType,
  PrivilegeRequestChangeData,
  PrivilegeRequestData,
  PrivilegeRequestWithEntityData,
  Role,
} from "../../types/utility";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

/**
 * Defines root entity state with support for PrivilegedData accountable tab
 */
export interface PrivilegedEntityState<TEntity> {
  singleData: TEntity | null | undefined;
  subData: {
    accountable: PrivilegeData[];
    /**
     * Describes default role for any newly created entity
     */
    accountableRole?: Role;
  };
}

/**
 *  Defines information for any entity privilege types that we should support
 */
interface PrivilegedEntityInfo {
  controller: string;
  storeKey: "location" | "equipment" | "orgUnit" | "employee" | "certification" | "training" | "actions";
}

/***
 * Contains list of all entity types and their privilege configuration data
 */
const privilegeTypeInfoMap = new Map<PrivilegedEntityType, PrivilegedEntityInfo>([
  [PrivilegedEntityType.Location, { controller: "EmployeeLocation", storeKey: "location" }],
  [PrivilegedEntityType.Equipment, { controller: "EmployeeEquipment", storeKey: "equipment" }],
  [PrivilegedEntityType.OrgUnit, { controller: "OrgUnitEmployee", storeKey: "orgUnit" }],
  [PrivilegedEntityType.Employee, { controller: "EmployeeParticipant", storeKey: "employee" }],
  [PrivilegedEntityType.Certificate, { controller: "EmployeeCertificateParticipant", storeKey: "certification" }],
  [PrivilegedEntityType.Training, { controller: "EmployeeTraining", storeKey: "training" }],
  [PrivilegedEntityType.HbTask, { controller: "TaskViewer", storeKey: "actions" }],
]);

/**
 * Returns the correct endpoint for the given request
 */
const getEndpoint = (req: PrivilegeRequestData, getState: () => RootState): string => {
  const info = privilegeTypeInfoMap.get(req.entityType);
  if (!req.id) {
    const state = getState();
    const entity = state[info!.storeKey];
    req.id = entity.singleData!.id;
  }

  return `/${info!.controller}/${req.id}`;
};

/**
 * Checks if the base entity is loaded before we can execute this request.
 * @param req information about the privilege request
 * @param getState - getState function to get the state from the store
 */
const isEntityLoadingFinished = (req: PrivilegeRequestData, getState: () => RootState): boolean => {
  const state = getState();
  const info = privilegeTypeInfoMap.get(req.entityType);
  const entity = state[info!.storeKey];
  return !entity.isLoading;
};

/**
 * Creates new empty row in the accountable tab for a specific entity
 * @param state
 * @param action
 */
export const createNewPrivilegeTemplate = (state: PrivilegedEntityState<any>) => {
  state.subData.accountable.unshift({
    id: nanoid(),
    userId: null,
    orgUnitId: null,
    name: "",
    status: null,
    role: state?.subData?.accountableRole || Role.Recipient,
    staging: true,
    isExternal: null,
    externalId: null,
    oldRole: undefined,
    isAssignedToOUBranch: false,
  });
};

/**
 * Used to fill any new privilege row with data from the selected entity (OrgUnit or Employee)
 * @param state reference to the privilege entity state
 * @param action action containing data for the operation
 */
export const fillNewPrivilegeEntryTemplate = (
  state: PrivilegedEntityState<any>,
  action: PayloadAction<{ row: PrivilegeData; targetEntity: Record<string, unknown> }>
) => {
  const stagedRecordIndex = state.subData.accountable.findIndex(r => r.id === action.payload.row.id);
  const castTargetEntity = action.payload.targetEntity as PrivilegeData;
  state.subData.accountable[stagedRecordIndex] = {
    ...state.subData.accountable[stagedRecordIndex],
    name: castTargetEntity.name,
    externalId: castTargetEntity.externalId ?? null,
    userId: castTargetEntity.userId ?? null,
    orgUnitId: castTargetEntity.orgUnitId ?? null,
    status: castTargetEntity.status,
    isExternal: typeof castTargetEntity.isExternal === "undefined" ? null : castTargetEntity.isExternal,
    isAssignedToOUBranch: castTargetEntity.isAssignedToOUBranch ?? false,
  };
};

/**
 * Removes previously created privilege line that was not yet saved
 * @param state reference to the privilege entity state
 * @param action action containing data for the operation
 */
export const deleteNewPrivilegeEntryTemplate = (
  state: PrivilegedEntityState<any>,
  action: PayloadAction<PrivilegeData>
) => {
  state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
};

export const updateNewPrivilegeEntry = (
  state: PrivilegedEntityState<any>,
  action: PayloadAction<{ entity: PrivilegeData; newValue: any; property: keyof PrivilegeData }>
) => {
  const updatedEntityIndex = state.subData.accountable.findIndex(r => r.id === action.payload.entity.id);
  if (updatedEntityIndex !== -1) {
    (state.subData.accountable[updatedEntityIndex] as any)[action.payload.property] = action.payload.newValue;
  }
};

// Thunks

/**
 * Gets all privileges for the given entity type
 */
export const getEntityPrivileges = createAsyncThunk<PrivilegeData[], PrivilegeRequestData, { state: RootState }>(
  "@@PRIVILEGES/FETCH_ALL_PRIVILEGES",
  async (req, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<PrivilegeData[]>(getEndpoint(req, getState), hbApiOptions(user.jwt));
    return response.data.map(e => {
      return {
        ...e,
        id: nanoid(),
      };
    });
  }
);

/**
 * Adds new privilege to a specific privileged entity
 */
export const addEntityPrivilege = createAsyncThunk<
  PrivilegeData,
  PrivilegeRequestWithEntityData<PrivilegeData>,
  { state: RootState }
>(
  "@@PRIVILEGES/ADD_PRIVILEGE",
  async (req, { getState }) => {
    const { user } = getState();
    const body = {
      role: req.entity.role,
      orgUnitId: req.entity.orgUnitId,
      userId: req.entity.userId,
      isAssignedToOUBranch: req.entity.isAssignedToOUBranch,
    };

    try {
      const result = await hbApi.post<PrivilegeData>(getEndpoint(req, getState), body, hbApiOptions(user.jwt));
      return {
        ...result.data,
        id: req.entity.id,
      };
    } catch (e) {
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (req, { getState }) => isEntityLoadingFinished(req, getState),
  }
);

/**
 * Changes the role of any selected privilege record
 */
export const updateEntityPrivilegeRole = createAsyncThunk<
  PrivilegeData,
  PrivilegeRequestChangeData<any>,
  { state: RootState }
>(
  "@@PRIVILEGES/CHANGE_PRIVILEGE_ROLE",
  async (req, { getState }) => {
    const { user } = getState();
    const entity = req.entity;
    const body = {
      oldRole: entity.role,
      userId: entity.userId,
      orgUnitId: entity.orgUnitId,
      role: req.role,
    };
    await hbApi.put<PrivilegeData>(getEndpoint(req, getState), body, hbApiOptions(user.jwt));
    return { ...entity, role: req.role, oldRole: entity.role };
  },
  {
    condition: (req, { getState }) => isEntityLoadingFinished(req, getState),
  }
);

/**
 * Changes the OUlvl of any selected privilege record
 */
export const updateEntityPrivilegeOULvl = createAsyncThunk<
  PrivilegeData,
  PrivilegeRequestChangeData<any>,
  { state: RootState }
>(
  "@@PRIVILEGES/CHANGE_PRIVILEGE_OULVL",
  async (req, { getState }) => {
    const { user } = getState();
    const entity = req.entity;

    const body = {
      oldRole: entity.role,
      userId: entity.userId,
      orgUnitId: entity.orgUnitId,
      isAssignedToOUBranch: Boolean(req.isAssignedToOUBranch),
      role: entity.role,
    };
    await hbApi.put<PrivilegeData>(getEndpoint(req, getState), body, hbApiOptions(user.jwt));
    return { ...entity, isAssignedToOUBranch: body.isAssignedToOUBranch };
  },
  {
    condition: (req, { getState }) => isEntityLoadingFinished(req, getState),
  }
);

/**
 * Deletes a privilege from the privileged entity
 */
export const deleteEntityPrivilege = createAsyncThunk<
  PrivilegeData,
  PrivilegeRequestWithEntityData<PrivilegeData>,
  { state: RootState }
>(
  "@@PRIVILEGES/DELETE_PRIVILEGE",
  async (req, { getState }) => {
    const { user } = getState();
    await hbApi.delete(getEndpoint(req, getState), hbApiOptions(user.jwt, req.entity));
    return req.entity;
  },
  {
    condition: (req, { getState }) => isEntityLoadingFinished(req, getState),
  }
);

/**
 * Deletes multiple privileges from a single Privileged entity
 */
export const deleteMultipleEntityPrivileges = createAsyncThunk<
  PrivilegeData[],
  PrivilegeRequestWithEntityData<PrivilegeData[]>,
  { state: RootState }
>(
  "@@PRIVILEGES/DELETE_BULK_PRIVILEGES",
  async (req, { getState }) => {
    const { user } = getState();
    await hbApi.delete(`${getEndpoint(req, getState)}/bulk`, hbApiOptions(user.jwt, req.entity));
    return req.entity;
  },
  {
    condition: (req, { getState }) => isEntityLoadingFinished(req, getState),
  }
);

/***
 * Validates that the current user is SU or Admin
 * @param user
 */
export const adminOrSuUserOnly = (user: UserState): boolean => {
  return user && user.settings && (user.settings.role == UserRole.SU || user.settings.role == UserRole.Admin);
};

export const updateAccountableEntity = (
  state: { subData: { accountable: Array<{ id?: string; isAssignedToOUBranch: boolean }> }; error: any },
  action: { payload: { id?: string; isAssignedToOUBranch: boolean } }
) => {
  const updatedEntityIndex = state.subData.accountable.findIndex(r => r?.id === action.payload?.id);

  if (updatedEntityIndex !== -1) {
    state.subData.accountable[updatedEntityIndex].isAssignedToOUBranch = action.payload.isAssignedToOUBranch;
    state.error = null;
  }
};
