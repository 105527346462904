import { Typography } from "antd";
import "./CardLabel.less";

type TProps = {
  label: string | number;
};

const CardLabel: React.FC<TProps> = ({ label }) => {
  const { Text } = Typography;

  if (!label) return null;

  return (
    <Text className="card-label" ellipsis>
      {label}
    </Text>
  );
};

export default CardLabel;
