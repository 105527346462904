import { Button, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { userSelectors } from "../../../selectors";
import { RootState, useAppDispatch } from "../../../store/store";
import { TableAddButtonType } from "../../../types/utility";
import { NEW_MOBILE_PATH } from "../../../utils/functions";
import { useMobileSingleViewContext } from "../context/useMobileSingleViewContext";
import { useMobileSingleViewTabContext } from "../context/useMobileSingleViewTabContext";
import MobileCardAddEntitySectionOptions from "./MobileCardAddEntitySectionOptions";
import MobileCardBulkActions from "./MobileCardBulkActions";

const MobileCardAddEntitySection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data, openNextPage } = useMobileSingleViewContext();
  const { tabConfig } = useMobileSingleViewTabContext();
  const user = useSelector(userSelectors.getCurrentUser);

  const [isOptionsShown, setIsOptionsShown] = useState(false);

  //TODO: move all of this to configuration
  const currentAction = useSelector((state: RootState) => state.actions.singleData);

  const addButton = tabConfig.addButton;

  const addButtonVisible = addButton?.enablingFor ? addButton.enablingFor(user) : true;

  if (!addButton || !addButtonVisible) return null;

  return (
    <>
      <div className="mobile-buttons-section">
        <Button
          className={"add-button mobile-add-button"}
          disabled={addButton?.label === "AddFile"}
          onClick={() => {
            // disabled until will not find a way to check permission on mobile
            if (addButton?.label === "AddFile") {
              return null;
            }
            if (addButton && addButton.type === TableAddButtonType.AddSubTask) {
              const newActionNavigationPath = `/actions${NEW_MOBILE_PATH}?parentId=${data.id}`;
              currentAction?.closeDate
                ? Modal.confirm({
                    content: t("ParentActionIsCompletedMessage"),
                    onOk: () => openNextPage(newActionNavigationPath),
                  })
                : openNextPage(newActionNavigationPath);
              return;
            }
            setIsOptionsShown(value => !value);
            if (addButton && addButton.type === TableAddButtonType.AddInline && addButton.action) {
              return dispatch(addButton.action());
            }
          }}
        >
          {`+ ${t(addButton.label)}`}
        </Button>
        <MobileCardBulkActions />
      </div>
      {isOptionsShown && <MobileCardAddEntitySectionOptions setIsOptionsShown={setIsOptionsShown} />}
    </>
  );
};

export default MobileCardAddEntitySection;
