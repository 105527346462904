import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { Button, Form, Select, Space } from "antd";
import { Colorpicker, ColorPickerValue } from "antd-colorpicker";
import { useContext } from "react";
import { useSelector } from "react-redux";

import { updateStagedChart } from "../../../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../../../store/store";
import useLocalizeDimensions from "../../../Localization/useLocalizeDimensions";
import { ChartContext } from "../../HBChart";

const { Option, OptGroup } = Select;

type ColorRule = {
  label: string;
  color: ColorPickerValue;
};

type ColorRulesForm = {
  labelColors: ColorRule[];
};

export default function LabelColoring() {
  const [form] = Form.useForm<ColorRulesForm>();
  const dispatch = useAppDispatch();
  const stagedChart = useSelector((state: RootState) => state.dashboard.stagedChart);
  const { formatSeriesLabel, formatCategoryLabel } = useLocalizeDimensions();
  const { chart } = useContext(ChartContext);
  const series = chart?.resultSet
    ?.series(stagedChart?.pivotConfig)
    .map(item => formatSeriesLabel(chart?.resultSet, item.key, item.title));
  const categories = chart?.resultSet
    ?.categories(chart?.pivotConfig)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((c: any) => formatCategoryLabel(chart?.resultSet, c.x));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (stagedChart) {
      dispatch(updateStagedChart({ ...stagedChart, customColors: values.labelColors }));
    }
  };

  return (
    <Form
      form={form}
      name="labelColoring"
      onValuesChange={(_, allValues) => {
        onFinish(allValues);
      }}
      autoComplete="off"
      initialValues={{
        labelColors: stagedChart?.customColors,
      }}
    >
      <Space direction="vertical">
        <Form.List name="labelColors">
          {(fields, { add, remove }) => (
            <>
              {" "}
              {fields.map(field => (
                <Space key={field.key} align="baseline">
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.series !== curValues.series || prevValues.color !== curValues.color
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        label="label"
                        name={[field.name, "label"]}
                        rules={[{ required: true, message: "missing series" }]}
                      >
                        <Select style={{ width: 130 }}>
                          <OptGroup label="Series">
                            {series &&
                              series.map(item => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                          </OptGroup>
                          <OptGroup label="Categories">
                            {categories &&
                              categories.map(item => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                          </OptGroup>
                        </Select>
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label="color"
                    name={[field.name, "color"]}
                    rules={[{ required: true, message: "Missing color" }]}
                  >
                    <Colorpicker popup onColorResult={color => color.hex} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Rule
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Space>
    </Form>
  );
}
