import { Col, Form, Row } from "antd";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/store";
import { BaseField, SectionType } from "../../../types/page";
import EditableInputMobileFirst from "../../EditableInput/EditableInputMobileFirst";
import HbFormItem from "../../HBComponents/HbFormItem/HbFormItem";
import { useMobileSingleViewContext } from "../context/useMobileSingleViewContext";

export type SectionProps = {
  fields: (BaseField<Record<string, unknown>> | null | undefined)[];
  title?: SectionType;
};

const SectionComponent: React.FC<SectionProps> = ({ fields, title }) => {
  const { t } = useTranslation();
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);

  const {
    currentData,
    handleChange,
    canEditCard,
    isGlobalEditForbidden,
    renderValue,
    isEdited,
    handleSetActiveTab,
    isNewEntity,
    data,
    form,
    setIsEdited,
  } = useMobileSingleViewContext();

  const renderEditableInput = (field: BaseField<Record<string, unknown>>) => {
    const {
      id,
      selector,
      basedOnDependensiesOptions,
      value,
      valueText,
      placeholderMobileFirst,
      multilineInput,
      type,
      options,
      optionsSelector,
      Icon,
      tabSwitchAction,
      searchById,
      changesConfirmation,
      editForbidden,
      treeData,
      localizedValue,
      cleanOnChange,
    } = field;

    return (
      <EditableInputMobileFirst
        enableMobileScanners={field.enableMobileScanners}
        dataFetchAction={field.dataFetchAction}
        onCardEdited={setIsEdited}
        isEditMode={isEdited}
        currentData={currentData}
        selector={selector}
        basedOnDependensiesOptions={basedOnDependensiesOptions}
        localizedValue={localizedValue && (renderValue(localizedValue) as string | null)}
        fieldValue={renderValue(value) as string | null}
        fieldText={valueText && (renderValue(valueText) as string | null)}
        globalEdit={isEdited}
        onChange={handleChange(id, changesConfirmation, true, cleanOnChange)}
        placeholder={placeholderMobileFirst && t(placeholderMobileFirst)}
        type={type}
        multilineInput={multilineInput}
        id={id}
        options={options}
        optionSelector={optionsSelector}
        icon={Icon && currentData && <Icon value={currentData} fill={primaryColor} />}
        treeData={treeData}
        handleSetActiveTab={handleSetActiveTab}
        tabSwitch={tabSwitchAction?.action}
        isNewEntity={isNewEntity}
        editForbidden={(data && editForbidden && editForbidden(data)) || isGlobalEditForbidden}
        searchById={searchById}
        canEdit={canEditCard}
      />
    );
  };

  const renderBooleanDependentField = (field: BaseField<Record<string, unknown>>, dependencyId: string) => {
    if (form.getFieldValue(dependencyId)) {
      return (
        <Form.Item
          key={field.id}
          name={field.id}
          label={field.title && `${t(field.title)}:`}
          dependencies={[dependencyId]}
          rules={[{ required: field.required, message: t("ErrorFieldRequired") }]}
        >
          {renderEditableInput(field)}
        </Form.Item>
      );
    }
    return null;
  };

  const renderFields = (fields: BaseField<Record<string, unknown>>[], className?: string) => {
    return (
      <Row className={className} gutter={[24, 0]}>
        {fields.map((input, index) =>
          input ? (
            input.isHidden && input.isHidden(currentData!) ? null : (
              <Fragment key={index}>
                <Col
                  className="field-wrapper"
                  span={input.type === "textArea" || title === SectionType.DatesSection ? 24 : 12}
                >
                  <Row className="full-width">
                    <HbFormItem labelDots input={input} label>
                      {renderEditableInput(input)}
                    </HbFormItem>
                  </Row>
                </Col>
                {input.dependentFields && (
                  <Col className="field-wrapper" span={12}>
                    <Row>{input.dependentFields?.map(field => renderBooleanDependentField(field, input.id))}</Row>
                  </Col>
                )}
              </Fragment>
            )
          ) : null
        )}
      </Row>
    );
  };

  if (!fields.length) return null;

  if (!title) {
    return renderFields(fields as BaseField<Record<string, unknown>>[], "section");
  }

  return (
    <div className="section section-context">
      <span className="context-title">{t(title)}</span>
      {renderFields(fields as BaseField<Record<string, unknown>>[])}
    </div>
  );
};

export default SectionComponent;
