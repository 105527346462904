import dayjs from "dayjs";
import { ReactComponent as DatePickerIcon } from "../../../../media/datePicker-icon.svg";
import { issueTypeSelectors, tasksSelectors } from "../../../../selectors";
import { Employee } from "../../../../types/employee";
import { BaseField, InputTypes } from "../../../../types/page";
import { ReviewOptions, Task } from "../../../../types/tasks";
import { boolOptions, priorityOptions } from "../common";
import {
  renderTaskPriority,
  constructTableTree,
  localizeText,
  renderBoolStatus,
  renderActionStatusIcon,
} from "../utilities";

export const titleField: BaseField<Task> = {
  id: "title",
  title: "FieldTitle",
  value: value => value.title,
  type: InputTypes.INPUT,
  required: true,
  placeholder: "Enter",
  optionsSelector: () => null,
};

export const projectIdField: BaseField<Task> = {
  id: "projectId",
  placeholder: "Select",
  title: "FieldProject",
  valueText: (e: Task) => e.project,
  value: (e: Task) => e.projectId,
  type: InputTypes.AUTOCOMPLETE,
  optionsSelector: state =>
    [...new Map(state.actions.usersQuickCreateData.map(item => [item.projectId, item])).values()]
      .filter(x => x.projectId)
      .map(x => ({ id: x.projectId as number, label: x.projectName })),
  cleanOnChange: ["responsibleUserId", "followUpReviewId"],
};

export const responsibleUserIdField: BaseField<Task> = {
  id: "responsibleUserId",
  title: "FieldResponsible",
  value: (e: Task) => e.responsibleUserId,
  valueText: (e: Task) => e.responsible,
  type: InputTypes.AUTOCOMPLETE,
  optionsSelector: () => null,
  placeholder: localizeText("Select"),
  selector: state => state.employee.basicData,
  basedOnDependensiesOptions: (currentData: Task, selectedDependentData: Employee[] | null) =>
    selectedDependentData
      ?.filter(x => (currentData?.projectId ? x.projectIds?.includes(currentData?.projectId) : true))
      .map(l => ({
        id: l.id,
        label: l.displayName,
      })) || [],
};

export const statusField: BaseField<Task> = {
  id: "status",
  title: "FieldStatus",
  value: (e: Task) => e.status,
  valueText: (e: Task) => localizeText(`TaskStatus_${e.status}`),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon: ({ value }) => renderActionStatusIcon(value.status) as any,
  type: InputTypes.TEXT,
  required: true,
  placeholder: "Enter",
  optionsSelector: () => null,
};

// Field constants
export const locationIdField: BaseField<Task> = {
  id: "locationId",
  title: "FieldLocation",
  value: (e: Task) => e.locationId,
  valueText: (e: Task) => e.locationName,
  type: InputTypes.AUTOCOMPLETE,
  optionsSelector: state =>
    state.location.basicData?.map(l => ({
      id: l.id,
      label: l.name,
    })),
  placeholder: localizeText("Select"),
  enableMobileScanners: true,
};

export const equipmentIdField: BaseField<Task> = {
  id: "equipmentId",
  title: "FieldEquipment",
  value: (e: Task) => e.equipmentId,
  valueText: (e: Task) => e.equipmentName,
  type: InputTypes.AUTOCOMPLETE,
  optionsSelector: state =>
    state.equipment.basicData?.map(l => ({
      id: l.id,
      label: l.name,
    })),
  placeholder: localizeText("Select"),
  enableMobileScanners: true,
};

export const employeeIdField: BaseField<Task> = {
  id: "employeeId",
  title: "FieldEmployee",
  value: (e: Task) => e.employeeId,
  valueText: (e: Task) => e.employeeName,
  type: InputTypes.AUTOCOMPLETE,
  optionsSelector: state =>
    state.employee.basicData?.map(l => ({
      id: l.id,
      label: l.displayName,
    })),
  placeholder: localizeText("Select"),
  enableMobileScanners: true,
};

export const issueTypeIdField: BaseField<Task> = {
  id: "issueTypeId",
  title: "FieldIssueType",
  value: (e: Task) => e.issueTypeId,
  valueText: (e: Task) => e.issueType,
  type: InputTypes.TREESELECT,
  treeData: {
    treeDataSelector: issueTypeSelectors.all,
    treeDataSet: dataSet =>
      constructTableTree(
        {
          data: dataSet,
          itemValueKey: "id",
          itemLabelKey: "name",
          isNullableField: true,
          disableInactiveItems: true,
        },
        true
      ),
  },
  enableMobileScanners: true,
  // required: true,
  placeholder: localizeText("Select"),
  // TODO: change the selector once we have more information on the fields
  optionsSelector: () => null,
  tabSwitchAction: {
    tabSwitch: true,
    action: () => "issueType",
  },
};

export const createdDateField: BaseField<Task> = {
  id: "createdDate",
  title: "FieldCreateDate",
  Icon: DatePickerIcon,
  value: e => (e.createdDate ? dayjs(e.createdDate).format("DD/MM/YYYY HH:mm") : null),
  type: InputTypes.TEXT,
  placeholder: localizeText("Undefined"),
  optionsSelector: () => null,
};

export const startDateField: BaseField<Task> = {
  id: "startDate",
  title: "FieldStartDate",
  Icon: DatePickerIcon,
  value: e => (e.startDate ? dayjs(e.startDate).format("DD/MM/YYYY HH:mm") : null),
  type: InputTypes.DATE,
  placeholder: localizeText("Undefined"),
  optionsSelector: () => null,
};

export const dueDateField: BaseField<Task> = {
  id: "dueDate",
  title: "FieldDueDate",
  Icon: DatePickerIcon,
  value: e => (e.dueDate ? dayjs(e.dueDate).format("DD/MM/YYYY HH:mm") : null),
  type: InputTypes.DATE,
  placeholder: "F",
  optionsSelector: () => null,
  disabledDate: e => current => {
    return current && current < dayjs(e?.createdDate);
  },
};

export const closeDateField: BaseField<Task> = {
  id: "closeDate",
  title: "FieldCloseDate",
  Icon: DatePickerIcon,
  value: (e: Task) => (e.closeDate ? dayjs(e.closeDate).format("DD/MM/YYYY HH:mm") : localizeText("None")),
  type: InputTypes.TEXT,
  optionsSelector: () => null,
};

export const descriptionField: BaseField<Task> = {
  id: "description",
  title: "FieldDescription",
  value: e => e.description,
  multilineInput: true,
  type: InputTypes.TEXT_AREA,
  optionsSelector: () => null,
};

export const priorityField: BaseField<Task> = {
  id: "priority",
  title: "FieldPriority",
  value: value => `TaskPriority_${value.priority}`,
  localizedValue: value => localizeText(`TaskPriority_${value.priority}`),
  Icon: ({ value }) => renderTaskPriority(value.priority),
  type: InputTypes.DROPDOWN,
  optionsSelector: () =>
    priorityOptions.map(l => ({
      id: l.id,
      label: l.label,
      icon: l.icon,
    })),
  required: true,
};

export const createdByField: BaseField<Task> = {
  id: "createdBy",
  title: "FieldCreatedBy",
  value: (e: Task) => e.createdBy,
  valueText: (e: Task) => e.createdByUserName,
  type: InputTypes.TEXT,
  optionsSelector: () => null,
};

export const tagsField: BaseField<Task> = {
  id: "tags",
  title: "FieldTags",
  value: (e: Task) => e.tags,
  type: InputTypes.TAGS,
  optionsSelector: tasksSelectors.tagsOptions,
};

export const isFormBasedField: BaseField<Task> = {
  id: "isFormBased",
  title: "FormBased",
  value: e => renderBoolStatus(e.isFormBased),
  type: InputTypes.BOOLEAN,
  options: boolOptions,
  optionsSelector: () =>
    boolOptions.map(l => ({
      id: l.id,
      label: localizeText(l.label),
    })),
  dependentFields: [
    {
      id: "followUpReviewId",
      title: "FieldReview",
      value: (e: Task) => e.followUpReviewId,
      valueText: (e: Task) => e.review,
      type: InputTypes.AUTOCOMPLETE,
      optionsSelector: () => null,
      selector: state => [
        ...new Map(
          state.actions.usersQuickCreateData
            .map(x => ({
              id: x.reviewId,
              label: x.reviewName,
              projectId: x.projectId,
            }))
            .map(item => [item.id, item])
        ).values(),
      ],
      basedOnDependensiesOptions: (currentData: Task, selectedDependentData: ReviewOptions[] | null) =>
        selectedDependentData
          ?.filter(x => (currentData?.projectId ? currentData?.projectId === x.projectId : true))
          .map(l => ({
            id: l.id,
            label: l.label,
          })) || [],
      placeholder: localizeText("Select"),
    },
  ],
};

export const idField: BaseField<Task> = {
  id: "id",
  title: "ID",
  value: value => value.id,
  type: InputTypes.TEXT,
  required: true,
  placeholder: "AutopopulatedField",
  optionsSelector: () => null,
};

export const primaryFields: BaseField<Task>[] = [titleField, projectIdField, responsibleUserIdField, statusField];

export const secondaryFields: BaseField<Task>[] = [
  locationIdField,
  equipmentIdField,
  employeeIdField,
  issueTypeIdField,
  createdDateField,
  startDateField,
  dueDateField,
  closeDateField,
  priorityField,
  createdByField,
  tagsField,
  isFormBasedField,
  idField,
  descriptionField,
];
