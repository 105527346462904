import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { CommonState, Company, TenantUser } from "../../types/common";
import { BaseFile } from "../../types/files";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

const DEFAULT_PRIMARY_COLOR = "#00b5c3";

export const shouldUpdate = (lastUpdated: string, isLoading: boolean, forceUpdate?: boolean): boolean => {
  return forceUpdate || (!isLoading && dayjs(lastUpdated).isBefore(dayjs()));
};
export const initialState: CommonState = {
  tenantUsers: [],
  companies: [],
  primaryColor: DEFAULT_PRIMARY_COLOR,
  isLoading: false,
  appointmentToOpen: undefined,
  fileInfo: undefined,
};

export const fetchCompanyList = createAsyncThunk<Company[], void, { state: RootState }>(
  "@@COMMON/FETCH_COMPANY_LIST",
  async (_, { getState }) => {
    const { user } = getState();
    const { data: companies } = await hbApi.get<Company[]>("/Company", hbApiOptions(user.jwt));

    return Array.isArray(companies) ? companies : [];
  }
);

export const fetchTenantUserList = createAsyncThunk<TenantUser[], number, { state: RootState }>(
  "@@COMMON/FETCH_TENANT_USER_LIST",
  async (companyId, { getState }) => {
    const { user } = getState();
    const { data: tenantUsers } = await hbApi.get<TenantUser[]>(
      `/Account/tenant-users?companyId=${companyId}`,
      hbApiOptions(user.jwt)
    );

    return Array.isArray(tenantUsers) ? tenantUsers : [];
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFile = createAsyncThunk<any, string, { state: RootState }>(
  "@@COMMON/GET_FILE",
  async (fileUrl, { getState }) => {
    const { user } = getState();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { data: file } = await hbApi.get<any>(`/file/${fileUrl}`, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${user.jwt}`,
      },
    });

    return file;
  }
);

export const getSasLinks = createAsyncThunk<{ path: string; tokenizedUrl: string }[], string[], { state: RootState }>(
  "@@COMMON/GET_SAS_LINKS",
  async (initialUrls, { getState }) => {
    const { user } = getState();
    const { data } = await hbApi.post<{ path: string; tokenizedUrl: string }[]>(
      "/File/getSasLinks",
      initialUrls,
      hbApiOptions(user.jwt)
    );

    return data;
  }
);

const slice = createSlice({
  name: "common",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    setPrimaryColor: (state, action: PayloadAction<string>) => {
      state.primaryColor = action.payload;
    },
    setAppointmentToOpen: (state, action: PayloadAction<number | undefined>) => {
      state.fillAForm = undefined;
      state.appointmentToOpen = action.payload;
    },
    setFileInfoToOpen: (state, action: PayloadAction<BaseFile | undefined>) => {
      state.fileInfo = action.payload;
    },
    setFillAForm: (state, action: PayloadAction<boolean | undefined>) => {
      state.appointmentToOpen = undefined;
      state.fillAForm = action.payload;
    },
    setFormFillingVersion: (state, action: PayloadAction<string | undefined>) => {
      state.formFillingVersion = action.payload;
    },
    setExitFormManagmentModal: (state, action: PayloadAction<string | undefined>) => {
      state.exitFormManagmentModal = action.payload;
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchCompanyList.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchTenantUserList.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchCompanyList.rejected, state => {
        state.isLoading = false;
      })
      .addCase(fetchTenantUserList.rejected, state => {
        state.isLoading = false;
      })
      .addCase(fetchCompanyList.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchTenantUserList.fulfilled, (state, action) => {
        state.tenantUsers = action.payload;
        state.isLoading = false;
      });
  },
});

export const {
  setPrimaryColor,
  setAppointmentToOpen,
  setFileInfoToOpen,
  setFillAForm,
  setFormFillingVersion,
  setExitFormManagmentModal,
} = slice.actions;
export default slice.reducer;
