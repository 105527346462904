import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortOrder } from "antd/lib/table/interface";
import { UserSettingsConstants } from "../../types/constants";
import { CustomPropInfo } from "../../types/customProperty";
import { CategoryId, TOption } from "../../types/page";
import { StoreUserFiltersParams, UserSettings } from "../../types/user";
import { DefaultFilter, Filter, FilterValueType } from "../../utils/hooks/useFilter";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

type FilterValuesState = {
  values: Record<string, TOption[]>;
  order: Record<string, SortOrder>;
  isLoading: boolean;
};

type FilterEntityType = {
  activeFilters: Filter[];
  stagingFilters: Filter[];
};
export type TFetchFilterValues = {
  pageId: CategoryId;
  entityKey: string;
  filterKey: string;
  value?: string;
  entityType?: string;
  pageSize?: number;
  propName?: string;
  customPropInfo?: CustomPropInfo;
  useKeyValue?: boolean;
  useEqualsForId?: boolean;
};
export type TInitializeFilters = { pageId: CategoryId; entityKey: string; defaultFilters: DefaultFilter[] };
export type TAddSorter = { pageId: CategoryId; entityKey: string; sortOrder: SortOrder };
export type TAddFilter = { pageId: CategoryId; entityKey: string; filter: Filter };
type TRemoveFilter = { key: string; value?: FilterValueType; pageId: CategoryId; entityKey: string };

type FilterState = {
  filters: Record<CategoryId, Record<string, FilterEntityType>>;
  filtersString: Record<CategoryId, string>;
  filterValues: Record<CategoryId, FilterValuesState | undefined>;
  loading: boolean;
};

export const initialState: FilterState = {
  // Note: a filter has to be added for each newly implemented page
  filters: {
    equipment: {},
    location: {},
    employee: {},
    training: {},
    certification: {},
    inspectionEquipment: {},
    inspectionLocation: {},
    orgUnit: {},
    survey: {},
    actions: {},
    dashboard: {},
    equipmentType: {},
    locationType: {},
    inspectionEquipmentType: {},
    inspectionLocationType: {},
    employeeCustomProperty: {},
    equipmentCustomProperty: {},
    locationCustomProperty: {},
    trainingCustomProperty: {},
    inspectionEquipmentCustomProperty: {},
    inspectionLocationCustomProperty: {},
    issueType: {},
    recurrentActions: {},
    webhooks: {},
  },
  filtersString: {
    webhooks: "",
    equipment: "",
    location: "",
    employee: "",
    training: "",
    certification: "",
    inspectionEquipment: "",
    inspectionLocation: "",
    orgUnit: "",
    survey: "",
    actions: "",
    dashboard: "",
    equipmentType: "",
    locationType: "",
    inspectionEquipmentType: "",
    inspectionLocationType: "",
    employeeCustomProperty: "",
    equipmentCustomProperty: "",
    locationCustomProperty: "",
    trainingCustomProperty: "",
    inspectionEquipmentCustomProperty: "",
    inspectionLocationCustomProperty: "",
    issueType: "",
    recurrentActions: "",
  },
  filterValues: {
    webhooks: undefined,
    equipment: undefined,
    employee: undefined,
    training: undefined,
    location: undefined,
    certification: undefined,
    inspectionEquipment: undefined,
    inspectionLocation: undefined,
    orgUnit: undefined,
    survey: undefined,
    equipmentType: undefined,
    locationType: undefined,
    inspectionEquipmentType: undefined,
    inspectionLocationType: undefined,
    equipmentCustomProperty: undefined,
    employeeCustomProperty: undefined,
    trainingCustomProperty: undefined,
    locationCustomProperty: undefined,
    inspectionEquipmentCustomProperty: undefined,
    inspectionLocationCustomProperty: undefined,
    actions: undefined,
    issueType: undefined,
    dashboard: undefined,
    recurrentActions: undefined,
  },
  loading: false,
};

const handleChangeFilter = (arr: Filter[], item: Filter) => [
  ...arr.filter(a => !(a.key === item.key && a.filterType === item.filterType)),
  item,
];

const handleAddFilter = (currentFilters: Filter[], item: Filter) => {
  return [...currentFilters, item];
};

const handleRemoveFilter = (currentFilters: Filter[], { key, value }: { key: string; value?: FilterValueType }) => {
  if (!value && value !== false) {
    return currentFilters.filter(f => !(f.key === key));
  }
  return currentFilters.filter(f => !(f.key === key && f.value === value));
};

export const fetchFilterValues = createAsyncThunk<TOption[], TFetchFilterValues, { state: RootState }>(
  "@@FILTERS/FETCH_VALUES",
  async (
    { value, entityType, pageSize, propName, customPropInfo, pageId, useKeyValue, useEqualsForId },
    { getState }
  ) => {
    const { user, customProperty } = getState();

    if (customPropInfo) {
      const response = await hbApi.get<string[]>(
        `/SelectList/${pageId}${entityType}`,
        hbApiOptions(user.jwt, null, null, { customPropertyId: customPropInfo.propId, pageSize, propName: "value" })
      );

      const dictionaryValues = customPropInfo.dictionaryId
        ? customProperty.companyDictionaries.find(d => d.id === customPropInfo?.dictionaryId)?.dictionaryValues
        : null;
      const getOptionValue = (value: string) =>
        customPropInfo?.dictionaryId ? dictionaryValues?.find(x => x.id === Number(value))?.value : value;

      if (customPropInfo) {
        customPropInfo = { ...customPropInfo, dictionaryValues };
      }
      return response.data
        .filter(x => getOptionValue(x))
        .map(f => ({ id: getOptionValue(f) || "", label: getOptionValue(f) || "", ...customPropInfo }));
    } else {
      const response = await hbApi.get<string[]>(
        `/SelectList${useKeyValue ? "/keyValue" : ""}/${entityType}`,
        hbApiOptions(user.jwt, null, null, {
          ...(useEqualsForId ? { equalsId: Number(value) } : { startsWith: value }),
          pageSize,
          propName,
        })
      );
      return useKeyValue
        ? Object.entries(response.data).map(([id, label]) => ({ id, label }))
        : Array.from(new Set(response.data)).map(f => ({ id: f, label: f }));
    }
  }
);

export const initializeFiltersFromDefaults = createAsyncThunk<
  Filter[] | undefined,
  TInitializeFilters,
  { state: RootState }
>("@@FILTERS/INIT_FILTERS", async ({ defaultFilters, pageId }, { getState }) => {
  const state = getState();

  if (state.user.jwt) {
    const mappedDefaultFilters = defaultFilters.map<Filter>(f => {
      return {
        ...f,
        value: f.value ? f.value : f.userBasedValue ? f.userBasedValue(state) : null,
        textValue: f.textValue ? f.textValue : f.userBasedTextValue ? f.userBasedTextValue(state) : null,
      };
    });
    const modifiedBody = {
      key: pageId + UserSettingsConstants.FILTER_KEYS_SUFFIX,
      value: JSON.stringify(mappedDefaultFilters),
    };
    await hbApi.post<UserSettings>("/UserUISetting", modifiedBody, hbApiOptions(state.user.jwt));
    return mappedDefaultFilters;
  }
  return undefined;
});

export const initializeStoredUserFilters = createAsyncThunk<
  Filter[] | undefined,
  { entityKey: string; pageId: CategoryId },
  { state: RootState }
>(
  "@@FILTERS/INIT_STORED_FILTERS",
  async ({ pageId }, { getState }) => {
    const { user } = getState();

    const response = await hbApi.post<UserSettings[]>(
      "/UserUISetting/getByKeys",
      [pageId + UserSettingsConstants.FILTER_KEYS_SUFFIX],
      hbApiOptions(user.jwt)
    );

    return response.data.length ? JSON.parse(response.data[0].value) : [];
  },
  {
    condition: ({ pageId, entityKey }, { getState }) => {
      const { filter } = getState();
      return !filter.loading && !filter.filters[pageId][entityKey]?.activeFilters.length;
    },
  }
);

export const storeUserFilters = createAsyncThunk<boolean, StoreUserFiltersParams, { state: RootState }>(
  "@@FILTERS/STORE_USER_FILTERS",
  async (entity, { getState }) => {
    const { user } = getState();

    const modifiedBody = {
      key: entity.newValues.key,
      value: JSON.stringify(entity.newValues.value),
    };

    await hbApi.post<UserSettings>("/UserUISetting", modifiedBody, hbApiOptions(user.jwt));

    return true;
  },
  {
    condition: (_, { getState }) => {
      const { filter } = getState();
      return !filter.loading;
    },
  }
);

export const slice = createSlice({
  name: "filter",
  initialState,
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchFilterValues.pending, (state, action) => {
        if (!state.filterValues[action.meta.arg.pageId]) {
          state.filterValues[action.meta.arg.pageId] = {
            values: {},
            order: {},
            isLoading: false,
          };
        } else {
          state.filterValues[action.meta.arg.pageId]!.isLoading = true;
        }
      })
      .addCase(initializeStoredUserFilters.pending, state => {
        state.loading = true;
      })
      .addCase(storeUserFilters.pending, state => {
        state.loading = true;
      })
      .addCase(initializeStoredUserFilters.rejected, state => {
        state.loading = false;
      })
      .addCase(storeUserFilters.rejected, state => {
        state.loading = false;
      })
      .addCase(fetchFilterValues.rejected, (state, action) => {
        state.filterValues[action.meta.arg.pageId]!.isLoading = false;
      })
      .addCase(fetchFilterValues.fulfilled, (state, action) => {
        state.filterValues[action.meta.arg.pageId]!.values[action.meta.arg.filterKey] = action.payload;
        state.filterValues[action.meta.arg.pageId]!.isLoading = false;
      })
      .addCase(initializeFiltersFromDefaults.fulfilled, (state, action) => {
        if (action.payload) {
          state.filters[action.meta.arg.pageId][action.meta.arg.entityKey] = {
            activeFilters: action.payload,
            stagingFilters: [],
          };
        }
      })
      .addCase(initializeStoredUserFilters.fulfilled, (state, action) => {
        if (!state.filters[action.meta.arg.pageId][action.meta.arg.entityKey]?.activeFilters.length && action.payload) {
          state.filters[action.meta.arg.pageId][action.meta.arg.entityKey] = {
            activeFilters: action.payload,
            stagingFilters: [],
          };
        }
        state.loading = false;
      })
      .addCase(storeUserFilters.fulfilled, state => {
        state.loading = false;
      });
  },
  reducers: {
    // Note: Active filters
    addActive: {
      prepare: (payload: TAddFilter) => ({
        payload,
      }),
      reducer: (state, action: PayloadAction<TAddFilter>) => {
        if (action.payload.filter.fieldType === "dateRange") {
          // Note: If the filter is a range, we need to replace the existing one (if any)
          if (!state.filters[action.payload.pageId][action.payload.entityKey]) {
            state.filters[action.payload.pageId][action.payload.entityKey] = { activeFilters: [], stagingFilters: [] };
          }
          state.filters[action.payload.pageId][action.payload.entityKey].activeFilters = handleChangeFilter(
            state.filters[action.payload.pageId][action.payload.entityKey].activeFilters,
            action.payload.filter
          );
        } else {
          // Note: We add additional filter regardles if there are already some of the same type
          if (!state.filters[action.payload.pageId][action.payload.entityKey]) {
            state.filters[action.payload.pageId][action.payload.entityKey] = { activeFilters: [], stagingFilters: [] };
          }
          state.filters[action.payload.pageId][action.payload.entityKey].activeFilters = handleAddFilter(
            state.filters[action.payload.pageId][action.payload.entityKey].activeFilters,
            action.payload.filter
          );
        }
        state.filtersString[action.payload.pageId] = JSON.stringify(
          state.filters[action.payload.pageId][action.payload.entityKey]
        );
      },
    },
    removeActive: {
      prepare: (payload: TRemoveFilter) => ({
        payload,
      }),
      reducer: (state, action: PayloadAction<TRemoveFilter>) => {
        if (state.filters[action.payload.pageId][action.payload.entityKey])
          state.filters[action.payload.pageId][action.payload.entityKey].activeFilters = handleRemoveFilter(
            state.filters[action.payload.pageId][action.payload.entityKey].activeFilters,
            action.payload
          );
        state.filtersString[action.payload.pageId] = JSON.stringify(
          state.filters[action.payload.pageId][action.payload.entityKey]
        );
      },
    },
    resetActiveFilters: {
      prepare: (payload: Omit<TAddFilter, "filter">) => ({
        payload,
      }),
      reducer: (state, action: PayloadAction<Omit<TAddFilter, "filter">>) => {
        state.filters[action.payload.pageId][action.payload.entityKey].activeFilters = [];
      },
    },
    resetActiveFiltersAndAddNew: {
      prepare: (payload: TAddFilter[]) => ({
        payload,
      }),
      reducer: (state, action: PayloadAction<TAddFilter[]>) => {
        if (action.payload.length) {
          const filterKeys = action.payload[0];
          if (state.filters[filterKeys.pageId][filterKeys.entityKey]) {
            state.filters[filterKeys.pageId][filterKeys.entityKey].activeFilters = [];
          }
        }
        action.payload.forEach(f => {
          if (f.filter.fieldType === "dateRange") {
            // Note: If the filter is a range, we need to replace the existing one (if any)
            if (!state.filters[f.pageId][f.entityKey]) {
              state.filters[f.pageId][f.entityKey] = {
                activeFilters: [],
                stagingFilters: [],
              };
            }
            state.filters[f.pageId][f.entityKey].activeFilters = handleChangeFilter(
              state.filters[f.pageId][f.entityKey].activeFilters,
              f.filter
            );
          } else {
            // Note: We add additional filter regardles if there are already some of the same type
            if (!state.filters[f.pageId][f.entityKey]) {
              state.filters[f.pageId][f.entityKey] = {
                activeFilters: [],
                stagingFilters: [],
              };
            }
            state.filters[f.pageId][f.entityKey].activeFilters = handleAddFilter(
              state.filters[f.pageId][f.entityKey].activeFilters,
              f.filter
            );
          }
        });
      },
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    // Note: Staging filters
    addStaging: {
      prepare: (payload: TAddFilter) => ({
        payload,
      }),
      reducer: (state, action: PayloadAction<TAddFilter>) => {
        if (action.payload.filter.fieldType === "dateRange") {
          // Note: If the filter is a range, we need to replace the existing one (if any)
          state.filters[action.payload.pageId][action.payload.entityKey].stagingFilters = handleChangeFilter(
            state.filters[action.payload.pageId][action.payload.entityKey].activeFilters,
            action.payload.filter
          );
        } else {
          // Note: We add additional filter regardles if there are already some of the same type
          state.filters[action.payload.pageId][action.payload.entityKey].stagingFilters = handleAddFilter(
            state.filters[action.payload.pageId][action.payload.entityKey].activeFilters,
            action.payload.filter
          );
        }
      },
    },
    removeStaging: {
      prepare: (payload: TRemoveFilter) => ({
        payload,
      }),
      reducer: (state, action: PayloadAction<TRemoveFilter>) => {
        state.filters[action.payload.pageId][action.payload.entityKey].stagingFilters = handleRemoveFilter(
          state.filters[action.payload.pageId][action.payload.entityKey].activeFilters,
          action.payload
        );
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    promoteStagingFilters(state) {
      // state.activeFilters = state.stagingFilters;
      // eslint-disable-next-line no-console
      console.log("TODO");
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetStagingFilters(state) {
      // eslint-disable-next-line no-console
      console.log("TODO");
    },
    updateOrder: {
      prepare: (payload: TAddSorter) => ({
        payload,
      }),
      reducer: (state, action: PayloadAction<TAddSorter>) => {
        // Note: We add additional filter regardles if there are already some of the same type
        if (!state.filterValues[action.payload.pageId]) {
          state.filterValues[action.payload.pageId] = { values: {}, order: {}, isLoading: false };
        }
        if (state.filterValues[action.payload.pageId]?.order) {
          const updatedOrder: Record<string, SortOrder> = {};
          updatedOrder[action.payload.entityKey] = action.payload.sortOrder;
          state.filterValues[action.payload.pageId]!.order = updatedOrder;
        }
      },
    },
  },
});

export const {
  addActive,
  removeActive,
  addStaging,
  removeStaging,
  resetActiveFilters,
  resetStagingFilters,
  promoteStagingFilters,
  resetActiveFiltersAndAddNew,
  updateOrder,
} = slice.actions;

export default slice.reducer;
