import { GlobalOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Modal, Popover, Space } from "antd";
import { nanoid } from "nanoid";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useFormManagmentEvents, { MobileEvent } from "../../components/EmbeddedModal/useFormManagmentEvents";
import { ReactComponent as ChangePassword } from "../../media/change-password.svg";
import { logout } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { isRoleReporter } from "../../utils/functions";
import { DEFAULT_COMPANY_COLOR, applyTheme } from "../../utils/hooks/useCompanyColors";
import usePasProFlag from "../../utils/hooks/usePasProFlag";
import useRouter from "../../utils/hooks/useRouter";
import { ChangePasswordModal } from "./ChangePasswordModal/ChangePasswordModal";
import LanguagePicker from "./LanguagePicker/LanguagePicker";

import "./ProfileMenu.less";
import { MenuItemType, ProfileMenuItem, getAlignment } from "./utility";

export default function ProfileMenu() {
  const { t } = useTranslation();
  const settings = useSelector((state: RootState) => state.user.settings);
  const companySettings = useSelector((state: RootState) => state.user.companySettings);
  const { history } = useRouter();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const isPASProEnabled = usePasProFlag();
  const { fireMobileEvent } = useFormManagmentEvents();
  const dispatch = useAppDispatch();

  const logoutListener = () => {
    dispatch(logout())
      .then(x => unwrapResult(x))
      .then(async () => {
        await applyTheme("@primary-color", DEFAULT_COMPANY_COLOR);
        Modal.destroyAll();
      });
  };

  const profileMenuItems: ProfileMenuItem[] = [
    {
      title: "Languages",
      icon: <GlobalOutlined />,
      type: MenuItemType.Menu,
      content: <LanguagePicker />,
      disabled: isRoleReporter(settings.role),
    },
    {
      title: "UserProfile",
      icon: <UserOutlined />,
      type: MenuItemType.Action,
      disabled: isRoleReporter(settings.role),
      action: () => history.push(`/employee/${companySettings.currentUserId}`),
    },
    {
      title: "ChangePassword",
      icon: <ChangePassword fill={primaryColor} />,
      type: MenuItemType.Action,
      action: () => {
        setIsModalVisible(!isModalVisible);
      },
    },
    {
      title: "MenuLogout",
      icon: <LogoutOutlined />,
      action: () => {
        if (isPASProEnabled) {
          fireMobileEvent(MobileEvent.LogoutFromWeb, {});
        }
        logoutListener();
      },
      type: MenuItemType.Action,
    },
  ];

  const getMenuItem: (item: ProfileMenuItem) => ReactNode = item => {
    return (
      <Button
        onClick={item.action ? item.action : null}
        disabled={item.disabled}
        type="text"
        className="menu-button"
        key={nanoid()}
      >
        <div id="icon-container">{item.icon}</div>
        {t(item.title)}
      </Button>
    );
  };
  return (
    <>
      <ChangePasswordModal isVisible={isModalVisible} closeModal={() => setIsModalVisible(false)} />
      <Space direction="vertical" className="profile-menu-item" wrap={true}>
        {profileMenuItems
          .filter(item => !item.disabled)
          .map(item => {
            if (item.type === MenuItemType.Menu) {
              return (
                <Popover
                  align={getAlignment(settings.direction)}
                  placement={settings.direction === "ltr" ? "rightBottom" : "leftTop"}
                  key={item.title}
                  trigger="hover"
                  content={item.content}
                >
                  {getMenuItem(item)}
                </Popover>
              );
            } else {
              return getMenuItem(item);
            }
          })}
      </Space>
    </>
  );
}
