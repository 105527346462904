import "./listViewVTable.less";

import { unwrapResult } from "@reduxjs/toolkit";

import { Col, Empty, Modal, Row, SpinProps, Table, TableColumnType } from "antd";
import { RcFile } from "antd/lib/upload";
import dayjs from "dayjs";
import sign from "jwt-encode";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { VList } from "virtual-table-ant-design";

import { ExportData, ExportEntityData, isPagedListExportSupported } from "../../actions/menuActions";
import { SystemActions } from "../../consts/listViewActions";
import PageConfigurationContext from "../../context/pageContext";
import { ReactComponent as FilterIcon } from "../../media/filter-icon.svg";
import { initializeFiltersFromDefaults } from "../../store/slices/filter";
import { bulkUpdateInspectionStatus } from "../../store/slices/inspection";
import { setActionsStatus } from "../../store/slices/tasks";
import { RootState, useAppDispatch } from "../../store/store";
import { HBEventName } from "../../types/analyticsTypes/HBEvent";
import { BaseEntityType } from "../../types/entityBase";
import { Inspection, InspectionStatus } from "../../types/inspection";
import { CategoryPage, ColumnType, DropDownAction } from "../../types/page";
import { Task } from "../../types/tasks";
import { ExportMapping, OpenClosedStatus, ParamsKeys, UploadModalType } from "../../types/utility";
import useColumn from "../../utils/hooks/useColumn";
import useInitTrackEvents from "../../utils/hooks/useInitTrackEvents";
import useRouter from "../../utils/hooks/useRouter";
import useTableResize from "../../utils/hooks/useTableResize";
import { useUIErrorHandling } from "../../utils/hooks/useUIErrorHandling";
import { ArrayElement } from "../../utils/types";
import { ColumnSelection } from "../ColumnSelection/ColumnSelection";
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";
import PaginatedTable from "../HBComponents/PaginatedTable/PaginatedTable";
import ResizableTitle from "../HBComponents/PaginatedTable/ResizableTitle";
import HBUploadModal from "../HBComponents/UploadModal/HBUploadModal";
import { CellRenderer } from "../TableCellRenderer/TableCellRenderer";
import TableTree from "../TableTree/TableTree";

type TProps = {
  filteredData: Record<string, unknown>[];
  fullData: Record<string, unknown>[];
  columnSelectionVisibility: boolean;
  handleColumnSelectionVisibility?: (visible: boolean) => void;
  selectedRows: Record<string, unknown>[];
  handleSetSelectedRows: (rows: Record<string, unknown>[]) => void;
};

export interface ListViewVTableState {
  isExporting: boolean;
  exportMessage?: string | null | undefined;
  error?: { title: string; message: string };
  sortedData: Record<string, unknown>[];
}

export const MAX_ACTIONS_COUNT_FOR_BULK_CANCEL = 200;

export interface ListViewColumn<RecordType> extends ColumnType<RecordType> {
  renderValue: (e: RecordType) => string | null;
}

const MAX_COLUMN_IN_TABLE_SINGLE_PAGE = 9;

export const getScrollXPercent = (columns: TableColumnType<Record<string, unknown>>[]) => {
  return Math.ceil(columns.length / MAX_COLUMN_IN_TABLE_SINGLE_PAGE) * 80;
};

// NOTE: This component works with the single view tabs
const ListViewVTable = ({
  filteredData,
  fullData,
  columnSelectionVisibility,
  handleColumnSelectionVisibility,
  handleSetSelectedRows,
  selectedRows,
}: TProps): JSX.Element | null => {
  const [state, setNewState] = useState({
    isExporting: false,
    sortedData: filteredData,
  } as ListViewVTableState);
  const dispatch = useAppDispatch();
  const { history, location } = useRouter();
  const settings = useSelector((state: RootState) => state.user.settings);
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const { table } = pageConfig.listView!;
  const { t } = useTranslation();
  const listExportLimit = useSelector((state: RootState) => state.user.companySettings?.listExportLimit);
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const [isUpdateInspectionModalVisible, setUpdateInspectionModalVisible] = useState(false);
  const { errorMessages, errorType, setErrors, handleClearErrors } = useUIErrorHandling();
  const { track } = useInitTrackEvents();
  // const [selectedRows, setSelectedRows] = useState<typeof data>([]);

  const { selectedColumns, allColumns, isTableLoading, handleColumnSelection } = useColumn(
    pageConfig,
    filteredData,
    handleColumnSelectionVisibility
  );

  const isLoading = useSelector(pageConfig.isLoading);
  const queryFilters = useSelector((state: RootState) => state.filter.filtersString[pageConfig.id]);

  // TODO: Eventually try to find a way not to store the data twice
  useEffect(() => {
    // debugger;
    if (filteredData && filteredData.length) {
      setNewState({ ...state, sortedData: filteredData });
    }
  }, [filteredData]);

  const user = useSelector((state: RootState) => state.user);

  const handlePrimaryColumnNavigation = (c: ColumnType<Record<string, unknown>>, entity: Record<string, unknown>) => {
    if (c.primaryColumn && !entity.staging) {
      const navigationTarget = c.primaryColumn(entity)?.navigationTarget;
      navigationTarget && history.replace(navigationTarget);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: typeof filteredData) => {
      handleSetSelectedRows(selectedRows);
    },
    columnWidth: 30,
    selectedRowKeys: selectedRows.map(r => r.id) as React.Key[],
  };

  const sortData = (id: string) => (a: ArrayElement<typeof filteredData>, b: ArrayElement<typeof filteredData>) => {
    const aa = a[id];
    const bb = b[id];
    if (typeof aa === "string" && typeof bb === "string") {
      if (dayjs(aa).isValid() && dayjs(bb).isValid() && dayjs(aa).toISOString() === aa) {
        return dayjs(aa).isAfter(dayjs(bb)) ? 1 : -1;
      }

      if (!Number.isNaN(aa) && !Number.isNaN(+aa)) {
        return Number(+aa) - Number(+bb);
      }

      return aa.localeCompare(bb);
    }

    if (!aa || !bb) {
      return aa ? 1 : -1;
    }

    if (typeof aa === "number" && typeof bb === "number") {
      return aa - bb;
    }

    return false;
  };

  const columns = useMemo(() => {
    return [...selectedColumns]
      .filter(c => c.hidden !== true)
      .map(c => {
        return {
          dataIndex: c.id,
          exportPropertyId: c.exportPropertyId ?? c.id,
          title: t(c.label),
          sortDirections: ["descend", "ascend"],
          sorter: c.sortable ? (pageConfig.listView?.table.type !== "paginatedTable" ? sortData(c.id) : true) : false,
          width: c.width,
          renderValue: c.renderValue,
          filterIcon: <FilterIcon stroke={primaryColor} />,
          render: (_, entity) => (
            <Row
              onClick={() => handlePrimaryColumnNavigation(c, entity)}
              className={c.primaryColumn ? "primaryColumn HB-table-cell" : "HB-table-cell"}
            >
              <CellRenderer c={c} entity={entity} />
            </Row>
          ),
        } as TableColumnType<ArrayElement<typeof filteredData>>;
      });
  }, [selectedColumns, t, primaryColor]);

  const { columns: resizableColumns, setColumns, tableRef } = useTableResize(columns);

  useEffect(() => {
    if (columns) {
      setColumns(columns);
    }
  }, [columns]);

  const copyToClipboardFiltersUrl = () => {
    const { REACT_APP_FILTERS_JWT_SECRET } = process.env;
    const jwtSecret = REACT_APP_FILTERS_JWT_SECRET || "";

    const columnKeys = selectedColumns.map(c => c.id);
    const columnsString = JSON.stringify(columnKeys);

    const columnsQueryString = columnsString
      ? `${ParamsKeys.COLUMNS_SEARCH_PREFIX}=${sign(columnsString, jwtSecret)}`
      : "";
    const filtersQueryString = queryFilters
      ? `${ParamsKeys.FILTERS_SEARCH_PREFIX}=${sign(queryFilters, jwtSecret)}`
      : "";
    const queryString = [columnsQueryString, filtersQueryString].filter(query => !!query).join("&");

    const filterUrl = `${window.location.origin}/${pageConfig.id}?${queryString}`;

    navigator.clipboard.writeText(filterUrl);
  };

  useEffect(() => {
    pageConfig.listViewActions
      ?.filter(mi => mi.isDataOperation)
      .forEach(mi => {
        if (mi) {
          if (mi.originalAction === undefined && mi.action == null) {
            mi.originalAction = null;
          } else if (mi.originalAction === undefined && mi.action) {
            mi.originalAction = mi.action;
          }
          if (mi.id == SystemActions.Export) {
            mi.action = () => exportVisibleData(mi, state, setNewState, t);
          } else if (mi.id == SystemActions.SetActionStatus) {
            mi.action = () => {
              setStatus();
            };
          } else {
            mi.action = () => {
              if (mi.originalAction) {
                const result = mi.originalAction(mi, user.jwt, state.sortedData, t, dispatch);
                if (result) {
                  setNewState({ ...state, isExporting: true, exportMessage: result });
                }
              }

              console.error("No action provided for DataOperation dropdown item");
              return null;
            };
          }
        }
      });
  }, [state, selectedRows, selectedColumns]);

  const exportVisibleData = (
    action: DropDownAction<Record<string, unknown>>,
    state: ListViewVTableState,
    setNewState: (state: ListViewVTableState) => void,
    translationFunction: TFunction
  ) => {
    if (action.originalAction) {
      const exportMessage = action.originalAction(action, user.jwt, state.sortedData, translationFunction);
      setNewState({ ...state, exportMessage: exportMessage || undefined, isExporting: true });
      return;
    }
    setNewState({ ...state, isExporting: true, exportMessage: undefined });
    // Generate data to be exported
    const exportTemplate: ExportMapping[] = [];
    for (const column of columns) {
      exportTemplate.push({
        type: "Header",
        property: String(column.exportPropertyId),
        value: String(column.title),
      });
    }

    if (settings.direction === "rtl") {
      exportTemplate.push({
        type: "Option",
        property: "RTL",
        value: "true",
      });
    }

    const tableData = state.sortedData.map(entity => {
      const finalEntity = { ...entity };
      columns.forEach(c => {
        const column = c as ListViewColumn<typeof entity>;
        const transformedValue = column.renderValue(entity);
        if (transformedValue != null) {
          finalEntity[c.dataIndex as keyof typeof finalEntity] = transformedValue;
        }
      });
      return finalEntity;
    });

    if (!tableData || !tableData.length) {
      setNewState({ ...state, isExporting: false, error: t("No data to export") });
      return;
    }

    const exportPromise = isPagedListExportSupported(pageConfig.id)
      ? ExportEntityData(pageConfig.id, exportTemplate)
      : ExportData(tableData, exportTemplate, `${pageConfig.id}.xlsx`);

    exportPromise
      .then(() => {
        setNewState({ ...state, isExporting: false });
      })
      .catch(() => {
        setNewState({
          ...state,
          error: {
            title: "OperationFailed",
            message: "FileDownloadError",
          },
        });
      })
      .finally(() => track({ eventName: HBEventName.ExportEntityList, data: { entity: location.pathname } }));
  };

  const updateInspection = (comment: string, status?: InspectionStatus, date?: string) => async (
    file: RcFile
  ): Promise<void | File | Blob | boolean> => {
    if (selectedRows.filter(x => x.recurrencyType !== "Fixed").length || selectedRows.filter(x => x.reviewId).length) {
      setErrors({
        title: ["OperationFailed"],
        message: [
          "One or more selected inspections are not “fixed” or linked to a survey, please refine your selection and try again",
        ],
      });
    } else {
      dispatch(
        bulkUpdateInspectionStatus({
          file: file,
          status: status,
          comment: comment,
          ids: selectedRows.map(x => (x as Inspection).activeCheckpointId) as number[],
          completedAt: date ?? "",
        })
      );
    }

    setUpdateInspectionModalVisible(!isUpdateInspectionModalVisible);

    return false;
  };

  const setStatus = () => {
    if (selectedRows.length > MAX_ACTIONS_COUNT_FOR_BULK_CANCEL) {
      setErrors({
        title: ["OperationFailed"],
        // to do add error with maximum add validation in BE also
        message: ["PleaseSelectLessThanMaxAllowedActions"],
      });
    } else if (
      selectedRows.filter(x => x.status === OpenClosedStatus.Closed || x.status === OpenClosedStatus.Canceled).length
    ) {
      setErrors({
        title: ["OperationFailed"],
        message: ["PleaseSelectActionWithStatusOpened"],
      });
    } else {
      Modal.confirm({
        title: t("ConfirmDefaultText"),
        content: t("BulkCancelActionsConfirmationMessage"),
        onOk() {
          dispatch(setActionsStatus(selectedRows.map(t => (t as Task).id)))
            .then(response => unwrapResult(response))
            .catch(errorMessage => {
              if (errorMessage) {
                setErrors(
                  {
                    message: [errorMessage || "SomethingWentWrong"],
                  },
                  "operationFailed"
                );
              }
            });
        },
      });
    }

    return false;
  };

  const closeUpdateInspectionModal = () => {
    setUpdateInspectionModalVisible(!isUpdateInspectionModalVisible);
  };

  const mannualyUpdateInspection = () => setUpdateInspectionModalVisible(!isUpdateInspectionModalVisible);

  const getFilterUrlAction = pageConfig.listViewActions?.find(mi => mi.id == SystemActions.GetFilterUrl);
  if (getFilterUrlAction) {
    getFilterUrlAction.action = copyToClipboardFiltersUrl;
  }

  const setFiltersToDefault = pageConfig.listViewActions?.find(mi => mi.id == SystemActions.SetFiltersToDefault);
  if (setFiltersToDefault) {
    setFiltersToDefault.action = () => {
      dispatch(
        initializeFiltersFromDefaults({
          defaultFilters: pageConfig.defaultFilters ?? [],
          entityKey: pageConfig.id,
          pageId: pageConfig.id,
        })
      );
    };
  }

  const mannualyUpdateInspectionAction = pageConfig.listViewActions?.find(mi => mi.id === "updateInspectionDetails");
  if (mannualyUpdateInspectionAction) {
    mannualyUpdateInspectionAction.action = mannualyUpdateInspection;
  }

  const onRowClick = (record: Record<string, unknown>) => {
    if (!table.disableNavigation) {
      if (pageConfig.listView?.customNavigation) {
        const pageKey = pageConfig.listView?.customNavigation.pageKey;
        const entityId = pageConfig.listView?.customNavigation.entityId(record);

        history.push(`/${pageKey}/${entityId}`);
      } else history.push(`/${pageConfig.id}/${record.id}`);
    }
  };

  const tableContent = () => {
    switch (table.type) {
      case "table":
        return (
          <Table
            ref={tableRef}
            key={`table-${pageConfig.id}`}
            locale={{
              emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("NoData")} />,
              triggerDesc: t("SortDesc"),
              triggerAsc: t("SortAsc"),
              cancelSort: t("SortCancel"),
            }}
            loading={
              {
                spinning: isTableLoading || isLoading,
                style: {
                  top: "20rem",
                },
              } as SpinProps
            }
            columns={resizableColumns}
            className="list-view-table"
            dataSource={filteredData}
            // showHeader={true}
            pagination={false}
            scroll={{
              x: `${getScrollXPercent(columns)}%`,
              y: "auto",
            }}
            rowKey={"id"}
            components={{ ...VList({ height: "auto" }), header: { cell: ResizableTitle } }}
            rowSelection={table.rowSelection ? { ...rowSelection } : undefined}
            onChange={(_, __, ___, extra) => {
              setNewState({ ...state, sortedData: extra.currentDataSource });
            }}
            onRow={record => {
              return {
                onClick: () => onRowClick(record),
              };
            }}
          />
        );

      case "tree":
        return (
          <TableTree
            tableRef={tableRef}
            pageId={pageConfig.id}
            tableData={filteredData}
            fullData={fullData}
            columns={resizableColumns}
            rowSelection={table.rowSelection ? { ...rowSelection } : undefined}
            isLoading={isTableLoading || isLoading}
            onRowClick={onRowClick}
          />
        );
      case "paginatedTable":
        return (
          <PaginatedTable
            tableRef={tableRef}
            pageId={pageConfig.id}
            isTableLoading={isTableLoading || isLoading}
            columns={resizableColumns}
            filteredData={filteredData}
            state={state}
            setNewState={setNewState}
            onRowClick={onRowClick}
            table={table}
            rowSelection={rowSelection}
          />
        );

      default:
        return null;
    }
  };

  // Note: if no data is still present in the store.
  return (
    <Col span={24} className="bordered-list-view-table">
      {/* <Dropdown.Button
        className="dropdown-button"
        trigger={["click"]}
        overlay={<Menu></Menu>}
        icon={<MoreOutlined className="dropdown-button__icon" />}
      /> */}
      <ConfirmationModal
        visible={errorMessages.length > 0}
        confirmationType={errorType ?? "cardValidationErrors"}
        confirmOkClick={handleClearErrors}
        messages={errorMessages}
      />
      <HBUploadModal
        setErrors={setErrors}
        uploadModalType={UploadModalType.TodoInspectionCheckpointFixed}
        isVisible={isUpdateInspectionModalVisible}
        beforeUpload={updateInspection}
        closeModal={closeUpdateInspectionModal}
      />
      <ConfirmationModal
        visible={state.isExporting}
        confirmOkClick={() => setNewState({ ...state, isExporting: false })}
        confirmationType="notification"
        messages={[t("Processing"), state.exportMessage || t("DownloadBegin", { ListExportLimit: listExportLimit })]}
      />
      <ConfirmationModal
        visible={!!state.error}
        confirmOkClick={() => setNewState({ ...state, error: undefined })}
        confirmationType="notification"
        messages={[
          state.error?.title ?? "Something went wrong",
          state.error?.message ?? "Please contact your administrator",
          "error",
        ]}
      />
      <Modal
        open={columnSelectionVisibility}
        footer={null}
        closable
        onCancel={() => handleColumnSelectionVisibility && handleColumnSelectionVisibility(false)}
        // confirmLoading={confirmAsyncModalLoading}
      >
        <ColumnSelection
          allColumns={allColumns}
          onSave={handleColumnSelection}
          onDiscard={handleColumnSelectionVisibility}
          selectedColumns={selectedColumns}
        />
      </Modal>
      <Col span={24} className="bordered-list-view-table" style={{ padding: 0 }}>
        {tableContent()}
      </Col>
    </Col>
  );
};

export default ListViewVTable;
