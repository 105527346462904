import { Button, Card, Row, Space } from "antd";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { userSelectors } from "../../../selectors";
import { useAppDispatch } from "../../../store/store";
import useNewEntityRecord from "../../../utils/hooks/useNewEntityRecord";
import EditableInputMobileFirst from "../../EditableInput/EditableInputMobileFirst";
import { DATE_FORMAT_WITHOUT_TIME } from "../../HBComponents/DatePicker/HBDatePicker";
import { useMobileSingleViewTabContext } from "../context/useMobileSingleViewTabContext";

interface TProps {
  setIsOptionsShown: (value: boolean) => void;
}

const MobileCardAddEntitySectionOptions: React.FC<TProps> = ({ setIsOptionsShown }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { tabConfig, stagingEntity } = useMobileSingleViewTabContext();
  const user = useSelector(userSelectors.getCurrentUser);

  const addButton = tabConfig.addButton;
  const fields = addButton?.addModalInputFields;
  const buttons = [...(fields?.primaryFields || []), ...(fields?.secondaryFields || [])];

  const { entity, setEntity, onChange, onFiledValueChange } = useNewEntityRecord({ fields });
  const addButtonVisible = addButton?.enablingFor ? addButton.enablingFor(user) : true;

  useEffect(() => {
    if (stagingEntity) {
      setEntity(stagingEntity);
    }
  }, [stagingEntity]);

  if (!addButton || !addButtonVisible) return null;

  const renderPredefinedButtons = () => {
    if (!buttons.length) return null;
    return (
      <Space direction="vertical" size={9} className="buttons-space">
        {buttons.map(field => {
          return (
            <EditableInputMobileFirst
              key={field.id}
              isEditMode={true}
              title={field.title}
              id={field.id}
              type={field.type}
              optionSelector={field.optionsSelector}
              fieldValue={isEmpty(entity) ? null : (field.value(entity) as string | null)}
              globalEdit={true}
              onChange={onChange(field.id)}
              prefixIcon={field.cellPrefixIcon}
              placeholder={field.placeholder}
            />
          );
        })}
      </Space>
    );
  };

  const renderAddButtonTemplate = () => {
    const Template = addButton?.mobileAddButtonTemplate;
    if (Template) {
      return <Template entity={entity} onChange={onChange} onFiledValueChange={onFiledValueChange} />;
    }
    return null;
  };

  const onCancel = () => {
    setIsOptionsShown(false);
  };

  const onSave = async () => {
    if (addButton?.uploadFileAction && entity.file) {
      await dispatch(
        addButton?.uploadFileAction({ file: entity.file, expiration: dayjs().format(DATE_FORMAT_WITHOUT_TIME) })
      );
    }
    if (addButton?.addRecordModalAction) {
      await dispatch(addButton?.addRecordModalAction(entity));
    }
    const actionField = addButton.actionField;
    if (actionField) {
      await dispatch(actionField.stagingActions?.[0]?.action(entity));
    }
    const addCommentAction = addButton.addCommentAction;
    if (addCommentAction && entity.comment) {
      await dispatch(addCommentAction({ comment: entity.comment as string }));
    }

    setIsOptionsShown(false);
  };

  return (
    <Card className="mobile-add-entity-section">
      {renderPredefinedButtons()}
      {renderAddButtonTemplate()}
      <Row className="mobile-control-buttons margin-top-15">
        <Button className="cancel" onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button className="save" type="primary" onClick={onSave}>
          {t("Add")}
        </Button>
      </Row>
    </Card>
  );
};

export default MobileCardAddEntitySectionOptions;
