import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Modal } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import dayjs from "dayjs";
import unionBy from "lodash/unionBy";
import { nanoid } from "nanoid";
import { CustomError, getCustomPropertiesWithValues } from "../../pages/pageConfig/category/utilities";
import { Equipment } from "../../types/equipment";
import {
  InspectionState,
  Inspection,
  InspectionSingleView,
  InspectionCheckpoint,
  InspectionStatus,
  InspectionPaginatedData,
  InspectionEquipmentResponseModel,
  InspectionLocationResponseModel,
  InspectionRecurrencyType,
} from "../../types/inspection";
import { ContextActions, ContextActionsPaginatedData } from "../../types/tasks";
import { CreateAppointmentResponseModel, ExplicitAdditionalProps } from "../../types/utility";
import { extractAndProcessAppointmentUrl } from "../../utils/functions";
import { generateFiltersAndEmptyProps, generateGridifySorterQuery } from "../../utils/gridifyQueryHelper";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

export const initialState: InspectionState = {
  data: [],
  defaultCustomProperties: [],
  subData: {
    equipments: [],
    actions: [],
  },
  isLoading: false,
  singleData: null,
  searchResults: [],
  error: null,
  paginationInfo: {
    count: 0,
    currentPage: 0,
  },
  lastUpdated: dayjs().toISOString(),
};

export const newInspection: InspectionSingleView = {
  id: 0,
  inspectionTypeId: 0,
  inspectionTypeName: "",
  status: InspectionStatus.Pending,
  expirationDate: "",
  lastCheck: "",
  nextCheck: "",
  checkpoints: [],
  recurrencyType: "",
  linkedEntity: {
    externalId: "",
    id: 0,
    location: "",
    name: "",
    type: "",
    typeId: 0,
    locationId: 0,
    linkedEntityType: "",
  },
  customPropertyValues: [],
  reviewId: 0,
  recurrency: 0,
};

const agragateInspectionBasedOnType = (
  inspections: (InspectionEquipmentResponseModel | InspectionLocationResponseModel)[],
  linkedEntityType: "equipment" | "location"
) => {
  return inspections.map(inspection => {
    if (linkedEntityType === "equipment") {
      const item: InspectionEquipmentResponseModel = { ...inspection } as InspectionEquipmentResponseModel;
      return {
        ...item,
        linkedEntityExternalId: item.equipmentSerialNumber,
        linkedEntityId: item.id,
        linkedEntityLocation: item.equipmentLocationName,
        linkedEntityName: item.equipmentName,
        linkedEntityTypeId: 0,
        linkedEntityTypeName: item.equipmentTypeName,
        linkedEntityLocationId: 0,
      };
    } else {
      const item: InspectionLocationResponseModel = { ...inspection } as InspectionLocationResponseModel;
      return {
        ...item,
        linkedEntityExternalId: item.locationExternalId,
        linkedEntityId: item.id,
        linkedEntityLocation: "",
        linkedEntityName: item.locationName,
        linkedType: item.inspectionTypeName,
        linkedEntityTypeName: "",
        linkedEntityTypeId: 0,
        linkedEntityLocationId: item.locationId,
      };
    }
  });
};

export const fetchInspectionCustomProps = createAsyncThunk<ExplicitAdditionalProps[], void, { state: RootState }>(
  "@@INSPECTION_CUSTOM_PROPS/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<ExplicitAdditionalProps[]>("/InspectionCustomProperty", hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { inspection } = getState();
      return !inspection.isLoading;
    },
  }
);

export const fetchSingleInspection = createAsyncThunk<
  {
    singleData: InspectionSingleView;
    subData: { equipments: Equipment[]; actions: ContextActions[] };
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>("@@INSPECTION/FETCH_SINGLE", async (id, { getState }) => {
  const { user } = getState();
  const pageId = window.location.pathname.replace("/", "").split("/")[0];
  const linkedEntityType = pageId === "inspectionEquipment" ? "equipment" : "location";
  const inspection = await hbApi.get<InspectionSingleView>(
    `/Inspection/${id}?linkedEntityType=${linkedEntityType}`,
    hbApiOptions(user.jwt)
  );
  const inspectionCustomProperties = await hbApi.get<ExplicitAdditionalProps[]>(
    "/InspectionCustomProperty",
    hbApiOptions(user.jwt)
  );

  const contextActionsResponse = await hbApi.get<ContextActionsPaginatedData>(
    `/Task/context-actions?entityId=${id}&entityType=Inspection`,
    hbApiOptions(user.jwt)
  );
  return {
    singleData: {
      ...inspection.data,
      customPropertyValues: getCustomPropertiesWithValues(
        inspectionCustomProperties.data,
        inspection.data.customPropertyValues
      ),
    },
    subData: {
      equipments: [],
      actions: contextActionsResponse.data.data,
    },
    defaultCustomProperties: inspectionCustomProperties.data,
  };
});

export const openNewAppointment = createAsyncThunk<void, { checkpoint: InspectionCheckpoint }, { state: RootState }>(
  "@@INSPECTION/OPEN_NEW_APPOINTMENT",
  async ({ checkpoint }, { getState, dispatch }) => {
    const { user, inspection } = getState();

    const isPasProEnabled = user.companySettings.enablePasPro;
    const baseUrl =
      inspection.singleData?.linkedEntity!.linkedEntityType === "Location"
        ? "/InspectionLocation/"
        : "/InspectionEquipment/";
    const getAppointmentInfo = async (check: boolean) =>
      await hbApi.post<CreateAppointmentResponseModel>(
        `${baseUrl}create-appointment`,
        {
          checkpointId: checkpoint.id,
          checkAppointmentAlreadyExists: check,
        },
        hbApiOptions(user.jwt, null, { LANGUAGE_KEY: user.settings.lang })
      );
    const {
      data: { warningMessage, urlToAppointment },
    } = await getAppointmentInfo(true);

    if (warningMessage) {
      Modal.confirm({
        content: warningMessage,
        onOk: async () => {
          try {
            const appointmentInfo = await getAppointmentInfo(false);
            extractAndProcessAppointmentUrl(appointmentInfo.data?.urlToAppointment, dispatch, isPasProEnabled);
          } catch (error) {
            console.error("Error processing appointment info on confirmation:", error);
          }
        },
        onCancel: () => null,
        closable: true,
        maskClosable: true,
      });
    } else {
      extractAndProcessAppointmentUrl(urlToAppointment, dispatch, isPasProEnabled);
    }

    return;
  }
);

export const confirmStatus = createAsyncThunk<
  { checkpoint: InspectionCheckpoint | null },
  { file: RcFile; value: string; entityId: number; entity?: Record<string, unknown> | undefined; date?: string },
  { state: RootState }
>("@@INSPECTION/CONFIRM_STATUS", async (model, { getState, dispatch }) => {
  const checkpoint = model.entity && (model.entity as InspectionCheckpoint);
  const { user, inspection } = getState();
  const currentCheckpoint = inspection.singleData?.checkpoints?.find(x => x.id === model.entityId) || null;

  // Data change check
  if (!currentCheckpoint) return { checkpoint: null };

  const formData = new FormData();

  formData.append("file", model.file);
  try {
    const fileUrl = await hbApi.post<string>("/File/upload-file", formData, hbApiOptions(user.jwt));
    const baseUrl =
      inspection.singleData?.linkedEntity!.linkedEntityType === "Location"
        ? "InspectionLocation"
        : "InspectionEquipment";

    let response;

    if (typeof model?.entity?.id === "string") {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...entity } = model.entity;

      const payload = {
        ...entity,
        fileUrl: fileUrl.data,
        completedAt: model?.date ? model.date.split("/").reverse().join("-") : "",
      };

      response = await hbApi.post<InspectionCheckpoint>(`/${baseUrl}`, payload, hbApiOptions(user.jwt));
    } else {
      const payload = [
        { path: "/comment", op: "replace", value: model.value },
        { path: "/fileUrl", op: "replace", value: fileUrl.data },
        { path: "/status", op: "replace", value: currentCheckpoint.status },
      ];
      if (model.date) {
        payload.push({ path: "/completedAt", op: "replace", value: model.date.split("/").reverse().join("-") });
      }

      response = await hbApi.patch<InspectionCheckpoint>(
        `/${baseUrl}/${model.entityId}`,
        payload,
        hbApiOptions(user.jwt)
      );
    }
    dispatch(fetchSingleInspection(checkpoint?.inspectionId.toString() || "0"));

    return {
      checkpoint: response.data,
    };
  } catch (e) {
    throw new CustomError(e.Message);
  }
});

export const changeCheckpointStatus = createAsyncThunk<
  { checkpoint: InspectionCheckpoint | null },
  {
    file: RcFile;
    comment: string;
    entityId: number;
    entity?: Record<string, unknown> | undefined;
    status: InspectionStatus | undefined;
    isLocationExists: boolean;
    completedAt: string;
  },
  { state: RootState }
>("@@INSPECTION/CHANGE_CHECKPOINT_STATUS", async (model, { getState }) => {
  const { user } = getState();
  const formData = new FormData();

  formData.append("file", model.file);

  const fileUrl = await hbApi.post<string>("/File/upload-file", formData, hbApiOptions(user.jwt));
  const baseUrl = model.isLocationExists ? "InspectionLocation" : "InspectionEquipment";
  const response = await hbApi.patch<InspectionCheckpoint>(
    `/${baseUrl}/${model.entityId}`,
    [
      { path: "/comment", op: "replace", value: model.comment },
      { path: "/fileUrl", op: "replace", value: fileUrl.data },
      { path: "/status", op: "replace", value: model.status },
      { path: "/completedAt", op: "replace", value: model.completedAt.split("/").reverse().join("-") },
    ],
    hbApiOptions(user.jwt)
  );
  return {
    checkpoint: response.data,
  };
});

export const bulkUpdateInspectionStatus = createAsyncThunk<
  boolean,
  {
    file: RcFile;
    comment: string;
    ids: number[];
    status: InspectionStatus | undefined;
    completedAt: string;
  },
  { state: RootState }
>("@@INSPECTION/BULK_UPDATE_INSPECTION_STATUS", async (model, { getState, dispatch }) => {
  const { user } = getState();
  const formData = new FormData();

  formData.append("file", model.file);
  const fileUrl = await hbApi.post<string>("/File/upload-file", formData, hbApiOptions(user.jwt));

  const pageId = window.location.pathname.replace("/", "");

  try {
    await hbApi.patch<InspectionCheckpoint>(
      `/${pageId}/bulk?ids=${model.ids.join(",")}`,
      [
        { path: "/comment", op: "replace", value: model.comment },
        { path: "/fileUrl", op: "replace", value: fileUrl.data },
        { path: "/status", op: "replace", value: model.status },
        { path: "/completedAt", op: "replace", value: model.completedAt.split("/").reverse().join("-") },
      ],
      hbApiOptions(user.jwt)
    );
    dispatch(fetchPaginatedInspection({ forceUpdate: true, page: 1, pageSize: user.companySettings.defaultPageSize }));
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
});

export const fetchPaginatedInspection = createAsyncThunk<
  { primaryData: Inspection[]; defaultCustomProperties: ExplicitAdditionalProps[]; possibleResults: number },
  { page?: number; pageSize?: number; forceUpdate?: boolean } | undefined,
  { state: RootState }
>(
  "@@INSPECTION/FETCH_PAGINATED",
  async (params, { getState }) => {
    const { user, inspection, filter } = getState();
    const pageId = window.location.pathname.replace("/", "") as "inspectionEquipment" | "inspectionLocation";
    const linkedEntityType = pageId === "inspectionEquipment" ? "equipment" : "location";

    const inspectionFilters = filter.filters[pageId][pageId]?.activeFilters;

    const { filters, emptyPropIds } = generateFiltersAndEmptyProps(inspectionFilters);

    const orders = filter.filterValues[pageId]?.order
      ? generateGridifySorterQuery(filter.filterValues[pageId]!.order)
      : undefined;

    const inspections = await hbApi.post<InspectionPaginatedData<"equipment" | "location">>(
      "/Inspection/pagedList",
      {
        gridifyQuery: {
          Page: params?.page || inspection.paginationInfo.currentPage + 1,
          PageSize: params?.pageSize,
          Filter: filters,
          OrderBy: orders,
        },
        emptyPropIds: emptyPropIds,
        linkedEntityType,
      },
      hbApiOptions(user.jwt)
    );

    return {
      primaryData: agragateInspectionBasedOnType(inspections.data.data, linkedEntityType),
      defaultCustomProperties: [],
      possibleResults: inspections.data.count,
    };
  },
  {
    condition: (arg, { getState }) => {
      const { inspection } = getState();
      return (
        arg?.forceUpdate ||
        (dayjs(inspection.lastUpdated).isBefore(dayjs()) &&
          !inspection.isLoading &&
          inspection.data.length !== inspection.paginationInfo.count)
      );
    },
  }
);
export const searchInspection = createAsyncThunk<
  Inspection[],
  { filters?: string; page?: number } | undefined,
  { state: RootState }
>(
  "@@INSPECTION/SEARCH_PAGINATED",
  async (params, { getState }) => {
    const { user } = getState();
    const pageId = window.location.pathname.replace("/", "") as "inspectionEquipment" | "inspectionLocation";
    const linkedEntityType = pageId === "inspectionEquipment" ? "equipment" : "location";
    const inspections = await hbApi.post<InspectionPaginatedData<"equipment" | "location">>(
      "/Inspection/pagedList",
      {
        gridifyQuery: {
          Page: params?.page || 1,
          PageSize: 100,
          Filter: params?.filters,
        },
        linkedEntityType,
      },
      hbApiOptions(user.jwt)
    );
    return agragateInspectionBasedOnType(inspections.data.data, linkedEntityType);
  },
  {
    condition: (_, { getState }) => {
      const { inspection } = getState();
      return !inspection.isLoading;
    },
  }
);

const slice = createSlice({
  name: "inspection",
  initialState,
  reducers: {
    resetCurrentPage: state => {
      state.paginationInfo.currentPage = 0;
    },
    createNewCheckpointEntryTemplate: state => {
      state?.singleData?.checkpoints.unshift({
        id: nanoid(),
        inspectionTypeName: "",
        status: InspectionStatus.Passed,
        endPeriod: "",
        completedAt: "",
        startPeriod: "",
        recurrencyType: InspectionRecurrencyType.Fixed,
        recurrency: 0,
        comment: "",
        fileUrl: "",
        staging: true,
        inspectionId: state.singleData?.id || 0,
        inspectionTypeId: state.singleData?.inspectionTypeId || 0,
        linkedEntityId: state.singleData?.linkedEntity?.id || 0,
      });
    },
    deleteNewCheckpointEntryTemplate: {
      prepare: (payload: InspectionCheckpoint) => ({ payload }),
      reducer: (state, action: PayloadAction<InspectionCheckpoint>) => {
        if (state.singleData && state.singleData.checkpoints && typeof action.payload.id === "string") {
          state.singleData.checkpoints = state.singleData.checkpoints.filter(r => r.id !== action.payload.id);
        }
      },
    },
    changeLocalStatus: {
      prepare: (payload: { entity: InspectionCheckpoint; status: InspectionStatus }) => ({ payload }),
      reducer: (state, action: PayloadAction<{ entity: InspectionCheckpoint; status: InspectionStatus }>) => {
        if (state.singleData && state.singleData.checkpoints) {
          const newStatus = action.payload.status;
          const checkpoint = state.singleData.checkpoints.find(x => x.id === action.payload.entity.id);

          if (!checkpoint || checkpoint.status === newStatus) return;
          state.editCheckpointData = { id: checkpoint.id, prevStatus: checkpoint.status };
          checkpoint.status = action.payload.status;
          checkpoint.staging = true;
        }
      },
    },
    confirmChangeLocalStatusFromEmbeded: state => {
      if (!state.singleData?.checkpoints || !state.editCheckpointData) return;
      const checkpoint = state.singleData.checkpoints.find(x => x.id === state.editCheckpointData?.id);
      if (!checkpoint) return;
      checkpoint.staging = false;
      state.editCheckpointData = undefined;
    },
    cancelCheckpointEdit: state => {
      if (state.singleData && state.singleData.checkpoints) {
        const checkpoint = state.singleData.checkpoints.find(x => x.id === state.editCheckpointData?.id);

        if (checkpoint) {
          const prevStatus = state.editCheckpointData?.prevStatus;
          if (prevStatus) {
            checkpoint.status = prevStatus;
          }
          checkpoint.staging = false;
        }
      }
    },
    clearInspectionError: state => {
      state.error = null;
    },
    resetSearchResults: state => {
      state.searchResults = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSingleInspection.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(confirmStatus.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(openNewAppointment.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchInspectionCustomProps.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(changeCheckpointStatus.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(bulkUpdateInspectionStatus.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPaginatedInspection.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchInspection.pending, state => {
        state.error = null;
      })
      .addCase(fetchSingleInspection.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchInspectionCustomProps.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(confirmStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(openNewAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(changeCheckpointStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(bulkUpdateInspectionStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchPaginatedInspection.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(searchInspection.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(fetchPaginatedInspection.fulfilled, (state, action) => {
        if (action.meta.arg?.page === 1) {
          state.data = action.payload.primaryData;
          state.paginationInfo.currentPage = 1;
        } else {
          state.data = unionBy(state.data, action.payload.primaryData, "id");
          state.paginationInfo.currentPage = state.paginationInfo.currentPage + 1;
        }
        state.paginationInfo.count = action.payload.possibleResults;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.lastUpdated = dayjs().toISOString();
        state.isLoading = false;
      })
      .addCase(searchInspection.fulfilled, (state, action) => {
        state.searchResults = [...action.payload];
        state.error = null;
      })
      .addCase(confirmStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        if (state.singleData && state.singleData.checkpoints && action.payload.checkpoint) {
          const index = state.singleData.checkpoints.findIndex(x => x.id === action.payload.checkpoint!.id);

          if (index >= 0) {
            state.singleData.checkpoints[index] = action.payload.checkpoint;
            state.singleData.checkpoints[index].staging = false;
          }
        }
      })
      .addCase(changeCheckpointStatus.fulfilled, state => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchSingleInspection.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.subData.equipments = action.payload.subData.equipments;
        state.subData.actions = action.payload.subData.actions;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchInspectionCustomProps.fulfilled, (state, action) => {
        state.defaultCustomProperties = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(bulkUpdateInspectionStatus.fulfilled, state => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(openNewAppointment.fulfilled, state => {
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const {
  changeLocalStatus,
  cancelCheckpointEdit,
  confirmChangeLocalStatusFromEmbeded,
  clearInspectionError,
  resetCurrentPage,
  createNewCheckpointEntryTemplate,
  deleteNewCheckpointEntryTemplate,
  resetSearchResults,
} = slice.actions;
export default slice.reducer;
