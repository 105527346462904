import "./webhookContextCondition.less";

import { SearchOutlined } from "@ant-design/icons";

import { Button, Input, Menu, Modal, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "../../store/store";
import { ReviewOptions } from "../../types/tasks";
import { CustomComponentProps } from "../TableCellRenderer/TableCellRenderer";

const mapStringToIds = (input: string): number[] => {
  return input
    .split("|")
    .map(item => parseInt(item.split("=")[1], 10))
    .filter(value => !isNaN(value));
};

export const WebhookContextConditionModal: React.FC<CustomComponentProps> = ({ onChange, managedValue }) => {
  const { t } = useTranslation();
  const { usersQuickCreateData } = useSelector((state: RootState) => state.actions);

  const [selectedReviews, setSelectedReviews] = useState<number[]>([]);
  const [reviewOptions, setReviewOptions] = useState<ReviewOptions[] | null>(null);
  const [filteredReviewOptions, setFilteredReviewOptions] = useState<ReviewOptions[] | null>(null);
  // const [initialProjectOptions, setInitialProjectOptions] = useState<Options[] | null>(null);
  // const [selectedProjectFilter, setSelectedProjectFilter] = useState<number | null>(null);
  const selectedProjectFilter = null;
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useEffect(() => {
    const allReviews = usersQuickCreateData.map(x => ({
      id: x.reviewId,
      label: x.reviewName,
      projectId: x.projectId,
    }));
    const uniqueReviews = [...new Map(allReviews.map(item => [item.id, item])).values()];
    setReviewOptions(uniqueReviews);

    // const projectOptions = [...new Map(usersQuickCreateData.map(item => [item.projectId, item])).values()]
    //   .filter(x => x.projectId)
    //   .map(x => ({ id: x.projectId as number, label: x.projectName }));
    // setInitialProjectOptions(projectOptions);
    setFilteredReviewOptions(uniqueReviews);
  }, [usersQuickCreateData]);

  useEffect(() => {
    if (modalVisible) {
      setSelectedReviews(mapStringToIds(String(managedValue)));
    }
  }, [modalVisible]);

  const handleReviewChange = useCallback((reviewId: number) => {
    setSelectedReviews(prevSelected =>
      prevSelected.includes(reviewId) ? prevSelected.filter(id => id !== reviewId) : [...prevSelected, reviewId]
    );
  }, []);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value.toLowerCase();
      setFilteredReviewOptions(
        reviewOptions?.filter(
          x =>
            x.label.toLowerCase().includes(searchValue) &&
            (selectedProjectFilter === 0
              ? !x.projectId
              : selectedProjectFilter
              ? x.projectId === selectedProjectFilter
              : true)
        ) || []
      );
    },
    [reviewOptions, selectedProjectFilter]
  );

  // const handleProjectFilterClick = useCallback(
  //   (projectId: number | null) => {
  //     setSelectedProjectFilter(projectId);
  //     setFilteredReviewOptions(reviewOptions?.filter(review => review.projectId === projectId) || []);
  //   },
  //   [reviewOptions]
  // );

  const searchInput = (
    <Input
      className="search"
      prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
      bordered={false}
      id="review-search"
      placeholder={t("SearchButton")}
      allowClear={true}
      onChange={handleSearchChange}
    />
  );

  return (
    <>
      <span className="pointer" onClick={() => setModalVisible(true)}>
        {t("SelectReviews")}
      </span>
      <Modal
        onOk={() => {
          onChange(selectedReviews.map(id => `ReviewId=${id}`).join("|"));
          setModalVisible(false);
        }}
        open={modalVisible}
        wrapClassName="modalWrapper"
        okButtonProps={{ disabled: selectedReviews.length === 0 }}
        onCancel={() => setModalVisible(false)}
        centered
        className="review-creation-modal"
      >
        <div>
          <Typography.Paragraph className="new-review-title">{t("SelectReviews")}</Typography.Paragraph>
        </div>
        <Menu selectedKeys={selectedReviews.map(String)} className="new-review-menu" multiple>
          <div className="search-bar">
            {searchInput}

            {/* //temp disabled
            <Popover
              placement="right"
              content={
                <Menu>
                  {initialProjectOptions?.map(x => (
                    <Menu.Item onClick={() => handleProjectFilterClick(x.id)} key={x.id}>
                      {x.label}
                    </Menu.Item>
                  ))}
                  <Menu.Item onClick={() => handleProjectFilterClick(0)} key="reviewWithoutProject">
                    {t("[NoProject]")}
                  </Menu.Item>
                </Menu>
              }
              trigger="click"
            >
              <FilterOutlined />
            </Popover> */}
          </div>

          <span className="selectall-wrapper">
            <Button
              onClick={() => {
                setSelectedReviews(
                  Array.from(new Set([...selectedReviews, ...(filteredReviewOptions ?? []).map(x => x.id)]))
                );
              }}
              type="text"
            >
              {t("FieldSelectAll")}
            </Button>

            <Button
              onClick={() => {
                setSelectedReviews(
                  [...selectedReviews].filter(id => !(filteredReviewOptions ?? []).map(x => x.id).includes(id))
                );
              }}
              type="text"
            >
              {t("FieldUnselectAll")}
            </Button>
          </span>

          {filteredReviewOptions?.map(x => (
            <Menu.Item onClick={() => handleReviewChange(x.id)} key={x.id}>
              {x.label}
            </Menu.Item>
          ))}
        </Menu>
      </Modal>
    </>
  );
};
