import "./singleViewCardMobileFirst.less";

import { LeftOutlined } from "@ant-design/icons";

import { Button, Col, Form, Row } from "antd";
import clsx from "clsx";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";

import PageConfigurationContext from "../../context/pageContext";
import { ReactComponent as EditIcon } from "../../media/edit-icon.svg";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage } from "../../types/page";
import useMobilePullToRefreshData from "../../utils/hooks/useMobilePullToRefreshData";
import { EditingButtons } from "../HBComponents/CreateTaskSwtich/EditingButtons";
import SingleViewHeader from "./components/HeaderComponent";
import SingleViewCardMobileDialogs from "./components/SingleViewCardMobileDialogs";
import SingleViewMobileTabs from "./components/SingleViewMobileTabs";
import { ABOUT_TAB_KEY, useMobileSingleViewContext } from "./context/useMobileSingleViewContext";

const SingleViewCardMobileFirst: React.FC = () => {
  const { summaryCard } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;

  const {
    singleViewRef,
    currentData,
    canEditCard,
    isNewEntity,
    isEdited,
    isGlobalEditForbidden,
    setIsEdited,
    form,
    onSubmitChanges,
    onFinishFailed,
    todoListInParams,
    handleDependentFieldsValues,
    handleBlockedNavigation,
    handleBack,
    handleBackToPageBeforeDetailPage,
    resetEditMode,
    activeTab,
    handleDataFetch,
  } = useMobileSingleViewContext();

  const { t } = useTranslation();

  const handleRefreshData = async () => {
    handleDataFetch();
    endRefreshData();
  };

  const { endRefreshData, PullToRefreshOnWeb, PullToRefreshContextProvider } = useMobilePullToRefreshData({
    refreshHandler: handleRefreshData,
  });

  return (
    <PullToRefreshContextProvider>
      <PullToRefreshOnWeb onRefresh={handleRefreshData} disabled={isEdited}>
        <div ref={singleViewRef} className={clsx("single-mobile-first", isEdited && "edit-mode")}>
          <Prompt when={isEdited} message={handleBlockedNavigation}></Prompt>
          <SingleViewCardMobileDialogs />
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmitChanges}
            onFinishFailed={onFinishFailed}
            requiredMark={false}
            validateTrigger={["onChange"]}
            onValuesChange={handleDependentFieldsValues}
          >
            <Row>
              <Row className="full-width">
                <Col className="fields-section full-width">
                  <div className="upper-section">
                    <div className="back">
                      <LeftOutlined onClick={handleBack} />
                      <Button className="back-to-search-button" onClick={handleBackToPageBeforeDetailPage}>
                        {t(`${todoListInParams ? "MobileBackToAgenda" : "MobileBackToSearch"}`)}
                      </Button>
                    </div>
                    {(!isEdited && summaryCard.actionComponent && !isNewEntity && currentData && (
                      <Row>{summaryCard.actionComponent(currentData)}</Row>
                    )) ||
                      null}
                  </div>

                  <SingleViewHeader
                    editIcon={
                      activeTab == ABOUT_TAB_KEY && !isEdited && !isGlobalEditForbidden && canEditCard ? (
                        <EditIcon className="edit-icon" onClick={() => setIsEdited(true)} />
                      ) : (
                        <></>
                      )
                    }
                  />

                  <SingleViewMobileTabs />
                </Col>
              </Row>
            </Row>
            {isEdited && (
              <div style={{ width: "100%" }}>
                <EditingButtons isEditing={isEdited} handleCancel={resetEditMode} />
              </div>
            )}
          </Form>
        </div>
      </PullToRefreshOnWeb>
    </PullToRefreshContextProvider>
  );
};

export default SingleViewCardMobileFirst;
