import { SerializedError } from "@reduxjs/toolkit";
import { Filter } from "../utils/hooks/useFilter";
import { TwoFactorType } from "./utility";

export type Credentials = {
  userName: string;
  password: string;
  rememberMe: boolean;
};

export type ResetPasswordModel = {
  key?: string;
  password?: string;
  repeatPassword?: string;
};

export type UserToken = {
  token?: string;
};

export type ChangeCompanyContext = {
  token?: string;
  companyId?: number;
  clearMobileData?: boolean;
};

export type ChangePasswordModel = {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
};

export type ImpersonateData = {
  companyId: number;
  userId: number;
  supportTicket: string;
  jiraTicket: string;
  reason: string;
};

export type SaveEntityColSelSettingsParams = {
  method: "post" | "put";
  newValues: { key: string; value: string[] };
};

export type StoreUserFiltersParams = {
  method: "post" | "put";
  newValues: { key: string; value: Filter[] };
};

export type AvailableComapny = {
  name: string;
  id: number;
};

export enum UserRole {
  SU = "su",
  Admin = "Admin",
  TenantAdmin = "Tenant Admin",
  Manager = "Manager",
  User = "User",
  TechnicalUser = "Technical User",
  Reporter = "Reporter",
  None = "None",
}

export enum EmployeeRole {
  Admin = "Admin",
  User = "User",
}

export enum SuMode {
  SU = "SU",
  Impersonate = "Impersonate",
}

export interface UserState {
  id: number;
  jwt: string;
  cubeToken: string;
  isLoading: boolean;
  changeUserContextInProgress: boolean;
  error: SerializedError | null;
  userId: string | undefined;
  settings: {
    companyId: number;
    defaultComapnyId: number;
    availableComapnies: AvailableComapny[];
    displayName: string;
    direction: "rtl" | "ltr";
    isSysAdmin: boolean;
    suMode?: SuMode;
    avatarUrl: string;
    columns: FetchUISettingsResponse[];
    lang: Language;
    role: UserRole;
  };
  companySettings: TCompanySettings;
  personalSettings: UserPersonalSettings;
  mode: Mode;
  logoInternalUrl?: string;
  avatarInternalUrl?: string;
}

export enum Language {
  "en-US" = "en-US",
  "es-ES" = "es-ES",
  "he-IL" = "he-IL",
  "ru-RU" = "ru-RU",
  "uk-UA" = "uk-UA",
  "ar" = "ar",
  "fr-FR" = "fr-FR",
}

export type FetchUISettingsResponse = {
  key: string;
  value: string[];
};

export type UserSettings = {
  key: string;
  value: string;
};

export type UserPrivileges = {
  orgUnitOwner: boolean;
};

export type UserPersonalSettings = {
  language: string;
  avatarUrl: string;
  timeZone: string;
  jobTitle: string;
  hasForms: boolean;
  userPrivileges: UserPrivileges;
};

export type TResponse = {
  error: string;
  userError: string;
  data: {
    status: string;
    token: string;
    displayName: string;
    avatarUrl: string;
    defaultCompanyId: number;
    isSysAdmin: boolean;
    companyId?: string;
  };
};

export type TCubeTokenResponse = string;

export type TCompanySettingsRequest = {
  jwt?: string;
};

type ColorsType = {
  mainColor?: string;
  mainHoverColor?: string;
  buttonCancelColor?: string;
  buttonCancelHoverColor?: string;
  buttonPrimaryColor?: string;
  buttonPrimaryHoverColor?: string;
  backgroundColor?: string;
  navbarTopColor?: string;
};

export type TCompanySettings = {
  firstDayOfWeek: string;
  colors: ColorsType;
  phoneCountryCode: string;
  logo: string;
  currentUserId: number;
  isMapEnabled: boolean;
  isNewPasEnabled: boolean;
  newPassSmartDashboardAccess?: SmartDashboardAccess;
  newPassAccess: string;
  isTracked: boolean;
  legacyEnabled: boolean;
  agendaActionsMode: boolean;
  authType: AuthType;
  twoFactorType: TwoFactorType;
  enablePasPro?: boolean;
  defaultPageSize: number;
  listExportLimit: number;
};

export enum SmartDashboardAccess {
  Disabled = "Disabled",
  TenantAdmin = "TenantAdmin",
  AllUsers = "AllUsers",
}

export enum NewPassAccess {
  NewPasDisabled = "NewPasDisabled",
  TenantAdmin = "TenantAdmin",
  AllUsers = "AllUsers",
  NewPasHome = "NewPasHome",
  LegacyDisabled = "LegacyDisabled",
}

export enum AuthType {
  OTP = "OTP",
  SSO = "SSO",
  Username = "Username",
}

export type TUserContextResponse = {
  error: string;
  userError: string;
  data: {
    companies: [
      {
        id: number;
        name: string;
      }
    ];
  };
};

export type LoginWithKeyResp = {
  data: {
    token: string;
  };
};

export enum Mode {
  Phone = "Phone",
  Code = "Code",
}
