import "./fileInfoSideBar.less";

import { unwrapResult } from "@reduxjs/toolkit";

import { Col, Form, Image, Row, Skeleton, Space, Spin } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as CloseIcon } from "../../../media/close-icon.svg";
import { ReactComponent as DatePickerIcon } from "../../../media/datePicker-icon.svg";
import { ReactComponent as DownloadIcon } from "../../../media/download-icon.svg";
import { getSasLinks, setFileInfoToOpen } from "../../../store/slices/common";
import { RootState, useAppDispatch } from "../../../store/store";
import { onDownloadFile } from "../../../utils/files";
import EditableInput from "../../EditableInput/EditableInput";
import PDFViewComponent from "../../PDFViewModal/PDFViewComponent";
import { DATE_FORMAT_WITHOUT_TIME } from "../DatePicker/HBDatePicker";

const supportedPreviewImageTypes = ["png", "jpg", "jpeg"];

export const FileInfoSideBar = (): JSX.Element | null => {
  const { t } = useTranslation();
  const fileInfo = useSelector((state: RootState) => state.common.fileInfo);
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);

  const dispatch = useAppDispatch();
  const [fileLocalURL, setFileLocalURL] = useState<string | undefined>();
  const [fileType, setFileType] = useState<string | undefined>();

  const onClose = () => {
    dispatch(setFileInfoToOpen(undefined));
  };

  useEffect(() => {
    if (fileInfo?.url) {
      setFileType(fileInfo.url.split(".").pop());
      dispatch(getSasLinks([fileInfo.url]))
        .then(response => unwrapResult(response))
        .then(data => {
          setFileLocalURL(data[0].tokenizedUrl);
        });
    }
    return () => {
      setFileLocalURL(undefined);
      setFileType(undefined);
    };
  }, [fileInfo?.url]);

  if (!fileInfo) return null;

  const getField = (id: string, title: string, value: string | number | null, icon?: React.ReactNode | null) => {
    return (
      <Col key={id} className="field-wrapper" span={12}>
        <Row>
          <Form.Item name={id} className={`${id} form-item`}>
            <EditableInput
              fieldValue={value}
              onChange={value => console.warn(value)}
              type="text"
              title={title}
              placeholder={t(title)}
              icon={icon}
              id={id}
              globalEdit={true}
              optionSelector={() => null}
            />
          </Form.Item>
        </Row>
      </Col>
    );
  };

  const onDownload = () => {
    onDownloadFile(fileInfo);
  };

  const getPreviewOfFile = () => {
    if (fileType) {
      if (supportedPreviewImageTypes.includes(fileType)) {
        return (
          <div className="centeralized-preview">
            <Image width={200} src={fileLocalURL} preview={false} />
          </div>
        );
      }
      if (fileType === "pdf") {
        return <PDFViewComponent fileId={fileInfo.url} hideButtons />;
      } else {
        return (
          <div className="centeralized-preview">
            <Space direction="vertical" align="center">
              <Skeleton.Image style={{ backgroundColor: "lightgray" }} />
              <span>{t("PreviewNotSupported", "Preview Not Supported")}</span>
            </Space>
          </div>
        );
      }
    }
  };

  return (
    <div className="file-info-sidebar">
      <div className="file-info-block" onBlur={onClose}>
        <div className="header">
          {/* <ActionsIcon /> */}
          {/* Remove span, when actions will be returned */}
          <span></span>
          <CloseIcon onClick={onClose} />
        </div>
        <div className="file-data">
          <div className="title">{fileInfo.name}</div>
          <Row className="fields-section__primary" gutter={[32, 0]}>
            {fileInfo.createdDate
              ? getField(
                  "createDate",
                  t("CreateDate"),
                  dayjs(fileInfo.createdDate).isValid()
                    ? dayjs(fileInfo.createdDate).format(DATE_FORMAT_WITHOUT_TIME)
                    : t("Undefined").toString(),
                  <DatePickerIcon fill={primaryColor} />
                )
              : null}
            {fileInfo.expirationDate
              ? getField(
                  "expiration",
                  t("FieldExpiration"),
                  dayjs(fileInfo.expirationDate).isValid()
                    ? dayjs(fileInfo.expirationDate).format(DATE_FORMAT_WITHOUT_TIME)
                    : t("Undefined").toString(),
                  <DatePickerIcon fill={primaryColor} />
                )
              : null}
            {fileInfo.createdByUser ? getField("userCreated", t("UserCreated"), fileInfo.createdByUser?.name) : null}
            {fileType ? getField("type", t("FieldType"), fileType) : null}
          </Row>
          {fileLocalURL ? getPreviewOfFile() : <Spin className="file-loader-spinner" spinning />}
          <div className="download-button" onClick={onDownload}>
            <DownloadIcon /> {t("Download")}
          </div>
        </div>
      </div>
    </div>
  );
};
