import { createAsyncThunk, createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";

import dayjs from "dayjs";
import unionBy from "lodash/unionBy";

import { DATE_FORMAT_WITHOUT_TIME } from "../../components/HBComponents/DatePicker/HBDatePicker";
import { CustomError, getCustomPropertiesWithValues } from "../../pages/pageConfig/category/utilities";
import { Equipment } from "../../types/equipment";
import { File, FileUploadResponse } from "../../types/files";
import { InspectionStatus, InspectionTabModel } from "../../types/inspection";
import { Location, LocationPaginatedData, LocationState } from "../../types/location";
import { LocationType } from "../../types/locationType";
import { ContextActions, ContextActionsPaginatedData } from "../../types/tasks";
import { ExplicitAdditionalProps, PrivilegeData, Status, UpdateCustomProps } from "../../types/utility";
import { generateFiltersAndEmptyProps, generateGridifySorterQuery } from "../../utils/gridifyQueryHelper";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import { fetchHistoryLog } from "./commonThunks";
import {
  addEntityPrivilege,
  createNewPrivilegeTemplate,
  deleteEntityPrivilege,
  deleteMultipleEntityPrivileges,
  deleteNewPrivilegeEntryTemplate,
  fillNewPrivilegeEntryTemplate,
  getEntityPrivileges,
  updateAccountableEntity,
  updateEntityPrivilegeOULvl,
  updateEntityPrivilegeRole,
  updateNewPrivilegeEntry,
} from "./privileges";

// TODO: REMOVE DRY CODE (ACTIONS/THUNKS/TYPES). ESPECIALLY FOR TABS FUNCTIONALITY

export const initialState: LocationState = {
  data: [],
  basicData: [],
  isLoading: false,
  subData: {
    locations: [],
    accountable: [],
    equipmentCustomProps: [],
    equipments: [],
    historyLog: [],
    inspections: [],
    locationTypes: [],
    actions: [],
  },
  singleData: null,
  error: null,
  defaultCustomProperties: [],
  lastUpdated: dayjs().toISOString(),
  searchResults: [],
  paginationInfo: {
    count: 0,
    currentPage: 0,
  },
};

export const newLocation: Location = {
  id: 0,
  parentId: null,
  parentName: "",
  name: "",
  status: Status.Active,
  externalId: null,
  typeId: null,
  typeName: "",
  isSite: false,
  iconType: "10",
};

export const fetchFullLocations = createAsyncThunk<{ basicData: Location[] }, void, { state: RootState }>(
  "@@LOCATIONS/FETCH_BASIC",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<Location[]>("/Location/full", hbApiOptions(user.jwt));
    return {
      basicData: response.status === 200 ? response.data : [],
    };
  }
);

export const fetchLocationCustomProps = createAsyncThunk<ExplicitAdditionalProps[], void, { state: RootState }>(
  "@@LOCATIONS_CUSTOM_PROPS/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<ExplicitAdditionalProps[]>("/LocationCustomProperty", hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { location } = getState();
      return !location.isLoading;
    },
  }
);

export const fetchSingleLocation = createAsyncThunk<
  {
    singleData: Location;
    subData: {
      locations: Location[];
      equipments: Equipment[];
      equipmentCustomProps: ExplicitAdditionalProps[];
      inspections: InspectionTabModel[];
      locationTypes: LocationType[];
      actions: ContextActions[];
    };
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>(
  "@@SINGLE_LOCATION/FETCH",
  async (id, { getState, rejectWithValue }) => {
    const { user } = getState();
    try {
      const response = await hbApi.get<Location>(`/Location/${id}`, hbApiOptions(user.jwt));
      const equipmentsResponse = await hbApi.get<Equipment[]>(`/Equipment?locationId=${id}`, hbApiOptions(user.jwt));
      const equipmentCustomProperties = await hbApi.get<ExplicitAdditionalProps[]>(
        "/EquipmentCustomProperty",
        hbApiOptions(user.jwt)
      );
      const inspections = await hbApi.get<InspectionTabModel[]>(
        `/InspectionLocation/get-inspections-by-location/${id}`,
        hbApiOptions(user.jwt)
      );
      const contextActionsResponse = await hbApi.get<ContextActionsPaginatedData>(
        `/Task/context-actions?entityId=${id}&entityType=Location`,
        hbApiOptions(user.jwt)
      );
      const locationTypesResponse = await hbApi.get<LocationType[]>("/LocationType", hbApiOptions(user.jwt));
      const locationCustomProperties = await hbApi.get<ExplicitAdditionalProps[]>(
        "/LocationCustomProperty",
        hbApiOptions(user.jwt)
      );

      return {
        singleData: {
          ...response.data,
          customPropertyValues: getCustomPropertiesWithValues(
            locationCustomProperties.data,
            response.data.customPropertyValues
          ),
        },
        subData: {
          locations: getState().location.data,
          equipments: equipmentsResponse.data,
          equipmentCustomProps: equipmentCustomProperties.data,
          inspections: inspections.data,
          locationTypes: locationTypesResponse.data,
          actions: contextActionsResponse.data.data,
        },
        defaultCustomProperties: locationCustomProperties.data,
      };
    } catch (e) {
      if (e.status === 400 || e.status === 404) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();
      return !!user.jwt;
    },
  }
);

export const addLocation = createAsyncThunk<Location, { entity: Location }, { state: RootState }>(
  "@@LOCATION/ADD",
  async ({ entity }, { getState, rejectWithValue }) => {
    const { user, location } = getState();
    // Note: We need to modify the request body for the custom props, before sending it to the server
    const modifiedPropertyValues: UpdateCustomProps = {};
    entity.customPropertyValues?.forEach(prop => {
      modifiedPropertyValues[prop.propertyId] = prop.value;
    });

    try {
      const modifiedEntity = { ...entity, customPropertyValues: modifiedPropertyValues };
      const response = await hbApi.post<Location>("/Location", modifiedEntity, hbApiOptions(user.jwt));

      return {
        ...response.data,
        customPropertyValues: getCustomPropertiesWithValues(
          location.defaultCustomProperties,
          response.data.customPropertyValues
        ),
      };
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { location, user } = getState();
      return !location.isLoading && !!user.jwt;
    },
  }
);

export const patchLocationParentId = createAsyncThunk<
  Location,
  { objectId: number; newValue: number },
  { state: RootState }
>(
  "@@LOCATION/PATCH_PARENT_ID",
  async ({ objectId, newValue }, { getState, rejectWithValue }) => {
    const { user } = getState();

    try {
      const response = await hbApi.patch<Location>(
        `/Location/${objectId}`,
        [{ path: "/parentId", op: "replace", value: newValue }],
        hbApiOptions(user.jwt)
      );
      return response.data;
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError();
    }
  },
  {
    condition: (_, { getState }) => {
      const { location, user } = getState();
      return !location.isLoading && !!user.jwt;
    },
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadFile = createAsyncThunk<File[] | null, { file: any; expiration: string }, { state: RootState }>(
  "@@LOCATION/UPLOAD_FILE",
  async ({ file, expiration }, { getState }) => {
    const { user, location } = getState();
    const formData = new FormData();
    formData.append("files[]", file);
    const fileEntity = await hbApi.post<FileUploadResponse>(
      `/File/upload?entityType=Location&entityId=${location?.singleData?.id || 0}&expirationDate=${expiration}`,
      formData,
      hbApiOptions(user.jwt)
    );

    return fileEntity.data?.data;
  }
);

export const updateLocation = createAsyncThunk<Location, Location, { state: RootState }>(
  "@@LOCATION/UPDATE",
  async (entity, { getState, rejectWithValue }) => {
    const { user, location } = getState();

    // Note: We need to modify the request body for the custom props, before sending it to the server
    const modifiedPropertyValues: UpdateCustomProps = {};
    entity.customPropertyValues?.forEach(prop => {
      modifiedPropertyValues[prop.propertyId] = prop.value;
    });

    try {
      const modifiedEntity = { ...entity, customPropertyValues: modifiedPropertyValues };
      const response = await hbApi.put<Location>(`/Location/${entity.id}`, modifiedEntity, hbApiOptions(user.jwt));

      return {
        ...response.data,
        customPropertyValues: getCustomPropertiesWithValues(
          location.defaultCustomProperties,
          response.data.customPropertyValues
        ),
      };
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { location, user } = getState();
      return !location.isLoading && !!user.jwt;
    },
  }
);

export const fetchPaginatedLocations = createAsyncThunk<
  { primaryData: Location[]; defaultCustomProperties: ExplicitAdditionalProps[]; possibleResults: number },
  { page?: number; pageSize?: number; forceUpdate?: boolean } | undefined,
  { state: RootState }
>(
  "@@LOCATION/FETCH_PAGINATED",
  async (params, { getState }) => {
    const { user, location, filter } = getState();

    const locationFilters = filter.filters.location?.location?.activeFilters;

    const { filters, emptyPropIds } = generateFiltersAndEmptyProps(locationFilters);

    const orders = filter.filterValues.location?.order
      ? generateGridifySorterQuery(filter.filterValues.location?.order)
      : undefined;

    const response = await hbApi.post<LocationPaginatedData>(
      "/Location/pagedList",
      {
        gridifyQuery: {
          Page: params?.page || location.paginationInfo.currentPage + 1,
          PageSize: params?.pageSize,
          Filter: filters,
          OrderBy: orders,
        },
        emptyPropIds: emptyPropIds,
      },
      hbApiOptions(user.jwt)
    );

    const locationCustomProperties = await hbApi.get<ExplicitAdditionalProps[]>(
      "/LocationCustomProperty",
      hbApiOptions(user.jwt)
    );
    return {
      primaryData: response.data.data,
      defaultCustomProperties: locationCustomProperties.data,
      possibleResults: response.data.count,
    };
  },
  {
    condition: (arg, { getState }) => {
      const { location } = getState();

      return (
        arg?.forceUpdate ||
        (dayjs(location.lastUpdated).isBefore(dayjs()) &&
          !location.isLoading &&
          location.data.length !== location.paginationInfo.count)
      );
    },
  }
);
export const searchLocation = createAsyncThunk<
  Location[],
  { filters?: string; page?: number } | undefined,
  { state: RootState }
>(
  "@@LOCATION/SEARCH_PAGINATED",
  async (params, { getState }) => {
    const { user } = getState();
    const response = await hbApi.post<LocationPaginatedData>(
      "/Location/pagedList",
      {
        gridifyQuery: {
          Page: params?.page || 1,
          PageSize: 100,
          Filter: params?.filters,
        },
      },
      hbApiOptions(user.jwt)
    );
    return response.data.data;
  },
  {
    condition: (_, { getState }) => {
      const { location } = getState();
      return !location.isLoading;
    },
  }
);

export const slice = createSlice({
  name: "location",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    createLocationTemplate: state => {
      const customProps = state.defaultCustomProperties;
      state.singleData = {
        ...newLocation,
        customPropertyValues: getCustomPropertiesWithValues(customProps),
      };
      state.subData.locations = state.data;
    },
    clearLocationError: state => {
      state.error = null;
    },
    createNewAccountableEntryTemplate: createNewPrivilegeTemplate,
    fillNewAccountableEntryTemplate: {
      prepare: (payload: { row: PrivilegeData; targetEntity: Record<string, unknown> }) => ({ payload }),
      reducer: fillNewPrivilegeEntryTemplate,
    },
    deleteNewAccountableEntryTemplate: {
      prepare: (payload: PrivilegeData) => ({ payload }),
      reducer: deleteNewPrivilegeEntryTemplate,
    },
    updateNewAccountableEntry: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      prepare: (payload: { entity: PrivilegeData; newValue: any; property: keyof PrivilegeData }) => ({ payload }),
      reducer: updateNewPrivilegeEntry,
    },
    updateParentId: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        if (state.singleData) {
          state.singleData = { ...state.singleData, parentId: action.payload };
        }
      },
    },
    resetCurrentPage: state => {
      state.paginationInfo.currentPage = 0;
    },
    resetSearchResults: state => {
      state.searchResults = [];
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchFullLocations.pending, state => {
        state.isLoading = true;
        state.basicData = [];
        state.error = null;
      })
      .addCase(fetchSingleLocation.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPaginatedLocations.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchLocation.pending, state => {
        state.error = null;
      })
      // Fetch location custom props
      .addCase(fetchLocationCustomProps.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadFile.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Add
      .addCase(addLocation.pending, state => {
        state.isLoading = true;
        state.error = null;
      })

      // Update
      .addCase(updateLocation.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEntityPrivileges.pending, state => {
        state.isLoading = true;
        state.error = null;
      })

      // Patch Parent ID
      .addCase(patchLocationParentId.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Note - Rejected:
      .addCase(fetchFullLocations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
        state.singleData = undefined;
      })
      // Fetch location custom props
      .addCase(fetchLocationCustomProps.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // Add
      .addCase(addLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      // Update
      .addCase(updateLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      // Patch Parent ID
      .addCase(patchLocationParentId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchHistoryLog.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(getEntityPrivileges.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchPaginatedLocations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(searchLocation.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      // Note - Fulfilled:
      .addCase(fetchPaginatedLocations.fulfilled, (state, action) => {
        if (action.meta.arg?.page === 1) {
          state.data = action.payload.primaryData;
          state.paginationInfo.currentPage = 1;
        } else {
          state.data = unionBy(state.data, action.payload.primaryData, "id");
          state.paginationInfo.currentPage = state.paginationInfo.currentPage + 1;
        }
        state.paginationInfo.count = action.payload.possibleResults;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.lastUpdated = dayjs().toISOString();
        state.isLoading = false;
      })
      .addCase(searchLocation.fulfilled, (state, action) => {
        state.searchResults = [...action.payload];
        state.error = null;
      })
      .addCase(fetchFullLocations.fulfilled, (state, action) => {
        state.basicData = action.payload.basicData;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchSingleLocation.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.subData.locations = action.payload.subData.locations;
        state.subData.equipments = action.payload.subData.equipments;
        state.subData.equipmentCustomProps = action.payload.subData.equipmentCustomProps;
        state.subData.inspections = action.payload.subData.inspections;
        state.subData.locationTypes = action.payload.subData.locationTypes;
        state.subData.actions = action.payload.subData.actions;
        state.subData.accountable = [];
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.isLoading = false;
      })
      // Fetch location custom props
      .addCase(fetchLocationCustomProps.fulfilled, (state, action) => {
        state.defaultCustomProperties = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      // Add
      .addCase(addLocation.fulfilled, (state, action) => {
        state.singleData = action.payload;
        state.data = state.data ? [...state.data, action.payload] : [action.payload];
        state.basicData = state.basicData ? [...state.basicData, action.payload] : [action.payload];
        state.isLoading = false;
        state.error = null;
      })

      // Update
      .addCase(updateLocation.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(equipment => equipment.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.subData.inspections = state.subData.inspections.map(x => ({
          ...x,
          status:
            action.payload.status === Status.InActive
              ? InspectionStatus.IsNoLongerNeeded
              : x.status === InspectionStatus.IsNoLongerNeeded
              ? InspectionStatus.Pending
              : x.status,
        }));
        state.isLoading = false;
        state.error = null;
      })

      // Patch Parent ID
      .addCase(patchLocationParentId.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(equipment => equipment.id === action.payload.id);
        state.data[updatedEntityIndex].parentId = action.payload.parentId;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getEntityPrivileges.fulfilled, (state, action) => {
        state.subData.accountable = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      // Add LocationEmployee
      .addCase(addEntityPrivilege.fulfilled, (state, action) => {
        state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
        state.subData.accountable.unshift({
          ...action.payload,
          id: action.payload.id!,
          staging: false,
        });
        // state.isLoading = false;
        state.error = null;
      })
      // Delete LocationEmployee relation
      .addCase(deleteEntityPrivilege.fulfilled, (state, action) => {
        state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.id);
        // state.isLoading = false;
        state.error = null;
      })
      // Change Relation Role
      .addCase(updateEntityPrivilegeRole.fulfilled, (state, action) => {
        const updatedEntityIndex = state.subData.accountable.findIndex(r => r.id === action.payload.id);
        state.subData.accountable[updatedEntityIndex].role = action.payload.role;
        // state.isLoading = false;
        state.error = null;
      })
      // Delete Bulk Relations
      .addCase(deleteMultipleEntityPrivileges.fulfilled, (state, action) => {
        const relationsToRemove = action.payload.map(r => r.id);
        state.subData.accountable = state.subData.accountable.filter(r => !relationsToRemove.includes(r.id));
        // state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchHistoryLog.fulfilled, (state, action) => {
        state.subData.historyLog = action.payload
          ? action.payload.map(r => ({
              ...r,
              id: nanoid(),
              timeStamp: dayjs(r.timeStamp).format(DATE_FORMAT_WITHOUT_TIME),
            }))
          : [];
        // state.isLoading = false;
        state.error = null;
      })
      .addCase(updateEntityPrivilegeOULvl.fulfilled, (state, action) => {
        updateAccountableEntity(state, action);
      });
  },
});

export const {
  createLocationTemplate,
  updateParentId,
  createNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  deleteNewAccountableEntryTemplate,
  updateNewAccountableEntry,
  clearLocationError,
  resetCurrentPage,
  resetSearchResults,
} = slice.actions;
export default slice.reducer;
