import { Badge } from "antd";
import dayjs from "dayjs";

import { DATE_FORMAT_WITHOUT_TIME } from "../../../../components/HBComponents/DatePicker/HBDatePicker";
import { employeeSelectors } from "../../../../selectors";
import {
  deleteNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  updateNewAccountableEntry,
} from "../../../../store/slices/location";
import {
  addEntityPrivilege,
  updateEntityPrivilegeOULvl,
  updateEntityPrivilegeRole,
} from "../../../../store/slices/privileges";
import { Inspection, InspectionStatus } from "../../../../types/inspection";
import { CellTypes, ColumnWithDifferentCells, InputTypes } from "../../../../types/page";
import { PrivilegeData, PrivilegedEntityType, Role } from "../../../../types/utility";
import { AccountabilityOptions, boolOptions, getCellSearchResultColumns } from "../common";
import { getAccoutableColumnCellType, localizeText, newRenderBoolStatus, renderBoolStatus } from "../utilities";

export const employeesTabColumns: ColumnWithDifferentCells<PrivilegeData>[] = [
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name || "",
    sortable: false,
    filterType: InputTypes.MULTI_SELECT,
    cellType: e => (e.staging ? CellTypes.SEARCH : CellTypes.TEXT),
    cellSearchSelector: employeeSelectors.allLocationRelations,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["name", "externalId"],
    cellSearchRecordClick: fillNewAccountableEntryTemplate,
    optionsSelector: () => null,
    width: 100,
    primaryColumn: e => ({
      navigationTarget: e.userId ? `/employee/${e.userId}` : `/orgUnit/${e.orgUnitId}`,
    }),
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId || "",
    sortable: false,
    filterType: InputTypes.MULTI_SELECT,
    cellType: e => (e.staging ? CellTypes.SEARCH : CellTypes.TEXT),
    cellSearchSelector: employeeSelectors.allLocationRelations,
    cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
    cellSearchKeys: ["externalId"],
    cellSearchRecordClick: fillNewAccountableEntryTemplate,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: value => (value.status ? localizeText(value.status.toString()) : ""),
    sortable: false,
    optionsSelector: () => null,
    width: 50,
  },
  {
    id: "isExternal",
    label: "External",
    renderValue: value => (value.isExternal !== null ? renderBoolStatus(value.isExternal) : ""),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "role",
    label: "Accountability",
    renderValue: e => (e.role ? localizeText(e.role.toString()) : ""),
    cellType: () => CellTypes.DROPDOWN,
    cellDropdownOptions: () =>
      AccountabilityOptions.map(ao => ({
        id: ao.id,
        label: localizeText(ao.label),
      })),
    cellDropdownOnChange: (e, newValue) =>
      e.staging
        ? updateNewAccountableEntry({ entity: e, newValue: newValue, property: "role" })
        : updateEntityPrivilegeRole({
            entity: e,
            role: newValue as Role,
            id: null,
            entityType: PrivilegedEntityType.Location,
          }),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "isAssignedToOUBranch",
    label: "AllLevels",
    renderValue: value => {
      return value.orgUnitId && value.role === Role.Recipient ? newRenderBoolStatus(value.isAssignedToOUBranch) : null;
    },
    cellType: e => getAccoutableColumnCellType(e.role, e.orgUnitId),
    sortable: true,
    cellDropdownOptions: () =>
      boolOptions.map(b => ({
        id: b.id,
        label: localizeText(b.label),
      })),
    cellDropdownOnChange: (entity, newValue) =>
      entity.staging
        ? updateNewAccountableEntry({ entity: entity, newValue: newValue, property: "isAssignedToOUBranch" })
        : updateEntityPrivilegeOULvl({
            entity: entity,
            isAssignedToOUBranch: newValue as boolean,
            entityType: PrivilegedEntityType.Location,
            id: null,
          }),
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "action" as keyof PrivilegeData,
    label: "FieldAction",
    renderValue: () => null,
    sortable: false,
    optionsSelector: () => null,
    cellType: () => CellTypes.BUTTON_ACTIONS,
    width: 100,
    stagingActions: [
      {
        label: "Add",
        className: "hb-primary-button",
        action: entity => addEntityPrivilege({ entity: entity, entityType: PrivilegedEntityType.Location, id: null }),
      },
      {
        label: "Cancel",
        className: "hb-tertiary-button",
        action: entity => deleteNewAccountableEntryTemplate(entity),
      },
    ],
    // buttonWithDropdownActions: {
    //   primaryButton: {
    //     label: "Action Button",
    //     action: entity => console.log("clicked primary button"),
    //   },
    //   dropdownButtons: [
    //     {
    //       label: "Delete Relation",
    //       className: "hb-primary-button",
    //       action: entity => deleteLocationRelation(entity), // TODO: Implement a thunk for deleting a LocationOrgUnit relation which would be based on 'staging'
    //       changesConfirmation: {
    //         body: "Are you sure you wish to remove this entity?",
    //         okText: "Remove",
    //         cancelText: "Keep",
    //         addValueToBody: false,
    //         addValueToOkText: false,
    //       },
    //     },
    //   ],
    // },
  },
];

export const locationInspectionTableColumns: ColumnWithDifferentCells<Inspection>[] = [
  {
    id: "inspectionTypeId",
    label: "FieldType",
    renderValue: value => value.inspectionTypeName,
    sortable: true,
    width: 100,
    optionsSelector: () => null,
    primaryColumn: e => ({
      navigationTarget: `/inspectionLocation/${e.id}`,
    }),
  },
  {
    id: "recurrencyType",
    label: "RecurrencyType",
    renderValue: value => value.recurrencyType,
    sortable: true,
    width: 100,
    optionsSelector: () => null,
  },
  {
    id: "inspectionDate",
    label: "PluginPropertyGroupCheck_LastCheck",
    renderValue: value =>
      dayjs(value.lastCheck).isValid() ? dayjs(value.lastCheck).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: true,
    width: 100,
    optionsSelector: () => null,
  },
  {
    id: "expirationDate",
    label: "FieldExpiration",
    renderValue: value =>
      dayjs(value.expirationDate).isValid() ? dayjs(value.expirationDate).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: false,
    width: 100,
    optionsSelector: () => null,
    valueIcon: value => {
      const expDate = dayjs(value.expirationDate);

      if (!expDate) return null;

      const isValidDate = expDate.isValid();
      const isExpired = expDate.isBefore(dayjs(), "day");

      return isValidDate ? <Badge color={!isExpired ? "green" : "red"} /> : null;
    },
  },
  {
    id: "status",
    label: "FieldStatus",
    width: 50,
    renderValue: entity =>
      entity.status === InspectionStatus.Missing ? localizeText(InspectionStatus.Expired) : localizeText(entity.status),
    sortable: false,
    optionsSelector: () => null,
  },
];
