import { BaseEntityType } from "./entityBase";
import { TaskFile } from "./files";
import {
  AdditionalProps,
  ExplicitAdditionalProps,
  HistoryLog,
  OpenClosedStatus,
  PrivilegeData,
  Role,
  Status,
  TaskPriority,
} from "./utility";

export type IdName = {
  id: number;
  name: string;
};

export type Review = IdName & { projectId: number };
export interface TasksState {
  data: Task[];
  tags: IdName[];
  usersQuickCreateData: UsersQuickCreateData[];
  isLoading: boolean;
  singleData: Task | null | undefined;
  subData: {
    tags: IdName[];
    accountable: PrivilegeData[];
    accountableRole?: Role;
    historyLog: HistoryLog[];
    files: TaskFile[];
    linkedActions: LinkedAction[];
  };
  searchResults: Task[];
  closeInfo?: {
    info: TaskInfo;
    taskId: number;
  };
  error: string | null;
  defaultCustomProperties: ExplicitAdditionalProps[];
  lastUpdated: string;
  paginationInfo: {
    currentPage: number;
    count: number;
    lastPage?: boolean;
  };
}

export type PDFInfo = {
  livePdfGuid?: string; // latest from ReviewHistory if available
  fileUrl?: string; // generated pdf file for this review - should be only one.
};

export type ActionsPaginatedData = {
  data: Task[];
  count: number;
};

export type Task = Record<string, unknown> & {
  id: number;
  title: string;
  responsibleUserId: number | null;
  createdDate: string;
  //diff between paginated list model and regular model
  createDate: string;
  startDate: string;
  dueDate: string;
  closeDate: string;
  responsible: string;
  //diff between paginated list model and regular model
  responsibleUserName: string;
  status: OpenClosedStatus;
  openClosed: OpenClosedStatus;
  createdBy: string;
  //diff between paginated list model and regular model
  createdByUserName: string;
  priority: TaskPriority;
  projectId: number | null;
  //diff between paginated list model and regular model
  projectName: string;
  project: string;
  followUpReviewId: number | null;
  isFormBased: boolean;
  review: string;
  reviewId: number;
  //diff between paginated list model and regular model
  followUpReviewName: string;
  tags: string;
  locationId: number | null;
  description: string;
  customPropertyValues?: AdditionalProps[];
  children?: Location[];
  issueTypeId: number | string | null;
  employeeId: number | null;
  equipmentId: number | null;
  equipmentTypeId: number | null;
  issueTypeName: string;
  employeeName: string;
  equipmentName: string;
  equipmentTypeName: string;
  locationName: string;
  pdfInfo?: PDFInfo;
  parentTaskId: number | null;
  //diff between paginated list model and regular model
  issueType: string;
};

export enum RelationshipType {
  Parent = "Parent",
  Child = "Child",
  Related = "Related",
}

export type LinkedAction = {
  id: number;
  title: string;
  relationship: RelationshipType;
  createDate: string;
  status: OpenClosedStatus;
  pdfInfo?: PDFInfo;
};

export type TasksPage = BaseEntityType & {
  entityData: {
    primaryData: Task;
  };
  subData: {
    files: TaskFile[];
  };
  listViewData: Task;
  tabs: PrivilegeData | HistoryLog | LinkedAction | TaskFile;
};

export type TaskRelation = {
  externalId: string | undefined | null;
  status: Status | null;
  id: number | string;
  name: string;
  displayName?: string;
  userId?: number | string;
  isExternal: boolean | null;
  staging?: boolean; // Is this a 'new' record (still not saved)
  activeId?: number | string; // The ID of the active counterpart of a staged new entity
  isOrgUnit?: boolean;
  isEmployee?: boolean;
  role: Role;
  orgUnitId?: number | null;
};

export type TaskViewer = {
  taskId: number;
  viewerUserId: number;
};

export type TaskCloseModel = {
  TaskId: number;
  IsHandled: boolean;
  ResponsibleChange?: {
    Description?: string;
    NewResponsibleId?: number | null;
    NewResponsibleDisplayName?: string | null;
    ResponsibleChangeReasonType?: ResponsibleChangeReasonType | null;
  };
  Handled?: {
    Description?: string;
    UploadedCloseFile?: string;
    CloseFileOriginalName?: string;
  };
};

export type TaskReopenModel = {
  TaskId: number;
  Description?: string;
};

export enum ResponsibleChangeReasonType {
  NotMyResponsibility = "NotMyResponsibility",
  NotAbleToFix = "NotAbleToFix",
  Other = "Other",
}

export type UsersQuickCreateData = {
  reviewId: number;
  reviewName: string;
  projectId: number | null;
  projectName: string;
};

export type Options = {
  id: number;
  label: string;
};

export type ReviewOptions = Options & { projectId?: number | null };

export type ActionSummary = {
  title: string;
  reviewName: string;
  status: OpenClosedStatus;
  pdfInfo: PDFInfo;
};

export type FollowUpReview = {
  id: number;
  displayName: string;
};

export type TaskInfo = {
  followUpInfo: {
    canBeCompleted: boolean;
    review: FollowUpReview;
    askEdit: boolean;
  };
  appointmentId: number;
  isAnyOpenSubTasks: boolean;
  projectId: number;
};

export type ContextActions = {
  id: number;
  title: string;
  reviewName: string;
  responsibleUserId: number | null;
  responsibleUserName: string;
  status: OpenClosedStatus;
  subTasksTotal: number;
  subTasksCompleted: number;
  pdfInfo: PDFInfo;
};

export type ContextActionsPaginatedData = {
  data: ContextActions[];
  count: number;
};
